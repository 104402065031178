import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-export-pdf',
  templateUrl: './export-pdf.component.html',
  styleUrls: ['./export-pdf.component.scss']
})
export class ExportPdfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
