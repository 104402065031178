import {Injectable} from '@angular/core';
import {Menu} from 'app/shared/models/aplicacao/menu';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MenuDataService {
    private listaMenus$ = new BehaviorSubject<Menu[]>([]);
    moduloComActivo = new BehaviorSubject<boolean>(false);
    constructor() {
    }
    setListaMenus(menus: Menu[]) {
        this.listaMenus$.next(menus);
    }
    getListaMenus(): Observable<Menu[]> {
        return this.listaMenus$.asObservable().pipe(map(ms => {
            ms.forEach(m => m.codMenu = m.codMenu.trim());
            return ms;
        }));
    }
}
