import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Accao} from 'app/shared/models/ENUM';
import {ThemeService} from 'app/shared/services/theme.service';

export interface AdicionarConfig {
    matTooltip: string;
    callback: () => void;
}

@Component({
    selector: 'app-auto-complete',
    templateUrl: './auto-complete.component.html',
    styleUrls: ['./auto-complete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoCompleteComponent implements OnInit, AfterViewInit, OnChanges {
    @Input('lista') lista: any[];
    @Input('listaAux') listaAux: any[];
    @Input('colunas') colunas: any[];
    @Input('chaves') chaves: any[];
    @Input('tamanhoPainel') tamanhoPainel: string;
    @Input('tamanhoColunas') tamanhoColunas: string;
    @Input('mostrarBotaoAdd') mostrarBotaoAdd = false;
    @Input() adicionarConfig!: AdicionarConfig;
    @Input() chaveIndex = 0;
    @Input('setValue') setValue: string = null;
    @Input('checkBox') checkBox = true;
    @Input('placeholder') placeholder = 'nome';
    @Input('placeholderAux') placeholderAux = 'nome';
    @Output('selecionados') selecionados = new EventEmitter();
    @Input('Reset') Reset: boolean;

    @ViewChild('componetKey') componetKey: any;
    @ViewChild('input1') input1: any;
    @ViewChild('t') t: any;
    @ViewChild('icon') icon: any;
    @ViewChild('matform') matform: any;
    @ViewChild('header') header: any;
    @ViewChildren('options') options: QueryList<AutoCompleteComponent>;
    @ViewChildren('d') d: QueryList<AutoCompleteComponent>;
    @Input() _readonly = false;

    @Input() accao = '';
    actions = Accao;
    form: FormGroup;
    todosbol = false;
    items: any[] = [{caracteristicaP: 'teste'}];

    itemsSelecionados: any[] = [];
    stateClose: any;
    letInputfocusInState: any;
    letInputfocusState: any;
    letmatSonStaste: any;
    simaforoTodos = false;
    simaforooptionClick = false;
    mostrarIcon = true;
    OcultarIcon = true;
    OcultarIcon1 = false;
    semaforo = 0;
    visibilidadeIcon = 'hidden';

    constructor(
        private fb: FormBuilder,
        private themeService: ThemeService
    ) {
        this.form = this.fb.group({
            valor: ['']
        });
        this.lista = [];
        this.listaAux = [];
    }


    ngAfterViewInit() {
        this.initOptionsState();
    }


    ngOnChanges(changes: SimpleChanges) {

        if (this.setValue !== null) {
            this.form.get('valor').setValue(this.setValue);
        }

        if (this.Reset) {
            this.itemsSelecionados = [];
            this.placeholder = this.placeholderAux;
            if (this.t) {

                this.t._checked = false;
            }

            this.form.get('valor').setValue('');
            const checkList: any[] = this.d?.toArray();

            checkList?.forEach((check) => {
                check._checked = false;
            });

        }
    }

    config() {
        (this.componetKey._element.nativeElement as HTMLElement).style.position = 'unset !important';

    }

    getValue(obj, key) {
        const keys = key.split('.');
        let value = obj;

        for (const element of keys) {
            value = value ? value[element] : null;
        }

        return value;
    }

    initOptionsState() {

        if (this.checkBox) {
            (this.icon._elementRef.nativeElement as HTMLElement).style.visibility = 'hidden';
        }

        this.header._element.nativeElement.__zone_symbol__clickfalse = [];
        this.letInputfocusInState = this.input1.nativeElement.__zone_symbol__focusinfalse;
        this.letInputfocusState = this.input1.nativeElement.__zone_symbol__focusfalse;
        this.letmatSonStaste = this.matform._elementRef.nativeElement.children[0].children[0].__zone_symbol__clickfalse;
        if (this.checkBox) {

            this.options.forEach((option: any) => {
                option._element.nativeElement.__zone_symbol__clickfalse = false;
            });
        }


    }

    ngOnInit(): void {
    }


    adicionarClassificacao(item: any) {

    }


    async ocultarIcon(se, matform) {

        this.OcultarIcon1 = true;

        if (this.simaforooptionClick) {

            this.input1.nativeElement.__zone_symbol__focusinfalse = [];
            this.input1.nativeElement.__zone_symbol__focusfalse = [];
            matform._elementRef.nativeElement.children[0].children[0].__zone_symbol__clickfalse = [];

            await (se as HTMLElement).click();

            if (this.itemsSelecionados.length === 1) {
                this.placeholder = this.itemsSelecionados[0]['' + this.chaves[this.chaveIndex].valor + ''];
            } else if (this.itemsSelecionados.length > 1) {
                this.placeholder = '(' + this.itemsSelecionados.length + ')  selecionados';
            }

            setTimeout(() => {
                this.mostrarIcon = true;
                this.simaforooptionClick = true;

                if (this.checkBox) {
                    (this.icon?._elementRef.nativeElement as HTMLElement).style.visibility = 'hidden';
                }
                this.input1.nativeElement.__zone_symbol__focusinfalse = this.letInputfocusInState;
                this.input1.nativeElement.__zone_symbol__focusfalse = this.letInputfocusState;
                matform._elementRef.nativeElement.children[0].children[0].__zone_symbol__clickfalse = this.letmatSonStaste;
            }, 100);
        } else {
            setTimeout(async () => {

                if (this.checkBox && await this.OcultarIcon) {
                    (this.icon?._elementRef.nativeElement as HTMLElement).style.visibility = 'hidden';
                }

                this.OcultarIcon = true;
            }, 150);
        }
    }


    filtrarArtigoClassificacao(event: any) {

        const termo: string = event.target.value;
        this.lista = this.listaAux;
        if (termo && termo !== '') {
            this.lista = this.lista.filter(item1 => {

                let bol = false;
                this.chaves.forEach(chave => {
                    bol ||= (item1['' + chave.valor + ''] as string)?.toLowerCase().includes(termo.toLowerCase());
                });
                return bol;
            });
        }

        if (!this.checkBox) {

            this.selecionados.emit(
                {
                    'selecionado': null
                }
            );
        }

        setTimeout(() => {

            const checkList: any[] = this.d.toArray();
            // checkList.forEach( (item: any) => {
            this.itemsSelecionados.forEach((selecionado, i) => {

                this.lista.forEach((item, j) => {
                    if (selecionado['' + this.chaves[0].valor + ''] === item['' + this.chaves[0].valor + '']) {

                        if (checkList[j]) {
                            checkList[j]._checked = true;
                        }
                    }

                });
                // })
            });
            if (this.itemsSelecionados.length === this.listaAux.length) {


                if (this.t) {

                    this.t._checked = true;
                }
            }
        }, 0);

    }

    onClose() {
        let valor = '';
        this.itemsSelecionados.forEach((selecionado, i) => {
            const key = this.chaves[this.chaveIndex].valor;
            const keyParts = key.split('.'); // Split the key by dot

            let value = selecionado;
            for (const part of keyParts) {
                value = value[part]; // Access each nested property
            }
            valor += value;
            if ((i + 1) < this.itemsSelecionados.length) {
                valor += ', ';
            }

            this.form.get('valor').setValue(valor);
        });
    }


    activar() {

        // this.close._element.nativeElement.__zone_symbol__clickfalse = this.stateClose;
        // this.input1._element.nativeElement.focus({ focusVisible: false });
    }

    setIconVisible() {
        this.lista = this.listaAux;
        if (this.checkBox) {

            (this.icon?._elementRef.nativeElement as HTMLElement).style.visibility = 'visible';
        }
    }

    closeList(se, matform, icon) {
        this.OcultarIcon1 = true;
        if (this.checkBox) {
            this.placeholder = this.placeholderAux;
            this.input1.nativeElement.__zone_symbol__focusinfalse = [];
            this.input1.nativeElement.__zone_symbol__focusfalse = [];
            matform._elementRef.nativeElement.children[0].children[0].__zone_symbol__clickfalse = [];
            (se as HTMLElement).click();

            if (this.itemsSelecionados.length === 1) {
                this.placeholder = this.itemsSelecionados[0]['' + this.chaves[0].valor + ''];
            } else if (this.itemsSelecionados.length > 1) {
                this.placeholder = '(' + this.itemsSelecionados.length + ')  selecionados';
            }

            setTimeout(() => {
                this.mostrarIcon = true;
                (icon._elementRef.nativeElement as HTMLElement).style.visibility = 'hidden';
                this.input1.nativeElement.__zone_symbol__focusinfalse = this.letInputfocusInState;
                this.input1.nativeElement.__zone_symbol__focusfalse = this.letInputfocusState;
                matform._elementRef.nativeElement.children[0].children[0].__zone_symbol__clickfalse = this.letmatSonStaste;

            }, 100);
        } else {
            this.input1.nativeElement.__zone_symbol__focusinfalse = [];
            this.input1.nativeElement.__zone_symbol__focusfalse = [];
            matform._elementRef.nativeElement.children[0].children[0].__zone_symbol__clickfalse = [];
            (se as HTMLElement).click();

            setTimeout(() => {
                this.mostrarIcon = true;
                this.input1.nativeElement.__zone_symbol__focusinfalse = this.letInputfocusInState;
                this.input1.nativeElement.__zone_symbol__focusfalse = this.letInputfocusState;
                matform._elementRef.nativeElement.children[0].children[0].__zone_symbol__clickfalse = this.letmatSonStaste;
            }, 100);
        }
    }

    desativarAccaoAoClicarNoHeader(matform) {
        matform._element.nativeElement.__zone_symbol__clickfalse = [];

    }


    private updateText() {
        this.placeholder = this.placeholderAux;

        let valor = '';
        this.itemsSelecionados.forEach((item, i) => {
            valor += item['' + this.chaves[this.chaveIndex].valor + ''];
            if ((i + 1) < this.itemsSelecionados.length) {
                valor += ', ';
            }
        });
        this.form.get('valor').setValue(valor);
        valor;


    }

    async prevDefault(evento: any) {
        setTimeout(async () => {
                // this.close._element.nativeElement.__zone_symbol__clickfalse = false;
            }, 0
        );
    }

    activarSemaforo() {
        this.simaforoTodos = true;
    }


    async checkAll(evento) {
        await this.input1.nativeElement.focus({focusVisible: true});
        (this.icon?._elementRef.nativeElement as HTMLElement).style.visibility = 'visible';
        const checkList: any[] = this.d.toArray();
        this.itemsSelecionados = [];
        if (evento.checked) {
            await checkList.forEach((check) => {
                check._checked = true;
            });

            this.itemsSelecionados = this.listaAux;
            this.selecionados.emit(
                {'selecionados': this.itemsSelecionados}
            );
        } else {
            await checkList.forEach((check) => {
                check._checked = false;
            });
            this.selecionados.emit(
                {'selecionados': this.itemsSelecionados}
            );
        }

        this.updateText();
    }

    async todos(valor: any, todosbol: boolean) {
        this.mostrarIcon = false;
        this.simaforooptionClick = false;
        this.OcultarIcon = false;


        this.updateText();
        this.simaforoTodos = false;
    }

    async selecionar(evento, item, todosOption) {

        if (this.semaforo < 1) {
            if (this.checkBox) {
                if (evento.checked) {
                    await this.itemsSelecionados.push(item);
                } else {

                    this.itemsSelecionados = await this.itemsSelecionados.filter(item1 => {
                        return item1['' + this.chaves[0].valor + ''] !== item['' + this.chaves[0].valor + ''];
                    });
                }

                this.selecionados.emit(
                    {'selecionados': this.itemsSelecionados}
                );

                if (this.itemsSelecionados.length === this.listaAux.length) {
                    todosOption._checked = true;
                } else {
                    todosOption._checked = false;
                }
            } else {
                this.selecionados.emit(
                    {'selecionado': item}
                );
            }
            this.updateText();
        } else {
            this.semaforo = 0;
            evento.source._checked = !evento.source._checked;
        }


        this.semaforo++;


    }

    async defi(matform, idOption?: string) {
        //  console.log('lo2', this.semaforo)

        this.semaforo = 0;
        if (this.checkBox) {
            await this.options.forEach(async (option: any) => {
                option._element.nativeElement.__zone_symbol__clickfalse = false;
            });
        }
        if (this.semaforo === 0) {
            if (idOption) {
                const el: HTMLElement = document.getElementById(idOption) as HTMLElement;
                if (el) {
                    el.click();
                }
            }
        }


    }

    async definirIdModulo(item, todosOption: any, valor?: any, index?: number, se?: any, idOption?: string) {
        // console.log('SEEE 1:::')

        const el: HTMLElement = document.getElementById(idOption) as HTMLElement;
        el.click();
        if (this.checkBox) {
            this.mostrarIcon = false;
            this.simaforooptionClick = false;
            this.OcultarIcon = false;
            await this.input1.nativeElement.focus({focusVisible: true});

            (this.icon?._elementRef.nativeElement as HTMLElement).style.visibility = 'visible';
            this.OcultarIcon = false;

            if (this.checkBox) {
                await this.options.forEach(async (option: any) => {
                    option._element.nativeElement.__zone_symbol__clickfalse = false;
                });
            }

            const checkList: any[] = this.d.toArray();
            let bol: boolean = checkList[index]._checked;
            todosOption._checked = false;
            this.todosbol = false;

            if (await !this.simaforoTodos) {
                checkList[index]._checked = await !valor._results[index]._checked;
                bol = await !checkList[index]._checked;
            }

            if (!bol) {
                let existe = false;
                await this.itemsSelecionados.forEach(selecionado => {
                    if (selecionado[this.chaves[0].valor] === item[this.chaves[0].valor]) {
                        existe = true;
                    }
                });

                if (!existe) {
                    await this.itemsSelecionados.push(item);
                }
                this.selecionados.emit(
                    {'selecionados': this.itemsSelecionados}
                );
            } else if (bol) {
                this.itemsSelecionados = await this.itemsSelecionados.filter(item1 => {
                    return item1['' + this.chaves[0].valor + ''] !== item['' + this.chaves[0].valor + ''];
                });
                this.selecionados.emit(
                    {'selecionados': this.itemsSelecionados}
                );
            }

            if (this.itemsSelecionados.length === this.listaAux.length) {
                todosOption._checked = true;
            }

            this.updateText();
            this.simaforoTodos = false;
        } else {
            // Aqui
            this.form.get('valor').setValue(item[this.chaves[this.chaveIndex].valor]);

            this.selecionados.emit(
                {'selecionado': item}
            );


            (se as HTMLElement).click();
            setTimeout(() => {
            }, 100);
        }

    }

    aoSelecClassificacaoPorAutoComplete(evento: any, se: any) {
        if (!this.checkBox) {
            const item = evento.option.value;
            this.itemsSelecionados = [];
            this.itemsSelecionados.push(item);
            this.updateText();
            this.selecionados.emit(
                {'selecionado': this.itemsSelecionados[0]}
            );
        }
    }

    openPanel(evento) {

    }
}
