import { Injectable, EventEmitter } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DefinirDadosDoFooterService {

  definirDadosDoFooterService: EventEmitter<any> = new EventEmitter();
  constructor() { 

  }

  definirValores(dados: any) {
    this.definirDadosDoFooterService.emit(dados);
  }


}
