import {Component, Input, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'k-card-column',
    templateUrl: './k-card-column.component.html',
    styleUrls: ['./k-card-column.component.scss'],
    // tslint:disable-next-line:use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None
})
export class KCardColumnComponent implements OnInit {
    @Input() template!: TemplateRef<any>;

    constructor() {
    }

    ngOnInit(): void {
    }

}
