import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
    }),
};

@Injectable({
    providedIn: 'root',
})


export class rhService<T> {

    baseUrlRH: string;
    constructor(
        private _http: HttpClient
    ) {
        // inicializar valores
        this.baseUrlRH = `${environment.apiRHumanos}`;
    }

    listar<T>(endpoint: string) {
        return this._http.get<T>(this.baseUrlRH + endpoint);
    }

    obterPeloId<T>(endpoint: string, id: number): Observable<T> {
        return this._http.get<T>(this.baseUrlRH + endpoint + '/' +
        +id);
    }

    adicionar<T>(endpoint: string, objecto: any): Observable<T> {
        return this._http.post<T>(this.baseUrlRH + endpoint, objecto);
    }

    remover<T>(endpoint: string, id: number): Observable<T> {
        return this._http.post<T>(this.baseUrlRH + endpoint + '/' + id,[]);
    }

}
