import { Component, Input, OnInit, ViewChild, ViewChildren,
    SimpleChanges, ChangeDetectionStrategy, OnChanges, Output,
     EventEmitter, AfterViewInit } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Country, CountryCod } from 'app/shared/models/artigos/country-code-json/country-cod';
import { ClienteContactos } from 'app/shared/models/clientes/cliente-contactos';
import { ComponenteTelefonico } from 'app/shared/models/recursos-humanos/Colaboradores/componente-telefonico';
import { AplicacaoService } from 'app/shared/services/aplicacao-service/aplicacao.service';
import { Console } from 'console';
import { Subscription } from 'rxjs';

const 	CONTACTOS_INICIAIS: any[] = [
  { idTipoContacto: 1},
  { idTipoContacto: 1},
  { idTipoContacto: 2},
  { idTipoContacto: 2},
  { idTipoContacto: 3},
  { idTipoContacto: 3},
];
@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CountryCod]
})
export class ContactoComponent implements OnInit, AfterViewInit {

  public formContacto: FormGroup;
  public contactos: FormArray;
  protected mostrarDelete: number;
  protected listaTiposContactos;
  protected listaContriesFlag: any[];
  protected ContactosRemover: number[];
  protected initVectorComponenteTelefonico: ComponenteTelefonico[];
  // protected idsRentecoesClientes: number[];
  protected selectedRows: any[];
  protected isoCountryCode: Country;
  protected indicativoActual: string;
  protected telInvalido: boolean;
  protected selected: any[];
  protected selTel: Country;
  protected strSelect1: string;
  protected strSelect2: string;
  protected countryCode: string;
  protected focusAll: boolean = false;
  protected overAll: boolean = false;
  protected valido: boolean = true;
  protected accao: string;
  protected subscriptions: Subscription[];
  @Input('listaDeContactos') listaDeContactos: ClienteContactos[];
  @Input('obterListasContacto') obterListasContacto:boolean = false;
  @Input('idCliente') idCliente:number = 0;
  @Input('actualizarListasContacto') actualizarListasContacto:boolean = false;
  @Output('obterLista') obterLista: any = new EventEmitter();


  constructor(
    private fb: FormBuilder,
    private countryCod: CountryCod,
    private aplicacao: AplicacaoService
  ) {

    this.subscriptions = [];
    this.listaTiposContactos = [];
    this.listaDeContactos = [];
    this.listaContriesFlag = this.countryCod.getContryObject();
    this.ContactosRemover = [];
    this.selectedRows = [];
    this.isoCountryCode = null;
    this.indicativoActual = '';
    this.telInvalido = true;
    this.selected = [];
    this.selTel = null;
    this.accao= 'C';
    this.contactos = this.fb.array([]);
    this.initStrSelect();
    this.formContacto = this.fb.group({
      contactos: this.contactos
    });

  }

  ngOnInit(): void {
    const formGroup: FormGroup = this.initFormContacto(null);
    // Back
    if ( !(this.actualizarListasContacto && this.listaDeContactos.length > 0) ){
      this.contactos.push(formGroup);
      // CONTACTOS_INICIAIS.forEach(tipoContacto => this.contactos.push(this.initFormContacto({ idTipoContacto: tipoContacto.idTipoContacto})));
      // this.contactos.controls.forEach((control, index) => {
      //   control.setValidators(null);
      //   control.updateValueAndValidity();
      //   control.markAsTouched();
      // });
    }

    this.subscriptions.push(
      this.aplicacao.getTiposContactos()
          .subscribe(tiposContacto => {
              this.listaTiposContactos = tiposContacto;
          })
     );
  }

  ngAfterViewInit(): void {

  }

  validarContacto( index: number, chaves: string[]): boolean {
    chaves.forEach( (chave) => {
      let control: FormControl = this.contactos['controls'][index].get(chave) as FormControl;
      control?.setErrors(null);
      switch ( chave ){
        case 'idTipoContacto' : {

          if (control.value < 1) {
            control.setErrors({invalido: true});
            this.valido = false;
          }
          break;
        }
        case 'selectedCountryCode' : {
          const idTipoContacto: number = this.contactos['controls'][index].get('idTipoContacto').value;
          if ( idTipoContacto > 1 && idTipoContacto<5){

            if (!control.value){
              control.setErrors({invalido: true});
              this.contactos['controls'][index].get('contacto').setErrors({invalido: true});
              this.contactos['controls'][index].get('contacto').markAsTouched();
              this.valido = false;
            }
          }
          break;
        }
        case 'contacto' : {

            const idTipoContacto: number = this.contactos['controls'][index].get('idTipoContacto').value;
            if ( idTipoContacto > 1 && idTipoContacto<5){
                let country: Country[] = this.listaContriesFlag.filter((country: Country) => {
                  return country.dialCode === (
                    control.value as string).replace(/\s/g, '');
                });

                if (!(country.length === 0 && this.contactos['controls'][index].get('selectedCountryCode').value && control.value)){
                  control.setErrors({invalido: true});
                  this.valido = false;
                }
            } else if (idTipoContacto === 1){
              control.addValidators([Validators.pattern(/^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
              control.updateValueAndValidity();
              if (control.invalid){
                this.valido = false;
              }
            }else {
              control.addValidators([Validators.required]);
              control.updateValueAndValidity();

              if (control.invalid){
                this.valido = false;
              }
            }
            break;
          }

      }
      control?.markAsTouched();
    });
     return this.valido;
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.obterListasContacto) {
      // Emitir O evento ao pai
      let valido: boolean = false;
      let contactos: any[] = this.contactos.value;
      this.valido = true;

      contactos = contactos.filter((contacto, i) => {

        if (i=== 0) return false;

        const Keys = ['idTipoContacto','selectedCountryCode', 'contacto'];
        valido= this.validarContacto(i, Keys);

        return true;
      });


      this.obterLista.emit({
        valido: contactos.length=== 0? true: valido,
        contactos: contactos,
        ContactosRemover: this.ContactosRemover
      });
    }

    if (this.actualizarListasContacto && this.listaDeContactos.length > 0) {
      this.contactos.clear();
      this.ContactosRemover = [];
      this.listaDeContactos.forEach( (contacto, i) => {
        const formGroupContacto: FormGroup = this.initFormContacto(contacto);
        this.contactos.insert(0, formGroupContacto);
      });
      const formGroupContactoPreenchimento: FormGroup = this.initFormContacto(null);
      this.contactos.insert(0, formGroupContactoPreenchimento);
      
      setTimeout ( () => {
      let elementosNativosRef = (this.contactosRef as any)._results as any[];

      (elementosNativosRef)?.forEach ( nativoRef => {
        let id = nativoRef?._id;
        if ( id ) {
          const lista = (id as string).split('-')
          const index = lista[lista.length-1];
              let native: HTMLElement = document.getElementById(id);
              let country: Country = (this.contactos['controls'][index].get('selectedCountryCode')).value;

              //Ver se uso outro prara inicializar
               this.changeCountryCode(id, country, parseInt(index));
              }
            })
          }, 500);
    }


     
  }

  // waiting a HTML Element ...
   waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}


initStrSelect() {
  this.strSelect1 = '<div class="mat-select-value ng-tns-c143-30" ng-reflect-ng-switch="false" id="mat-select-value-17"><span class="mat-select-value-text ng-tns-c143-30 ng-star-inserted" ng-reflect-ng-switch="false" style=""><span class="mat-select-min-line ng-tns-c143-30 ng-star-inserted">'
  this.strSelect2 = '</span></div><div class="mat-select-arrow-wrapper ng-tns-c143-30"><div class="mat-select-arrow ng-tns-c143-30"></div></div>'
  // this.strSelect1 = '<div class=\"mat-select-value ng-tns-c143-30\" ng-reflect-ng-switch=\"false\" id=\"mat-select-value-17\"><!--bindings={\n  \"ng-reflect-ng-switch-case\": \"true\"\n}--><span class=\"mat-select-value-text ng-tns-c143-30 ng-star-inserted\" ng-reflect-ng-switch=\"false\" style=\"\"><span class=\"mat-select-min-line ng-tns-c143-30 ng-star-inserted\">'
  // this.strSelect2 = '</span><!--container--><!--bindings={\n  \"ng-reflect-ng-switch-case\": \"true\"\n}--></span><!--bindings={\n  \"ng-reflect-ng-switch-case\": \"false\"\n}--></div><div class=\"mat-select-arrow-wrapper ng-tns-c143-30\"><div class=\"mat-select-arrow ng-tns-c143-30\"></div></div>'
}


  initFormContacto(contacto: ClienteContactos| null  ): FormGroup {
      let res = null;
      let countryCod = null;
      if ( contacto?.idTipoContacto > 1 &&  contacto?.idTipoContacto < 5){
        res = contacto && contacto.contacto ? this.encontrarCodIso(contacto.contacto): null;
        countryCod = res && res.country? res.country: null;
      }
      return this.fb.group({
      idContacto:  contacto ? contacto.idContacto:  0,
      idCliente:  this.idCliente ,
      contacto: contacto ? contacto.contacto : [''],
      caracterValido:  [false],
      valorAux: [countryCod ? countryCod : ''],
      selectedCountryCode: countryCod? countryCod : [],
      idTipoContacto: contacto ? contacto.idTipoContacto : [],
      tipoContacto: contacto ? contacto.tipoContacto : [],
      activo: contacto ? contacto.activo : [true],
      default: contacto ? contacto.default : [false],

    })
  }


  mostrarDeleteIcon(indexContaco: number) {
    if (this.mostrarDelete !== indexContaco) {

        this.mostrarDelete = indexContaco;
    }
}

  ocultarDeleteIcon() {
    this.mostrarDelete = -1;
  }

  trocarTipoInput(value: number, indextipoForm: number, indexContaco: number, formControl: FormControl,
    idContactoForm: string) {

    formControl.get('contacto').setValue('');
    this.adicionarNovoContactoForm(idContactoForm, indexContaco, value, 1);
    switch (value) {
      case 1: {
        formControl.get('tipoContacto').setValue('E-mail');

        break;
        }
        case 2: {
        formControl.get('tipoContacto').setValue('Telemóvel');
        break;
        }
        case 3: {
        formControl.get('tipoContacto').setValue('Telefone');
        break;
        }
        case 4: {
        formControl.get('tipoContacto').setValue('Fax');
        break;
        }
        case 5: {
        formControl.get('tipoContacto').setValue('Skype');
        break;
      }
    }


  }

  adicionarNovoContactoForm(idContactoForm: string, indexContaco: number, value: number, from: number) {
    const listaContactos: FormArray = this.contactos;
    const posicao = indexContaco;

    if (posicao === 0 && listaContactos.controls[indexContaco].get('idTipoContacto').value > 0
        && (listaContactos.controls[indexContaco] && listaContactos.controls[indexContaco]?.get('contacto')?.value !== ''
            || (listaContactos.controls[indexContaco].get('contacto') && listaContactos.controls[indexContaco].get('contacto').value !== ''))) {

        this.novoContacto(null).then(
            (form) => {
                listaContactos.insert(0, form);
            }
        ).catch(
            (error) => {

            }
        );

    } else {

        if ( value > 1 &&  value < 5) {

          if (listaContactos.controls[indexContaco]
              && listaContactos.controls[indexContaco]?.get('selectedCountryCode')?.value === '') {
              setTimeout(
                  () => {
              this.initContacto('tel-select-component-'+indexContaco, indexContaco, '');

              // this.initContacto(idContactoForm, indexContaco);
            }, 0);
          } else if (posicao === 0) {
            setTimeout(
              () => {
                this.initContacto('tel-select-component-'+indexContaco, indexContaco, '');
                    // this.initContacto(idContactoForm, indexContaco);

                  }, 0);
          }
          if (from === 1) {
            setTimeout(
              () => {

                this.initContacto('tel-select-component-'+indexContaco, indexContaco, '');
                      // this.initContacto(idContactoForm, indexContaco);
                }, 0);
          }
        }

    }
  }

  async novoContacto(contacto, index?: number): Promise<any> {
    let tipo = null;
    let isoCountryCode: Country = null;

    if (contacto && contacto?.idTipoContacto) {
        tipo = await this.listaTiposContactos?.filter(tipo => {
            return tipo.id === contacto?.idTipoContacto;
        })[0].tipoContacto;
    }

    let contactoAux: string = contacto ? contacto.contacto : '';

    if (tipo === 'Telefone' || tipo === 'Telemóvel') {

        // Se for Telefone Tratar aqui com os novos métodos ...


    }

    return this.initFormContacto(null);
  }

  async aoEscrever(formGroup: FormGroup, idComponent: string, i) {
    await this.adicionarNovoContactoForm(idComponent, i, 3, 2);
    const control: FormControl = formGroup.get('contacto') as FormControl;

    control.setValidators(null);
    control.updateValueAndValidity();
    control.markAsTouched();
    // this.validarcontacto(control, 'E-mail');
  }

//   async validarcontacto(formControl: FormControl, tipoContacto: string) {
//     await formControl.clearValidators();

//     switch (tipoContacto) {
//         case 'E-mail': {
//             formControl.addValidators(
//                 Validators.pattern(/^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
//             );
//             break;
//         }
//             ;

//         case 'Skype': {
//             formControl.addValidators(
//                 Validators.required
//             );
//             break;
//         }
//             ;

//         case 'Fax': {
//             formControl.addValidators(
//                 [Validators.pattern('[1-9][0-9]+'),
//                     Validators.minLength(4)]
//             );
//             break;
//         }
//             ;

//         default: {
//             formControl.addValidators(
//                 Validators.pattern('[+]([1-9]([0-9]|[0-9]{0}){3})[ ][0-9]{4}([0-9]|[0-9]{0}){7}')
//             );
//             break;
//         }
//     }

//     formControl.markAsTouched();
// }

removerContacto( contacto: FormGroup, index: number) {

  const idContacto = contacto.value.idContacto
  if ( idContacto > 0 ) {
    if (this.ContactosRemover.indexOf(idContacto) < 0){
      this.ContactosRemover.push(idContacto);
    }

  }
    this.contactos.removeAt(index);

  }


changeCountryCode(idInPut: string, country: Country, index: number) {
    console.log(idInPut, country, index);
  this.isoCountryCode = null;

  let fg = this.contactos['controls'][index];

  let native: HTMLElement =  document
      .getElementById(idInPut) ?
      document.getElementById(idInPut)
      .children[0] as HTMLElement: null;

  let valor: string[] = [];
  let contacto: string = '';

  if ((fg.get('contacto').value.split(' ') as []).length > 1) {
      valor = fg
          .get('contacto')
          .value.split(' ');
  } else {
      contacto = (fg
          .get('contacto')
          .value as string).replace(this.indicativoActual, '');
  }

  let valorAux = ''
  for (let i = 1; i < valor.length; i++) {

      valorAux += ' ' + valor[i]
  }

  if (native && country) {
 

      native.innerHTML = this.strSelect1 + country.flag + this.strSelect2;
   
  }

  let countryCode: string = '';

  this.indicativoActual = country.dialCode;
  if (valor.length > 1) {
      countryCode = valor.length > 1 ? country.dialCode + valorAux :
          country.dialCode + ' ';
  } else {

      const Tel = this.encontrarCodIso(contacto);
      if (Tel === null || Tel === undefined) {

          countryCode = country.dialCode + contacto;
      } else {
          countryCode = contacto.replace(Tel.country.dialCode, country.dialCode);

      }
  }


  fg
      .get('contacto').setValue(countryCode);

  fg
      .get('valorAux').setValue(this.countryCode);
}


encontrarCodIso(telefone: string): { telefone: string, country: Country } | null {
  if (telefone && telefone !== '') {
      let auxTel: string = telefone;
      if ((telefone as string)
          .startsWith('00')) {
          auxTel = telefone.replace('00', '+');

      }
      let listaContriesFlagAux: Country[] = this.countryCod.getContryObject();

      listaContriesFlagAux.sort((a, b) => {
          return parseInt(b.dialCode) - parseInt(a.dialCode);
      });

      for (let i = 0; i < listaContriesFlagAux.length; i++) {
          const codIso = listaContriesFlagAux[i].dialCode;
          if (auxTel.startsWith(codIso)) {
              return { telefone: telefone, country: listaContriesFlagAux[i] };
          }
      }
  } else {
      return null;
  }
}

definirNoComponenteNativo(result: { telefone: string, country: Country } | null, native: HTMLElement,
  fg: FormGroup, telefone?: string) {
  if (result) {
      this.indicativoActual = result.country.dialCode;
      native.innerHTML = this.strSelect1 + result?.country?.flag + this.strSelect2;
      fg.get('selectedCountryCode').setValue(result?.country);
      fg.get('contacto').setValue(result.telefone && this.accao !== 'C' ? result.telefone : result.country.dialCode + ' ');
      fg?.get('valorAux').setValue(result.telefone && this.accao !== 'C' ? result.telefone : result.country.dialCode + ' ');
  } else if (telefone) {
      fg.get('selectedCountryCode').setValue(null);
      fg.get('contacto').setValue(telefone);
      fg?.get('valorAux').setValue(telefone);
  } else {
      fg.get('selectedCountryCode').setValue(null);
      fg.get('contacto').setValue('');
      fg?.get('valorAux').setValue('');
  }
}



isbackSpaceKeyBoard(evento , index: number) {
  let keyCode: number = evento.keyCode;
  let fg = this.contactos['controls'][index];;

  if (keyCode === 8) {
      fg.get('caracterValido').setValue(true);
  }

}
reconhecerCaracter(evento: any, index: number) {

  let charCode: number = evento.charCode;
  const str: string = evento.target.value;

  let fg = this.contactos['controls'][index];


  if ((
      (charCode >= 48 && charCode <= 57)
      || charCode === 43
      || charCode === 32 || charCode === 8)) {
      fg.get('caracterValido').setValue(true);
  } else {
      fg.get('caracterValido').setValue(false);

  }

}


validarTel(idInPut: string, value: any, index: number, contacto: FormGroup) {
  let bool: boolean = true;
  if (value.length > 1) {
      if ((value as string)
          .substring(1, (value as string).length)
          .includes('+')) {
          bool = false;
      }
  }

  let contactoValor = contacto.value;
  if (index === 0 && contactoValor.contacto!=='' && contactoValor.idTipoContacto > 0){

     this.contactos.insert(0, this.initFormContacto(null));
  }

  let fg =  this.contactos['controls'][index];
  let valido: boolean = fg.get('caracterValido').value && bool;

  if (!valido) {
      fg?.get('contacto').setValue(
          fg?.get('valorAux').value
      );
      return
  }

  fg?.get('contacto').setErrors(null);
  // this.aoEscrever(formControl, idInPut, index);
  const regExp = RegExp('(([+]|[00])([1-9]([0-9]|[0-9]{0}){3})[ ]([0-9]|[0-9]{0}){7})|(([+]|[00])([1-9]([0-9]|[0-9]{0}){3})([0-9]|[0-9]{0}){7})');

  // const regExp = RegExp('(([+]|[00])([1-9]([0-9]|[0-9]{0}){3})[ ][0-9]{4}([0-9]|[0-9]{0}){7})|(([+]|[00])([1-9]([0-9]|[0-9]{0}){3})[0-9]{4}([0-9]|[0-9]{0}){7})');

  if (regExp.test(value)) {
      this.telInvalido = false;
  } else {
      this.telInvalido = true;
  }

  fg?.get('valorAux').setValue(
      fg?.get('contacto').value
  );

  let native: HTMLElement = document.getElementById(idInPut)?  document.getElementById(idInPut)?.children[0] as HTMLElement: null;

  //Usar os primeiros 5 caracteres, para cada uns dos 5... buscar o indicativo.
  let codISO: string;
  if (value.split(' ').length > 1) {
      if ((value.split(' ')[0] as string).startsWith('00')) {
          codISO = (value.split(' ') as string)[0].replace('00', '+');
      } else {

          codISO = value.split(' ')[0];
      }
  } else {
      if ((value.split(' ')[0] as string).startsWith('00')) {
          value = (value.split(' ') as string)[0].replace('00', '+');
      }
      const valor: string = value;
      const sub04: string = valor && valor.length > 4 ? valor.substring(0, 5) : null;
      const sub03: string = valor && valor.length > 3 ? valor.substring(0, 4) : null;
      const sub02: string = valor && valor.length > 2 ? valor.substring(0, 3) : null;
      const sub01: string = valor && valor.length > 1 ? valor.substring(0, 2) : null;

      let listaContriesFlagAux: Country[] = this.countryCod.getContryObject();

      listaContriesFlagAux.sort((a, b) => {
          return parseInt(b.dialCode) - parseInt(a.dialCode);
      });

      for (let i = 0; i < listaContriesFlagAux.length; i++) {

          if (sub04) {
              //Condição para quebrar ... Se cumprir quebrar ...
              if (listaContriesFlagAux[i].dialCode === sub04) {
                  codISO = listaContriesFlagAux[i].dialCode;
                  break;
              }
          }

          if (sub03) {
              if (listaContriesFlagAux[i].dialCode === sub03) {
                  codISO = listaContriesFlagAux[i].dialCode;
                  break;
              }
          }

          if (sub02) {
              if (listaContriesFlagAux[i].dialCode === sub02) {
                  codISO = listaContriesFlagAux[i].dialCode;
                  break;
              }
          }

          if (sub01) {
              if (listaContriesFlagAux[i].dialCode === sub01) {
                  codISO = listaContriesFlagAux[i].dialCode;
                  break;
              }
          }

      }
  }

  let control: FormControl = fg
      .get('contacto') as FormControl;

  let country: Country[] = this.listaContriesFlag.filter((country: Country) => {
      return country.dialCode === codISO
  });

  if (country.length > 0) {
      this.indicativoActual = codISO;
      // this.count
      fg?.get('selectedCountryCode')
          .setValue(country[0]);

      if(native){
        native.innerHTML = this.strSelect1 + country[0].flag + this.strSelect2;
      }
  } else {
      this.indicativoActual = '';

      if(native){
        native.innerHTML = this.strSelect1 + '' + this.strSelect2;
      }
      fg?.get('selectedCountryCode').setValue(null);
  }




}



@ViewChildren('matSelect1') contactosRef: any[] = [];
initContacto(idComponent: string, index: number,telefone?: string) {

    let fg = this.contactos['controls'][index] as FormGroup;
    let native: HTMLElement = document.getElementById(idComponent) && document.getElementById(idComponent).children[0] ? document.getElementById(idComponent).children[0] as HTMLElement: null;
    if (document.getElementById(idComponent) && native) {


      if (this.accao === 'C') {
        const country: Country =  this.listaContriesFlag.filter((country: Country) => {
          return country.flag.toLowerCase() === localStorage
                    .getItem('__cod__Pais')
                    .replace(/\s/g, '').toLowerCase()
            })[0];

            this.definirNoComponenteNativo({ telefone: telefone, country: country }, native, fg, telefone);
        } else if (this.accao === 'D' || this.accao === 'E') {

            const result: { telefone: string, country: Country } = this.encontrarCodIso(telefone);

            if (result) {
                this.definirNoComponenteNativo(result, native, fg);

            } else {
                this.definirNoComponenteNativo(result, native, fg, telefone);
            }
        }
    }
}

}
