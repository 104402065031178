import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'codTitulo',
  pure: true
}) export class CodTituloPipe implements PipeTransform {
  public transform(valor: any, titulo: string): any {
    if (valor) {

    }
    return '';
  }
}
