<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<ngx-datatable
        class="material expandable fullscreen"
        #table
        columnMode="force"
        [rows]="filteredArquivos"
        [headerHeight]="40"
        [footerHeight]="40"
        [messages]="messages"
        [limit]="5"
        style="height: calc(100vh - 350px)"
        [rowHeight]="50"
        [scrollbarV]="true"
        (sort)="sort($event)"
>
    <ngx-datatable-row-detail rowHeight="500">
        <ng-template let-row="row" ngx-datatable-row-detail-template>
            <div class="p-1" style="padding-left:35px;">
                <div fxLayout="row wrap">
                    <div fxFlex="100" fxFlex.xl="100">
                        <mat-card class="m-0">
                            <div fxLayout="row wrap">
                                <div *ngFor="let anexo of row.anexos; let i = index" class="p-1 cursor-pointer"
                                     fxFlex="13"
                                     fxFlex.xl="13"
                                     fxFlex.lg="13" fxFlex.md="30">
                                    <div class="equi-card-ficheiro">
                                        <div class="bg-dark-gray">
                                            <div class="equi-card-ficheiro-opcoes">
                                               <span class="menu-opcoes">
                                                     <button type="button" class="bg-lighter-gray text-white"
                                                             mat-icon-button
                                                             [matMenuTriggerFor]="menu">
                                                        <mat-icon class="text-white">more_vert</mat-icon>
                                                     </button>
                                                    <mat-menu #menu="matMenu">
                                                        <button [disabled]="action === actions.detalhes"
                                                                (click)="removerFicheiro(row, anexo)" type="button"
                                                                mat-menu-item>
                                                            <span>Remover</span>
                                                        </button>
                                                        <button type="button" mat-menu-item
                                                                (click)="download(row, anexo)">
                                                            <span>Download</span>
                                                        </button>
                                                    </mat-menu>
                                                  </span>
                                            </div>
                                            <div class="equi-card-ficheiro-img" style="height: 150px;">
                                                <img [src]="anexo.conteudo | tipoFicheiro | safe: 'resourceUrl'"
                                                     class="full-width" [alt]="">
                                            </div>
                                        </div>

                                        <div class="p-1">
                                            {{ anexo?.nome }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>

                    </div>

                </div>
            </div>
        </ng-template>
    </ngx-datatable-row-detail>

    <ngx-datatable-column [sortable]="false" [resizeable]="false"
                          [draggable]="false"
                          [canAutoResize]="false" [width]="50">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <div>
              <span class="btn-table-expandir"
                    [class.datatable-icon-right]="!expanded"
                    [class.datatable-icon-down]="expanded"
                    [class.bg-primary]="expanded"
                    title="Expandir/Colapsar"
                    (click)="table.rowDetail.toggleExpandRow(row)"
              ></span>
            </div>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
            [width]="200"
            [resizeable]="true"
            [draggable]="false"
            [canAutoResize]="false"
            [sortable]="true" prop="nome"
            name="Nome"
    >
        <ng-template let-row="row" ngx-datatable-cell-template>
            <a *ngIf="action !== actions.cadastrar" class="hover-1 py-1 flex flex-y-center"
               [routerLink]="['/gestao-arquivos/arquivos', action, row.id]">{{ row.nome }}</a>
            <span *ngIf="action === actions.cadastrar" class="cursor-pointer">{{ row.nome }}</span>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column *ngFor="let campo of tipo.campos" [name]="campo.nome" [flexGrow]="2">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <ng-container [ngSwitch]="campo.codTipo">
                <span *ngSwitchCase="types.TEXT">{{ (row.campos | filterFn: byCampo: campo)[0]?.valor }}</span>
                <span *ngSwitchCase="types.INTEIRO">{{ (row.campos | filterFn: byCampo: campo)[0]?.valor }}</span>
                <span *ngSwitchCase="types.DATE">{{ (row.campos | filterFn: byCampo: campo)[0]?.valor | parseValue: campo.codTipo | date:'dd/MM/yyyy' }}</span>
                <span *ngSwitchCase="types.DECIMAL">{{ (row.campos | filterFn: byCampo: campo)[0]?.valor | currency: '':'' }}</span>
                <span *ngSwitchCase="types.BOOL">{{ (row.campos | filterFn: byCampo: campo)[0]?.valor ? 'Sim' : 'Não' }}</span>
            </ng-container>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Data Inserção" [sortable]="true" prop="dataCriacao" [width]="80">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span>{{ row.dataCriacao | date: 'dd/MM/yyyy HH:mm:ss' }}</span>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Nº Anexos" [width]="40">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span>{{ row.anexos.length }}</span>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column *ngIf="action !== actions.detalhes" [frozenRight]="true" [sortable]="true"
                          [width]="50">
        <ng-template let-index="rowIndex" let-row="row" ngx-datatable-cell-template>
            <div class="bg-white w-100">
                <button mat-icon-button [matMenuTriggerFor]="menu" type="button" aria-label="Opções"
                        style="transform: translateY(1px)">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="remover(row, index)"
                            type="button">
                        <span>Remover</span>
                    </button>
                    <button [disabled]="action === actions.detalhes"
                            (click)="editar(row)" type="button"
                            mat-menu-item>
                        <span>Editar</span>
                    </button>
                </mat-menu>
            </div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
