import {Component, OnInit, Input, Renderer2, EventEmitter, Output} from '@angular/core';
import { NavigationService } from '../../../shared/services/navigation.service';
import { LayoutService } from '../../../shared/services/layout.service';
import PerfectScrollbar from 'perfect-scrollbar';
import { CustomizerService } from 'app/shared/services/customizer.service';
import { ThemeService, ITheme } from 'app/shared/services/theme.service';
import {UtilizadoresService} from '../../services/aplicacao-service/utilizadores.service';
import {LoginService} from '../../services/aplicacao-service/login.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-customizer',
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss'],
})
export class CustomizerComponent implements OnInit {
  @Input() isCustomizerOpen = false;
  @Output() fechar: EventEmitter<any> =  new EventEmitter();
  viewMode: 'options' | 'json' = 'options';
  sidenavTypes = [
    {
      name: 'Default Menu',
      value: 'default-menu',
    },
    {
      name: 'Separator Menu',
      value: 'separator-menu',
    },
    {
      name: 'Icon Menu',
      value: 'icon-menu',
    },
  ];
  sidebarColors: any[];
  topbarColors: any[];

  layoutConf;
  selectedMenu = 'icon-menu';
  selectedLayout: string;
  isTopbarFixed = false;
  isFooterFixed = false;
  isRTL = false;
  egretThemes: ITheme[];
  perfectScrollbarEnabled = true;

  constructor(
    private navService: NavigationService,
    public layout: LayoutService,
    private themeService: ThemeService,
    public customizer: CustomizerService,
    private renderer: Renderer2,
    private snackBar: MatSnackBar,
    private utilizadorService: UtilizadoresService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    this.selectedLayout = this.layoutConf.navigationPos;
    this.isTopbarFixed = this.layoutConf.topbarFixed;
    this.isRTL = this.layoutConf.dir === 'rtl';
    this.egretThemes = this.themeService.egretThemes;
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
    this.layout.publishLayoutChange({ matTheme: theme.name });
  }
  changeLayoutStyle(data) {
    this.layout.publishLayoutChange({ navigationPos: this.selectedLayout });
  }
  changeSidenav(data) {
    this.navService.publishNavigationChange(data.value);
  }
  toggleBreadcrumb(data) {
    this.layout.publishLayoutChange({ useBreadcrumb: data.checked });
  }
  toggleTopbarFixed(data) {
    this.layout.publishLayoutChange({ topbarFixed: data.checked });
  }
  toggleDir(data) {
    let dir = data.checked ? 'rtl' : 'ltr';
    this.layout.publishLayoutChange({ dir: dir });
  }
  tooglePerfectScrollbar(data) {
    this.layout.publishLayoutChange({ perfectScrollbar: this.perfectScrollbarEnabled });
  }


  fecharCustomizer() {
    if (this.layoutConf) {
      const novoLayout = JSON.stringify(this.layoutConf);
      const modelo = {
        IdUtilizador: this.loginService.decodedToken?.nameid,
        Layout: novoLayout
      };

      this.utilizadorService.editarLayoutUtilizador(modelo).subscribe(res => {
      }, (error) => {
        this.getMensagemErro(error.error);
        console.log('Falha ao editar layout', error.error.mensagem);
      });
    }
    this.fechar.emit(false);
  }

  getMensagem(mensagem: string) {
    this.snackBar.open(mensagem, '✔️', {
      verticalPosition: 'top',
      duration: 3000,
    });
  }
  getMensagemErro(mensagem: string) {
    this.snackBar.open(mensagem, '♦', {
      verticalPosition: 'top',
      duration: 5000,
    });
  }

  reporDefault() {
    if (localStorage.getItem('_userTheme')) {
      localStorage.removeItem('_userTheme');
      window.location.reload();
    }
  }
}
