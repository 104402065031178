import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterById',
})
export class FilterByIdPipe implements PipeTransform {
    transform(items: any[], id: number): any[] {
        if (!items) {
            return [];
        }
        if (!id) {
            return items;
        }
        return items.filter(item => item.id !== id);
    }
}
