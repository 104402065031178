import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iconFicheiro'
})
export class IconFicheiroPipe implements PipeTransform {

  transform(valor: any, ...args: unknown[]): any {
    let extensaoFicheiro = '';
    let partesLinkFicheiro;
    if (valor) {
      partesLinkFicheiro = valor.nome.split('.');

      if (partesLinkFicheiro?.length > 0) {
        extensaoFicheiro = partesLinkFicheiro[partesLinkFicheiro.length - 1];
        console.log('Extensao ', extensaoFicheiro)
      }

      switch (extensaoFicheiro) {
        case 'pdf': return  'assets/images/icons/pdf-icon.svg';
        case 'msword': return  'assets/images/file-types/005-documents.svg';
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document': return  'assets/images/icons/word-icon.svg';
          // Ficheiro Excel
        case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet': return  'assets/images/icons/xcel-icon.svg';
          // Ficheiro PowerPoint
        case 'vnd.openxmlformats-officedocument.presentationml.presentation': return  'assets/images/icons/powerpoint-icon.svg';
        case 'jpeg': return valor.link;
        case 'jpg': return valor.link;
        case 'png': return valor.link;
        case 'svg': return valor.link;
        case 'webp': return valor.link;
        default: return 'assets/images/icons/default_ficheiro.svg';
      }

    }
  }
}
