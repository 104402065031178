import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RelativeTimePipe} from './relative-time.pipe';
import {ExcerptPipe} from './excerpt.pipe';
import {GetValueByKeyPipe} from './get-value-by-key.pipe';
import {SafePipe} from './safe.pipe';
import {StringFloatPipe} from './string-float.pipe';
import {FormatarOrdinalPipe} from './formatar-ordinal.pipe';
import {TipoFicheiroPipe} from './tipo-ficheiro.pipe';
import {InserirQuebraLinhaPipe} from './inserir-quebra-linha.pipe';
import {PrintWithBrPipe} from './print-with-br.pipe';
import {LabelProvinciaPipe} from './label-provincia.pipe';
import {LabelMunicipioPipe} from './label-municipio.pipe';
import {StrNormalCasePipe} from './str-normal-case.pipe';
import {LinkYoutubePipe} from './link-youtube.pipe';
import {TamanhoFicheiroPipe} from './tamanhoFicheiro.pipe';
import {IconFicheiroPipe} from './icon-ficheiro.pipe';
import {SetBgFicheiroPipe} from './set-bg-ficheiro.pipe';
import {DataReversePtPipe} from './data-reverse-pt.pipe';
import {FilterByIdPipe} from './filter-by-id.pipe';
import {FormatarQuantidadePipe} from './formatar-quantidade.pipe';
import {CalculoDeVagaPipe} from './calculo-vagas-disponivel/calculo-de-vaga.pipe';
import {GetMenuPipe} from './get-menu/get-menu.pipe';
import {MostrarCodEmpresaPipe} from './mostrar-codEmpresa/mostrar-cod-empresa.pipe';
import {TipoDocumentoPipe} from './compras/tipo-documento.pipe';
import {ValidarDocumentoCompraPipe} from './compras/validar-documento.ts/validar-documento-compra.pipe';
import {ListaTemMaisDePipe} from './financas-shared/listas/lista-tem-mais-de.pipe';
import {FiltrarPessoaContactoRelacaoPipe} from './filtros-auto-complete/pipe/filtrar-pessoa-contacto-relacao.pipe';
import {FiltroEmailsManuaisPipe} from './filtros-auto-complete/pipe/filtro-emails-manuais.pipe';
import {ColNamePipe} from './col-name.pipe';
import {ColCriadorPipe} from './col-criador.pipe';
import {ColTipoPipe} from './col-tipo.pipe';
import {CodTipoPipe} from './cod-tipo.pipe';
import {CodTituloPipe} from './col-titulo.pipe';
import {MsgExpiracaoPipe} from './msg-expiracao.pipe';
import {FilterFnPipe} from './filter-fn.pipe';
import { SomeFnPipe } from './some-fn.pipe';
import { SumPipe } from './sum.pipe';
import { TabTotalizadorPipe } from './tabela-totalizador/tab-totalizador.pipe';
import { AllPipe } from './all.pipe';
import { ConvertToFloatPipe } from './convert-to-float.pipe';
import { ParseValuePipe } from './parse-value.pipe';
import { AgruparContactosPipe } from '@pipes/agrupar-contactos.pipe';


const pipes = [
    RelativeTimePipe,
    ExcerptPipe,
    GetValueByKeyPipe,
    StringFloatPipe,
    FormatarOrdinalPipe,
    TipoFicheiroPipe,
    InserirQuebraLinhaPipe,
    PrintWithBrPipe,
    LabelProvinciaPipe,
    LabelMunicipioPipe,
    StrNormalCasePipe,
    LinkYoutubePipe,
    TamanhoFicheiroPipe,
    IconFicheiroPipe,
    SetBgFicheiroPipe,
    CalculoDeVagaPipe,
    GetMenuPipe,
    MostrarCodEmpresaPipe,
    TipoDocumentoPipe,
    ValidarDocumentoCompraPipe,
    ListaTemMaisDePipe,
    FiltrarPessoaContactoRelacaoPipe,
    FiltroEmailsManuaisPipe,
    ColNamePipe,
    ColCriadorPipe,
    ColTipoPipe,
    CodTipoPipe,
    CodTituloPipe,
    MsgExpiracaoPipe,
    AgruparContactosPipe
];

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        pipes,
        SafePipe,
        DataReversePtPipe,
        FilterByIdPipe,
        FormatarQuantidadePipe,
        FilterFnPipe,
        SomeFnPipe,
        SumPipe,
        TabTotalizadorPipe,
        AllPipe,
        ConvertToFloatPipe,
        ParseValuePipe,

    ],
    exports: [
        pipes,
        SafePipe,
        FilterByIdPipe,
        FilterFnPipe,
        SomeFnPipe,
        SumPipe,
        TabTotalizadorPipe,
        AllPipe,
        ConvertToFloatPipe,
        ParseValuePipe
    ]
})
export class SharedPipesModule {
}
