<div id="app-customizer" *ngIf="isCustomizerOpen">
    <mat-card class="p-1">
        <mat-card-title class="m-0 light-gray">
            <div class="card-title-text" fxLayout="row wrap" fxLayoutAlign="center center">
                <button mat-flat-button [color]="viewMode === 'options' ? 'primary':''" (click)="viewMode = 'options'">Op&ccedil;&otilde;es</button>
                <span fxFlex="15px"></span>

                <button mat-flat-button *ngIf="true" [color]="viewMode === 'json' ? 'primary':''" (click)="viewMode = 'json'">Json</button>
                <span fxFlex></span>
                <button
                        class="card-control"
                        mat-icon-button
                        (click)="fecharCustomizer()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </mat-card-title>
        <mat-card-content *ngIf="viewMode === 'json'" style="min-height: 100vh">
            <pre class="text-muted">{{this.layoutConf | json}}</pre>
            <pre><code [egretHighlight]="this.layoutConf | json"></code></pre>
            <div>
                <span fxFlex></span>
                <a href="http://demos.ui-lib.com/egret-doc/#layout" target="_blank" mat-mini-fab><mat-icon>help</mat-icon></a>
            </div>
        </mat-card-content>

        <mat-card-content [perfectScrollbar] *ngIf="viewMode === 'options'">
            <p *ngIf="false"><small>Customize the template then copy configuration json.</small></p>
            <p>Personalize o layout do seu Kiami.</p>
            <div *ngIf="false" class="pb-16 mb-16 border-bottom">
                <h6 class="title text-muted">Layouts</h6>
                <mat-radio-group  fxLayout="column" [(ngModel)]="selectedLayout" (change)="changeLayoutStyle($event)">
                    <mat-radio-button  [value]="'top'">Navegação Top  </mat-radio-button>
                    <mat-radio-button [value]="'side'">Navegação Lateral  </mat-radio-button>
                </mat-radio-group>
            </div>

            <!--
            <div class="pb-16 mb-16 border-bottom">
                <h6 class="title text-muted">Header Colors</h6>
                <div class="mb-16">
                  <mat-checkbox [(ngModel)]="isTopbarFixed" (change)="toggleTopbarFixed($event)" [disabled]="selectedLayout === 'top'" [value]="selectedLayout !== 'top'">Fixed Header</mat-checkbox>
                </div>

                <div class="colors">
                  <div
                    class="color {{c.class}}"
                    *ngFor="let c of customizer.topbarColors"
                    (click)="customizer.changeTopbarColor(c)">
                    <mat-icon class="active-icon" *ngIf="c.active">check</mat-icon>
                  </div>
                </div>
            </div>
            -->

            <div class="pb-16 mb-16 border-bottom">
                <h6 class="title text-muted">Sidebar colors</h6>
                <div class="colors">
                    <div
                            class="color {{c.class}}"
                            *ngFor="let c of customizer.sidebarColors"
                            (click)="customizer.changeSidebarColor(c)">
                        <mat-icon class="active-icon" *ngIf="c.active">check</mat-icon>
                    </div>
                </div>
            </div>
            <div class="pb-16 mb-16 border-bottom">
                <div>
                    <button mat-flat-button class="bg-secondary" (click)="reporDefault()">Repôr default</button>
                </div>
            </div>
            <div class="pb-16 mb-16 border-bottom">
                <h6 class="title text-muted">Material Themes</h6>
                <div class="colors">
                    <div class="color" *ngFor="let theme of egretThemes"
                         (click)="changeTheme(theme)" [style.background]="theme.baseColor">
                        <mat-icon class="active-icon" *ngIf="theme.isActive">check</mat-icon>
                    </div>
                </div>
            </div>

            <div class="pb-16 mb-16 border-bottom">
                <h6 class="title text-muted">Footer Colors</h6>
                <div class="mb-16">
                    <mat-checkbox [(ngModel)]="isFooterFixed" (change)="layout.publishLayoutChange({ footerFixed: $event.checked })" [value]="selectedLayout !== 'top'">Fixed Footer</mat-checkbox>
                </div>

                <div class="colors">
                    <div
                            class="color {{c.class}}"
                            *ngFor="let c of customizer.footerColors"
                            (click)="customizer.changeFooterColor(c)">
                        <mat-icon class="active-icon" *ngIf="c.active">check</mat-icon>
                    </div>
                </div>
            </div>
            <!--
                  <div class="pb-16 mb-16 border-bottom">
                      <h6 class="title text-muted">Breadcrumb</h6>
                      <div class="mb-16">
                          <mat-checkbox [(ngModel)]="layoutConf.useBreadcrumb" (change)="toggleBreadcrumb($event)">Use breadcrumb</mat-checkbox>
                      </div>
                      <small class="text-muted">Breadcrumb types</small>
                      <mat-radio-group fxLayout="column" [(ngModel)]="layoutConf.breadcrumb" [disabled]="!layoutConf.useBreadcrumb">
                          <mat-radio-button [value]="'simple'"> Simple </mat-radio-button>
                          <mat-radio-button [value]="'title'"> Simple with title </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  -->

            <!--
            <div class="pb-16 pos-rel mb-16 border-bottom">
              <h6 class="title text-muted">Navigation</h6>
              <mat-radio-group
              fxLayout="column"
              [(ngModel)]="selectedMenu"
              (change)="changeSidenav($event)"
              [disabled]="selectedLayout === 'top'">
                <mat-radio-button
                *ngFor="let type of sidenavTypes"
                [value]="type.value">
                  {{type.name}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            -->

            <!--
                  <div class="pb-16 pos-rel mb-16 border-bottom">
                    <mat-checkbox [(ngModel)]="perfectScrollbarEnabled" (change)="tooglePerfectScrollbar($event)">Custom scrollbar</mat-checkbox>
                  </div>
                -->

            <h6 class="title text-muted">Posição do menu</h6>
            <div class="pb-16 ">
                <mat-checkbox [(ngModel)]="isRTL" (change)="toggleDir($event)">Esquerda para Direta</mat-checkbox>
            </div>
        </mat-card-content>
    </mat-card>
</div>
