import {ProcessosTarefas} from '../models/processos/processostarefas';
import {FiltrosClientes} from '../models/clientes/filtros/filtroclientes';
import * as moment from 'moment';
import {Accao} from '../models/ENUM';

export const tabMessages = {
    emptyMessage: 'Sem dados para mostrar',
    totalMessage: 'Total',
    selectedMessage: 'seleccionados'
};

export const imagensLista: any[] = [
    {src: 'assets/images/sq-1.jpg'},
    {src: 'assets/images/sq-2.jpg'},
    {src: 'assets/images/sq-3.jpg'},
    {src: 'assets/images/sq-4.jpg'},
    {src: 'assets/images/sq-5.jpg'},
    {src: 'assets/images/sq-6.jpg'},
    {src: 'assets/images/sq-7.jpg'},
    {src: 'assets/images/sq-8.jpg'},
    {src: 'assets/images/sq-9.jpg'},
    {src: 'assets/images/sq-10.jpg'},
    {src: 'assets/images/sq-11.jpg'},
    {src: 'assets/images/sq-12.jpg'}
];

export const Operacoes: { [key: string]: string } = {
    [Accao.cadastrar]: 'Criar',
    [Accao.editar]: 'Editar',
    [Accao.detalhes]: 'Consultar'
};

export const lista: number[] = [];

export const tarefas: ProcessosTarefas[] = [];

export const menuCode = [
    {
        codMenu: 'CLIENT',
        RotaAPI: '',
        menu: 'Clientes'
    },
    {
        codMenu: 'RECHUM',
        RotaAPI: '',
        menu: 'Recursos Humanos'
    },
    {
        codMenu: 'ARTIGO',
        RotaAPI: '',
        menu: 'Artigos'
    },
    {
        codMenu: 'PROCES',
        RotaAPI: '',
        menu: 'Processos'
    },
    {
        codMenu: 'VENDAS',
        RotaAPI: '',
        menu: 'Vendas'
    },

    {
        codMenu: 'EVENTO',
        RotaAPI: '',
        menu: 'Eventos'
    },

    {
        codMenu: 'COMUNI',
        RotaAPI: '',
        menu: 'Comunicação'
    },
    {
        codMenu: 'UTILIZ',
        RotaAPI: '',
        menu: 'Utilizadores'
    },
    {
        codMenu: 'EQUIPA',
        RotaAPI: '',
        menu: 'Equipamentos'
    },
    {
        codMenu: 'GAR',
        RotaAPI: '',
        menu: 'Gestão de Arquivos'
    },
    {
        codMenu: 'ADMINI',
        RotaAPI: '',
        menu: 'Administração'
    }
];

export const model = new FiltrosClientes(
    1,
    1,
    '',
    '',
    '',
    '',
    '',
    '',
    lista,
    lista
);

export const myBase64 = '';

export const artigosFA = [{
    idClassificacao: 3,
    classificacaoP: 'Artigos',
    idArtigo: 40,
    codArtigo: '2020_KIAMI',
    idIdioma: 1,
    codIdioma: null,
    artigo: '2020_KIAMI',
    descricao: '2020_KIAMI',
    condicoesAdesao: '2020_KIAMI',
    informacaoAdicional: '2020_KIAMI',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 2000,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: false,
    tipo: 'Produto Composto',
    estado: 'Activo'
}, {
    idClassificacao: 3,
    classificacaoP: 'Artigos',
    idArtigo: 42,
    codArtigo: '202020213108',
    idIdioma: 1,
    codIdioma: null,
    artigo: '202020213108',
    descricao: '202020213108',
    condicoesAdesao: '202020213108',
    informacaoAdicional: '202020213108',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 20000,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: false,
    tipo: 'Produto Composto',
    estado: 'Activo'
}, {
    idClassificacao: 3,
    classificacaoP: 'Artigos',
    idArtigo: 41,
    codArtigo: '202991995',
    idIdioma: 1,
    codIdioma: null,
    artigo: '202991995',
    descricao: '202991995',
    condicoesAdesao: '202991995',
    informacaoAdicional: '202991995',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 200000,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: false,
    tipo: 'Produto Composto',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 110,
    codArtigo: 'SH00000016',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Ads',
    descricao: 'asd',
    condicoesAdesao: 'asd',
    informacaoAdicional: 'asd',
    idIva: 6,
    codIva: 'M02',
    taxaIva: 0,
    'precoM1': 2222,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 110,
    codArtigo: 'SH00000016',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Ads',
    descricao: 'asd',
    condicoesAdesao: 'asd',
    informacaoAdicional: 'asd',
    idIva: 6,
    codIva: 'M02',
    taxaIva: 0,
    'precoM1': 2222,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 2,
    classificacaoP: 'Produtos',
    idArtigo: 9,
    codArtigo: 'KIAMI_2020',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Calças brancas',
    descricao: 'Nossas Calças',
    condicoesAdesao: null,
    informacaoAdicional: null,
    idIva: 5,
    codIva: 'M00 ',
    taxaIva: 0,
    'precoM1': 50,
    'precoM2': 50,
    imagem: '9.jpeg',
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Produto',
    estado: 'Activo'
}, {
    idClassificacao: 2,
    classificacaoP: 'Produtos',
    idArtigo: 9,
    codArtigo: 'KIAMI_2020',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Calças brancas',
    descricao: 'Nossas Calças',
    condicoesAdesao: null,
    informacaoAdicional: null,
    idIva: 5,
    codIva: 'M00 ',
    taxaIva: 0,
    'precoM1': 50,
    'precoM2': 50,
    imagem: '9.jpeg',
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Produto',
    estado: 'Activo'
}, {
    idClassificacao: 2,
    classificacaoP: 'Produtos',
    idArtigo: 13,
    codArtigo: 'ARTIGO',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Camisolas pretas.',
    descricao: 'Nossas camisolas pretas.',
    condicoesAdesao: 'Condições editada.',
    informacaoAdicional: 'Informação editada.',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 648,
    'precoM2': 648,
    imagem: '13.jpeg',
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Produto',
    estado: 'Activo'
}, {
    idClassificacao: 2,
    classificacaoP: 'Produtos',
    idArtigo: 13,
    codArtigo: 'ARTIGO',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Camisolas pretas.',
    descricao: 'Nossas camisolas pretas.',
    condicoesAdesao: 'Condições editada.',
    informacaoAdicional: 'Informação editada.',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 648,
    'precoM2': 648,
    imagem: '13.jpeg',
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Produto',
    estado: 'Activo'
}, {
    idClassificacao: 2,
    classificacaoP: 'Produtos',
    idArtigo: 4,
    codArtigo: 'ARTIGO -1',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Com Fornecedor',
    descricao: 'Artigo com Fornecedor',
    condicoesAdesao: null,
    informacaoAdicional: null,
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 100,
    'precoM2': 600,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Produto',
    estado: 'Activo'
}, {
    idClassificacao: 2,
    classificacaoP: 'Produtos',
    idArtigo: 4,
    codArtigo: 'ARTIGO -1',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Com Fornecedor',
    descricao: 'Artigo com Fornecedor',
    condicoesAdesao: null,
    informacaoAdicional: null,
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 100,
    'precoM2': 600,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Produto',
    estado: 'Activo'
}, {
    idClassificacao: 3,
    classificacaoP: 'Artigos',
    idArtigo: 64,
    codArtigo: 'COSTA_2021',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'COSTA_2021',
    descricao: 'COSTA_2021',
    condicoesAdesao: 'COSTA_2021',
    informacaoAdicional: 'COSTA_2021',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 20000,
    'precoM2': 20000,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: false,
    tipo: 'Produto Composto',
    estado: 'Activo'
}, {
    idClassificacao: 3,
    classificacaoP: 'Artigos',
    idArtigo: 63,
    codArtigo: 'DEF_2021',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'DEF_2021',
    descricao: 'DEF_2021',
    condicoesAdesao: 'DEF_2021',
    informacaoAdicional: 'DEF_2021',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 0.55,
    'precoM2': 2000,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: false,
    tipo: 'Produto Composto',
    estado: 'Activo'
}, {
    idClassificacao: 2,
    classificacaoP: 'Produtos',
    idArtigo: 133,
    codArtigo: '3234567890',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Designacao',
    descricao: 'Descriao',
    condicoesAdesao: 'Condicoes de Adesao',
    informacaoAdicional: 'Informacao Adicional',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 2345,
    'precoM2': 2345,
    imagem: '133.jpeg',
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: false,
    tipo: 'Produto',
    estado: 'Suspenso'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 111,
    codArtigo: 'SH00000017',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'dffdf',
    descricao: '',
    condicoesAdesao: '',
    informacaoAdicional: '',
    idIva: 6,
    codIva: 'M02',
    taxaIva: 0,
    'precoM1': 13323,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 111,
    codArtigo: 'SH00000017',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'dffdf',
    descricao: '',
    condicoesAdesao: '',
    informacaoAdicional: '',
    idIva: 6,
    codIva: 'M02',
    taxaIva: 0,
    'precoM1': 13323,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 120,
    codArtigo: 'SH00000025',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Marcação',
    descricao: 'Marcação',
    condicoesAdesao: 'Marcação',
    informacaoAdicional: 'Marcação',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 4000,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 120,
    codArtigo: 'SH00000025',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Marcação',
    descricao: 'Marcação',
    condicoesAdesao: 'Marcação',
    informacaoAdicional: 'Marcação',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 4000,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 2,
    classificacaoP: 'Produtos',
    idArtigo: 10,
    codArtigo: 'P_2020',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Nossas Calças Jeans',
    descricao: 'Calças Jeans',
    condicoesAdesao: 'Condições segundo produto',
    informacaoAdicional: 'Informações segundo produto',
    idIva: 6,
    codIva: 'M02',
    taxaIva: 0,
    'precoM1': 10,
    'precoM2': 10,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Produto',
    estado: 'Activo'
}, {
    idClassificacao: 2,
    classificacaoP: 'Produtos',
    idArtigo: 10,
    codArtigo: 'P_2020',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Nossas Calças Jeans',
    descricao: 'Calças Jeans',
    condicoesAdesao: 'Condições segundo produto',
    informacaoAdicional: 'Informações segundo produto',
    idIva: 6,
    codIva: 'M02',
    taxaIva: 0,
    'precoM1': 10,
    'precoM2': 10,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Produto',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 119,
    codArtigo: 'SH00000024',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Obrigatorio',
    descricao: 'Obrigatorio',
    condicoesAdesao: 'Obrigatorio',
    informacaoAdicional: 'Obrigatorio',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 3444,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 119,
    codArtigo: 'SH00000024',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Obrigatorio',
    descricao: 'Obrigatorio',
    condicoesAdesao: 'Obrigatorio',
    informacaoAdicional: 'Obrigatorio',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 3444,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 2,
    classificacaoP: 'Produtos',
    idArtigo: 134,
    codArtigo: 'PC-GAMER22',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'pv',
    descricao: 'nada a descrever',
    condicoesAdesao: null,
    informacaoAdicional: null,
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 5000000,
    'precoM2': 20000,
    imagem: '134.jpeg',
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: false,
    tipo: 'Produto',
    estado: 'Inactivo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 29,
    codArtigo: 'S28',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'sddfd',
    descricao: 'vxf',
    condicoesAdesao: 'xcv',
    informacaoAdicional: 'xcv',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 233234,
    'precoM2': 233234,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 87,
    codArtigo: 'ACA',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'sdf',
    descricao: 'asdsad',
    condicoesAdesao: 'asdas',
    informacaoAdicional: 'asd',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 5000,
    'precoM2': 4000,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: false,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 32,
    codArtigo: 'MC 220',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Serviço 2021',
    descricao: 'a',
    condicoesAdesao: 'a',
    informacaoAdicional: 'a',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 7777,
    'precoM2': 7777,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 31,
    codArtigo: '001245',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Servico Rotura',
    descricao: 'Bla',
    condicoesAdesao: 'Bla',
    informacaoAdicional: 'Bla',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 2000,
    'precoM2': 2000,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 135,
    codArtigo: '00000001',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Serviço teste',
    descricao: null,
    condicoesAdesao: null,
    informacaoAdicional: null,
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 0.55,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: false,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 113,
    codArtigo: 'SH00000019',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Teste',
    descricao: '',
    condicoesAdesao: '',
    informacaoAdicional: '',
    idIva: 6,
    codIva: 'M02',
    taxaIva: 0,
    'precoM1': 0,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 113,
    codArtigo: 'SH00000019',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Teste',
    descricao: '',
    condicoesAdesao: '',
    informacaoAdicional: '',
    idIva: 6,
    codIva: 'M02',
    taxaIva: 0,
    'precoM1': 0,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 121,
    codArtigo: '00000000',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Teste',
    descricao: null,
    condicoesAdesao: null,
    informacaoAdicional: null,
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 3000,
    'precoM2': 299,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: false,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 115,
    codArtigo: 'SH00000020',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Teste 0012',
    descricao: 'asdasd',
    condicoesAdesao: 'asd',
    informacaoAdicional: 'asd',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 2000,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 115,
    codArtigo: 'SH00000020',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Teste 0012',
    descricao: 'asdasd',
    condicoesAdesao: 'asd',
    informacaoAdicional: 'asd',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 2000,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 112,
    codArtigo: 'SH00000018',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Teste 2',
    descricao: '',
    condicoesAdesao: '',
    informacaoAdicional: '',
    idIva: 6,
    codIva: 'M02',
    taxaIva: 0,
    'precoM1': 2000,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 112,
    codArtigo: 'SH00000018',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Teste 2',
    descricao: '',
    condicoesAdesao: '',
    informacaoAdicional: '',
    idIva: 6,
    codIva: 'M02',
    taxaIva: 0,
    'precoM1': 2000,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 117,
    codArtigo: 'SH00000022',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Teste 2021-03-09',
    descricao: 'Teste 2021-03-09',
    condicoesAdesao: 'Teste 2021-03-09',
    informacaoAdicional: 'Teste 2021-03-09',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 12122,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 117,
    codArtigo: 'SH00000022',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Teste 2021-03-09',
    descricao: 'Teste 2021-03-09',
    condicoesAdesao: 'Teste 2021-03-09',
    informacaoAdicional: 'Teste 2021-03-09',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 12122,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 118,
    codArtigo: 'SH00000023',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'tipoObrigatorio',
    descricao: 'tipoObrigatorio',
    condicoesAdesao: 'tipoObrigatorio',
    informacaoAdicional: 'tipoObrigatorio',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 978979,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 118,
    codArtigo: 'SH00000023',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Tipo Obrigatorio',
    descricao: 'Tipo de servico obrigatorio',
    condicoesAdesao: 'tipoObrigatorio',
    informacaoAdicional: 'tipoObrigatorio',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 978979,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 2,
    codUnidade: 'CX',
    unidadeSingular: 'Caixa',
    unidadePlural: 'Caixas',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 116,
    codArtigo: 'SH00000021',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Transportes de Luxo (UBER EAT)',
    descricao: '',
    condicoesAdesao: '',
    informacaoAdicional: '',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 0,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 116,
    codArtigo: 'SH00000021',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'Transportes',
    descricao: '',
    condicoesAdesao: '',
    informacaoAdicional: '',
    idIva: 4,
    codIva: '14',
    taxaIva: 14,
    'precoM1': 0,
    'precoM2': 0,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 109,
    codArtigo: 'SH00000015',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'zasdasd',
    descricao: 'Escrevendo Alguma coisa',
    condicoesAdesao: '',
    informacaoAdicional: '',
    idIva: 6,
    codIva: 'M02',
    taxaIva: 0,
    'precoM1': 0,
    'precoM2': 123123,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço Composto',
    estado: 'Activo'
}, {
    idClassificacao: 1,
    classificacaoP: 'Serviços',
    idArtigo: 109,
    codArtigo: 'SH00000015',
    idIdioma: 1,
    codIdioma: null,
    artigo: 'zasdasd',
    descricao: 'Escrevendo Alguma coisa',
    condicoesAdesao: '',
    informacaoAdicional: '',
    idIva: 6,
    codIva: 'M02',
    taxaIva: 0,
    'precoM1': 0,
    'precoM2': 123123,
    imagem: null,
    idUnidadeMedida: 1,
    codUnidade: 'UN',
    unidadeSingular: 'Unidade',
    unidadePlural: 'Unidades',
    sujeitoARetencao: true,
    tipo: 'Serviço Composto',
    estado: 'Activo'
}];

export const ranges: any = {
    'Hoje': [moment(), moment()],
    'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Últimos 7 dias': [moment().subtract(6, 'days'), moment()],
    'Últimos 30 dias': [moment().subtract(29, 'days'), moment()],
    'Mês corrente': [moment().startOf('month'), moment().endOf('month')],
    'Ano corrente': [moment().startOf('year'), moment().endOf('year')],
    'Último mês': [moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month')]
};

export const horasEvento = [
    {id: 1, valor: '00:00'},
    {id: 2, valor: '00:15'},
    {id: 3, valor: '00:30'},
    {id: 4, valor: '00:45'},
    {id: 5, valor: '01:00'},
    {id: 6, valor: '01:15'},
    {id: 7, valor: '01:30'},
    {id: 8, valor: '01:45'},
    {id: 9, valor: '02:00'},
    {id: 10, valor: '02:15'},
    {id: 11, valor: '02:30'},
    {id: 12, valor: '02:45'},
    {id: 13, valor: '03:00'},
    {id: 14, valor: '03:15'},
    {id: 15, valor: '03:30'},
    {id: 16, valor: '03:45'},
    {id: 17, valor: '04:00'},
    {id: 18, valor: '04:15'},
    {id: 19, valor: '04:30'},
    {id: 20, valor: '04:45'},
    {id: 21, valor: '05:00'},
    {id: 22, valor: '05:15'},
    {id: 23, valor: '05:30'},
    {id: 24, valor: '05:45'},
    {id: 25, valor: '06:00'},
    {id: 26, valor: '06:15'},
    {id: 27, valor: '06:30'},
    {id: 28, valor: '06:45'},
    {id: 29, valor: '07:00'},
    {id: 30, valor: '07:15'},
    {id: 31, valor: '07:30'},
    {id: 32, valor: '07:45'},
    {id: 33, valor: '08:00'},
    {id: 34, valor: '08:15'},
    {id: 35, valor: '08:30'},
    {id: 36, valor: '08:45'},
    {id: 37, valor: '09:00'},
    {id: 38, valor: '09:15'},
    {id: 39, valor: '09:30'},
    {id: 40, valor: '09:45'},
    {id: 41, valor: '10:00'},
    {id: 42, valor: '10:15'},
    {id: 43, valor: '10:30'},
    {id: 44, valor: '10:45'},
    {id: 45, valor: '11:00'},
    {id: 46, valor: '11:15'},
    {id: 47, valor: '11:30'},
    {id: 48, valor: '11:45'},
    {id: 49, valor: '12:00'},
    {id: 50, valor: '12:15'},
    {id: 51, valor: '12:30'},
    {id: 52, valor: '12:45'},
    {id: 53, valor: '13:00'},
    {id: 54, valor: '13:15'},
    {id: 55, valor: '13:30'},
    {id: 56, valor: '13:45'},
    {id: 57, valor: '14:00'},
    {id: 58, valor: '14:15'},
    {id: 59, valor: '14:30'},
    {id: 60, valor: '14:45'},
    {id: 61, valor: '15:00'},
    {id: 62, valor: '15:15'},
    {id: 63, valor: '15:30'},
    {id: 64, valor: '15:45'},
    {id: 65, valor: '16:00'},
    {id: 66, valor: '16:15'},
    {id: 67, valor: '16:30'},
    {id: 68, valor: '16:45'},
    {id: 69, valor: '17:00'},
    {id: 70, valor: '17:15'},
    {id: 71, valor: '17:30'},
    {id: 72, valor: '17:45'},
    {id: 73, valor: '18:00'},
    {id: 74, valor: '18:15'},
    {id: 75, valor: '18:30'},
    {id: 76, valor: '18:45'},
    {id: 77, valor: '19:00'},
    {id: 78, valor: '19:15'},
    {id: 79, valor: '19:30'},
    {id: 80, valor: '19:45'},
    {id: 81, valor: '20:00'},
    {id: 82, valor: '20:15'},
    {id: 83, valor: '20:30'},
    {id: 84, valor: '20:45'},
    {id: 85, valor: '21:00'},
    {id: 86, valor: '21:15'},
    {id: 87, valor: '21:30'},
    {id: 88, valor: '21:45'},
    {id: 89, valor: '22:00'},
    {id: 90, valor: '22:15'},
    {id: 91, valor: '22:30'},
    {id: 92, valor: '22:45'},
    {id: 93, valor: '23:00'},
    {id: 94, valor: '23:15'},
    {id: 95, valor: '23:30'},
    {id: 96, valor: '23:45'}
];
