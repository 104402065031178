import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders,} from '@angular/common/http';
import {environment} from 'environments/environment';
import {Observable, throwError} from 'rxjs';
import {ProcessosEstados} from 'app/shared/models/processos/processosestados';
import {TiposTarefas} from 'app/shared/models/processos/tipostarefas';
import {Categoria} from '../../models/processos/categoria';
import {ProcessosEmailsAutomaticos} from 'app/shared/models/processos/emails-automaticos';
import {Resposta} from '../../models/resposta';
import {Documentos} from '../../models/financas/documentos/documentos';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),

    }),
};

@Injectable({
    providedIn: 'root',
})
export class ProcessosService {

    baseUrl: string;
    baseUrlProcesso: string;

    constructor(private http: HttpClient) {
        this.baseUrl = `${environment.apiAplicacao}`;
        this.baseUrlProcesso = `${environment.apiProcessos}`;
    }

    getEmailsAutomaticos(idClassificacao: number): Observable<any> {
        return this.http
            .get(
                this.baseUrlProcesso + 'emailsautomaticos/' + idClassificacao,
                httpOptions
            );
    }

    gravarEmailsAutomatico(modelo: any): Observable<any> {
        return this.http
            .post(
                this.baseUrlProcesso + 'emailsautomaticos', modelo,
                httpOptions
            );
    }

    getEmails(
        activo: boolean,
        disponivelEmails: boolean
    ): Observable<any> {
        return this.http
            .get(
                this.baseUrlProcesso +
                'emailsautomaticos/emailsEnvio/' +
                activo +
                '/' +
                disponivelEmails,
                httpOptions
            );
    }

    getPeriodicidadeEmails(): Observable<any> {
        return this.http
            .get(
                this.baseUrlProcesso + 'notificacao/getPeriodicidadeEmails',
                httpOptions
            );
    }

    getEmailAutomatico(idEmailAutomatico: number, idClassificacao: number): Observable<ProcessosEmailsAutomaticos> {
        return this.http
            .get(
                this.baseUrlProcesso + 'emailsautomaticos/' + idEmailAutomatico + '/' + idClassificacao,
                httpOptions
            );
    }

    getClassificacoes(): Observable<any> {
        return this.http
            .get(this.baseUrlProcesso + 'classificacoes', httpOptions);
    }

    getClassificacoesParametrizacao(): Observable<any> {
        return this.http
            .get(
                this.baseUrlProcesso + 'classificacoes/parametrizacao',
                httpOptions
            );
    }

    salvarParametrizacao(modelo: any): Observable<any> {
        return this.http.post(
            this.baseUrlProcesso + 'classificacoes', modelo, httpOptions
        );
    }

    getProcessos(model: any): Observable<any> {
        return this.http
            .post(
                this.baseUrlProcesso + 'processos/filtro',
                model,
                httpOptions
            );
    }


    getProcesso(idProcesso: number, idIdioma: number): Observable<any> {
        return this.http
            .get(
                this.baseUrlProcesso + 'processos/processo/' + idIdioma + '/' + idProcesso,
                httpOptions
            );
    }

    removerProcesso(idProcesso: number): Observable<any> {
        return this.http
            .post(
                this.baseUrlProcesso + 'processos/eliminar',
                idProcesso,
                httpOptions
            )
            .pipe();
    }

    getClientes() {
        return this.http.get<any>(this.baseUrlProcesso + 'clientes', httpOptions);
    }

    getTarefas(model: any): Observable<any> {
        return this.http
            .post(
                this.baseUrlProcesso + 'tarefas/GetPrtarefas',
                model,
                httpOptions
            )
            .pipe();
    }

    getEstados(idClassificacao: number): Observable<any> {
        return this.http
            .get(this.baseUrlProcesso + 'estados/' + idClassificacao, httpOptions);
    }

    getEstado(idEstado: number): Observable<ProcessosEstados> {
        return this.http
            .get(this.baseUrlProcesso + 'estados/PrEstados/' + idEstado, httpOptions);
    }

    salvar(model: any): Observable<any> {
        return this.http
            .post(this.baseUrlProcesso + 'processos/', model, httpOptions);
    }

    editar(model: any): Observable<any> {
        return this.http
            .post(this.baseUrlProcesso + 'processos/Atualizar', model, httpOptions);
    }

    alterarEstadoProcesso(idEstado, idProcesso) {
        return this.http.post<Resposta<number>>(this.baseUrlProcesso + `processos/${idProcesso}/estados/${idEstado}`, httpOptions);
    }

    // gerarToken(model: any) {
    //   return this.http
    //     .post(this.baseUrlProcesso + 'gerartoken/', model)
    //     .pipe();
    // }

    getCodigoProcesso(modeloCodigo: any): Observable<any> {
        return this.http
            .post(
                this.baseUrlProcesso +
                'numeracao/', modeloCodigo,
                httpOptions
            )
            .pipe();
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }

    getResponsavel(idClassificacao: number): Observable<any> {
        return this.http
            .get(this.baseUrlProcesso + 'responsavel/' + idClassificacao, httpOptions)
            .pipe();
    }

    getResponsavelTipo(idClassificacao: number): Observable<any> {
        return this.http
            .get(this.baseUrlProcesso + 'responsavel/tipo/' + idClassificacao, httpOptions)
            .pipe();
    }

    // Caracteristicas
    getCaracteristicas(idClassificacao: number, activo: boolean): Observable<any> {
        return this.http
            .get(this.baseUrlProcesso + 'caracteristicas/' + idClassificacao + '/' + activo, httpOptions)
            .pipe();
    }

    // Categoria
    getCategorias(idClassificacao: number, idIdioma: number): Observable<any> {
        return this.http
            .get(this.baseUrlProcesso + 'categorias/' + idClassificacao + '/' + idIdioma, httpOptions)
            .pipe();
    }

    getCategoriasConfiguracao(idClassificacao: number, idIdioma: number): Observable<any> {
        return this.http
            .get(this.baseUrlProcesso + 'categorias/categorias/' + idClassificacao + '/' + idIdioma, httpOptions)
            .pipe();
    }

    getCategoria(idCategoria: number): Observable<Categoria> {
        return this.http
            .get(this.baseUrlProcesso + 'categorias/categoria/' + idCategoria, httpOptions)
            .pipe();
    }

    SalvarCategoria(model: any): Observable<Categoria> {
        return this.http
            .post(this.baseUrlProcesso + 'categorias/', model, httpOptions)
            .pipe();
    }

    eliminarCategoria(idCategoria: number): Observable<Categoria> {
        return this.http
            .post(this.baseUrlProcesso + 'categorias/eliminar', idCategoria, httpOptions)
            .pipe();
    }

    // Salvar Estado
    salvarEstado(model: any): Observable<any> {
        return this.http.post(this.baseUrlProcesso + 'estados/', model, httpOptions);
    }

    // Eliminar Estado fora de funcionalidade
    eliminarEstadoConfig(idEstado: number) {
        return this.http
            .post(this.baseUrlProcesso + 'estados/eliminar/', idEstado, httpOptions)
            .pipe();
    }

    eliminarEstado(id: number) {
        return this.http
            .post(this.baseUrlProcesso + 'tipostarefas/eliminar/estado/', id, httpOptions)
            .pipe();
    }


    // Tipo de tarefa
    getTipoTarefas(idClassificacao: number): Observable<any> {
        return this.http
            .get(this.baseUrlProcesso + 'tarefas/tipos/', {...httpOptions, params: {idClassificacao}})
            .pipe();
    }

    getTipoTarefasConfiguracao(idClassificacao: number): Observable<any> {
        return this.http
            .get(this.baseUrlProcesso + 'tipostarefas/Conf/' + idClassificacao, httpOptions)
            .pipe();
    }

    getTipoTarefa(id: number) {
        return this.http.get<Resposta<TiposTarefas>>(this.baseUrlProcesso + 'tarefas/tipos/' + id, httpOptions);
    }

    gravarTipoTarefa(model: any): Observable<any> {
        return this.http
            .post(this.baseUrlProcesso + 'tarefas/tipos', model, httpOptions);
    }

    eliminarTipoTarefa(id: number): Observable<any> {
        return this.http
            .post(this.baseUrlProcesso + 'tipostarefas/eliminar', id, httpOptions)
            .pipe();
    }

    // fim Tipo de tarefa

    // ProcessoArtigo
    getPrArtigosFornecedor(idArtigo?: number): Observable<any> {
        return this.http
            .get(this.baseUrlProcesso + 'processosartigos/fornecedor/' + idArtigo, httpOptions)
            .pipe();
    }

    // Fim ProcessoArtigo


    gravarPermissoes(modelo: any) {
        return this.http
            .post(this.baseUrlProcesso + 'permissoes', modelo, httpOptions)
            .pipe();
    }

    getContratosXML(dataInicio: any, dataFim: any) {

        return this.http.get(this.baseUrlProcesso + 'relatorios/contratosXml/' + dataInicio, httpOptions);
    }

    getRelatoriosXML(tipo: any, dataInicio: any, dataFim: any) {
        return this.http.get(this.baseUrlProcesso + 'relatorios/' + tipo + 'Xml/' + dataInicio + '/' + dataFim, httpOptions);
    }

    getRelatoriosContractosXML(dataInicio: any,) {
        return this.http.get(this.baseUrlProcesso + 'relatorios/contratosXml/' + dataInicio, httpOptions);
    }

    getRelatorioGarantiasXML(dataInicio?: any, dataFim?: any) {
        console.log('Datta no service: ', dataInicio);
        return this.http.get(this.baseUrlProcesso + 'relatorios/garantiasXml/' + dataInicio, httpOptions);
    }

    getTiposDadosCaracteristica(): Observable<any> {
        return this.http.get(this.baseUrlProcesso + 'caracteristicas/tipoDados', httpOptions);
    }

    // Localização
    getTiposLocalizacao(idClassificacao: number): Observable<any> {
        return this.http.get(this.baseUrlProcesso + 'localizacao/tipos/' + idClassificacao, httpOptions)
            .pipe();
    }

    getLocalizacaoValoresFixo(idTipoLocalizacao: number): Observable<any> {
        return this.http.get(this.baseUrlProcesso + 'localizacao/valorFixo/' + idTipoLocalizacao, httpOptions)
            .pipe();
    }

    getTiposLocalizacaoConf(idClassificacao: number): Observable<any> {
        return this.http.get(this.baseUrlProcesso + 'localizacao/tiposConf/' + idClassificacao, httpOptions)
            .pipe();
    }

    getTipoLocalizacao(idTipoLocalizacao: number): Observable<any> {
        return this.http.get(this.baseUrlProcesso + 'localizacao/tipo/' + idTipoLocalizacao, httpOptions)
            .pipe();
    }

    getLocalizacao(idProcesso: number): Observable<any> {
        return this.http.get(this.baseUrlProcesso + 'localizacao/' + idProcesso, httpOptions)
            .pipe();
    }

    gravarTipoLocalizacao(modelo: any): Observable<any> {
        return this.http.post(this.baseUrlProcesso + 'localizacao/tipo/', modelo, httpOptions)
            .pipe();
    }

    getItensProcessoFA(parametros) {
        return this.http.post(this.baseUrlProcesso + 'processos/ProcessosFactItens', parametros, httpOptions).pipe();
    }

    getClassificacaoProcessosFA() {
        // https://appdemo.kiami.ao/pro/api/v1/classificacoes/facturacao
        return this.http.get(this.baseUrlProcesso + 'classificacoes/facturacao');
    }

    getProcessosFA() {
        return this.http.get(this.baseUrlProcesso + 'processos/facturacao');
    }

    getArtigosProcessosFA(idIdioma?: number, idProcesso?: number) {
        return this.http.get(this.baseUrlProcesso + 'processos/processoF/' + idIdioma + '/' + idProcesso);
    }

    postLocalizacao(req: any) {
        return this.http.post<Resposta<any>>(`${this.baseUrlProcesso}processos/${req.idProcesso}/localizacoes`, req);
    }

    eliminarLocalizacao(id: number) {
        return this.http.post<Resposta<any>>(`${this.baseUrlProcesso}processos/localizacoes/remover`, id);
    }

    onActual(id: number) {
        return this.http.post<Resposta<any>>(`${this.baseUrlProcesso}processos/localizacoes/actual`, id);
    }

    getDocumentos(id: number) {
        return this.http.get<Resposta<Documentos[]>>(`${this.baseUrlProcesso}processos/${id}/documentos`);
    }

    getDocumentosCP(id: number) {
        return this.http.get<Resposta<Documentos[]>>(`${this.baseUrlProcesso}processos/${id}/documentos-compra`);
    }

    getSubEstados(id: number) {
        return this.http.get<Resposta<any[]>>(`${this.baseUrlProcesso}estados/${id}/sub-estados`);
    }

    addSubEstado(id: number, idEstado: number) {
        return this.http.post<Resposta<any>>(`${this.baseUrlProcesso}estados/${id}/sub-estados`, idEstado);
    }

    removerSubEstado(id: number, idEstado: number) {
        return this.http.post<Resposta<any>>(`${this.baseUrlProcesso}estados/${id}/sub-estados/remover`, idEstado);
    }
}
