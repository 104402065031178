import {Component, EventEmitter, Input, Output} from '@angular/core';
import { mapaAccao } from '@config/accao.config';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ki-modal-base',
    template: `
        <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        <section>
            <!--  Header da modal  -->
            <div class="modal-header p-1 relative" [class.bg-primary]="primary">
                <span mat-dialog-close (click)="cancelar(true)" class="cursor-pointer btn-close-modal overflow-hidden">
                  <mat-icon>close</mat-icon>
                </span>
                <div class="bg-blue">
                    <h3 mat-dialog-title class="flex-x-btw font-weight-bold">
                        <span [ngClass]="{'text-primario': !primary}">{{ tituloModal }}</span>
                    </h3>
                </div>
            </div>
            <mat-divider *ngIf="comBorda"></mat-divider>
            <!-- Corpo da Modal -->
            <div class="k-form-crud">
                <!-- Se estiver a cpomplicar, copiar codigo base do formulario e colocar no lugar do ng-content -->
                <ng-content></ng-content>
            </div>
            <!-- Area dos botoes das accoes da modal -->
            <div class="sec-accao-modal bg-white" style="z-index: 23">
                <mat-divider *ngIf="comBorda"></mat-divider>
                <div class="p-1 flex-x-btw">
                    <span *ngIf="!verBtnGravar" fxFlex></span>
                    <button *ngIf="verBtnGravar" color="primary" [disabled]="accao === 'D'" (click)="aoGravar.emit(true)" mat-button
                            class="mr-1 bg-primary">{{ btnGravar ?? 'Gravar' }}
                    </button>
                    <span *ngIf="verBtnGravar" fxFlex></span>
                    <button color="primary" mat-dialog-close="null" (click)="cancelar(true)" mat-button
                            class="mx-1 bg-secondary"> {{ accao === 'D' || accao === 'E' ? 'Fechar' : 'Cancelar' }}
                    </button>
                </div>
            </div>
        </section>
    `
})
export class ModalBaseComponent {
    @Input() tituloModal = '';
    @Input() loading = false;
    @Input() btnCancelar = '';
    @Input() btnGravar = 'Guardar';
    @Input() verBtnGravar = true;
    @Input() comBorda = true;
    @Input() primary = false;
    @Input() accao = '';
    @Input() codigoAccao = '';
    @Output() aoGravar = new EventEmitter();
    @Output() aoCancelar = new EventEmitter();
    mapaAccao = mapaAccao;
    constructor() {
    }

    cancelar(valor: boolean) {
        this.aoCancelar.emit(valor);
    }

}
