import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChildren
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatSelect} from '@angular/material/select';

@Component({
    selector: 'app-select-retecoes-check-box',
    templateUrl: './select-retecoes-check-box.component.html',
    styleUrls: ['./select-retecoes-check-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectRetecoesCheckBoxComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() lista: any[] = [];
    @Input() chaveId = '';
    @Input() chaveTexto = '';
    @Input() placeholder = '';
    @Input() limpar = false;
    @Output() obterSelecionadoId = new EventEmitter();
    @ViewChildren('check') check: any[] = [];
    listaInterna: any [] = [];
    semaforo = true;
    idSelected = 0;
    ocultarCheckBoxNoForm = true;
    form: FormGroup;

    constructor(private fb: FormBuilder) {
        this.form = this.initForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.limpar) {
            this.ocultarCheckBoxNoForm = true;
            this.form.get('selecionado').setValue('');
        }

        const obj = {
            [this.chaveId]: 0,
            [this.chaveTexto]: 'Não usa'
        };
        this.listaInterna = this.lista;
        this.listaInterna.push(obj);
        this.listaInterna.sort((a, b) => a[this.chaveId] - b[this.chaveId]);

    }

    initForm(): FormGroup {
        return this.fb.group({
            selecionado: []
        });
    }

    ngOnInit(): void {
    }

    initStr(): string {
        return '<input type=\'checkBox\'><span class=\'mat-select-min-line ng-tns-c143-3 ng-star-inserted\'>Não Usa1</span>';
    }

    ngAfterViewInit() {
        const el = document.getElementById('mat-select-value-1');
        if (el) {
            // el.children[0].innerHTML = this.initStr();
        }
    }

    pevDefault(evento) {
        evento.preventDefault();
    }

    setFromCheckBox(id: number) {
        this.idSelected = id;
        const checks: MatCheckbox[] = (this.check as any)._results as MatCheckbox[];
        this.semaforo = false;
        checks[0].checked = id !== 0;
    }


    onSelect(evento: any) {
        if (evento?.value && (evento?.value as []).length > 1) {
            evento.value = (evento?.value as []).slice(1, 2);
        }
        (evento?.source as MatSelect)?.close();
    }

    // MatCheckbox
    setOption(id: number) {
        if (!id) {
            id = this.idSelected;
        } else {
            this.semaforo = true;
        }

        const checks: MatCheckbox[] = (this.check as any)._results as MatCheckbox[];
        let desmarcar = true;
        checks.forEach(check => {
            if (id === parseInt(check.id, 10)) {
                desmarcar = !(id !== 0 && !check.checked);

                if (this.semaforo) {
                    check.toggle();
                } else {
                    this.semaforo = true;
                }

                // && (check.checked !== false || )
                // if (id !== '0' ){

                //   desmarcar = false;
                // } else {
                //   desmarcar = true;
                // }

                // if (check.checked === false) {
                //   desmarcar = true;
                // }
                checks[0].checked = true;
            } else {
                check.checked = false;
            }
        });

        if (desmarcar) {
            checks[0].checked = false;
            this.ocultarCheckBoxNoForm = true;
            this.form.get('selecionado').setValue('0');
            this.obterSelecionadoId.emit({
                idSelecionado: null
            });
        } else {
            this.ocultarCheckBoxNoForm = false;
            this.obterSelecionadoId.emit({
                idSelecionado: this.form.get('selecionado').value
            });
        }
    }
}
