<div class="card-container">
    <mat-card fxLayout="row" *ngFor="let item of list" class="p-1" style="justify-content: space-between">
        <div fxLayout="column" style="max-width: 185px;height: 100%;" class="cdk-drag-preview">
            <p class="font-weight-bold text-whi bg-primary"
               style="min-height: 20px;text-align: center;margin-bottom: 0">{{ item[header] }}
            </p>
            <div class="img-container">
                <ng-container *ngTemplateOutlet="columns.get(0).template; context: {$implicit: item}"></ng-container>
            </div>
            <p class="font-weight-bold text-whi bg-secondary"
               style="text-align: center;margin: 0">{{ item[footer] }}</p>
        </div>

        <div class="bio">
            <ng-container *ngTemplateOutlet="columns.get(1).template; context: {$implicit: item}"></ng-container>
        </div>

        <div class="relative bg-primary">
          <span class="absolute right-0 top-0">
            <ng-container *ngTemplateOutlet="columns.get(2).template; context: {$implicit: item}"></ng-container>
          </span>
        </div>
    </mat-card>
</div>
