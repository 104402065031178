import { Pipe, PipeTransform } from '@angular/core';
import { Empresa } from 'app/shared/models/empresa/empresa';

@Pipe({
  name: 'mostrarCodEmpresa',
  pure: true
})
export class MostrarCodEmpresaPipe implements PipeTransform {

  transform(empresas: Empresa[]): boolean {

    if ( empresas && empresas?.length >1 ){
      let tamanho = 0;
      for( let i = 0; i < empresas?.length; i++) {
        if ( empresas[i]?.estado?.toLowerCase() ==="Activo".toLowerCase()){
          tamanho ++;
        }
        if ( tamanho > 1) {
          return true;
        }
      }
    } else {

      return false;
    }

    return false;
  }

}
