import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroEmailsManuais',
  pure: true
})
export class FiltroEmailsManuaisPipe implements PipeTransform {

  transform(emailsManuais:any[], value: string): any[] {
    const termo = value;
    if (emailsManuais.length > 0 && termo){
      emailsManuais = emailsManuais.filter( emailManual => {
        return (emailManual?.descricao.toLowerCase() as string)
        .includes( termo.toLowerCase());
       })
    }

    return emailsManuais;
  }

}
