import { Pipe, PipeTransform } from '@angular/core';

interface Artigo {
  idClassificacao: number;
  idArtigo: number;
  codArtigo:string;
  artigo: string;
  idImposto: number;
  taxaIva: number;
  idUnidadeMedida: number;
  codUnidade: string;
  unidadeSingular: string;
  unidadePlural:  string;
  sujeitoARetencao: boolean,
  tipo: string;
  estado: string;
  idRetencao: number;
  preco1: number;
  geridoPorStocks: boolean;
  renovavel: boolean
}

@Pipe({
  name: 'filtarArtigo',
  pure: true
})

export class FiltarArtigoPipe implements PipeTransform {

  transform( artigos: Artigo[], codCategoriaDocumento): Artigo[] {

    if (artigos.length > 0 && codCategoriaDocumento !== 'O') {
      artigos = artigos.filter( artigo => {
        return artigo.tipo !== 'Evento' && !(artigo.tipo === 'Serviço' && artigo?.renovavel);
      });
    }

    return artigos;
  }

}
