<div #se>
    <div class="full-width" [formGroup]='form'>
        <mat-form-field #matform class="full-width" appearance="outline">

            <mat-label>{{placeholder}}</mat-label>
            <input
                #input1
                (focus)="setIconVisible()"
                (blur)="ocultarIcon(se,matform)"
                matInput
                formControlName="valor"
                [placeholder]="placeholder" [readonly]="accao === actions.detalhes"
                [matAutocomplete]="componetKey"
                (input)="filtrarArtigoClassificacao($event)"
                (click)="openPanel(input1)"
            />

            <mat-icon
                #icon
                *ngIf="checkBox"
                matSuffix
                [ngStyle]="{'cursor': 'pointer'}"
                (click)="closeList(se, matform, icon)"
            >
                arrow_drop_down
            </mat-icon>

        </mat-form-field>

        <mat-autocomplete
                id="autoC-1"
                [panelWidth]="tamanhoPainel"
                #componetKey="matAutocomplete"
                class="full-width"
                style="min-width: 250px!important;"
                (closed)="onClose()"
                (optionSelected)="aoSelecClassificacaoPorAutoComplete($event, se)"
        >
            <div class="content-height">
                <mat-option
                    #header
                    class="bg-primary"
                    style="padding: 0;"
                    disabled
                    (click)="desativarAccaoAoClicarNoHeader(header)"
                >
                    <div
                            *ngIf="checkBox"
                            fxLayout="row"
                            class="padding-div-content"
                            (click)="todos(t, !todosbol)"
                    >
                        <span
                                (click)="activarSemaforo()"
                                class="flex border-b-light height-0"
                        >
                            <small class="height-0">
                                <mat-checkbox
                                    #t
                                    id="check-autoAll"
                                    color="primary"
                                    class="height-0"
                                    (change)="checkAll($event)"
                                >
                                </mat-checkbox>
                            </small>
                        </span>

                    </div>

                    <span
                            *ngFor="let coluna of colunas"
                            class="flex border-b-light"
                            [ngClass]="{'px-16': !checkBox}"
                            fxFlex="{{coluna.tamanho}}"
                    >
                        <small
                                class="w-30 p disabilitar"
                        >
                            {{coluna.valor}}
                        </small>
                    </span>

                    <div
                            class="flex-btn"
                            *ngIf="adicionarConfig"
                    >
                        <button
                                class="btn-autocomplete-plus"
                                [matTooltip]="adicionarConfig.matTooltip"
                                matTooltipClass="bg-secondary"
                                matTooltipPosition="above"
                                (click)="adicionarConfig.callback()"
                        >
                            <span>+</span>
                        </button>
                    </div>
                </mat-option>

                <div
                        class="overflow"
                >
                    <mat-option
                            fxLayout="row"
                            #options
                            class="padding-option"
                            *ngFor="let item of lista; let i=index"
                            [value]="item"
                    >
                        <div
                                (click)="defi( matform, 'option-'+i+'-input')"
                                fxLayout="row"
                                class="padding-div-content"
                        >
                            <span
                                    *ngIf="checkBox"
                                    (click)="activarSemaforo()"
                                    class="flex border-b-light span-position height-0"
                            >
                                <small class="height-0">
                                    <mat-checkbox
                                        #d
                                        id="option-{{i}}"
                                        (change)="selecionar($event, item, t)"
                                        color="primary"
                                        class="checkbox-position height-0"
                                    >
                                    </mat-checkbox>
                                </small>
                            </span>

                            <span
                                    *ngFor="let chave of chaves"
                                    class="flex border-b-light px-16"
                                    fxFlex="{{chave.tamanho}}"
                            >
                                <small
                                        class="font"
                                        matTooltip="{{getValue(item, chave.valor)}}"
                                        matTooltipClass="blue-1"
                                >
                                    {{getValue(item, chave.valor)}}
                                </small>
                            </span>
                        </div>
                    </mat-option>
                </div>
            </div>
        </mat-autocomplete>
    </div>
</div>
