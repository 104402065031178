import { Pipe, PipeTransform } from '@angular/core';
import { PessoasContactos } from '../models/clientes/pessoas-contactos';
import { GrupoContactoCliDto } from '../models/clientes/grupo-contacto-cli.dto';

@Pipe({
  name: 'agruparContactos',
  pure: true
}) export class AgruparContactosPipe implements PipeTransform {
  grupoContactos: GrupoContactoCliDto[] = [];
  transform(pessoaContacto: PessoasContactos): any {
    if(pessoaContacto && pessoaContacto.contactos && pessoaContacto.contactos.length > 0) {
      for(let i = 0; i < pessoaContacto.contactos.length; i++) {
        if (!this.grupoContactos.find(x => x.idTipoContacto === pessoaContacto.contactos[i].idTipoContacto)) {
          this.grupoContactos.push({
            idTipoContacto: pessoaContacto.contactos[i].idTipoContacto,
            tipoContacto: pessoaContacto.contactos[i].tipoContacto,
            contactos: [],
          });
        }
      }
      for(let i = 0; i < this.grupoContactos.length; i++){
        for(let j = 0; j < pessoaContacto.contactos.length; j++){
          if(this.grupoContactos[i].idTipoContacto === pessoaContacto.contactos[j].idTipoContacto){
            this.grupoContactos[i].contactos.push({
              activo: pessoaContacto.contactos[j].activo,
              contacto: pessoaContacto.contactos[j].contacto,
            })
          }
        }
      }
    }
    return this.grupoContactos.sort((a, b) => {
      return a.idTipoContacto - b.idTipoContacto;
    });
  }
}
