import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class NotificacaoService {
    accao!: string;

    constructor(private snack: MatSnackBar) {
    }

    notificar(mensagem: string, sucesso?: boolean, duracao?: number) {
        this.snack.open(
            mensagem,
            sucesso ? '✔️' : '❌',
            {
                verticalPosition: 'top',
                duration: duracao <= 0 || !duracao ? 5000 : duracao,
                panelClass: 'custom-snackbar'
            }
        );
    }


}
