import {ContentChild, Directive, Input, TemplateRef} from '@angular/core';
import {SectionDirective} from '@directives/section.directive';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[k-card-column]',
})
export class KCardColumnDirective {
    @ContentChild(SectionDirective, {read: TemplateRef, static: true})
    _cellTemplateQuery: TemplateRef<any>;

    @Input('section')
    _cellTemplateInput: TemplateRef<any>;

    get sectionTemplate(): TemplateRef<any> {
        return this._cellTemplateInput || this._cellTemplateQuery;
    }

    constructor() {
    }

}
