import { F } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output,  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-intervalo',
  templateUrl: './intervalo.component.html',
  styleUrls: ['./intervalo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntervaloComponent implements OnInit {
  canOver = true;
  permitir = false;
  auxMin = '';
  auxMax = '';
  @Input('placeholder') placeholder: any;
  @Input('min') min: any;
  @Input('max') max: any;
  @Input('descricao') descricao: any;
  @Output('valores') valores = new EventEmitter();
  constructor(
    private fb: FormBuilder
  ) {
    this.placeholder = 'texto default'
  }

  ngOnInit(): void {
    if ( this.min=== null ) {
      this.filterForm.get(this.FORM.minVer).setValue('');

    } 
    
    if ( this.max=== null ) {
      this.filterForm.get(this.FORM.maxVer).setValue('');
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    if ( this.min=== null ) {
      this.filterForm.get(this.FORM.min).setValue(this.min);
      this.filterForm.get(this.FORM.minVer).setValue('');

    } 
    
    if ( this.max=== null ) {
      this.filterForm.get(this.FORM.max).setValue(this.FORM.max);
      this.filterForm.get(this.FORM.maxVer).setValue('');
    }
    
  }

  FORM = {
    empresas: 'idEmpresas',
    movimentos: 'idMovimentos',
    tipoMovimentos: 'tipoMovimentos',
    armazens: 'idArmazens',
    artigos: 'idArtigos',
    dataInicio: 'dataInicio',
    dataFim: 'dataFim',
    min: 'min',
    max: 'max',
    montanteMin: 'montanteMin',
    montanteMax: 'montanteMax',
    minVer: 'minVer',
    maxVer: 'maxVer',
    montanteMinVer: 'montanteMinVer',
    montanteMaxVer: 'montanteMaxVer',
    minQuantidadeAux: 'minQuantidadeAux',
    maxQuantidadeAux: 'maxQuantidadeAux',
    minMontanteAux: 'minMontanteAux',
    maxMontanteAux: 'maxMontanteAux',
    caracterQuantidadeMinValido: 'caracterQuantidadeMinValido',
    caracterQuantidadeMaxValido: 'caracterQuantidadeMaxValido',
    caracterMontanteMinValido: 'caracterMontanteMinValido',
    caracterMontanteMaxValido: 'caracterMontanteMaxValido'
  };

  filterForm: FormGroup = this.fb.group({
    [this.FORM.empresas]: [],
    [this.FORM.movimentos]: [],
    [this.FORM.tipoMovimentos]: [],
    [this.FORM.armazens]: [],
    [this.FORM.artigos]: [],
    [this.FORM.dataInicio]: null,
    [this.FORM.dataFim]: null,
    [this.FORM.min]: [null],
    [this.FORM.max]: [null],
    [this.FORM.montanteMin]: [],
    [this.FORM.montanteMax]: [],
    [this.FORM.minVer]: [''],
    [this.FORM.maxVer]: [''],
    [this.FORM.montanteMinVer]: [''],
    [this.FORM.montanteMaxVer]: [''],
    [this.FORM.minQuantidadeAux]: [''],
    [this.FORM.maxQuantidadeAux]: [''],
    [this.FORM.minMontanteAux]: [''],
    [this.FORM.maxMontanteAux]: [''],
    [this.FORM.caracterQuantidadeMinValido]: [],
    [this.FORM.caracterQuantidadeMaxValido]: [],
    [this.FORM.caracterMontanteMinValido]: [],
    [this.FORM.caracterMontanteMaxValido]: [],
  });

  get f() {
    return this.filterForm.controls;
  }

 OnHidden(evento: any) {
  // console.log('++++')
   this.canOver = true;
   
  }

async setState(evento: any) {
  // await this.delay(100);
  // console.log('why')
  setTimeout(() => {

      this.canOver = false;
  }, 0);
  const auxMin = this.filterForm.get(this.FORM.minVer).value;
  const auxMax = this.filterForm.get(this.FORM.maxVer).value;
  const min: number = auxMin && auxMin !== '' ? parseFloat(auxMin.replace(/[.]/g, '').replace(/,/g, '.')) : null;
  const max: number = auxMax && auxMax !== '' ? parseFloat(auxMax.replace(/[.]/g, '').replace(/,/g, '.')) : null;
  this.filterForm.get(this.FORM.minVer).setErrors(null);
  this.filterForm.get(this.FORM.maxVer).setErrors(null);

  if (min && max) {
      min > max ? this.filterForm.get(this.FORM.minVer).setErrors({inválido: true}) : '';
      min > max ? this.filterForm.get(this.FORM.maxVer).setErrors({inválido: true}) : '';
  }

  this.filterForm.get(this.FORM.minVer).markAsTouched();
  this.filterForm.get(this.FORM.maxVer).markAsTouched();
}

  validarIntervalo(formControl1: FormControl, formControl2: FormControl, from: string) {


  }

  validarEntrada(event: any, formControlEstado: FormControl) {
    this.permitir = true;
    let keyCode: number = event.keyCode;
    if (keyCode === 8 || (keyCode >= 40 && keyCode <= 57)
    || (keyCode >= 96 && keyCode <= 105)
    ) {
      formControlEstado.setValue(true);
      return;
    }
    formControlEstado.setValue(false);
  }


  async validarMin(
    valor: string,
    formControlVer: FormControl,
    formControl: FormControl,
    formControlAux: FormControl,
    formControlEstado: FormControl,
    from) {

    let sinalValido: boolean = true;
    valor = valor.replace(/\s/g, '');

    // if (valor.includes('-')) {
    //   sinalValido = valor.startsWith('-') && 1 >= valor.match(/-/g).length;
    // }
    //Aqui
    let valido: boolean = formControlEstado.value

    if (!valido || parseInt(valor.replace(/\s/g, ''), 10) === 0) {
      this.permitir = false;
      formControl.setValue(formControlAux.value);
      formControlVer.setValue(formControlAux.value);
      return;
    }

    this.permitir = false;

    await formControl.setValue(valor);
    await formControlVer.setValue(valor);
    await formControlAux.setValue(valor);

    if (from === 'min') {
      this.auxMin = valor;

  } else if (from === 'max') {
      this.auxMax = valor;

  }

  const min: number = this.auxMin && this.auxMin !== '' ? parseFloat(this.auxMin.replace(/[.]/g, '').replace(/,/g, '.')) : null;
  const max: number = this.auxMax && this.auxMax !== '' ? parseFloat(this.auxMax.replace(/[.]/g, '').replace(/,/g, '.')) : null;
  this.filterForm.get(this.FORM.minVer).setErrors(null);
  this.filterForm.get(this.FORM.maxVer).setErrors(null);
  if (this.auxMin && this.auxMax) {
      min > max ? this.filterForm.get(this.FORM.minVer).setErrors({inválido: true}) : '';
      min > max ? this.filterForm.get(this.FORM.maxVer).setErrors({inválido: true}) : '';

  }

  this.filterForm.get(this.FORM.minVer).markAsTouched();
  this.filterForm.get(this.FORM.maxVer).markAsTouched();

  this.valores.emit({
      min,
      max,
      valido: (this.filterForm.get(this.FORM.minVer).valid && this.filterForm.get(this.FORM.maxVer).valid)
  });

  }


}
