import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Utilizadores} from '../../models/aplicacao/utilizadores';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  private userData$ = new BehaviorSubject<Utilizadores>(null);
  private logotipoApp$ = new BehaviorSubject<any>(null);
  private nome$ = new BehaviorSubject<string>('');
  private fotoPerfil$ = new BehaviorSubject<string>('');
  private varianteTab$ = new BehaviorSubject<any>(null);
   exportar = new BehaviorSubject<boolean>(false);

  constructor() { }

  setLogo(logo: any) {
    this.logotipoApp$.next(logo);
  }

 getLogo(): Observable<any> {
   return this.logotipoApp$.asObservable();
  }

    setInfoUtilizador(utilizador: Utilizadores) {
        this.userData$.next(utilizador);
    }

    getInfoUtilizador(): Observable<Utilizadores> {
        return this.userData$.asObservable();
    }

  setNome(nome: string) {
    this.nome$.next(nome);
  }

    setFotoPerfil(foto: string) {
        this.fotoPerfil$.next(foto);
    }
    getFotoPerfil(): Observable<string> {
        return this.fotoPerfil$.asObservable();
    }

  getNome(): Observable<string> {
   return this.nome$.asObservable();
  }
  setTabVariante(tabvariante: any) {
    this.varianteTab$.next(tabvariante);
  }
  getTabVariante() {
    return this.varianteTab$.asObservable();
}

setExportar(exportar: boolean) {
      this.exportar.next(exportar);
}

getExportar() {
      return this.exportar.asObservable();
}
}
