import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Utilizadores} from '../../models/aplicacao/utilizadores';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class FixedStoreService {
  private fixTop$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  setFixTop(fixed: any) {
    this.fixTop$.next(fixed);
  }

 getFixTop(): Observable<any> {
   return this.fixTop$.asObservable();
  }

}
