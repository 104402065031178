import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tabTotalizador',
  pure: true,
})
export class TabTotalizadorPipe implements PipeTransform {
  transform(listaDados: any[], prop: string): any {
    let total = 0;
    if (listaDados && listaDados.length && listaDados.length > 0) {
      if (prop && prop.length && prop.length > 0) {
        if(prop === 'pendenteTotal') {
          total = listaDados.reduce((a, obj) => {
            return a + (obj[prop] + obj['pendenteIva']);
          }, 0);
        } else {
          total = listaDados.reduce((a, obj) => {
            return a + obj[prop];
          }, 0);
        }

      }
    }
    return total;
  }

}
