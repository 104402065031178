import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'modal-sessao-expirada',
    template: `
    <section>
        <div style="margin-bottom: 40px; padding: 20px " >
            <div class="flex-xy-center p-1">
                <button mat-mini-fab color="warn">
                     <mat-icon>warning</mat-icon>
                </button>
            </div>
            <h3 class="py-1">
                <span class="text-primary"> {{mensagem}} </span>
            </h3>
        </div>
        
        <div fxLayout="row wrap" class="sec-accao-modal " >
            <div fxFlex="100" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100"  class="p-1" >
                 <span></span>
                 <button class="bg-primary" fxFlex type="button" mat-button (click)="redirecionarLogin()">OK</button>
                 <span></span>
            </div>
        </div>
    </section>
    `
// tslint:disable-next-line:component-class-suffix
}) export class ModalSessaoExpirada {
    mensagem = '';
    constructor(private modalRef: MatDialogRef<ModalSessaoExpirada>, private rota: Router, @Inject(MAT_DIALOG_DATA) public dados: any) {
        if (dados && dados.mensagem) {
            this.mensagem = dados.mensagem;
        }
    }

    redirecionarLogin() {
        localStorage.removeItem('utilizadorSessao');
        localStorage.removeItem('_utilizador');
        localStorage.removeItem('token');
        localStorage.removeItem('permissoesUtilizador');
        this.rota.navigateByUrl('/sessoes/login');
        this.modalRef.disableClose = false;
        this.modalRef.afterClosed();
    }
}
