import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[statusBgColor]'
})
export  class EstadoBgColorDirective implements OnInit {
  @Input() statusPag: string = '';
  bgClass: string = 'light-blue-700 light-blue-700-fg';
  constructor(private el: ElementRef, private renderer: Renderer2) {
  }
    ngOnInit() {
      this.setBgClass();
    }

//   class="pr-1 pl-1
// {{row?.estadoPagamento &&  row.estadoPagamento?.toLowerCase().trim() ===
//   ' Por Pagar '.toLowerCase().trim() ? 'orange-800 orange-800-fg':
//     row?.estadoPagamento &&  row.estadoPagamento?.toLowerCase().trim() ===
//   ' Pago '.toLowerCase().trim() ? 'light-green-800 light-green-800-fg':
//     row.estadoPagamento?.toLowerCase().trim() ===
//   ' Anulado '.toLowerCase().trim() ? 'red-A100 red-A400-fg':'light-blue-700 light-blue-700-fg'
// }}"
    setBgClass() {
      switch (true) {
        case this.statusPag.toLowerCase().trim() === 'por pagar': {
          this.bgClass = 'orange-800 orange-800-fg';
        }
        break;
        case this.statusPag.toLowerCase().trim() === 'pago':
        {
          this.bgClass = 'light-green-800 light-green-800-fg';
        }
          break;
        case this.statusPag.toLowerCase().trim() === 'anulado':
        {
          this.bgClass = 'light-green-800 light-green-800-fg';
        }
          break;
      }

      this.renderer.setAttribute(this.el.nativeElement, 'class', this.bgClass);
    }
}
