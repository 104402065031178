<!-- Telefone -->
<div class="flex container-tel flex-tel" fxFlex="32" fxFlex.xl="22" fxFlex.lg="26"
fxFlex.gt-xs="50" fxFlex.xs="100" class="pr-1 " formGroupName="formGroupTel">
<div class="input-tel-flag-code flex flex-tel">
    <div id="tel-component-1" class="tel-component">
        <mat-select #matSelect1 id="tel-select-component-1"
            class="select-tel tel-select-component" (selectionChange)="
            changeCountryCode(
            $event.source._id, 
            $event.value)" [value]="selTel" formControlName="selectedCountryCode">

            <mat-option *ngFor="let countryCode of  this.listaContriesFlag"
                [value]="countryCode">
                <span
                    class="flag-icon mr-8 flag-icon-{{countryCode.flag.toLowerCase()}}"></span>
                &nbsp;
                <span class="country"> {{countryCode.countryName}} </span> <span>
                    {{countryCode.dialCode}} </span>
            </mat-option>
        </mat-select>
    </div>
    <div class="w-100">
        <mat-form-field id="matForm-1" appearance="outline"
            class="div-in-tel flex flex-tel">
            <mat-label>Telefone</mat-label>
            <input #inTel autocomplete="off" name="tel-select-component-1" matInput
                class="in-tel-1" type="tel" placeholder="" formControlName="contacto"
                (keypress)="reconhecerCaracter($event)"
                (keydown)="isbackSpaceKeyBoard($event)" 
                (input)="validarTel(
                    $event.target.name, 
                    $event.target.value,1)" maxlength="17">
        </mat-form-field>
    </div>
</div>
</div>