import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'colTipo',
  pure: true
})
export class ColTipoPipe implements PipeTransform {

 public transform(tipo: any, prop: string): any {
     if (tipo && tipo[prop]) {
        return tipo[prop];
     }
     return '';
  }

}
