
<div  class="full-width content-intervelo" [formGroup]="filterForm" >
                   
                    <button 
                    class="qtd-button" 
                    [ngClass]="{
                        'qtd-button-sucess': !canOver && !(this.filterForm.get(this.FORM.minVer).invalid || this.filterForm.get(this.FORM.maxVer).invalid),
                        'qtd-button-error': this.filterForm.get(this.FORM.minVer).invalid || this.filterForm.get(this.FORM.maxVer).invalid,
                        'qtd-button-over': canOver && !(this.filterForm.get(this.FORM.minVer).invalid || this.filterForm.get(this.FORM.maxVer).invalid)
                        }"
                        
                        type="button"
                        (click)="op.toggle($event)"> 
                        <span>
                            {{(f[FORM.minVer].value ==='' && f[FORM.maxVer].value ==='')? ''+placeholder+'':
                            f[FORM.maxVer].value ==='' ? 'Desde '+f[FORM.minVer].value+' '+ descricao:
                            f[FORM.minVer].value ==='' ? 'Até '+f[FORM.maxVer].value+' '+descricao:
                            f[FORM.minVer].value+' a '+f[FORM.maxVer].value +' '+descricao}}
                        </span>
                        &nbsp;
                        <svg width="12px" height="12px" viewBox="0 0 512 512">

                            <path
                                d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z">
                            </path>
                        </svg>
                    </button>
                    <p-overlayPanel id="cdk-componente-intervalo" #op
                    (onShow)="setState($event);"
                    (onHide)="OnHidden($event)"
                     [style]="{width: '300px', height: '100px', 'font-size': '14px'}">
                        <ng-template pTemplate="body">
                            <mat-form-field id="cdk-componente-intervalo" id="cdk-componente-intervalo" appearance="outline" class="w-full" style="max-width: 40%">
                                <mat-label>Min</mat-label>
                                <input #Min matInput type="text" [name]="FORM.minVer" class="texto-right"
                                    (keydown)="validarEntrada($event, filterForm.get(this.FORM.caracterQuantidadeMinValido))"
                                    maxlength="13" 
                                    (input)="validarMin(
                                        Min.value, 
                                        filterForm.get(FORM.minVer), 
                                        filterForm.get(FORM.min),
                                        filterForm.get(FORM.minQuantidadeAux),
                                        filterForm.get(this.FORM.caracterQuantidadeMinValido),
                                        'min'   );
                               validarIntervalo(filterForm.get(FORM.minVer),
                                 filterForm.get(FORM.maxVer),
                                 'min');" [formControlName]="FORM.minVer">
                            </mat-form-field>
                            -
                             <mat-form-field id="cdk-componente-intervalo" appearance="outline" class="w-full" style="max-width: 40%">
                                <mat-label>Max</mat-label>
                                <input matInput #Max [name]="FORM.maxVer" class="texto-right"
                                    (keydown)="validarEntrada($event,
                                     filterForm.get(
                                        this.FORM.caracterQuantidadeMaxValido))"

                                    maxlength="13" 
                                    (input)="validarMin(
                                        Max.value,filterForm.get(FORM.maxVer), 
                                        filterForm.get(FORM.max), 
                                        filterForm.get(FORM.maxQuantidadeAux),
                                        filterForm.get(this.FORM.caracterQuantidadeMaxValido), 'max');

                                 validarIntervalo(filterForm.get(FORM.minVer),
                                 filterForm.get(FORM.maxVer),
                                 'max');
                                 " [formControlName]="FORM.maxVer">
                            </mat-form-field>
                        </ng-template> 
                    </p-overlayPanel>
                </div>
           