import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ArtigosService, Ciclo} from 'app/shared/services/artigos/artigos.service';
import {ArtigoClassificacao} from 'app/shared/models/artigos/artigo-classificacao';
import {Accao} from 'app/shared/models/ENUM';
import {mergeMap, switchMap} from 'rxjs/operators';
import {ClientesService} from 'app/shared/services/clientes-service/clientes.service';
import {LoginService} from 'app/shared/services/aplicacao-service/login.service';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CurrencyPipe, Location} from '@angular/common';
import {NotificacaoService} from 'app/shared/services/notificacao/notificacao.service';
import {ModalClientesInscricaoComponent} from '../modais/modal-clientes-inscricao/modal-clientes-inscricao.component';
import {MatDialog} from '@angular/material/dialog';
import {SelectionType} from '@swimlane/ngx-datatable';
import {Empresa} from 'app/shared/models/empresa/empresa';
import {FinancasService} from 'app/shared/services/financas/financas.service';
import {TipoDocumento} from 'app/shared/models/artigos/entidade/tipo-documento';
import {Util} from 'app/shared/models/generico/util';
import {MatSelectChange} from '@angular/material/select';
import {ArtigoComposicao} from 'app/shared/models/artigos/artigoComposicao';
import {CustomValidators} from 'app/shared/models/artigos/formacoes/custom-validators';
import {ModalConfirmacaoComponent} from '../../equipamentos/componentes/modais/modal-confirmacao/modal-confirmacao.component';
import {Resposta} from 'app/shared/models/resposta';
import {EquipamentoService} from 'app/shared/services/equipamentos/equipamento.service';
import {ClienteFacturacao, newClienteFat} from 'app/shared/models/financas/clientes/cliente-facturacao';
import {MetodosPagRec} from 'app/shared/models/financas/metodosPagRec';
import {Format} from '@directives/input-format.directive';
import {RenoModalFacturacaoComponent} from '../modais/reno-modal-facturacao/reno-modal-facturacao.component';

const FormNames = {
    codArtigo: 'codArtigo',
    idArtigo: 'idArtigo',
    idCliente: 'idCliente',
    idClassificacao: 'idClassificacao',
    nomeCliente: 'nomeCliente',
    estado: 'idEstadoArtigoRenovavel',
    preco: 'preco',
    descricao: 'descricao',
    ciclo: 'ciclo',
    ciclos: 'ciclos',
    idImposto: 'idImposto',
    ivaIncluido: 'ivaIncluido',
    dataInicio: 'dataInicio',
    designacao: 'designacao',
    codCliente: 'codCliente',
    dataFim: 'dataFim',
    dataSuspensao: 'dataSuspensao',
    dataRenovacao: 'dataRenovacao',
    dataExpiracao: 'dataExpiracao',
    data1aNotificacao: 'data1aNotificacao',
    data2aNotificacao: 'data2aNotificacao',
    data3aNotificacao: 'data3aNotificacao',
    data4aNotificacao: 'data4aNotificacao',
    paymentEstado: 'idEstadoPagamento',
    codigo: 'codigo',
    dataRegisto: 'dataRegisto',
    empresa: 'empresa',
    artigos: 'artigos',
    unidade: 'unidade',
    quantidade: 'quantidade',
    total: 'total',
    imposto: 'imposto',
    nomeArtigo: 'nomeArtigo',
    id: 'id',
    idRenovavel: 'idRenovavel',
    composicao: 'composicao',
    precoBloqueado: 'precoBloqueado',
    equipamento: 'equipamento',
    renovacoesAutomaticas: 'renovacoesAutomaticas',
    metodoPagamento: 'idMetodoPagRec',
    descontoPerc: 'descontoPerc',
    desconto: 'desconto',
    notificacoesDesactivadas: 'notificacoesDesactivadas',
    emailDestinatarios: 'emailDestinatarios'
};

export const DAY = 86400000;

export enum Estados {
    POR_ACTIVAR = 1,
    ACTIVO = 2,
    SUSPENSO = 3,
    EXPIRADO = 4,
    ELIMINADO = 5
}

export enum EstadoPagamento {
    POR_COBRAR = 1,
    POR_PAGAR = 2,
    PARCIALMENTE = 3,
    PAGO = 4,
    ANULADO = 5
}

export interface EstadoFluxo {
    id: number;
    idEstado?: number;
    idEstadoSeguinte: number;
    estado?: any;
    estadoSeguinte?: any;
}

@Component({
    selector: 'app-renovavel-form',
    templateUrl: './renovavel-form.component.html',
    styleUrls: ['./renovavel-form.component.scss'],
    providers: [CurrencyPipe]
})
export class RenovavelFormComponent implements OnInit, OnDestroy {
    @ViewChild('renovacoesTable') renovacoesTable!: any;
    loading = true;
    subscriptions: Subscription[] = [];
    public classificacao!: ArtigoClassificacao;
    public accao = '';
    actions = Accao;
    renovavel!: any;
    tiposDocumento: TipoDocumento[] = [];
    startDate: Date = new Date();
    today: Date = new Date();
    stateMachine: EstadoFluxo[] = [];
    readonly colors: { [key: number]: string } = {
        [EstadoPagamento.ANULADO]: 'red-A400 red-A400-fg',
        [EstadoPagamento.POR_PAGAR]: 'bg-secondary',
        [EstadoPagamento.PAGO]: 'green-A400',
        [EstadoPagamento.POR_COBRAR]: 'orange-A400',
        [EstadoPagamento.PARCIALMENTE]: 'cyan-A400'
    };
    readonly states = Estados;
    readonly formats = Format;
    readonly stateMap: { [key: number]: string } = {
        [Estados.ACTIVO]: 'activo',
        [Estados.POR_ACTIVAR]: 'por activar',
        [Estados.EXPIRADO]: 'expirado',
        [Estados.SUSPENSO]: 'suspenso'
    };
    readonly title: { [key: string]: any } = {
        [Accao.detalhes]: 'Detalhes',
        [Accao.editar]: 'Editar',
        [Accao.cadastrar]: 'Criar'
    };
    public clientes: ClienteFacturacao[] = [];
    public filteredClientes: any[] = [];
    FORM: { [key: string]: string } = FormNames;
    form: FormGroup = this.fb.group({
        [FormNames.codigo]: '',
        [FormNames.empresa]: [null, Validators.required],
        [FormNames.designacao]: ['', Validators.maxLength(60)],
        [FormNames.descricao]: '',
        [FormNames.nomeCliente]: '',
        [FormNames.ciclo]: [null, Validators.required],
        [FormNames.idCliente]: [0, [Validators.min(1), Validators.required]],
        [FormNames.codCliente]: ['', Validators.required],
        [FormNames.estado]: [Estados.POR_ACTIVAR, [Validators.min(1), Validators.required]],
        [FormNames.dataInicio]: [this.startDate, Validators.required],
        [FormNames.dataFim]: [null, Validators.required],
        [FormNames.metodoPagamento]: [null, Validators.required],
        [FormNames.emailDestinatarios]: ['', CustomValidators.destinatario()],
        [FormNames.dataSuspensao]: null,
        [FormNames.dataRenovacao]: null,
        [FormNames.dataExpiracao]: null,
        [FormNames.data1aNotificacao]: null,
        [FormNames.data2aNotificacao]: null,
        [FormNames.data3aNotificacao]: null,
        [FormNames.data4aNotificacao]: null,
        [FormNames.equipamento]: null,
        [FormNames.dataRegisto]: this.startDate,
        [FormNames.renovacoesAutomaticas]: false,
        [FormNames.notificacoesDesactivadas]: false,
        [FormNames.artigos]: this.fb.array([])
    });
    artigos: any[] = [];
    filteredArtigos: any[] = [];
    estados: any[] = [];
    activo!: any;
    ciclos: any[] = [];
    empresas: Empresa[] = [];
    impostos: any[] = [];
    submitted = false;
    id = 0;
    paymentEstados: any[] = [];
    rowSelectionType = SelectionType;
    renovacoes: any[] = [];
    selectedIndex = 0;
    artigoCtrlConfig = {
        [FormNames.id]: 0,
        [FormNames.idClassificacao]: 0,
        [FormNames.codArtigo]: '',
        [FormNames.idArtigo]: 0,
        [FormNames.idRenovavel]: 0,
        [FormNames.quantidade]: this.currencyPipe.transform(1, '', ''),
        [FormNames.preco]: this.currencyPipe.transform(0, '', ''),
        [FormNames.total]: this.currencyPipe.transform(0, '', ''),
        [FormNames.descontoPerc]: '0,00',
        [FormNames.desconto]: '0,00',
        [FormNames.idImposto]: null,
        [FormNames.imposto]: this.currencyPipe.transform(0, '', ''),
        [FormNames.ciclos]: [[]],
        [FormNames.designacao]: '',
        [FormNames.precoBloqueado]: false
    };
    totalIliq = 0;
    total = 0;
    idEquipamento = 0;
    metodosPagamento: MetodosPagRec[] = [];
    transistions: EstadoFluxo[] = [];
    private artigosRemover: any[] = [];
    private submitAction: { [key: string]: (servico: any) => Observable<any> } = {
        [Accao.cadastrar]: (servico: any) => this.artigoService.postRenovavel(servico),
        [Accao.editar]: (servico: any) => this.artigoService.editarRenovavel({
            ...servico, id: this.id, artigosRemover: this.artigosRemover
        })
    };

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        public artigoService: ArtigosService,
        private clienteService: ClientesService,
        private loginService: LoginService,
        private financaService: FinancasService,
        private fb: FormBuilder,
        private currencyPipe: CurrencyPipe,
        private _notificacao: NotificacaoService,
        private financasService: FinancasService,
        private router: Router,
        private eqService: EquipamentoService,
        public location: Location,
        private modal: MatDialog
    ) {
    }

    get f() {
        return this.form.controls;
    }

    get artigosForm() {
        return this.f[FormNames.artigos] as FormArray;
    }

    some(item: any, estados: string[], categorias: string[]) {
        return estados.includes(item.codEstadoDocumento) && categorias.includes(item.codCategoriaDocumento);
    }

    none(item: any) {
        return item.estado.id === EstadoPagamento.POR_PAGAR;
    }

    setForm(r) {
        this.renovavel = r.objecto;
        this.renovavel.renovacoesAutomaticas = this.classificacao?.idOpcRenovacaoAutomatica === 2 ?
            this.renovavel.renovacoesAutomaticas : this.classificacao?.idOpcRenovacaoAutomatica !== 1;
        this.form.patchValue({...r.objecto, empresa: this.empresas.find(e => e.idEmpresa === r.objecto.idEmpresa)});
        // const cliente = this.getCliente(r.objecto.idCliente);
        const cliente = r.objecto.cliente;
        const artigo = this.getArtigo(r.objecto.idArtigo);
        const ciclo = this.ciclos.find(c => c.idCicloRenovacao === this.renovavel.idCicloRenovacao);
        this.form.patchValue({
            [FormNames.codArtigo]: artigo?.codArtigo ?? '',
            [FormNames.nomeCliente]: cliente?.nome ?? '',
            [FormNames.idCliente]: cliente?.idCliente ?? 0,
            [FormNames.codCliente]: cliente?.codCliente ?? '',
            [FormNames.ciclo]: ciclo,
            [FormNames.dataRenovacao]: this.renovavel.dataProximaRenovacao,
            [FormNames.dataInicio]: new Date(r.objecto.dataInicio),
            [FormNames.dataFim]: new Date(r.objecto.dataFim)
        });
        this.transistions = this.stateMachine.filter(x => x.idEstado === this.f[FormNames.estado].value);
        this.setDatas(ciclo);
        const dNotify = r.objecto.notificacoesRenovacao;

        if (this.accao === Accao.editar) {
            this.form.patchValue({
                [FormNames.dataRenovacao]: this.renovavel.dataProximaRenovacao ?
                    new Date(this.renovavel.dataProximaRenovacao) : this.f[FormNames.dataRenovacao].value,
                [FormNames.dataSuspensao]: this.renovavel.dataSuspensao ?
                    new Date(this.renovavel.dataSuspensao) : this.f[FormNames.dataSuspensao].value
            });
            if (dNotify) {
                this.form.patchValue({
                    [FormNames.data1aNotificacao]: new Date(dNotify.data1aNotificacao),
                    [FormNames.data2aNotificacao]: new Date(dNotify.data2aNotificacao),
                    [FormNames.data3aNotificacao]: new Date(dNotify.data3aNotificacao),
                    [FormNames.data4aNotificacao]: new Date(dNotify.data4aNotificacao),
                });
            }
        }

        this.artigosForm.clear();
        r.objecto?.artigos.forEach((a, index) => {
            if (a.eliminado) {
                return;
            }
            const art = this.getArtigo(a.idArtigo);
            this.artigosForm.push(this.fb.group({
                id: a.id,
                idRenovavel: a.idRenovavel,
                idClassificacao: a.idClassificacao,
                idArtigo: a.idArtigo,
                idCicloRenovacao: art?.arartigosPrecosRenovaveis?.find(p => p.idCicloRenovacao === a.idCicloRenovacao),
                codArtigo: art?.codArtigo ?? a.codArtigo,
                designacao: a.designacao,
                idImposto: this.f[FormNames.empresa].value?.impostos?.find(i => i.idImposto === a.idImposto),
                imposto: this.currencyPipe.transform(a.imposto, '', ''),
                preco: this.currencyPipe.transform(a.preco, '', ''),
                quantidade: this.currencyPipe.transform(a.quantidade, '', ''),
                descontoPerc: this.currencyPipe.transform(a.descontoPerc, '', ''),
                desconto: this.currencyPipe.transform(a.desconto, '', ''),
                total: this.currencyPipe.transform(a.quantidade * a.preco, '', ''),
                precoBloqueado: a.composicao.length > 0,
                ciclos: [[...(art?.arartigosPrecosRenovaveis ?? [])]]
            }));
            if (a.composicao.length > 0) {
                let cmps = [];
                this.artigos.forEach(b => cmps = cmps.concat(cmps, b.composicao));
                (this.artigosForm.at(index) as FormGroup).addControl(this.FORM.composicao, this.fb.array([]));
                a.composicao.map(c => {
                    const cmp = cmps.find(x => x.idArtigoComposicao === c.idArtigoComposicao);
                    return {
                        idArtigoComposicao: c.idArtigoComposicao,
                        codigo: c.artigo.codArtigo,
                        idArtigo: cmp?.idArtigo,
                        descricao: c.descricao ?? c.artigo?.nomeArtigo,
                        quantidade: c.quantidade,
                        preco1: c.preco,
                        quantidadeVariavel: cmp?.quantidadeVariavel,
                        artigo: c.artigo
                    };
                }).forEach(comp =>
                    (this.artigosForm
                        .at(index)
                        .get(this.FORM.composicao) as FormArray)
                        .push(this.addNovaComp(comp))
                );
                const precoArtigo = a.composicao.reduce((acc, val) => acc + val.preco * val.quantidade, 0);
                this.setArtigoPreco(precoArtigo, index);
            }
            this.calcularItemTotal(a.preco, a.quantidade, this.artigosForm.at(index));
        });
        this.calculateTotal();
        if (this.accao !== Accao.detalhes) {
            this.artigosForm.push(this.fb.group(this.artigoCtrlConfig));
        }
        this.renovacoes = r.objecto?.renovacoes ?? [];
        this.f[this.FORM.dataInicio].setValidators(
            this.f[this.FORM.estado].value === this.activo.idArtigoRenovavelEstado ? [Validators.required] : []
        );
        this.f[this.FORM.dataInicio].updateValueAndValidity();
    }


    handleError(err) {
        this.loading = false;
        console.error(err);
        this._notificacao.notificar(err.error?.mensagem || 'Falha ao gravar o renovável');
    }

    emissaoInactiva() {
        return this.renovavel?.idEstadoArtigoRenovavel === Estados.SUSPENSO ||
            this.renovavel?.idEstadoArtigoRenovavel === Estados.ELIMINADO ||
            this.accao === Accao.cadastrar;
    }

    ngOnInit(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.subscriptions.push(
            this.route.params.pipe(
                mergeMap(ps => {
                    this.loading = true;
                    this.form.reset({
                        [FormNames.dataRegisto]: this.today,
                        [FormNames.dataInicio]: this.today,
                        [FormNames.estado]: Estados.POR_ACTIVAR
                    });
                    this.artigosForm.clear();
                    this.accao = ps['accao'];
                    if (this.accao !== Accao.detalhes) {
                        this.artigosForm.push(this.fb.group(this.artigoCtrlConfig));
                    }
                    this.id = parseInt(ps['id'], 10);
                    this.idEquipamento = parseInt(ps['idEquipamento'], 10);
                    const idClassificacao = parseInt(ps['idClassificacao'], 10);
                    return forkJoin([
                        this.artigoService.getClassificacaoArtigo(idClassificacao),
                        this.financasService.getEntidadesClientePorArea('C'),
                        this.artigoService.getArtigos({
                            idClassificacao,
                            codIdioma: 'pt',
                            idTiposArtigo: [],
                            idEstado: [1],
                            idUnidade: [],
                            renovavel: true
                        }),
                        this.artigoService.getEstadosRenovavel(),
                        this.artigoService.getFluxos()
                    ]);
                }),
                switchMap(([c, cs, as, es, ss]) => {
                    this.classificacao = c;
                    this.f[FormNames.renovacoesAutomaticas].patchValue(this.classificacao.renovacaoAutomaticaDefault);
                    this.clientes = cs.filter(a => a.activoFact);
                    this.filteredClientes = this.clientes;
                    this.artigos = as.objecto;
                    this.filteredArtigos = this.artigos;
                    this.estados = es.objecto;
                    this.activo = this.estados.find(e => e.artigoRenovavelEstado.toUpperCase() === 'ACTIVO');
                    this.stateMachine = ss.objecto;
                    return this.financasService.getMetodosPagamentos('rec');
                }),
                switchMap((ps: MetodosPagRec[]) => {
                    this.metodosPagamento = ps;
                    return this.artigoService.getCiclosRenovacao();
                }),
                switchMap(cs => {
                    this.ciclos = cs.objecto.filter(x => x.activo);
                    return this.artigoService.getArtigoIva('AO');
                }),
                switchMap(is => {
                    this.impostos = is.filter(i => i.activo);
                    return this.artigoService.getEmpresas();
                }),
                switchMap(emps => {
                    this.empresas = emps.objecto;
                    if (this.empresas.length === 1) {
                        this.f[this.FORM.empresa].patchValue(this.empresas[0]);
                        this.setCodigo(this.empresas[0]);
                    }
                    return this.financasService.getTiposDocumentos();
                }),
                switchMap((ts: any) => {
                    this.tiposDocumento = ts.objecto;
                    return this.artigoService.getListEstadosPagamentos();
                }),
                switchMap(ps => {
                    this.paymentEstados = ps.objecto;
                    return isNaN(this.idEquipamento) ? of(null) : this.eqService.getEquipamento(this.idEquipamento);
                }),
                mergeMap(eq => {
                    if (eq) {
                        this.f[FormNames.equipamento].patchValue({
                            ...eq.objecto,
                            natureza: eq.objecto.natureza?.nome,
                            texto: `${eq.objecto.codigo}${(eq.objecto.designacao ? ': ' + eq.objecto.designacao : '')}`
                        });
                        this.clientes = this.clientes
                            .filter(c => eq.objecto.entidades.some(e => e.idCliente === c.idCliente && e.relacao?.permiteVendas));
                        this.updateClienteFields(this.clientes.length === 1 ? this.clientes[0] : null);
                    }
                    if (this.accao === Accao.cadastrar) {
                        this.loading = false;
                        this.transistions = this.stateMachine.filter(x => x.idEstado === this.f[FormNames.estado].value);
                        return new Observable<any>();
                    }
                    return this.artigoService.getRenovavel(this.id);
                }),
                switchMap((r: any) => {
                    this.accao = r.objecto.idEstadoArtigoRenovavel === Estados.ELIMINADO ? Accao.detalhes : this.accao;
                    this.setForm(r);
                    return this.clienteService.getCliente(r.objecto.idCliente);
                })
            ).subscribe({
                next: (c: any) => {
                    const cliente = newClienteFat(c.objecto);
                    this.clientes.push(cliente);
                    this.filteredClientes = this.clientes;
                    this.updateClienteFields(cliente);
                    this.loading = false;
                },
                error: err => {
                    this.loading = false;
                    console.error(err);
                    this._notificacao.notificar(err.error?.mensagem || 'Falha ao carregar o renovável', false, 4000);
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    getCliente(idCliente: number) {
        return this.clientes.find(c => c.idCliente === idCliente);
    }

    getArtigo(idArtigo: number) {
        return this.artigos.find(a => a.idArtigo === idArtigo);
    }

    getTipoDocumento(id: number) {
        return this.tiposDocumento.find(t => t.idTipoDocumento === id);
    }

    toggleExpandRow(row) {
        this.renovacoesTable.rowDetail.toggleExpandRow(row);
    }

    textSwitch() {
        switch (this.renovavel?.idEstadoArtigoRenovavel) {
            case Estados.POR_ACTIVAR:
                return this.renovacoes.length > 0 ? 'Activar' : 'Eliminar';
            case Estados.SUSPENSO:
                return 'Activar';
            default:
                return 'Suspender';
        }
    }

    NaN(value: any) {
        return isNaN(value);
    }

    updateDatas(ciclo: any) {
        const dataInicio = new Date(this.f[FormNames.dataInicio].value);
        const dataFim = this.artigoService
            .calculateExpirationDate(ciclo, dataInicio);
        this.f[FormNames.dataFim].patchValue(dataFim);
        if (this.accao === Accao.cadastrar) {
            this._updateDatas(ciclo, dataInicio, dataFim);
        }
    }

    _updateDatas(ciclo, dataInicio, dataFim) {
        this.f[FormNames.dataSuspensao].patchValue(Util.addDays(dataFim, ciclo?.diasSuspensao ?? 0));
        this.f[FormNames.dataExpiracao].patchValue(Util.addDays(dataInicio, ciclo?.diasExpiracao ?? 0));
        this.f[FormNames.dataRenovacao].patchValue(Util.addDays(dataFim, ciclo?.diasRenovacao ?? 0));
        this.f[FormNames.data1aNotificacao].patchValue(Util.addDays(dataFim, ciclo?.dias2Notificacao ?? 0));
        this.f[FormNames.data2aNotificacao].patchValue(Util.addDays(dataFim, ciclo?.dias3Notificacao ?? 0));
        this.f[FormNames.data3aNotificacao].patchValue(Util.addDays(dataFim, ciclo?.dias4Notificacao ?? 0));
        this.f[FormNames.data4aNotificacao].patchValue(Util.addDays(dataFim, ciclo?.dias5Notificacao ?? 0));
    }

    setDatas(ciclo: any) {
        const dataInicio = new Date(this.f[FormNames.dataInicio].value);
        const dataFim = new Date(this.f[FormNames.dataFim].value);
        this._updateDatas(ciclo, dataInicio, dataFim);
    }

    filtrarArtigos(event: any) {
        const termo = event.target.value;
        if (!termo) {
            this.filteredArtigos = this.artigos;
            return;
        }

        this.filteredArtigos = this.artigos.filter(a =>
            a?.nomeArtigo?.toLowerCase()?.includes(termo.toLowerCase()) ||
            a?.codArtigo?.toLowerCase()?.includes(termo.toLowerCase())
        );
    }

    showArtigos() {
        this.filteredArtigos = this.artigos;
    }

    composicao(indexArtFbArray: number) {
        return this.artigosForm.at(indexArtFbArray).get('composicao') as FormArray;
    }

    addNovaComp(comp: ArtigoComposicao) {
        return this.fb.group({
            codigo: comp.codigo,
            descricao: comp.descricao,
            idArtigo: comp.idArtigo,
            idArtigoComposicao: comp.idArtigoComposicao,
            artigo: comp.artigo,
            preco1: comp.preco1,
            preco2: comp.preco2,
            quantidade: this.currencyPipe.transform(comp.quantidade, '', ''),
            quantidadeVariavel: comp.quantidadeVariavel
        });
    }

    updateArtigoFields(artigo: any, index: number) {
        if (artigo === null) {
            return;
        }
        this.loading = true;
        this.artigosForm.at(index).patchValue({
            [FormNames.codArtigo]: '',
            [FormNames.idArtigo]: 0,
            [FormNames.idClassificacao]: 0,
            [FormNames.idRenovavel]: this.id,
            [FormNames.designacao]: '',
            [FormNames.idImposto]: null,
            [FormNames.ciclo]: null,
            [FormNames.ciclos]: [],
            [FormNames.quantidade]: '1,00',
            [FormNames.total]: '0,00',
            [FormNames.imposto]: '0,00',
            [FormNames.desconto]: '0,00',
            [FormNames.descontoPerc]: '0,00',
            [FormNames.preco]: this.currencyPipe.transform(0, '', '')
        });
        this.subscriptions.push(
            this.artigoService.isArtigoValidoRenovacao(this.f[this.FORM.idCliente].value, artigo.idArtigo, this.id).subscribe({
                next: _ => {
                    if (index === this.artigosForm.length - 1) {
                        this.artigosForm.push(this.fb.group(this.artigoCtrlConfig));
                    }
                    const preco = artigo.arartigosPrecosRenovaveis
                        .find(p => p.idCicloRenovacao === this.f[this.FORM.ciclo].value?.idCicloRenovacao)?.preco1 ?? artigo.preco1;
                    this.artigosForm.at(index).patchValue({
                        [FormNames.codArtigo]: artigo.codArtigo,
                        [FormNames.idArtigo]: artigo.idArtigo,
                        [FormNames.idClassificacao]: artigo.idClassificacao,
                        [FormNames.idRenovavel]: this.id,
                        [FormNames.designacao]: artigo.nomeArtigo,
                        [FormNames.idImposto]: this.f[FormNames.empresa].value?.impostos?.find(i => i.idImposto === artigo.idImposto),
                        [FormNames.ciclos]: artigo.arartigosPrecosRenovaveis,
                        [FormNames.preco]: this.currencyPipe
                            .transform(preco, '', '')
                    });
                    this.artigosForm.at(index).get(FormNames.preco)
                        .addValidators([Validators.required, CustomValidators.greaterThanZero()]);
                    this.artigosForm.at(index).get(FormNames.preco).updateValueAndValidity();
                    this.artigosForm.at(index).get(FormNames.preco).markAsDirty();
                    this.artigosForm.at(index).get(FormNames.preco).markAsTouched();
                    this.artigosForm.at(index).get(FormNames.quantidade)
                        .addValidators([Validators.required, CustomValidators.greaterThanZero()]);
                    this.artigosForm.at(index).get(FormNames.quantidade).updateValueAndValidity();
                    this.artigosForm.at(index).get(FormNames.quantidade).markAsDirty();
                    this.artigosForm.at(index).get(FormNames.quantidade).markAsTouched();
                    this.artigosForm.at(index).get(FormNames.idImposto).addValidators([Validators.required]);
                    this.artigosForm.at(index).get(FormNames.idImposto).updateValueAndValidity();
                    this.artigosForm.at(index).get(FormNames.idImposto).markAsDirty();
                    this.artigosForm.at(index).get(FormNames.idImposto).markAsTouched();
                    this.artigosForm.at(index).get(FormNames.idArtigo).addValidators([Validators.required, Validators.min(1)]);
                    this.artigosForm.at(index).get(FormNames.idArtigo).updateValueAndValidity();
                    this.artigosForm.at(index).get(FormNames.idArtigo).markAsDirty();
                    this.artigosForm.at(index).get(FormNames.idArtigo).markAsTouched();

                    if (artigo.composicao.length > 0) {
                        this.artigosForm.at(index).get(FormNames.precoBloqueado).patchValue(true);
                        (this.artigosForm.at(index) as FormGroup).addControl(FormNames.composicao, this.fb.array([]));
                        ((this.artigosForm.at(index) as FormGroup).get(FormNames.composicao) as FormArray).clear();
                        artigo.composicao.forEach(comp =>
                            (this.artigosForm
                                .at(index)
                                .get(FormNames.composicao) as FormArray)
                                .push(this.addNovaComp(comp))
                        );
                        const precoArtigo = artigo.composicao.reduce((acc, val) => acc + val.preco1 * val.quantidade, 0);
                        this.setArtigoPreco(precoArtigo, index);
                    } else {
                        this.artigosForm.at(index).get(FormNames.precoBloqueado).patchValue(false);
                        (this.artigosForm
                            .at(index)
                            .get(FormNames.composicao) as FormArray)?.clear();
                    }
                    this.loading = false;
                    this.calcularItemTotal(preco, 1, this.artigosForm.at(index));
                    this.calculateTotal();
                },
                error: err => {
                    this._notificacao.notificar(err.error.mensagem || err.mensagem, false, 4000);
                    ((this.artigosForm.at(index) as FormGroup).get(FormNames.composicao) as FormArray)?.clear();
                    this.calculateTotal();
                    this.loading = false;
                }
            }));
    }

    updatePrecoCiclo(ciclo: any) {
        this.artigosForm.controls.forEach(i => {
            const preco = (i.get(this.FORM.composicao) as FormArray)?.value
                    ?.reduce((acc, val) => acc + val.preco1 * Util.convertToFloat(val.quantidade), 0) ??
                i.get(this.FORM.ciclos).value?.find(p => p.idCicloRenovacao === ciclo.idCicloRenovacao)?.preco1;
            const total = preco * Util.convertToFloat(i.get(this.FORM.quantidade).value);
            i.get(this.FORM.preco).patchValue(this.currencyPipe.transform(preco, '', ''));
            i.get(this.FORM.imposto).patchValue(this.currencyPipe.transform((i.get(this.FORM.idImposto)?.value?.taxa ?? 0) * total * 0.01, '', ''));
            i.get(this.FORM.total).patchValue(this.currencyPipe.transform(total, '', ''));
        });
        this.calculateTotal();
    }

    filtrarClientes(event: any) {
        const termo = event.target.value;
        if (!termo) {
            this.filteredClientes = this.clientes;
            return;
        }

        this.filteredClientes = this.clientes.filter(c =>
            c.codCliente?.toLowerCase()?.includes(termo.toLowerCase()) ||
            c.nome?.toLowerCase()?.includes(termo.toLowerCase())
        );
    }

    calcularTotal(quantidade: string, preco: number) {
        return preco * Util.convertToFloat(quantidade);
    }

    calcularTotalCiclo(row, key: string) {
        return row.artigos.reduce((sum, item) => sum + item[key], 0);
    }

    openClienteModal(event?: any) {
        event.stopPropagation();
        const modalRef = this.modal.open(ModalClientesInscricaoComponent, {
            width: '90vw',
            data: {
                classificacao: this.classificacao
            }
        });

        this.subscriptions.push(modalRef.afterClosed().subscribe(data => {
            if (data) {
                this.updateClienteFields(data);
            }
        }));
    }

    showClientes() {
        this.filteredClientes = this.clientes;
    }

    updateClienteFields(value: any) {
        this.form.patchValue({
            [this.FORM.nomeCliente]: value?.nome,
            [this.FORM.idCliente]: value?.idCliente,
            [this.FORM.codCliente]: value?.codCliente
        });

        if (!value || this.accao !== Accao.cadastrar) {
            return;
        }

        this.loading = true;
        this.subscriptions.push(
            this.clienteService.getCliente(value.idCliente).subscribe({
                next: res => {
                    this.loading = false;
                    this.f[FormNames.emailDestinatarios].patchValue(
                        [value.email || '', ...res.objecto.pessoasContactos
                            .filter(c => c.contactoFinanceiro)
                            .flatMap(c => c.contactos.filter(x => x.idTipoContacto === 1))
                            .map(c => c.contacto)].join('; ')
                    ); // 1 => "E-mail"
                },
                error: err => {
                    this.loading = false;
                    console.error(err);
                    this._notificacao.notificar(err.error?.mensagem || 'Falha inesperada ao carregar dados adicionais do cliente');
                }
            })
        );
    }

    validatePrice(el: HTMLInputElement, ctrl: AbstractControl) {
        const preco: number = Util.convertToFloat(el.value);
        const quantidade: number = Util.convertToFloat(ctrl.get(FormNames.quantidade).value);
        this.calcularItemTotal(preco, quantidade, ctrl);
        this.calculateTotal();
    }

    recalcularTotal(index: number) {
        const preco = this.artigosForm
            .at(index)
            .get(this.FORM.composicao).value
            .reduce((acc, val) => acc + val.preco1 * Util.convertToFloat(val.quantidade), 0);
        this.setArtigoPreco(preco, index);
        this.calculateTotal();
    }

    setArtigoPreco(preco: number, index: number) {
        const art = this.artigosForm.at(index);
        const qtd = Util.convertToFloat(art.get(FormNames.quantidade).value);
        art.get(FormNames.preco)
            .patchValue(this.currencyPipe
                .transform(preco, '', '')
            );
        art.get(FormNames.total)
            .patchValue(this.currencyPipe
                .transform(preco * qtd, '', ''));
        art.get(FormNames.imposto)
            .patchValue(this.currencyPipe
                .transform(preco * qtd * (art.get(FormNames.idImposto)?.value?.taxa ?? 0) * 0.01, '', ''));
    }

    validateQtd(el: HTMLInputElement, ctrl: AbstractControl) {
        const quantidade: number = Util.convertToFloat(el.value);
        const preco: number = Util.convertToFloat(ctrl.get(FormNames.preco).value);
        this.calcularItemTotal(preco, quantidade, ctrl);
        this.calculateTotal();
    }

    calcularItemTotal(preco: number, qtd: number, ctrl: AbstractControl) {
        const descontoPerc = Util.convertToFloat(ctrl.get(FormNames.descontoPerc).value) * 0.01;
        let total: number = preco * qtd;
        const desconto = total * descontoPerc;
        total -= desconto;
        ctrl.get(FormNames.imposto)
            .patchValue(this.currencyPipe.transform((ctrl.get(FormNames.idImposto)?.value?.taxa ?? 0) * total * 0.01, '', ''));
        ctrl.get(FormNames.total).patchValue(this.currencyPipe.transform(total, '', ''));
        ctrl.get(FormNames.desconto).patchValue(this.currencyPipe.transform(desconto, '', ''));
    }

    submit(voltar: boolean = false) {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        let i = 0;
        const artigos = [];
        for (const item of this.artigosForm.controls) {
            const preco = Util.convertToFloat(item.value.preco);
            const quantidade = Util.convertToFloat(item.value.quantidade);
            if (item.invalid) {
                item.markAllAsTouched();
                this._notificacao.notificar(
                    `${this.classificacao?.classificacaoS.toLowerCase()} número ${i + 1} é inválido. Por favor, preencha corretamente ou remova o item!`);
                return;
            }

            if (item.get(FormNames.idArtigo).value && preco > 0 && quantidade > 0) {
                artigos.push({
                    id: item.value.id,
                    idArtigo: item.value.idArtigo,
                    idRenovavel: item.value.idRenovavel,
                    idClassificacao: item.value.idClassificacao,
                    quantidade,
                    preco,
                    idImposto: item.value.idImposto?.idImposto,
                    imposto: Util.convertToFloat(item.value.imposto),
                    descontoPerc: Util.convertToFloat(item.value.descontoPerc),
                    desconto: Util.convertToFloat(item.value.desconto),
                    total: Util.convertToFloat(item.value.total),
                    designacao: item.value.designacao,
                    composicao: (item.value.composicao ?? []).map(c => {
                        return {
                            idArtigoComposicao: c.idArtigoComposicao,
                            quantidade: Util.convertToFloat(c.quantidade),
                            preco: c.preco1,
                            descricao: c.descricao
                        };
                    })
                });
            }
            i++;
        }

        if (artigos.length === 0) {
            this._notificacao.notificar(
                `${this.classificacao?.nomeRenovaveisS} não possui ${this.classificacao?.classificacaoP.toLowerCase()} associados(as). Por favor, preencha corretamente!`);
            return;
        }
        const d = new Date(this.form.value.dataInicio);
        const payload = {
            codigo: this.f[FormNames.codigo].value,
            [FormNames.emailDestinatarios]: this.f[FormNames.emailDestinatarios].value,
            idCliente: this.form.value.idCliente,
            idEmpresa: this.form.value.empresa?.idEmpresa,
            idEquipamento: isNaN(this.idEquipamento) ? null : this.idEquipamento,
            idEstadoArtigoRenovavel: this.form.value.idEstadoArtigoRenovavel,
            descricao: this.form.value.descricao,
            designacao: this.form.value.designacao,
            idCicloRenovacao: this.form.value.ciclo?.idCicloRenovacao,
            idCriador: this.loginService.getUtilizadorSessao().idUtilizador,
            [FormNames.metodoPagamento]: this.f[FormNames.metodoPagamento].value,
            renovacoesAutomaticas: this.form.value.renovacoesAutomaticas,
            notificacoesDesactivadas: this.f[FormNames.notificacoesDesactivadas].value,
            notificacoesRenovacao: {
                dataExpiracao: Util._UTCDate(this.f[FormNames.dataExpiracao].value),
                dataRenovacao: Util._UTCDate(this.f[FormNames.dataRenovacao].value),
                dataSuspensao: Util._UTCDate(this.f[FormNames.dataSuspensao].value),
                data1aNotificacao: Util._UTCDate(this.f[FormNames.data1aNotificacao].value),
                data2aNotificacao: Util._UTCDate(this.f[FormNames.data2aNotificacao].value),
                data3aNotificacao: Util._UTCDate(this.f[FormNames.data3aNotificacao].value),
                data4aNotificacao: Util._UTCDate(this.f[FormNames.data4aNotificacao].value)
            },
            artigos,
            dataInicio: new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
        };
        if (this.accao === Accao.cadastrar) {
            this.emitirDocumento('AC', payload);
        } else {
            this.loading = true;
            this.subscriptions.push(this.submitAction[this.accao](payload).subscribe({
                next: r => {
                    this.loading = false;
                    if (voltar) {
                        this.location.back();
                    }
                },
                error: err => this.handleError(err)
            }));
        }
    }

    calcularDesconto(e: Event, ctrl: AbstractControl) {
        const target = e.target as HTMLInputElement;
        const val = this.currencyPipe.transform(Util.clamp(Util.convertToFloat(target.value), 0, 100), '', '');
        ctrl.get(FormNames.descontoPerc).patchValue(val);
        const preco: number = Util.convertToFloat(ctrl.get(FormNames.preco).value);
        const quantidade: number = Util.convertToFloat(ctrl.get(FormNames.quantidade).value);
        this.calcularItemTotal(preco, quantidade, ctrl);
        this.calculateTotal();
    }

    emitirDocumento(code: string, renovavel?: any) {
        const noOffset = [Ciclo.DIARIO, Ciclo.SEMANAL, Ciclo.QUINZENAL];
        const d0 = new Date(this.f[this.FORM.dataInicio].value);
        const rs = this.renovacoes.filter(r => r.estado?.id !== EstadoPagamento.ANULADO);
        let dataInicio;

        if (rs.length > 0) {
            const df = new Date(rs
                .reduce((newest, curr) => new Date(curr.dataFim) > new Date(newest.dataFim) ? curr : newest).dataFim);
            dataInicio = d0 > df ?
                d0 : noOffset.some(o => this.f[this.FORM.ciclo].value?.idCicloRenovacao === o) ?
                    df : Util.addDays(df, 1);
        } else {
            dataInicio = d0;
        }

        renovavel.notificacoesRenovacao ??= {
            dataExpiracao: Util._UTCDate(this.f[FormNames.dataExpiracao].value),
            dataRenovacao: Util._UTCDate(this.f[FormNames.dataRenovacao].value),
            dataSuspensao: Util._UTCDate(this.f[FormNames.dataSuspensao].value),
            data1aNotificacao: Util._UTCDate(this.f[FormNames.data1aNotificacao].value),
            data2aNotificacao: Util._UTCDate(this.f[FormNames.data2aNotificacao].value),
            data3aNotificacao: Util._UTCDate(this.f[FormNames.data3aNotificacao].value),
            data4aNotificacao: Util._UTCDate(this.f[FormNames.data4aNotificacao].value)
        };
        renovavel.dataRenovacao ??= renovavel.notificacoesRenovacao?.dataRenovacao;
        renovavel.dataSuspensao ??= renovavel.notificacoesRenovacao?.dataSuspensao;
        let dataFim = this.artigoService
            .calculateExpirationDate(this.f[this.FORM.ciclo].value, dataInicio);
        dataFim = new Date(Date.UTC(dataFim.getFullYear(), dataFim.getMonth(), dataFim.getDate()));
        dataInicio = new Date(Date.UTC(dataInicio.getFullYear(), dataInicio.getMonth(), dataInicio.getDate()));
        const empresa = this.empresas.find(e => e.idEmpresa === renovavel?.idEmpresa);
        const end = this.f[FormNames.ciclo].value?.idCicloRenovacao === Ciclo.DIARIO ? '' : ` - ${dataFim.getDate()}/${dataFim.getUTCMonth() + 1}/${dataFim.getUTCFullYear()}`;
        const modalRef = this.modal.open(RenoModalFacturacaoComponent, {
            width: '100vw',
            data: {
                empresa,
                entidade: this.clientes.find(c => c.idCliente === renovavel?.idCliente),
                tipos: this.tiposDocumento.filter(t => t.activo && ['C', 'F', 'L'].includes(t.codCategoriaDocumento)),
                codTipo: code,
                action: this.accao,
                metodosPagamento: this.metodosPagamento,
                renovavel,
                itens: renovavel?.artigos?.filter(i => !i.eliminado).map(i => {
                    const art = this.getArtigo(i.idArtigo);
                    return {
                        idRenovavelClienteArtigo: i.id,
                        idArtigo: i.idArtigo,
                        codArtigo: art?.codArtigo ?? '',
                        idRetencao: art?.idRetencao,
                        artigo: art,
                        quantidade: i.quantidade,
                        preco: i.preco,
                        descricao: i.designacao ? `${i.designacao} (${dataInicio.getDate()}/${dataInicio.getUTCMonth() + 1}/${dataInicio.getUTCFullYear()}${end})` : '',
                        renovavelClienteArtigo: i.designacao,
                        idImposto: i.idImposto,
                        desconto: i.desconto,
                        descontoPerc: i.descontoPerc,
                        total: i.quantidade * i.preco - i.desconto,
                        Iva: i.imposto ?? 0,
                        imposto: i.imposto ?? 0,
                        dataInicio: i.dataInicio,
                        dataFim: i.dataFim,
                        idUnidadeMedida: i.idUnidadeMedida,
                        artigosComposicao: (i.composicao ?? []).map(c => {
                            const tax = this.impostos.find(x => x.idImposto === c.artigo.idImposto);
                            return {
                                idArtigo: c.idArtigoComposicao,
                                codArtigo: c.artigo?.codArtigo ?? '',
                                descricao: c.descricao,
                                quantidade: c.quantidade,
                                codUnidade: c.artigo?.codUnidade ?? '',
                                preco: c.preco,
                                desconto: 0,
                                descontoPerc: 0,
                                taxaIVA: tax?.taxa ?? 0,
                                codImposto: tax?.codImposto ?? '',
                                idImposto: tax?.idImposto,
                                imposto: c.imposto,
                                total: c.preco * c.quantidade + c.imposto,
                                texto: false
                            };
                        })
                    };
                })
            }
        });

        this.subscriptions.push(
            modalRef.afterClosed().pipe(
                mergeMap(accepted => {
                    if (!accepted) {
                        return new Observable<any>();
                    }
                    if (this.accao === Accao.cadastrar) {
                        this.location.back();
                        return new Observable<any>();
                    }

                    this.loading = true;
                    return this.artigoService.getRenovavel(this.id);
                })
            ).subscribe({
                next: r => {
                    this.setForm(r);
                    this.loading = false;
                },
                error: err => {
                    this.loading = false;
                    console.error(err);
                    this._notificacao.notificar(err.error?.mensagem || 'Falha ao carregar os documentos');
                }
            })
        );
    }

    calculateTotal(): void {
        this.totalIliq = this.artigosForm.value
            .reduce((acc, val) => {
                const total = Util.convertToFloat(val.total);
                return acc + (isNaN(total) ? 0 : total);
            }, 0);
        this.total = this.artigosForm.value
            .reduce((acc, val) => {
                const imposto = Util.convertToFloat(val.imposto);
                return acc + (isNaN(imposto) ? 0 : imposto);
            }, this.totalIliq);
    }

    setCodigo(value: any) {
        if (this.accao !== Accao.cadastrar) {
            return;
        }
        this.loading = true;
        this.subscriptions.push(this.artigoService.getNumeracaoRenovavel(value?.idEmpresa).subscribe({
            next: res => {
                this.f[this.FORM.codigo].patchValue(res.objecto.item2);
                this.loading = false;
            },
            error: err => {
                this.loading = false;
                this._notificacao.notificar(err.mensagem);
            }
        }));
    }

    ivaActivo(item: any) {
        return item.activo;
    }

    changeState(transition: EstadoFluxo) {
        const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
            data: {
                header: 'Confirmação',
                acceptLabel: 'Confirmar',
                rejectLabel: 'Cancelar',
                message: `Tem a certeza que pretende ${transition.estadoSeguinte?.accao?.toLowerCase()} este ${this.classificacao.nomeRenovaveisS?.toLowerCase()}?`
            }
        });
        this.subscriptions.push(
            dialogRef.afterClosed().pipe(
                mergeMap(accepted => {
                    if (!accepted) {
                        return new Observable<Resposta<number>>();
                    }
                    this.loading = true;
                    return this.artigoService.alternarActivoSuspensoRenovavel(this.renovavel.id, transition.idEstadoSeguinte);
                })
            ).subscribe({
                next: v => {
                    this.loading = false;
                    this.renovavel.idEstadoArtigoRenovavel = v.objecto;
                    this.transistions = this.stateMachine.filter(x => x.idEstado === v.objecto);
                    this.f[this.FORM.estado].patchValue(v.objecto);
                    this._notificacao.notificar(`${this.classificacao.nomeRenovaveisS} está ${this.stateMap[v.objecto]}`, true);
                },
                error: err => {
                    this.loading = false;
                    this._notificacao.notificar(err.error?.mensagem || 'Falha ao alternar o estado do renovável');
                }
            })
        );
    }

    removerArtigo(index: number) {
        const idArtigo: number = this.artigosForm.at(index).get(this.FORM.id).value;
        if (idArtigo > 0) {
            this.artigosRemover.push(idArtigo);
        }
        this.artigosForm.removeAt(index);
        this.calculateTotal();
        if (this.artigosForm.length < 1) {
            this.artigosForm.push(this.fb.group(this.artigoCtrlConfig));
        }
    }

    calcularImposto(e: MatSelectChange, ctrl: AbstractControl) {
        const preco: number = Util.convertToFloat(ctrl.get(FormNames.preco).value);
        const quantidade: number = Util.convertToFloat(ctrl.get(FormNames.quantidade).value);
        this.calcularItemTotal(preco, quantidade, ctrl);
        this.calculateTotal();
    }

    anular(row: any) {
        const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
            data: {
                header: 'Confirmação',
                acceptLabel: 'Confirmar',
                rejectLabel: 'Cancelar',
                message: `Tem a certeza que pretende anular esta renovação?`
            }
        });
        this.subscriptions.push(
            dialogRef.afterClosed().pipe(
                mergeMap(accepted => {
                    if (!accepted) {
                        return new Observable<Resposta<any>>();
                    }
                    if (row.documentos.some(d => d.codEstadoDocumento === 'E')) {
                        this._notificacao.notificar('É necessesário anular os documentos emitidos!');
                        return new Observable<Resposta<any>>();
                    }
                    this.loading = true;
                    return this.artigoService.anularRenovacao(row.id);
                })
            ).subscribe({
                next: res => {
                    this.loading = false;
                    row.anulado = res.objecto.anulado;
                    row.estado = res.objecto.estado;
                    this._notificacao.notificar(res.mensagem, true);
                },
                error: err => {
                    this.loading = false;
                    console.error(err);
                    this._notificacao.notificar(err.error?.mensagem || 'Falha inesperada ao anular a renovação');
                }
            })
        );
    }
}
