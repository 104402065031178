import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listaTemMaisDe'
})
export class ListaTemMaisDePipe implements PipeTransform {

  transform(lista: any [], valor: number): boolean {
    if (lista) {

      return lista.length > valor; 
    }

    return false;
  } 

}
