import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DataIntervalService} from 'app/shared/services/data-service/data-interval/data-interval.service';
import {ranges} from '../../../fake.db/data';
import {Util} from 'app/shared/models/generico/util';

@Component({
    selector: 'app-intervalo-data',
    templateUrl: './intervalo-data.component.html',
    styleUrls: ['./intervalo-data.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntervaloDataComponent implements OnInit, OnChanges {

    @Input('placeholder') placerholder = '';
    @Input('selData1') selData1 = null;
    @Output('setData') setData = new EventEmitter();
    @Output('go') go = new EventEmitter();
    ranges: any = ranges;
    form: FormGroup;
    valorData: string;
    // [moment().subtract(29, 'days'), moment()]
    // selData1={
    //   inicio: moment().subtract(29, 'days'),
    //   fim: moment()
    // }
    constructor(
        private dataIntervalService: DataIntervalService,
        private fb: FormBuilder
    ) {
        this.initForm();
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.selData1) {
            this.setData.emit(this.selData1);
            this.go.emit({});
        }
    }

    initForm() {
        this.form = this.fb.group({
            data: []
        });
    }


    validarCaracter(evento: any) {
        this.dataIntervalService.validarCaracter(evento);
    }


    validarData(evento: any, idInput: string, formcontrolname: string): void {
        const formControl: FormControl = (this.form.get(formcontrolname) as FormControl);
        this.dataIntervalService.validarData(evento, formControl, idInput);

        if (this.dataIntervalService.getValido()) {

            this.valorData = this.dataIntervalService.getValorData();

            this.setData.emit({
                inicio: this.format((this.valorData as string).split('-')[0]),
                fim: this.format((this.valorData as string).split('-')[1])
            });
        }
    }

    format(strDate: string): any {
        return new Date(Util.strParaDataISO(strDate.replace(/\s/g, '').replace(/[/]/g, '-').split('-').reverse().toString().replace(/[,]/g, '-'))).toISOString();
    }

    // format2( strDate: string): any {
    //   return new Date(Util.strParaDataISO(strDate.replace(/\s/g,'')
    //   .replace(/[/]/g,'-').split('-')
    //   .reverse().toString()
    //   .replace(/[,]/g,'-')))
    //   .toISOString();
    // }

    getDataInterval(event: any, idInput: string) {
        if (event?.inicio?._d && event?.fim?._d) {
            this.dataIntervalService.setValorData(this.formatarStrParaDataISO((Util.strParaDataISO(event?.inicio?._d) as string).split('-')) + ' - ' +
                this.formatarStrParaDataISO(
                    (Util.strParaDataISO(event?.fim?._d) as string).split('-')));


            this.valorData = this.dataIntervalService.getValorData();

            this.setData.emit({
                inicio: this.format((this.valorData as string).split('-')[0]),
                fim: this.format((this.valorData as string).split('-')[1]).replace('T00:00', 'T23:59')
            });
        }
    }

    validarIntervalo(dia1: number, mes1: number, ano1: number, dia2: number, mes2: number, ano2: number): boolean {
        return this.dataIntervalService.validarIntervalo(dia1, mes1, ano1, dia2, mes2, ano2);
    }

    // Dia mãximo do mês y ...
    maxDia(mes: number, ano?: number): number {

        return this.dataIntervalService.maxDia(mes, ano);
    }


    formatarStrParaDataISO(data: string[]): string {
        return this.dataIntervalService.formatarStrParaDataISO(data);
    }

    reverteFormatarStrParaDataISO(data: string[]): string {
        return this.dataIntervalService.reverteFormatarStrParaDataISO(data);
    }

}
