import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Accao} from '../../../shared/models/ENUM';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {GaCampo, GaTipoDado} from '../../../shared/models/gestao-arquivos/ga-tipo';
import {Format} from '../../../shared/directives/input-format.directive';
import {MatDialog} from '@angular/material/dialog';
import {ModalAddValoresComponent} from '../modal-add-valores/modal-add-valores.component';

const FormNames = {
    id: 'id',
    nome: 'nome',
    nomeP: 'nomeP',
    activo: 'activo',
    tipo: 'tipo',
    select: 'select',
    insercaoMultipla: 'insercaoMultipla',
    usaCodigo: 'usaCodigo',
    disponivelLista: 'disponivelLista',
    min: 'min',
    max: 'max',
    numMin: 'numMin',
    numMax: 'numMax',
    obrigatorio: 'obrigatorio',
    valores: 'valores'
};

export class DataTypes {
    static INTEIRO = 'INT';
    static BOOL = 'BOL';
    static TEXT = 'TXT';
    static DECIMAL = 'DEC';
    static DATE = 'DAT';
}

// tslint:disable-next-line:max-classes-per-file
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ga-tipo-campo',
    templateUrl: './ga-tipo-campo.component.html',
    styleUrls: ['./ga-tipo-campo.component.scss']
})
export class GaTipoCampoComponent implements OnInit {
    @Input() campo!: FormGroup;
    @Input() action = '';
    @Input() tipos: GaTipoDado[] = [];
    readonly actions = Accao;
    readonly types = DataTypes;
    readonly formats = Format;
    readonly formNames: { [key: string]: string } = FormNames;

    static isPlural(campo: GaCampo) {
        return campo.select || campo.insercaoMultipla;
    }

    constructor(private dialog: MatDialog) {
    }

    ngOnInit(): void {
    }

    updateSelectFields(e: MatSlideToggleChange, campo: FormGroup) {
        campo.get(FormNames.usaCodigo).patchValue(e.checked && campo.get(FormNames.usaCodigo).value);
        campo.get(FormNames.insercaoMultipla).patchValue(e.checked && campo.get(FormNames.insercaoMultipla).value);
        this.resetNumInsercoes(campo);
    }

    resetNumInsercoes(campo: FormGroup) {
        campo.get(FormNames.numMin).reset();
        campo.get(FormNames.numMax).reset();
    }

    isPlural(campo: FormGroup) {
        return GaTipoCampoComponent.isPlural(campo.value);
    }

    adicionarOpcoes(campo: FormGroup) {
        const _ = this.dialog.open(ModalAddValoresComponent, {
            width: 'calc(70vw - 250px)',
            height: '75vh',
            data: {
                campo,
                action: this.action,
            }
        });
    }
}
