import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'codTipo',
  pure: true
}) export class CodTipoPipe implements PipeTransform {
  public transform(valor: any, codTipo: string): any {
    if (valor) {
      if (codTipo === 'BOL') {
        return valor ? 'Sim' : 'Não';
      }

      if (codTipo === 'DEC') {
        return valor;
      }

      if (codTipo === 'INT') {
        return valor;
      }

      if (codTipo === 'TXT') {
        return valor;
      }

      if (codTipo === 'DAT') {
        return formatDate(valor, 'dd/MM/yyyy', 'pt');
      }
    }
    return '';
  }
}
