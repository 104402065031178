import {Pipe, PipeTransform} from '@angular/core';
import {Util} from '../models/generico/util';

@Pipe({
    name: 'parseValue'
})
export class ParseValuePipe implements PipeTransform {

    transform(value: string, type: string): string {
        return Util.parseValue(value, type).toString();
    }

}
