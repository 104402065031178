import {Pipe, PipeTransform} from '@angular/core';
import {Util} from '../models/generico/util';

@Pipe({
    name: 'sum'
})
export class SumPipe implements PipeTransform {

    transform(value: string | number, value2: string | number): number {
        return Util.convertToFloat(value) + Util.convertToFloat(value2);
    }

}
