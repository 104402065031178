import {CmsFormulario} from 'app/shared/models/cms/cms-formularios';
import {Component, Input, OnInit} from '@angular/core';
import {Menu} from 'app/shared/models/aplicacao/menu';
import {Observable, Subscription} from 'rxjs';
import {ProcessosClassificacoes} from 'app/shared/models/processos/processosclassificacoes';
import {Entidades} from 'app/shared/models/clientes/entidades';
import {ArtigoClassificacao} from 'app/shared/models/artigos/artigo-classificacao';
import {menuCode} from '../../fake.db/data';
import {AplicacaoService} from 'app/shared/services/aplicacao-service/aplicacao.service';
import {ClientesGeraisService} from 'app/shared/services/clientes-service/clientes-gerais.service';

import {ArtigosService} from 'app/shared/services/artigos/artigos.service';
import {ProcessosService} from 'app/shared/services/processos/processos.service';
import {MenuDataService} from 'app/shared/services/aplicacao-service/data/menu-data.service';
import {LoginService} from 'app/shared/services/aplicacao-service/login.service';
import {Utilizadores} from 'app/shared/models/aplicacao/utilizadores';
import {JwtHelperService} from '@auth0/angular-jwt';
import {MenuDinamico} from 'app/shared/models/aplicacao/menudinamico';
import {AppDataService} from 'app/shared/services/data/app-data.service';
import {CmsPaginaListagem} from 'app/shared/models/cms/cms-paginaListagem';
import {RecursosHumanosService} from 'app/shared/services/recursos-humanos/recursos-humanos.service';
import {CmsGeralService} from 'app/shared/services/cms/cms-geral.service';
import {EqClassificacaoService} from '../../services/equipamentos/eq-classificacao.service';
import {EqClassificacao} from '../../models/equipamentos/eq-classificacao';
import {NavigationStart, Router} from '@angular/router';
import * as moment from 'moment';
import {AppStoreService} from 'app/shared/services/aplicacao-service/data/app-store.service';
import {DefinirDadosDoFooterService} from '../footer/definir-dados-do -footer/definir-dados-do-footer.service';
import {FinancasService} from '../../services/financas/financas.service';
import {JwtAuthService} from '../../services/auth/jwt-auth.service';
import {PosUtilityService} from 'app/views/pos/pos-services/pos-utility.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.template.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
    menus: Menu[];
    linkUrlRota = '';
    idClassRota = '';
    // tslint:disable-next-line: no-input-rename
    @Input('items') public menuItems: any[] = [];
    // tslint:disable-next-line:no-input-rename
    @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
    // tslint:disable-next-line:no-input-rename
    @Input('iconMenuTitle') public iconTypeMenuTitle: string;

    public listamenus: Menu[] = [];
    public listamenu: string[] = [];
    public listaMenusObservable$: Observable<string[]>;
    public clientesEntidades: Entidades[] = [];
    public processosClassificacoes: ProcessosClassificacoes[] = [];
    public artigosClassificacao: ArtigoClassificacao[] = [];
    public idMenuActivo: number;
    listaMenu: any[] = [];
    listaMenuArtigo: any[] = [];
    listaMenuProc: MenuDinamico[] = [];
    listaMenuEvent: MenuDinamico[] = [];
    listaMenuClient: MenuDinamico[] = [];
    listaMenuFornecedor: MenuDinamico[] = [];
    listaMenuEquipamento: MenuDinamico[] = [];
    subscriptions: Subscription[] = [];
    codigoMenu = menuCode;
    utlizador: Utilizadores;
    jwtHelper = new JwtHelperService();
    decodedToken: any;
    codigoMenuFinancas = 'FACTUR';
    codigoMenuCompra = 'COMPRA';
    codigoMenuTesouraria = 'TESOUR';
    codigoMenuArtigos = 'ARTIGO';
    codigoMenuProcessos = 'PROCES';
    codigoMenuCliente = 'CLIENT';
    codigoMenuEvento = 'EVENTO';
    codigoMenuCMS = 'GESCON';
    codigoMenurRecursosHumanos = 'RECHUM';
    codMenuEquipamento = 'EQUATI';
    UsaDashboard = false;
    usaMenuFacturacao = false;
    usaMenuPOS = false;
    usaMenuCompra = false;
    usaMenuTesouraria = false;
    usaMenuArtigo = false;
    usaMenuProcesso = false;
    usaMenuCliente = false;
    usaMenuFornecedor = false;
    usaMenuCMS = false;
    usaMenuEvento = false;
    usaMenuRecursosHumanos = false;
    paginas: CmsPaginaListagem[] = [];
    gruposRegistos: any[] = [];
    listaFormularios: CmsFormulario[] = [];
    idIdioma = 1;
    rotaActual: string;
    listaEstuturasOrganizativas: any[] = [];
    listaModulosEventos: any[] = [];
    codIdiomaDefoult: any = 'PT';

    ConfiguracaoCMS: any;
    galerias: any[] = [];
    EmDesenvolvimento = false;
    public gereStock = false;
    //  gereStock = false;
    equipamentos: EqClassificacao[] = [];
    dadosAplicacao: any;
    usaMenuEquipamento: boolean;
    confAdm = {
        name: 'Conf. {ART}',
        type: 'dropDown',
        sub: [
            {name: 'Famílias', state: 'configuracoes/tipos'},
            {name: 'Categorias', state: 'configuracoes/categoria'},
            {name: 'Variantes', state: 'configuracoes/variantes/lista'},
            {name: 'Estados', state: 'configuracoes/estados'},
            {name: 'Tipos de Arquivos', state: 'configuracoes/tiposArquivos'},
            {name: 'E-mails', state: 'configuracoes/emails/lista'},
            {
                name: 'E-mails Automáticos',
                state: 'configuracoes/emails/automaticos'
            },
            {name: 'Ciclos de Renovação', state: 'configuracoes/ciclos'}
        ]
    };
    vendaConfig!: any;

    constructor(
        private aplicacaoService: AplicacaoService,
        private clientesGeraisService: ClientesGeraisService,
        private artigosService: ArtigosService,
        private processosService: ProcessosService,
        private loginService: LoginService,
        private menuData: MenuDataService,
        private appDataService: AppDataService,
        private geralService: CmsGeralService,
        public appStore: AppDataService,
        private definirDadosDoFooterService: DefinirDadosDoFooterService,
        private rota: Router,
        private recursosHumanosService: RecursosHumanosService,
        private equipamentoClassService: EqClassificacaoService,
        private financaService: FinancasService,
        private aplicacaoDado: AppStoreService,
        private jwtAuthService: JwtAuthService,
        private posUtilityService: PosUtilityService
    ) {
        this.appStore.getRotalActual().subscribe((res: any) => {
            this.rotaActual = res;
        });
        this.getMenusAplicacao();
    }

    ngOnInit() {
        this.getAplicacao();
        this.aplicacaoService.gereStock$.subscribe(s => this.gereStock = s);
        this.actualizarToken();
        this.setRotaActiva();
    }

    // A tratar
    getAplicacao() {
        if (this.aplicacaoDado.aplicacao) {
            this.aplicacaoDado.getAplicacao().subscribe(app => {
                this.DadosAplicacao(app);
            });
        } else {
            this.aplicacaoService.getAplicacaoGeral().subscribe((res: any) => {
                this.DadosAplicacao(res);
            });
        }
    }

    naturezasActivas(ns: any[]) {
        return ns?.filter(n => n.activo) ?? [];
    }

    replaceConf(str: string, _class: ArtigoClassificacao) {
        return _class?.codNatureza === 'S' && this.getArtigo(6)?.activo && str.includes('Configurações') ?
            `Conf. ${_class?.classificacaoP}` : str;
    }

    // getPluralizedWord(palavra: string): string {
    //     return pluralize.plural(palavra, 2, 'pt');
    // }

    DadosAplicacao(res) {
        localStorage.setItem('codPais', res?.codPais ? res?.codPais.replace(/\s/g, '') : '');
        localStorage.setItem('gereStocks', res.gereStocks ? res.gereStocks : false);
        if (res) {
            this.definirDadosDoFooterService.definirValores({
                licencaEmpresa: res?.licencaEmpresa,
                aplicacao: res
            });
            this.dadosAplicacao = res;
            this.aplicacaoDado.setAplicacao(this.dadosAplicacao);
            this.EmDesenvolvimento = res.codTipoInstalacao === 'D';
            this.gereStock = res.gereStocks;
            this.jwtAuthService.gerePermissoesUtilizador$.next(res.gerePermissoesUtilizador);
            this.aplicacaoService.gereStockBS.next(this.gereStock);
            if (this.dadosAplicacao && this.dadosAplicacao.dataValidade) {
                const dataValidade = moment(
                    new Date(this.dadosAplicacao.dataValidade)
                );
                const dataHoje = moment((new Date()).setHours(0, 0, 0, 0));
                const diasEmFalta = dataValidade.diff(dataHoje, 'days');
                this.appStore.diasEmFalta.next(diasEmFalta);
                if (diasEmFalta < 15) {
                    this.appStore.$mostrarAviso.next(true);
                } else {
                    this.appStore.$mostrarAviso.next(false);
                }
            }
            localStorage.setItem('__cod__Pais', res.codPais);
            localStorage.setItem('moedaEmpresa', res.moeda1);
        }
    }

    getMenusAplicacao() {
        this.menuData.getListaMenus().subscribe((menus) => {
            if (menus.length > 0) {
                this.listamenus = menus;
                this.UsaDashboard = menus.some(m => m.codMenu === 'DASHBO');
                this.criarListaMenu(menus);
            } else {
                this.aplicacaoService.getMenus().subscribe((res) => {
                    this.listamenus = res;
                    this.criarListaMenu(res);
                    this.menuData.setListaMenus(res);
                });
            }
        });
    }

    setRotaActiva() {
        this.rota.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                const partesRota = event.url.split('/');
                this.linkUrlRota = event.url.split('/')[1];
                localStorage.setItem('rotaMenuActivo', this.linkUrlRota);
                if (partesRota && partesRota.length > 0) {
                    localStorage.setItem('idClassRota', partesRota[partesRota.length - 1]);
                }
            }
        });

        if (localStorage.getItem('rotaMenuActivo')) {
            this.linkUrlRota = localStorage.getItem('rotaMenuActivo');
            this.idClassRota = localStorage.getItem('idClassRota');
        }
    }

    criarListaMenu(menus: Menu[]) {
        // tslint:disable-next-line:prefer-for-of
        if (menus.some(m => m.codMenu === 'FACTUR')) {
            this.subscriptions.push(
                this.financaService.getConfiguracao().subscribe(c => {
                    const fact = this.menuItems.find(x => x.code === 'FACTUR');
                    const reno = fact?.sub?.find(x => x.name === 'Renováveis');
                    const adm = fact?.sub?.find(x => x.name === 'Administrativos');
                    // const subMenuPOS = fact?.sub.find(x => x.name === 'POS');

                    // if (!c.objecto.usaPOS) {
                    //     const indexMenuPOS = fact?.sub.indexOf(subMenuPOS);
                    //     if (indexMenuPOS > -1) {
                    //         fact?.sub?.splice(indexMenuPOS, 1);
                    //     }
                    // } else {
                    //     this.usaMenuPOS = c.objecto.usaPOS;
                    //     this.jwtService.usaMenuPOS$.next(c.objecto.usaPOS);
                    // }
                    // TODO: Implementar principio DRY nesta area. By XP //
                    this.vendaConfig = c.objecto;
                    if (!this.vendaConfig.usaRenovaveis) {
                        const i = fact?.sub?.indexOf(reno);
                        if (i > -1) {
                            fact?.sub?.splice(i, 1);
                        }
                    } else if (reno) {
                        reno.name = this.vendaConfig.nomeRenovaveisP;
                        reno.sub[0].name = this.vendaConfig.nomeRenovaveisP;
                    }

                    if (!this.vendaConfig.usaAdministrativos) {
                        const i = fact?.sub?.indexOf(adm);
                        if (i > -1) {
                            fact?.sub?.splice(i, 1);
                        }
                    }
                })
            );
        }

        const menu = menus.find(m => m?.codMenu === 'RECHUM');

        if (menu) {
            const listaMenusDinamico = menus.find(m => m.codMenu === 'CLIENT')?.menusDinamicos?.filter(m => m.idClassificacao !== 5);

            if (listaMenusDinamico) {

                menus.find(m => m.codMenu === 'CLIENT').menusDinamicos = listaMenusDinamico;
            }
        }

        for (const element of menus) {
            this.listamenu.push(element.codMenu);
            if (element.codMenu.toUpperCase() === 'COMUNI') {
                this.menuData.moduloComActivo.next(true);
            }
            if (element.codMenu === this.codigoMenuCliente) {
                if (!this.usaMenuCliente) {
                    this.listaMenuClient = element.menusDinamicos;
                    this.clientesGeraisService.getEntidades().subscribe((res) => {
                        this.clientesEntidades = res.objecto;
                    });
                    this.usaMenuCliente = true;
                }
            }

            if (element.codMenu === this.codigoMenuArtigos) {
                if (!this.usaMenuArtigo) {
                    this.listaMenuArtigo = element.menusDinamicos;
                    this.artigosService.getClassificacoesArtigos().subscribe(
                        (res) => {
                            this.artigosClassificacao = res;
                        });
                    this.usaMenuArtigo = true;
                }
            }

            if (element.codMenu === this.codMenuEquipamento) {
                if (!this.usaMenuEquipamento) {
                    this.listaMenuEquipamento = element?.menusDinamicos ?? [];
                    this.usaMenuEquipamento = true;
                    this.equipamentoClassService
                        .getClassificacoes()
                        .subscribe(x => this.equipamentos = x.objecto.filter(e => e.activo));
                }
            }

            if (element.codMenu === this.codigoMenuCMS) {
                if (!this.usaMenuCMS) {
                    this.usaMenuCMS = true;
                    this.getMenusCMS();
                }
            }
            if (element.codMenu === this.codigoMenuProcessos) {
                if (!this.usaMenuProcesso) {
                    this.listaMenuProc = element.menusDinamicos;
                    this.processosService.getClassificacoes()?.subscribe((res) => {
                            this.processosClassificacoes = res.objecto;
                        },
                        (error) => {
                            console.log('Erro1', error);
                        });
                    this.usaMenuProcesso = true;
                    this.appDataService.setEstadoModuloProcessos(this.usaMenuProcesso);
                }
            }
            if (element.codMenu === this.codigoMenuFinancas) {
                this.usaMenuFacturacao = true;
            }
            if (element.codMenu === this.codigoMenuCompra) {
                this.usaMenuCompra = true;
            }

            if (element.codMenu === this.codigoMenurRecursosHumanos) {
                if (!this.usaMenuRecursosHumanos) {
                    this.usaMenuRecursosHumanos = true;
                    this.recursosHumanosService.getListaEstruturasOrgnizativas().subscribe(x => {
                            this.listaEstuturasOrganizativas = x.objecto.filter(y => y.activo === true);
                        },
                        erro => {
                        });
                }
            }

        }
    }

    getNomeModulo(idClassificacao?: number): string {
        if (idClassificacao > 0) {
            const obj = this.listaModulosEventos?.find(x => x.idClassificacao === idClassificacao);
            if (obj && obj.nomeModuloP?.length > 0) {
                return obj.nomeModuloP;
            } else {
                return 'Módulos';
            }
        }
        return 'Módulos';
    }

    getNomeEvento(idClassificacao?: number): string {
        if (idClassificacao > 0) {
            const obj = this.listaModulosEventos?.find(x => x.idClassificacao === idClassificacao);
            if (obj && obj?.nomeEventoP?.length > 0) {
                return obj?.nomeEventoP;
            } else {
                return obj?.classificacaoP;
            }
        }
        return '';
    }

    getNomeRealizacao(idClassificacao?: number): string {
        if (idClassificacao > 0) {
            const obj = this.listaModulosEventos?.find(x => x.idClassificacao === idClassificacao);
            if (obj && obj?.nomeRealizacaoP?.length > 0) {
                return obj?.nomeRealizacaoP;
            } else {
                return obj?.classificacaoP;
            }
        }
        return '';
    }

    getEquipamento(id: number) {
        return this.equipamentos.find(x => x.id === id);
    }

    usarModulo(idClassificacao: number): boolean {
        if (idClassificacao > 0) {
            const obj = this.listaModulosEventos?.find(x => x.idClassificacao === idClassificacao);
            if (obj && obj.idOpcModulos > 1) {
                return true;
            }
        }
        return false;
    }

    multiplasRealizacoes(idClassificacao: number): boolean {
        if (idClassificacao > 0) {
            const obj = this.listaModulosEventos?.find(x => x.idClassificacao === idClassificacao);
            if (obj?.multiplasRealizacoes) {
                return true;
            }
        }
        return false;
    }

    usaInscricao(idClassificacao: number): boolean {
        if (idClassificacao > 0) {
            const obj = this.listaModulosEventos?.find(x => x.idClassificacao === idClassificacao);
            return obj && obj.idOpcInscricoes > 1;
        }
        return false;
    }

    opcaoCertificado(idClassificacao: number): boolean {
        if (idClassificacao > 0) {
            const obj = this.listaModulosEventos?.find(x => x.idClassificacao === idClassificacao);
            return obj && obj.idOpcCertificados > 1;
        }
        return false;
    }

    Formacao(idClassificacao: number): boolean {
        if (idClassificacao > 0) {
            const obj = this.listaModulosEventos?.find(x => x.idClassificacao === idClassificacao);
            return obj && obj.codNatureza === 'F';
        }
        return false;
    }

    getMenuNaoDinamico(codMenu: string, i): boolean {
        return !this.listamenus.find((m) => m.codMenu === codMenu)?.dinamico;
    }

    getCliente(idEntidade: number): Entidades {
        if (this.clientesEntidades) {
            return this.clientesEntidades?.find((m) => m.idEntidade === idEntidade);
        }
        return null;
    }

    getArtigo(idClassificacao: number): ArtigoClassificacao {
        return this.artigosClassificacao?.find(m => m.idClassificacao === idClassificacao) ?? null;
    }

    getProcessos(idClassificacao: number): ProcessosClassificacoes {
        if (this.processosClassificacoes) {
            return this.processosClassificacoes.find(
                (m) => m.idClassificacao === idClassificacao
            );
        }
        return null;
    }

    getConfiguracaoVenda() {

    }

    getMenu(codMenu: string): Menu {
        return this.listamenus.find((m) => m.codMenu === codMenu);
    }

    async actualizarToken() {
        const token = localStorage.getItem('token');
        this.decodedToken = await this.jwtHelper.decodeToken(token);
        this.loginService.decodedToken = this.decodedToken;
    }

    getMenusCMS() {
        this.geralService.getMenusCMS().subscribe((res: any) => {
            const dadosMenu = res?.objecto;
            this.paginas = dadosMenu?.paginas;
            this.gruposRegistos = [...dadosMenu?.gruposRegistos];
            this.listaFormularios = dadosMenu?.listaFormularios;
            this.galerias = dadosMenu?.galerias;
            this.ConfiguracaoCMS = dadosMenu?.configuracaoCMS;
        });
    }

    // Only for demo purpose
    addMenuItem() {
        this.menuItems.push({
            name: 'ITEM',
            type: 'dropDown',
            tooltip: 'Item',
            icon: 'done',
            state: 'material',
            sub: [
                {name: 'SUBITEM', state: 'cards'},
                {name: 'SUBITEM', state: 'buttons'}
            ]
        });
    }

    activarDropDown(id: number, state?: string, rota?: string) {
        if (this.idMenuActivo === id) {
            return (this.idMenuActivo = null);
        }
        return (this.idMenuActivo = id);
    }

    openFullScreen() {

    }
}
