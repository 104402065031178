import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {environment} from 'environments/environment';
import {Cliente} from '../../models/clientes/cliente';
import {ClientesEmails} from 'app/shared/models/clientes/clienteemails';
import {Resposta} from '../../models/resposta';

/*const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
    'Content-Type': 'application/json; charset=utf-8',
  }),
};
*/
const httpOptions = {
    headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class ClientesService {
    baseUrlCliente: string;
    tabPessoaContactoActiva$ = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient) {
        this.baseUrlCliente = `${environment.apiClientes}`;
    }

    gravar(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientes/', modelo, httpOptions)
            .pipe();
    }

    gravarClienteInscricao(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientes/incricao/', modelo, httpOptions)
            .pipe();
    }

    getEstadosClientes() {
        // https://dev-back.kiami.ao/cli/api/v1/clientes/estados
        return this.http.get(this.baseUrlCliente + 'clientes/estados');
    }

    Actualizar(modelo: any): Observable<any> {
        return this.http.post(
            this.baseUrlCliente + 'clientes/actualizar',
            modelo,
            httpOptions
        );
    }

    remover(idEntidade: number, idCliente: number): Observable<any> {
        return this.http
            .post(
                this.baseUrlCliente +
                'clientes/remover/' +
                idEntidade +
                '/' +
                idCliente,
                httpOptions
            )
            .pipe();
    }

    removerVarios(modelo: any[]): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientes/removerVarios', modelo, httpOptions)
            .pipe();
    }

    getClientesListagem(model: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientes/listagem', model, httpOptions);
    }

    getClientesListagem2(model: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientes/listagem2', model, httpOptions);
    }

    getClientesPdf(model: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientes/pdf', model, httpOptions);
    }

    getTodosClientes(filtro: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientes/listabase', filtro, httpOptions)
            .pipe(catchError(err => {
                console.error(err);
                return err.status === 404 ? of({objecto: [], statusCode: err.status, mensagem: ''} as Resposta<Cliente[]>) : err;
            }));
    }

    getTiposEntidadesClientes() {
        return this.http.get(
            this.baseUrlCliente + 'clientes/entidades',
            httpOptions
        );
    }

    getEntidadesClientes() {
        return this.http.get<any>(
            this.baseUrlCliente + 'clientes/entidades',
            httpOptions
        );
    }

    getEntidadesClientesComtipos(): Observable<any> {
        return this.http.get(
            this.baseUrlCliente + 'entidades/entidadesComTipos',
            httpOptions
        );
    }

    getCliente(idCliente: number): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/' + idCliente, httpOptions)
            .pipe();
    }

    getClientesTodasEntidades(idCliente: number): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/' + idCliente, httpOptions)
            .pipe();
    }

    getClienteEnderecos(idCliente: number): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/enderecos/' + idCliente, httpOptions)
            .pipe();
    }

    getTiposEntidadesCliente(idEntidade: number): Observable<any> {
        return this.http
            .get(
                this.baseUrlCliente + 'clientesTipos/entidade/' + idEntidade,
                httpOptions
            )
            .pipe();
    }

    getClientePeloCodigo(codigo: string): Observable<Cliente> {
        return this.http
            .get(
                this.baseUrlCliente + 'clientes/verificarPeloCodigo/' + codigo,
                httpOptions
            )
            .pipe(catchError(this.handleError));
    }

    getEmailsManuais(idEntidade: number): Observable<ClientesEmails[]> {
        return this.http
            .get(
                this.baseUrlCliente + 'clientes/emails/emailsManuais/' + idEntidade,
                httpOptions
            )
            .pipe(catchError(null));
    }

    getPersonalidadesJuridicas(idEntidade: number): Observable<any> {
        return this.http
            .get(
                this.baseUrlCliente + 'entidades/personalidadesJuridicas/' + idEntidade,
                httpOptions
            )
            .pipe(catchError(null));
    }

    getTodasPersonalidadesJuridicas(): Observable<any> {
        return this.http
            .get(
                this.baseUrlCliente + 'entidades/personalidadesJuridicas',
                httpOptions
            )
            .pipe(catchError(null));
    }

    gravarEmailsManuais(modelo: any): Observable<any> {
        return this.http
            .post(
                this.baseUrlCliente + 'clientes/emails/emailsManuais',
                modelo,
                httpOptions
            )
            .pipe(catchError(this.handleError));
    }

    /*emails automaticos*/
    gravarEmailsAutomatico(modelo: any): Observable<any> {
        return this.http
            .post(
                this.baseUrlCliente + 'clientes/emailsautomaticos/',
                modelo,
                httpOptions
            )
            .pipe(catchError(this.handleError));
    }

    getEmailsAutomaticos(idEntidade: number): Observable<any> {
        return this.http
            .get(
                this.baseUrlCliente + 'clientes/emailsautomaticos/' + idEntidade,
                httpOptions
            )
            .pipe();
    }

    removerEmailsAutomatico(modelo: any): Observable<any> {
        return this.http
            .post(
                this.baseUrlCliente + 'clientes/emailsautomaticos/remover/',
                modelo,
                httpOptions
            )
            .pipe(catchError(this.handleError));
    }

    getEmailsManual(
        idEmailManual: number
    ): Observable<any> {
        return this.http
            .get(
                this.baseUrlCliente +
                'clientes/emails/emailManual/' +
                idEmailManual,
                httpOptions
            )
            .pipe(catchError(null));
    }

    getEmailsAutomatico(
        idEmailAutomatico: number,
        idEntidade: number
    ): Observable<any> {
        return this.http
            .get(
                this.baseUrlCliente +
                'clientes/emailsautomaticos/' +
                idEmailAutomatico +
                '/' +
                idEntidade,
                httpOptions
            )
            .pipe(catchError(null));
    }

    getRelatorioPessoasXML(dataInicio?: any, dataFim?: any): Observable<any> {
        return this.http.get(
            this.baseUrlCliente + 'clientesRelatorios/pessoasXml/' + dataInicio,
            httpOptions
        );
        // return this.http.get(this.baseUrl + 'clientesRelatorios/pessoasXml/' + dataInicio + '/' + dataFim, httpOptions);
    }

    getTiposDadosCaracteristica(): Observable<any> {
        return this.http.get(
            this.baseUrlCliente + 'clientes/caracteristicas/tiposDados',
            httpOptions
        );
    }

    getExcelParaJson(ficheiroExcel): Observable<any> {
        return this.http.post(
            this.baseUrlCliente + 'clientes/caracteristicas/carregarDados',
            ficheiroExcel,
            httpOptions
        );
    }

    gravarValor(modelo): Observable<any> {
        return this.http.post(this.baseUrlCliente + 'clientes/caracteristicas/salvar', modelo, httpOptions);
    }

    getValorCaracteristica(idValor: number): Observable<any> {
        return this.http.get(this.baseUrlCliente + 'clientes/caracteristicas/valor/' + idValor, httpOptions);
    }

    verificarCaracteristica(idCaracteristica: number): Observable<any> {
        return this.http.get(this.baseUrlCliente + 'clientes/caracteristicas/verificar/' + idCaracteristica, httpOptions);
    }

    getCaracteristica(idEntidade: number, idCaracteristica: number): Observable<any> {
        return this.http.get(this.baseUrlCliente + 'clientes/caracteristicas/caracteristica/' + idEntidade + '/' + idCaracteristica, httpOptions);
    }

    getPermissoesCLI(filtro: any) {
        return this.http.post(
            this.baseUrlCliente + 'clientesPermissoes/obter',
            filtro
        );
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }

    // Tipos Colaboração

    getTiposColaboracao(): Observable<any> {
        return this.http.get(this.baseUrlCliente + 'tipoColaboracao/lista/', httpOptions);
    }

    getGraus() {
        return this.http.get<Resposta<any[]>>(`${this.baseUrlCliente}clientes/graus`);
    }
}
