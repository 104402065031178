<div *ngIf="loading">
    <app-loader></app-loader>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<mat-card class="p-0">
    <mat-card-title>
        <div class="card-title pr-1 pl-1 pt-1 pb-05">
            <p class="pr-1 mb-0" style="margin-bottom: 0 !important" fxLayoutAlign="space-between">
                <strong fxLayout="row" fxLayoutGap="6px">
                    <span [innerText]="title[accao]"></span>
                    <span [innerText]="classificacao?.nomeRenovaveisS"></span>
                    <span [innerText]=" "></span>
                </strong>
            </p>
        </div>
    </mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
        <div class="pl-1">
            <form [formGroup]="form" (submit)="submit()" class="k-form-crud p-1 mt-16" fxLayout="row wrap">
                <div fxFlex="100" class="p-1 flex" style="justify-content: space-between">
                    <div>
                        <button class="mr-1" [class.disabilitar]="loading" type="button"
                                routerLink="/artigos/renovaveis/C/{{classificacao?.idClassificacao}}/0"
                                mat-raised-button color="primary" [disabled]="loading">
                            Novo
                        </button>

                        <button *ngIf="accao !== actions.detalhes" class="ml-1 mr-1" color="primary"
                                [class.opacity-2]="loading"
                                [disabled]="loading" type="button"
                                mat-raised-button [matMenuTriggerFor]="accoesMenuGravar">
                            <span>Gravar</span>
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                        <mat-menu #accoesMenuGravar="matMenu">
                            <button (click)="submit()" mat-menu-item>
                                Gravar
                            </button>
                            <button (click)="submit(true)" mat-menu-item>
                                Gravar & Voltar
                            </button>
                        </mat-menu>

                        <button class="mr-1" type="button" mat-raised-button
                                [class.btn-disable]="emissaoInactiva()"
                                color="primary"
                                [disabled]="emissaoInactiva() || loading"
                                (click)="emitirDocumento('AC', renovavel)">
                            <mat-icon>refresh</mat-icon>
                            <span>Emitir Renovação</span>
                        </button>

                        <button *ngFor="let t of transistions" class="m-2" type="button" mat-raised-button
                                color="primary"
                                [disabled]="accao === actions.cadastrar || renovavel?.idEstadoArtigoRenovavel === states.ELIMINADO || loading"
                                [class.btn-disable]="accao === actions.cadastrar  || renovavel?.idEstadoArtigoRenovavel === states.ELIMINADO"
                                (click)="changeState(t)" style="margin-right: 8px;">
                            <span>{{ t.estadoSeguinte?.accao }}</span>
                        </button>
                    </div>

                    <button class="mr-1" mat-raised-button color="accent" type="button"
                            (click)="location.back()">
                        {{ accao === actions.detalhes ? "Voltar" : "Cancelar" }}
                    </button>
                </div>
                <!--Dados gerais-->
                <div fxFlex="100" fxFlex.gt-xs="100" class="pr-16 mb-30 mt-16 d-flex justify-content-start"
                     fxLayout="row wrap" style="gap: 8px;">
                    <!--Empresa-->
                    <div *ngIf="empresas.length > 1" fxFlex="20" fxFlex.xl="15" fxFlex.lg="15" fxFlex.gt-xs="20"
                         class="pr-1 mb-1"
                         [class.campo-inactivo]="accao !== actions.cadastrar">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Empresa</mat-label>
                            <mat-select [formControlName]="FORM.empresa" [disabled]="accao !== actions.cadastrar"
                                        (selectionChange)="setCodigo($event.value)" required>
                                <mat-option *ngFor="let empresa of empresas" [value]="empresa">
                                        <span class="flex border-b-light">
                                            <span class="w-40 pr-03"><small> {{ empresa.codEmpresa }}</small></span>
                                        </span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <small *ngIf="f[FORM.empresa]?.errors &&
                            f[FORM.empresa]?.errors!['required'] && submitted"
                               class="p-error">
                            Empresa é obrigatória!
                        </small>
                    </div>
                    <!--Código-->
                    <div fxFlex="120px" [class.campo-inactivo]="true" fxFlex.xs="100" class="mb-1">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Código</mat-label>
                            <input matInput placeholder="Código" maxlength="127" readonly
                                   [formControlName]="FORM.codigo"/>
                        </mat-form-field>
                    </div>
                    <!-- Designação -->
                    <div fxFlex="370px" fxFlex.sm="100" fxFlex.xs="100" class="pr-1">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Designação</mat-label>
                            <input matInput [formControlName]="FORM.designacao" autocomplete="false"
                                   placeholder="Designação" [readonly]="accao === actions.detalhes"
                                   maxlength="60"/>
                        </mat-form-field>
                    </div>
                    <!--Data Registo-->
                    <div fxFlex="145px" [class.campo-inactivo]="true" class="mb-1">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Data Registo</mat-label>
                            <input matInput [formControlName]="FORM.dataRegisto" autocomplete="off"
                                   [matDatepicker]="dataRegisto" readonly/>
                            <mat-datepicker-toggle matSuffix [for]="dataRegisto"
                                                   [ngClass]="{disabled: true}"></mat-datepicker-toggle>
                            <mat-datepicker #dataRegisto></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div>
                        <!--Cod. Cliente-->
                        <div fxFlex="153px" class="pr-1 mb-1"
                             [class.campo-inactivo]="accao !== actions.cadastrar">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Cod. Cliente</mat-label>
                                <input matInput [formControlName]="FORM.codCliente" placeholder="Cod. Cliente"
                                       maxlength="127" (input)="filtrarClientes($event)"
                                       (focus)="filtrarClientes($event)" [matAutocomplete]="clientesAutoComplete"
                                       [readonly]="accao !== actions.cadastrar"/>
                                <mat-autocomplete [panelWidth]="700"
                                                  (optionSelected)="showClientes();updateClienteFields($event.option.value)"
                                                  #clientesAutoComplete="matAutocomplete">
                                    <mat-option class="bg-primary sticky-header" style="cursor: default;"
                                                *ngIf="filteredClientes.length > 0">
                                        <span class="flex-y-center border-b-light">
                                            <small style="width: 12em" class="pr-03">Código</small>
                                            <small style="width: 30em;text-align: left"
                                                   class="pl-03">Nome</small>
                                            <small style="width: 20em;text-align: left"
                                                   class="pr-03">E-mail</small>
                                            <small style="width: 15em" class="pr-03">NIF</small>
                                            <small fxFlex="10" class="pl-1 disabilitar">Ret.</small>
                                        </span>
                                    </mat-option>
                                    <mat-option *ngFor="let cliente of filteredClientes" [value]="cliente">
                                        <span class="flex border-b-light">
                                            <small style="width: 12em" class="pr-03">{{ cliente.codCliente }}</small>
                                            <small style="width: 30em;text-align: left"
                                                   class="pl-03">{{ cliente.nome }}</small>
                                            <small style="width: 20em;text-align: left"
                                                   class="pr-03">{{ cliente.email }}</small>
                                            <small style="width: 15em" class="pr-03">{{ cliente.nif }}</small>
                                            <small fxFlex="5" class="pl-03 overflow-hidden">
                                                <mat-icon style="font-size: 17px;"
                                                          *ngIf="cliente.clientesRetencoes?.length > 0"
                                                          color="primary">check_small</mat-icon>
                                            </small>
                                        </span>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <small *ngIf="f[FORM.idCliente]?.errors &&
                    (f[FORM.idCliente]?.errors!['required'] || f[FORM.idCliente]?.errors!['min']) && submitted"
                                   class="p-error">
                                Cliente é obrigatório!
                            </small>
                        </div>
                        <!--Nome Cliente-->
                        <div style="min-width: 20em"
                             class="pr-05 pl-05 mb-1" [class.campo-inactivo]="accao !== actions.detalhes">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Cliente</mat-label>
                                <input matInput [formControlName]="FORM.nomeCliente" type="text"
                                       placeholder="Nome do cliente" readonly>
                            </mat-form-field>
                        </div>
                    </div>
                    <!--Estado-->
                    <mat-form-field style="min-width: 8em" appearance="outline" [class.campo-inactivo]="true">
                        <mat-label>Estado</mat-label>
                        <mat-select disabled
                                    [formControlName]="FORM.estado">
                            <mat-option *ngFor="let estado of estados" [value]="estado.idArtigoRenovavelEstado">
                                {{ estado.artigoRenovavelEstado }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!--Ciclo-->
                    <div fxFlex="140px" fxFlex.xs="20" class="pr-1 mb-1">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Ciclo</mat-label>
                            <mat-select [ngClass]="{disabled: accao === actions.detalhes}"
                                        (selectionChange)="updateDatas($event.value);updatePrecoCiclo($event.value)"
                                        [disabled]="accao === actions.detalhes" required
                                        [formControlName]="FORM.ciclo">
                                <mat-option *ngFor="let c of ciclos"
                                            [value]="c"
                                            [disabled]="accao === actions.detalhes">
                                    {{ c.cicloRenovacao }}
                                </mat-option>
                            </mat-select>
                            <mat-error style="margin-top: 10px"
                                       *ngIf="f[FORM.ciclo].hasError('required') && submitted">
                                Preencha o ciclo
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- Data Início -->
                    <div fxFlex="136px" class="mb-1"
                         [class.campo-inactivo]="accao !== actions.cadastrar && !(renovacoes | someFn: none)">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Data Início</mat-label>
                            <input matInput [formControlName]="FORM.dataInicio" autocomplete="off"
                                   [matDatepicker]="dataInicio" [appInputFormat]="'data'" required
                                   (dateChange)="updateDatas(f[FORM.ciclo].value)"
                                   [readonly]="(accao === actions.detalhes) || accao !== actions.cadastrar && !(renovacoes | someFn: none)"/>
                            <mat-datepicker-toggle matSuffix [for]="dataInicio"
                                                   [ngClass]="{disabled: (accao === actions.detalhes) || accao !== actions.cadastrar && !(renovacoes | someFn: none)}"></mat-datepicker-toggle>
                            <mat-datepicker #dataInicio></mat-datepicker>
                        </mat-form-field>
                        <small *ngIf="f[FORM.dataInicio]?.invalid" class="p-error">
                            Selecione uma data válida!
                        </small>
                    </div>
                    <!-- Data Fim -->
                    <div fxFlex="136px" class="mb-1" [class.campo-inactivo]="accao !== actions.detalhes">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Validade</mat-label>
                            <input matInput autocomplete="off"
                                   [formControlName]="FORM.dataFim"
                                   [matDatepicker]="dataFim" readonly/>
                            <mat-datepicker-toggle matSuffix [for]="dataFim"
                                                   [ngClass]="{disabled: true}">
                            </mat-datepicker-toggle>
                            <mat-datepicker #dataFim></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <!--Método Pagamento-->
                    <mat-form-field style="min-width: 200px" appearance="outline">
                        <mat-label>Método Pagamento</mat-label>
                        <mat-select [formControlName]="FORM.metodoPagamento" required
                                    [disabled]="accao === actions.detalhes">
                            <mat-option [value]="null">Nenhum</mat-option>
                            <mat-option *ngFor="let m of metodosPagamento" [value]="m.idMetodoPagRec">
                                {{ m.metodoPagRec }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- Totais -->
                    <div style="max-width: 9em;" class="mb-1" [class.campo-inactivo]="true">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Total Iliq.:</mat-label>
                            <input matInput autocomplete="false" [value]="totalIliq | currency: ' '"
                                   placeholder="0,00" style="text-align: right" readonly/>
                        </mat-form-field>
                    </div>
                    <div style="max-width: 9em;" class="mb-1" [class.campo-inactivo]="true">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Total:</mat-label>
                            <input matInput autocomplete="false" [value]="total | currency: ' '"
                                   placeholder="0,00" style="text-align: right" readonly/>
                        </mat-form-field>
                    </div>
                    <!-- Descrição -->
                    <div fxFlex="100" fxFlex.gt-lg="33.3" fxFlex.lt-md="33.33" fxFlex.lg="33.33" fxFlex.gt-xs="100"
                         class="mb-1 pr-1">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <textarea rows="3" matInput name="Descrição" placeholder="Descrição"
                                      [formControlName]="FORM.descricao"
                                      [readonly]="accao === actions.detalhes"></textarea>
                        </mat-form-field>
                    </div>
                    <!-- Equipamento -->
                    <ng-container *ngIf="f[FORM.equipamento].value?.id > 0">
                        <div class="pr-05 pl-05"
                             [ngStyle]="{'min-width.ch': f[FORM.equipamento].value?.codigo?.length * 2}"
                             [class.campo-inactivo]="accao !== actions.detalhes">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Cod. {{ f[FORM.equipamento].value?.natureza ?? 'Equipamento' }}</mat-label>
                                <input matInput [value]="f[FORM.equipamento].value?.codigo" type="text"
                                       placeholder="Código" readonly>
                            </mat-form-field>
                        </div>

                        <div [ngStyle]="{'min-width.ch': f[FORM.equipamento].value?.designacao?.length * 2}"
                             class="mb-1" [class.campo-inactivo]="accao !== actions.detalhes">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>
                                    Designação {{ f[FORM.equipamento].value?.natureza ?? 'Equipamento' }}
                                </mat-label>
                                <input matInput autocomplete="false" [value]="f[FORM.equipamento].value?.designacao"
                                       placeholder="Designação" readonly/>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <div style="overflow-x: auto;white-space: nowrap; width: fit-content;" class="mb-32">
                        <fieldset style="flex-direction: column"
                                  *ngIf="classificacao?.idOpcRenovacaoAutomatica === 2"
                        >
                            <legend>Dados renovação</legend>
                            <div>
                                <div fxFlex="180px"
                                     class="pr-16 pt-1 pr-1">
                                    <mat-checkbox [disabled]="accao === actions.detalhes"
                                                  [formControlName]="FORM.renovacoesAutomaticas"
                                                  class="font-weight-bold text-primary" color="primary">
                                        Renovação Automática
                                    </mat-checkbox>
                                </div>

                                <div *ngIf="classificacao?.idOpcRenovacaoAutomatica > 1" fxFlex="15" fxFlex.xl="15"
                                     fxFlex.lg="15"
                                     fxFlex.xs="100" class="pr-16 pt-1">
                                    <mat-checkbox
                                            [disabled]="accao === actions.detalhes || classificacao?.idOpcRenovacaoAutomatica === 2 && !f[FORM.renovacoesAutomaticas].value"
                                            [formControlName]="FORM.notificacoesDesactivadas"
                                            class="font-weight-bold text-primary" color="primary">
                                        Desactivar notificações
                                    </mat-checkbox>
                                </div>
                            </div>

                            <div>
                                <!-- Data Renovação -->
                                <div *ngIf="!NaN(f[FORM.ciclo].value?.diasRenovacao)" fxFlex="185px" class="mr-1"
                                     [class.campo-inactivo]="accao !== actions.detalhes">
                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>Data Renovação</mat-label>
                                        <input matInput autocomplete="off"
                                               [formControlName]="FORM.dataRenovacao"
                                               [matDatepicker]="dataRenovacao" readonly/>
                                        <mat-datepicker-toggle matSuffix [for]="dataRenovacao"
                                                               [ngClass]="{disabled: true}">

                                        </mat-datepicker-toggle>
                                        <mat-datepicker #dataRenovacao></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <!-- Data Suspensão -->
                                <div *ngIf="!NaN(f[FORM.ciclo].value?.diasSuspensao)" fxFlex="185px" class="mr-1"
                                     [class.campo-inactivo]="accao !== actions.detalhes">
                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>Data Suspensão</mat-label>
                                        <input matInput autocomplete="off"
                                               [formControlName]="FORM.dataSuspensao"
                                               [matDatepicker]="dataSuspensao" readonly/>
                                        <mat-datepicker-toggle matSuffix [for]="dataSuspensao"
                                                               [ngClass]="{disabled: true}">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #dataSuspensao></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <!-- Data Expiração -->
                                <div *ngIf="!NaN(f[FORM.ciclo].value?.diasExpiracao) && false" fxFlex="185px"
                                     class="mr-1"
                                     [class.campo-inactivo]="accao !== actions.detalhes">
                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>Data Expiração</mat-label>
                                        <input matInput autocomplete="off"
                                               [formControlName]="FORM.dataExpiracao"
                                               [matDatepicker]="dataExpiracao" readonly/>
                                        <mat-datepicker-toggle matSuffix [for]="dataExpiracao"
                                                               [ngClass]="{disabled: true}">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #dataExpiracao></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <!-- Data 1ª Notificação -->
                                <div *ngIf="!NaN(f[FORM.ciclo].value?.dias2Notificacao) && false" fxFlex="176px"
                                     class="mr-1"
                                     [class.campo-inactivo]="accao !== actions.detalhes">
                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>Data 1ª Notificação</mat-label>
                                        <input matInput autocomplete="off"
                                               [formControlName]="FORM.data1aNotificacao"
                                               [matDatepicker]="data2Notificacao" readonly/>
                                        <mat-datepicker-toggle matSuffix [for]="data2Notificacao"
                                                               [ngClass]="{disabled: true}">

                                        </mat-datepicker-toggle>
                                        <mat-datepicker #data2Notificacao></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <!-- Data 2ª Notificação -->
                                <div *ngIf="!NaN(f[FORM.ciclo].value?.dias3Notificacao) && false" fxFlex="176px"
                                     class="mr-1"
                                     [class.campo-inactivo]="accao !== actions.detalhes">
                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>Data 2ª Notificação</mat-label>
                                        <input matInput autocomplete="off"
                                               [formControlName]="FORM.data2aNotificacao"
                                               [matDatepicker]="data3Notificacao" readonly/>
                                        <mat-datepicker-toggle matSuffix [for]="data3Notificacao"
                                                               [ngClass]="{disabled: true}">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #data3Notificacao></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <!-- Data 3ª Notificação -->
                                <div *ngIf="!NaN(f[FORM.ciclo].value?.dias4Notificacao) && false" fxFlex="176px"
                                     class="mr-1"
                                     [class.campo-inactivo]="accao !== actions.detalhes">
                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>Data 3ª Notificação</mat-label>
                                        <input matInput autocomplete="off"
                                               [formControlName]="FORM.data3aNotificacao"
                                               [matDatepicker]="data3Notificacao" readonly/>
                                        <mat-datepicker-toggle matSuffix [for]="data3Notificacao"
                                                               [ngClass]="{disabled: true}">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #data3Notificacao></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <!-- Data 4ª Notificação -->
                                <div *ngIf="!NaN(f[FORM.ciclo].value?.dias5Notificacao) && false" fxFlex="176px"
                                     class="mr-1"
                                     [class.campo-inactivo]="accao !== actions.detalhes">
                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>Data 4ª Notificação</mat-label>
                                        <input matInput autocomplete="off"
                                               [formControlName]="FORM.data4aNotificacao"
                                               [matDatepicker]="data3Notificacao" readonly/>
                                        <mat-datepicker-toggle matSuffix [for]="data3Notificacao"
                                                               [ngClass]="{disabled: true}">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #data3Notificacao></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div fxFlex="100" class="pr-1 mb-1">
                        <mat-form-field class="full-width" appearance="outline"
                                        matTooltip='Os emails devem estar separados por ";"'
                                        matTooltipClass="bg-secondary"
                                        matTooltipPosition="above">
                            <mat-label>E-mails a Notificar</mat-label>
                            <input matInput [formControlName]="FORM.emailDestinatarios" autocomplete="false"
                                   placeholder="Ex: pat@mail.ao" [readonly]="accao === actions.detalhes">
                        </mat-form-field>
                        <mat-error *ngIf="f[FORM.emailDestinatarios].getError('invalid') && submitted">
                            <small>Formato de endereços de e-mail inválido(s)</small>
                        </mat-error>
                    </div>
                </div>
                <!--Itens associados-->
                <div style="overflow-x: auto;white-space: nowrap; width: fit-content">
                    <fieldset [formArrayName]="FORM.artigos">
                        <legend>{{ classificacao?.classificacaoP }}</legend>
                        <div *ngFor="let artigo of artigosForm?.controls; index as i">
                            <form [formGroupName]="i">
                                <div fxLayout="row">
                                    <div fxFlex="2" fxLayoutAlign="space-around end" class="pr-1 icom-m-b"
                                         style="cursor: pointer; padding-bottom: 8px;margin-left: 10px;margin-right: 10px;">
                                        <span><b># {{ i + 1 }}</b></span>
                                        <mat-icon fxLayoutAlign="center float-right" (click)="removerArtigo(i)"
                                                  *ngIf="accao !== actions.detalhes"
                                                  class="mat-icon notranslate mat-warn material-icons">
                                            delete
                                        </mat-icon>
                                    </div>
                                    <!--Cod. Artigo-->
                                    <div fxFlex="12" class="pr-1 mt-1"
                                         [class.campo-inactivo]="f[FORM.idCliente].invalid">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Cod. Artigo</mat-label>
                                            <input matInput [formControlName]="FORM.codArtigo"
                                                   placeholder="Cod. Artigo"
                                                   maxlength="100" (input)="filtrarArtigos($event)"
                                                   [matAutocomplete]="artigosAutoComplete"
                                                   [readonly]="accao === actions.detalhes || f[FORM.idCliente].invalid"/>
                                            <mat-icon *ngIf="accao !== actions.detalhes"
                                                      class="cursor-pointer text-primary" color="accent"
                                                      title="Ajuda de pesquisa" matSuffix>
                                                folder
                                            </mat-icon>
                                            <mat-autocomplete [panelWidth]="500"
                                                              #artigosAutoComplete="matAutocomplete"
                                                              (optionSelected)="showArtigos();updateArtigoFields($event.option.value, i)"
                                                              style="max-width: 150px!important">
                                                <mat-option class="bg-primary sticky-header"
                                                            *ngIf="filteredArtigos.length > 0"
                                                            [value]="null">
                                                    <span class="flex border-b-light">
                                                        <small class="w-60 disabilitar">Código</small>
                                                        <small class="w-60 disabilitar">Designação</small>
                                                        <small class="w-15 p disabilitar text-center">Reten.</small>
                                                    </span>
                                                </mat-option>
                                                <mat-option *ngFor="let a of filteredArtigos" [value]="a">
                                                    <span class="flex border-b-light">
                                                        <span class="w-60"><small>{{ a.codArtigo }}</small></span>
                                                    <span class="w-60"><small>{{ a.nomeArtigo }}</small></span>
                                                        <small class="w-15 pr-03 text-center">
                                                            <mat-icon style="font-size: 17px;"
                                                                      *ngIf="a.sujeitoAretencoes"
                                                                      color="primary">check_small</mat-icon>
                                                        </small>
                                                    </span>
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                        <mat-error *ngIf="artigo.get(FORM.idArtigo)?.errors &&
                                                (artigo.get(FORM.idArtigo)?.errors!['required'] || artigo.get(FORM.idArtigo)?.errors!['min']) && submitted">
                                            <small>Seleccione
                                                um {{ classificacao?.classificacaoS?.toLowerCase() }}</small>
                                        </mat-error>
                                    </div>
                                    <!--Designação-->
                                    <div fxFlex="50"
                                         class="pr-1 mt-1">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Designação</mat-label>
                                            <input matInput [formControlName]="FORM.designacao"
                                                   placeholder="Designação"
                                                   maxlength="100" [readonly]="accao === actions.detalhes"/>
                                        </mat-form-field>
                                    </div>
                                    <!--Quantidade-->
                                    <div fxFlex="98px" class="pr-1 mt-1">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Quantidade</mat-label>
                                            <input matInput autocomplete="false" placeholder="0,00"
                                                   style="text-align: right" [formControlName]="FORM.quantidade"
                                                   [readonly]="accao === actions.detalhes"
                                                   [appInputFormat]="formats.Decimal"
                                                   (input)="validateQtd(qtdRef, artigo)" #qtdRef/>
                                        </mat-form-field>
                                    </div>
                                    <!--Preço-->
                                    <div style="min-width: 9em;" class="pr-1 mt-1"
                                         [class.campo-inactivo]="artigo.get(FORM.precoBloqueado).value">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Preço</mat-label>
                                            <input matInput autocomplete="false" placeholder="0,00"
                                                   style="text-align: right" [formControlName]="FORM.preco"
                                                   [appInputFormat]="formats.Decimal"
                                                   [readonly]="accao === actions.detalhes || artigo.get(FORM.precoBloqueado).value"
                                                   (input)="validatePrice(priceRef, artigo)" #priceRef/>
                                        </mat-form-field>
                                    </div>
                                    <!-- Desconto (%) -->
                                    <div style="min-width: 6em" class="pr-1 mt-1 perc">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Desc (%)</mat-label>
                                            <input matInput autocomplete="false"
                                                   [formControlName]="FORM.descontoPerc"
                                                   (input)="calcularDesconto($event, artigo)"
                                                   style="text-align: right" placeholder="0%"
                                                   [appInputFormat]="formats.Decimal"/>
                                        </mat-form-field>
                                    </div>
                                    <!-- Desconto -->
                                    <div fxFlex="120px" class="pr-1 mt-1">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Desconto</mat-label>
                                            <input matInput autocomplete="false" [formControlName]="FORM.desconto"
                                                   placeholder="0,00" style="text-align: right" readonly/>
                                        </mat-form-field>
                                    </div>
                                    <!--Total-->
                                    <div style="min-width: 9em;" class="pr-1 mt-1">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Total</mat-label>
                                            <input matInput autocomplete="false" [formControlName]="FORM.total"
                                                   placeholder="0,00" style="text-align: right" readonly/>
                                        </mat-form-field>
                                    </div>
                                    <!--IVA %-->
                                    <div fxFlex="120px" class="pr-1 mt-1">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>IVA</mat-label>
                                            <mat-select [formControlName]="FORM.idImposto"
                                                        (selectionChange)="calcularImposto($event, artigo)"
                                                        [disabled]="accao === actions.detalhes">
                                                <mat-option
                                                        *ngFor="let iva of f[FORM.empresa].value?.impostos | filterFn: ivaActivo"
                                                        [value]="iva"
                                                        [disabled]="accao === actions.detalhes">
                                                    {{ iva.codImposto }} -{{ iva.taxa }}%
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="artigo.get(FORM.idImposto)?.errors &&
                                                            (artigo.get(FORM.idImposto)?.errors!['required'] || artigo.get(FORM.idImposto)?.errors!['min']) && submitted">
                                            <small>Selecione um imposto!</small>
                                        </mat-error>
                                    </div>
                                    <!--Imposto-->
                                    <div style="min-width: 9em;" class="pr-1 mt-1">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Imposto</mat-label>
                                            <input matInput autocomplete="false" [formControlName]="FORM.imposto"
                                                   placeholder="0,00" style="text-align: right" readonly #priceRef/>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <ng-container *ngIf="artigo.get(FORM.composicao)?.controls.length > 0">
                                    <!--
                               |************************  |
                               | COMPOSICOES DO RENOVAVEL |
                               |************************  |
                                  -->
                                    <div [formArrayName]="FORM.composicao">
                                        <div fxLayout="row" class="border-bottom">
                                            <div fxFlex="10" class="py-1">
                                                <div><strong class="p-1">Código</strong></div>
                                            </div>
                                            <div fxFlex="30" class="py-1">
                                                <div><strong class="p-1">Descrição</strong></div>
                                            </div>
                                            <div fxFlex="10" class="py-1">
                                                <div><strong class="p-1">Preço</strong></div>
                                            </div>
                                            <div fxFlex="10" class="py-1">
                                                <div><strong class="p-1">Unidade</strong></div>
                                            </div>
                                            <div fxFlex="7" class="py-1" style="margin-right: 50px">
                                                <div><strong class="p-1">Quantidade</strong></div>
                                            </div>
                                            <div fxFlex="10" class="py-1" style="padding-left: 11px">
                                                <div><strong class="p-1">Total</strong></div>
                                            </div>
                                        </div>
                                        <div>
                                            <ng-container
                                                    *ngFor="let comp of artigo.get(FORM.composicao)?.controls; index as compIndex"
                                                    class="flex flex-y-center">
                                                <div fxLayout="row" class="border-bottom"
                                                     [formGroupName]="compIndex">
                                                    <div fxFlex="10" class="py-1 flex flex-y-center">
                                                        <div><span class="p-1">{{ comp?.value.codigo }}</span></div>
                                                    </div>
                                                    <div fxFlex="30" class="py-1 flex flex-y-center">
                                                        <div><span class="p-1">{{ comp?.value.descricao }}</span>
                                                        </div>
                                                    </div>
                                                    <div fxFlex="10" class="py-1 flex flex-y-center">
                                                        <div class="text-right"><span
                                                                class="p-1">{{ comp?.value.preco1 | currency: ' ' }}</span>
                                                        </div>
                                                    </div>
                                                    <div fxFlex="10" class="py-1 flex flex-y-center text-center">
                                                        <div class="text-center"><span
                                                                class="p-1"> {{ comp.value.artigo?.codUnidade }} </span>
                                                        </div>
                                                    </div>
                                                    <div fxFlex="103px" class="py-1 px-1" style="margin-right: 50px"
                                                         [class.campo-inactivo]="!comp?.value.quantidadeVariavel">
                                                        <mat-form-field class="full-width" appearance="outline">
                                                            <mat-label>Quantidade</mat-label>
                                                            <input matInput autocomplete="false"
                                                                   [readonly]="!comp?.value.quantidadeVariavel"
                                                                   [formControlName]="FORM.quantidade"
                                                                   (input)="recalcularTotal(i)"
                                                                   class="text-right"
                                                                   [appInputFormat]="'decimal'"
                                                                   placeholder="0,00"
                                                            />
                                                        </mat-form-field>
                                                    </div>
                                                    <div fxFlex="10" class="py-1 flex flex-y-center">
                                                        <div class="text-right"><span
                                                                class="p-1">{{ calcularTotal(comp.value.quantidade, comp.value.preco1) | currency: '':'' }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <!-- COMPOSICOES DO RENOVAVEL -->
                                </ng-container>
                            </form>
                        </div>
                    </fieldset>
                </div>
            </form>
        </div>
    </mat-card-content>

    <!--Renovações-->
    <mat-card class="m-0 pr-0 pl-0" *ngIf="accao !== actions.cadastrar">
        <mat-card-title class="font-weight-bold text-whi bg-primary mb-0 pb-1 pt-1" style="padding-left: 7px">
            Renovações
        </mat-card-title>
        <ngx-datatable #renovacoesTable class="material expandable"
                       style="height: calc(100vh - 500px)"
                       [messages]="{ emptyMessage: 'Sem renovações para mostrar', totalMessage: 'Total' }"
                       [rows]="renovacoes" [headerHeight]="35" [footerHeight]="45"
                       [rowHeight]="35" [scrollbarV]="true" [scrollbarH]="true"
                       columnMode="force"
                       [selectAllRowsOnPage]="false" [selectionType]="rowSelectionType">
            <ngx-datatable-column [sortable]="false" [resizeable]="false"
                                  [draggable]="false"
                                  [canAutoResize]="false" [width]="50">
                <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                    <div>
                            <span class="btn-table-expandir"
                                  [class.datatable-icon-right]="!expanded"
                                  [class.datatable-icon-down]="expanded"
                                  [class.bg-primary]="expanded"
                                  title="Expandir/Colapsar"
                                  (click)="toggleExpandRow(row)"
                            ></span>
                    </div>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-row-detail rowHeight="350">
                <ng-template let-row="row" ngx-datatable-row-detail-template>
                    <ngx-datatable [rows]="row.artigos" class="material fullscreen bg-white selection-cell"
                                   [columnMode]="'force'" [headerHeight]="40"
                                   [messages]="{ emptyMessage: 'Sem itens para mostrar', totalMessage: 'Total' }"
                                   [footerHeight]="40"
                                   [limit]="5"
                                   [rowHeight]="50"
                                   style="height: 98%"
                                   [scrollbarV]="true">
                        <ngx-datatable-column name="Designação" [maxWidth]="350">
                            <ng-template let-innerRow="row" ngx-datatable-cell-template>
                                {{ innerRow.designacao }}
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Quantidade" [maxWidth]="150">
                            <ng-template let-innerRow="row" ngx-datatable-cell-template>
                                {{ innerRow.quantidade | currency:'':'' }}
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Preço" [maxWidth]="200">
                            <ng-template let-innerRow="row" ngx-datatable-cell-template>
                                {{ innerRow.preco | currency:'':'' }}
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Desc (%)" [maxWidth]="200">
                            <ng-template let-innerRow="row" ngx-datatable-cell-template>
                                {{ innerRow.descontoPerc | currency:'':'' }} %
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Desconto" [maxWidth]="200">
                            <ng-template let-innerRow="row" ngx-datatable-cell-template>
                                {{ innerRow.desconto | currency:'':'' }}
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Imposto" [maxWidth]="200">
                            <ng-template let-innerRow="row" ngx-datatable-cell-template>
                                {{ innerRow.imposto | currency:'':'' }}
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </ng-template>
            </ngx-datatable-row-detail>

            <ngx-datatable-column name="Data Início" [maxWidth]="150">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.dataInicio | date: 'dd-MM-yyyy' }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Data Fim" [maxWidth]="150">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.dataFim | date: 'dd-MM-yyyy' }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Documentos" [maxWidth]="150">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <mat-chip [matMenuTriggerFor]="docs" mat-sm-chip [color]="'accent'" style="cursor: pointer">
                        {{ row.documentos?.length ?? 0 }}
                    </mat-chip>
                    <mat-menu #docs="matMenu" [class]="'docs'" yPosition="below" xPosition="after">
                        <app-documentos [documentos]="row.documentos"></app-documentos>
                    </mat-menu>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Total" [maxWidth]="200">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.total | currency:'':'' }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Pendente" [maxWidth]="200">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.pendente | currency:'':'' }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Estado" [maxWidth]="200">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <mat-chip mat-sm-chip style="color: white"
                              class="pr-1 pl-1 {{colors[row.estado?.id]}}">
                        {{ row.estado?.nome }}
                    </mat-chip>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [maxWidth]="80">
                <!-- 5 => "Anulado" -->
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <button mat-icon-button type="button" (click)="anular(row)"
                            matTooltip="Anular"
                            [disabled]="(row.documentos | someFn: some: ['E', 'R'] : ['L', 'F']) || row.estado.id === 5"
                            [class.opacity-2]="(row.documentos | someFn: some: ['E', 'R'] : ['L', 'F']) || row.estado.id === 5"
                            matTooltipClass="bg-secondary"
                            matTooltipPosition="above">
                        <mat-icon class="mat-icon notranslate mat-warn material-icons">cancel</mat-icon>
                    </button>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </mat-card>
</mat-card>
