import { Injectable } from '@angular/core';
import { Aplicacao } from 'app/shared/models/aplicacao/aplicacao';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppStoreService {
  public aplicacao$ = new BehaviorSubject<Aplicacao>(null);
  public aplicacao = false;

  constructor() {}

  setAplicacao(aplicacao: Aplicacao) {
    this.aplicacao$.next(aplicacao);
  }

  getAplicacao(): Observable<Aplicacao> {
    this.aplicacao=true;
    return this.aplicacao$.asObservable();
  }
}
