<div class="pt-16 pb-0 px-8">
    <mat-progress-bar *ngIf="loadings | someFn: some" mode="indeterminate"></mat-progress-bar>
    <section>
        <form [formGroup]="form" (ngSubmit)="submit()" id="factura-area">
            <div class="mb-1 pt-0" fxLayout="row" fxLayoutAlign="space-between center" id="sec-barra-accoes">
                <span [mat-dialog-close]="false" class="cursor-pointer btn-close-modal">
                    <mat-icon>close</mat-icon>
                </span>

                <div class="flex">
                    <button class="ml-1 mr-1" color="primary" [class.opacity-2]="loadings | someFn: some"
                            [disabled]="loadings | someFn: some" type="button"
                            mat-raised-button [matMenuTriggerFor]="accoesMenuGravar">
                        <span>Gravar</span>
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #accoesMenuGravar="matMenu">
                        <button (click)="sendMail = false;submit()" mat-menu-item>
                            Gravar
                        </button>
                        <button (click)="sendMail = true;submit()" mat-menu-item>
                            Gravar-Enviar Email
                        </button>
                    </mat-menu>
                </div>
            </div>

            <mat-card class="p-0" id="factura-conteudo">
                <section style="padding: 4px">
                    <div class="pt-0">
                        <div fxLayout="row wrap mb-1" style="gap: 8px">
                            <mat-form-field [appearance]="'legacy'"
                                            [style.width.em]="data.empresa?.codEmpresa?.length">
                                <mat-label>Empresa</mat-label>
                                <input matInput readonly [value]="data.empresa?.codEmpresa">
                            </mat-form-field>

                            <mat-form-field [appearance]="'legacy'" style="min-width: 6em">
                                <mat-label>Série</mat-label>
                                <mat-select [formControlName]="formNames.serie" (selectionChange)="getCodigo()">
                                    <mat-option [value]="null">Seleccione</mat-option>
                                    <mat-option *ngFor="let serie of series" [value]="serie">
                                        {{ serie.codSerie }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="mat-override">
                                <mat-form-field [appearance]="'legacy'"
                                                style="min-width: 14em">
                                    <mat-label>Tipo Documento</mat-label>
                                    <mat-select [formControlName]="formNames.tipo"
                                                (selectionChange)="getCodigo();setDates()">
                                        <mat-option>Seleccione</mat-option>
                                        <mat-option *ngFor="let tipo of data.tipos"
                                                    [value]="tipo">
                                            {{ tipo.codTipoDocumento }} - {{ tipo.tipoDocumento }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div style="width: 6em" class="pr-1 remover-marcas-validacao">
                                <mat-form-field [appearance]="'legacy'" class="full-width">
                                    <mat-label>Número</mat-label>
                                    <input [formControlName]="formNames.numero" matInput
                                           min="1" name="numero" placeholder="Número" type="number"/>
                                    <mat-icon class="cursor-pointer fs-white bg-secondary btn-research ml-05"
                                              color="primary" matSuffix>search
                                    </mat-icon>
                                </mat-form-field>
                            </div>

                            <div style="min-width: 10em" class="pr-1 remover-marcas-validacao">
                                <mat-form-field [appearance]="'legacy'">
                                    <mat-label>Documento</mat-label>
                                    <input [formControlName]="formNames.codDocumento"
                                           matInput maxlength="15" name="codigo" placeholder="Documento"
                                           [readonly]="f[formNames.tipo].value?.numeracaoSequencial"/>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="pb-0 pt-0 mb-1" fxLayout="row wrap" style="gap: 12px;width: 100%">
                            <div fxLayout="row wrap" style="gap: 8px;max-width: 55%">
                                <mat-form-field fxFlex="153px" class="full-width" appearance="legacy">
                                    <mat-label>Entidade</mat-label>
                                    <input matInput [value]="data.entidade?.codCliente" readonly>
                                </mat-form-field>

                                <mat-form-field appearance="legacy"
                                                style="min-width: 10em"
                                                [style.width.em]="data.entidade?.nome?.length ?? 10">
                                    <mat-label>Denominação Fiscal</mat-label>
                                    <input matInput
                                           name="nomeCliente" placeholder="Nome"
                                           [value]="data.entidade?.nome" readonly/>
                                </mat-form-field>

                                <mat-form-field [appearance]="'legacy'"
                                                [style.width.em]="data.entidade?.nif?.length ?? 10">
                                    <mat-label>NIF</mat-label>
                                    <input matInput
                                           name="nif" placeholder="NIF"
                                           [value]="data.entidade?.nif" readonly/>
                                </mat-form-field>

                                <mat-form-field [appearance]="'legacy'"
                                                [style.width.em]="data.entidade?.paisFact?.length ?? 4">
                                    <mat-label>País</mat-label>
                                    <input matInput
                                           name="pais" placeholder="País"
                                           [value]="data.entidade?.paisFact" readonly/>
                                </mat-form-field>

                                <mat-form-field *ngIf="data.entidade?.provinciaFact"
                                                [appearance]="'legacy'"
                                                [style.width.em]="data.entidade?.provinciaFact?.length ?? 9">
                                    <mat-label>Província</mat-label>
                                    <input matInput
                                           name="provincia" placeholder="Província" readonly
                                           [value]="data.entidade?.provinciaFact"/>
                                </mat-form-field>

                                <mat-form-field [appearance]="'legacy'"
                                                [style.width.em]="data.entidade?.cidade?.length ?? 6">
                                    <mat-label>Cidade</mat-label>
                                    <input matInput
                                           name="cidade" placeholder="Cidade" readonly
                                           [value]="data.entidade?.cidade"/>
                                </mat-form-field>

                                <mat-form-field [appearance]="'legacy'"
                                                [style.width.em]="data.entidade?.codMoeda?.length * 4 ?? 8">
                                    <mat-label>Moeda {{data.code === 'CP' ? 'Fornecedor' : 'Cliente'}}</mat-label>
                                    <input matInput
                                           name="clienteMoeda" placeholder="Moeda" readonly
                                           [value]="data.entidade?.codMoeda"/>
                                </mat-form-field>
                            </div>

                            <mat-form-field [appearance]="'outline'" fxFlex="40">
                                <mat-label>Morada</mat-label>
                                <textarea class="no-risize"
                                          matInput name="morada" placeholder="Morada"
                                          rows="3" readonly
                                          [value]="data.entidade?.moradaFact"></textarea>
                            </mat-form-field>
                        </div>

                        <div class="pb-0 pt-0 mb-1" fxLayout="row wrap" style="gap: 12px;width: 100%">
                            <mat-form-field [appearance]="'legacy'" fxFlex="136px" fxFill>
                                <mat-label>Data Documento</mat-label>
                                <input
                                        [matDatepicker]="dataDoc" [max]="maxDate"
                                        [min]="minDate" [appInputFormat]="formats.Data"
                                        [formControlName]="formNames.dataDocumento"
                                        matInput name="dataDocumento" placeholder="Data Documento"/>
                                <mat-datepicker-toggle [for]="dataDoc"
                                                       matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #dataDoc></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field [appearance]="'legacy'" style="min-width: 13em">
                                <mat-label>Condição de Pagamento</mat-label>
                                <mat-select [formControlName]="formNames.idCondicaoPagamento">
                                    <mat-option [value]="null">Seleccione
                                    </mat-option>
                                    <mat-option *ngFor="let condicao of condicoesPagamento"
                                                [value]="condicao.idCondicaoPagamento"
                                                [innerText]="condicao.condicao">
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field [appearance]="'legacy'" fxFlex="136px" fxFill>
                                <mat-label>Data Vencimento</mat-label>
                                <input
                                        [matDatepicker]="dataVencimento"
                                        [min]="f[formNames.dataDocumento].value" [appInputFormat]="formats.Data"
                                        [formControlName]="formNames.dataVencimento"
                                        matInput name="dataVencimento" placeholder="Data Vencimento"/>
                                <mat-datepicker-toggle [for]="dataVencimento"
                                                       matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #dataVencimento></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field appearance="legacy" style="min-width: 13em">
                                <mat-label>Método Pagamento</mat-label>
                                <mat-select [formControlName]="formNames.metodoPagamento" disabled>
                                    <mat-option [value]="null">Seleccione</mat-option>
                                    <mat-option *ngFor="let metodo of data.metodosPagamento"
                                                [value]="metodo" [innerText]="metodo.metodoPagRec">
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div *ngIf="f[formNames.metodoPagamento].value?.codMetodoPagRec === 'RB'">
                                <mat-form-field [appearance]="'legacy'" style="min-width: 8em;margin-right: 8px">
                                    <mat-label>Entidade</mat-label>
                                    <mat-select [formControlName]="formNames.idRefBancEntidade"
                                                (selectionChange)="gerarReferencia($event)">
                                        <mat-option [value]="null">Seleccione</mat-option>
                                        <mat-option *ngFor="let entidade of entidades"
                                                    [value]="entidade.idRefBancEntidade"
                                                    [innerText]="entidade.codRefBancEntidade">
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field [appearance]="'legacy'" style="min-width: 8em">
                                    <mat-label>Referência</mat-label>
                                    <input [formControlName]="formNames.numRefBancaria" matInput
                                           placeholder="Referência" readonly/>
                                </mat-form-field>
                            </div>

                            <mat-form-field *ngIf="f[formNames.metodoPagamento].value?.usaConta &&
                            f[formNames.tipo].value?.codCategoriaDocumento === 'L'"
                                            [appearance]="'legacy'"
                                            [style.min-width.em]="f[formNames.metodoPagamento].value?.tipoConta?.length ?? 8">
                                <mat-label>Conta</mat-label>
                                <mat-select [formControlName]="formNames.idContaMetodoPagRec"
                                            [required]="f[formNames.tipo].value?.codCategoriaDocumento === 'L'">
                                    <mat-option [value]="null">Seleccione</mat-option>
                                    <mat-option *ngFor="let conta of contas"
                                                [value]="conta?.idConta">
                                        {{ conta?.nomeConta }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="pb-0 pt-0 mb-1 mt-1 totais" fxLayout="row wrap">
                            <div fxLayout="row wrap" style="max-width: 55%">
                                <div class="mb-8" style="align-items: baseline">
                                    <div class="pr-8 text-primario" style="min-width: 170px">
                                        <strong>Total Iliq.:</strong> {{totalIliq | currency: " " }}
                                    </div>

                                    <div style="min-width: 170px" class="text-primario pr-1 pb-1">
                                        <strong>Total ({{data.empresa?.moedaPrincipal}}
                                            ): </strong>{{ total | currency: '':''}}
                                    </div>

                                    <div *ngIf="data.empresa?.moedaPrincipal !== data.entidade?.codMoeda"
                                         fxLayout="row wrap" class="flex-xy-left">
                                            <span class="text-primario pr-1">
                                                <strong>Total ({{data.entidade?.codMoeda}}
                                                    ): </strong>{{ totalIliqCliente | currency: '':'' }}
                                            </span>
                                    </div>
                                </div>

                                <div>
                                    <div class="pr-8 text-primario">
                                        <strong>IVA:</strong> {{ totalImposto | currency: '':'' }}
                                    </div>
                                    <div *ngIf="f[formNames.tipo].value?.calculaRetencoes"
                                         class="text-primario pr-1 pb-1">
                                        <b>Retenções:</b> {{ valorRetencao | currency: '':'' }}
                                    </div>
                                    <div class="pr-8 text-primario " style="min-width: 170px">
                                        <strong>Total Desc.:</strong> {{totalDescontos | currency: '':''}}
                                    </div>
                                </div>

                                <div>
                                    <div fxLayout="row">
                                        <span class="txt-bold text-primario">Moeda:&nbsp;</span>
                                        <span style="width: 3em" class="underline"
                                              [innerText]="data.empresa?.moedaPrincipal"></span>
                                    </div>

                                    <div *ngIf="data.empresa?.moedaPrincipal !== data.entidade?.codMoeda"
                                         class="flex-y-center">
                                            <span class="txt-bold text-primario">Câmbio({{data.entidade?.codMoeda}}
                                                /{{data.empresa?.moedaPrincipal}}):</span>
                                        <mat-form-field [appearance]="'legacy'" style="width: 8em">
                                            <input matInput [value]="f[formNames.taxaCambio].value?.taxaCambio"
                                                   name="taxaCambio" class="text-center" type="number"
                                                   readonly/>
                                            <mat-icon *ngIf="false" (click)="pesquisarCambio()"
                                                      class="mat-icon material-icons text-primary cursor-pointer"
                                                      matSuffix>folder
                                            </mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <mat-form-field [appearance]="'outline'" fxFlex="40">
                                <mat-label> Observa&ccedil;&otilde;es</mat-label>
                                <textarea [formControlName]="formNames.observacoes" matInput maxlength="255"
                                          name="observacoes"
                                          placeholder="Observações"></textarea>
                            </mat-form-field>
                        </div>

                        <div class="table-wrapper" style="min-height: 35vh;">
                            <table class="tb-title overflow-tabela" style="overflow-x: scroll;">
                                <caption class="bg-primary">Itens</caption>
                                <thead>
                                <tr>
                                    <th class="w-30px">#</th>
                                    <th class="w-120px">Referência</th>
                                    <th class="w-300px ml-05 text-center">Descrição</th>
                                    <th class="text-center w-120px">Qtd.</th>
                                    <th class="w-50px text-center">UN</th>
                                    <th class="w-120px">Preço ({{data.empresa?.moedaPrincipal}})</th>
                                    <th class="w-60px text-center">Desc %</th>
                                    <th class="text-center w-120px">Desc.</th>
                                    <th class="w-120px text-center">Total</th>
                                    <th class="w-70px text-center">IVA %</th>
                                    <th class="text-center w-150px">IVA</th>
                                    <th class="text-center w-160px text-left mr-24">Total c/ IVA</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of data.itens; index as i">
                                    <td>{{ i + 1 }}</td>
                                    <td><span class="underline">{{item.artigo?.codArtigo}}</span></td>
                                    <td><span class="underline">{{item.descricao}}</span></td>
                                    <td><span class="underline text-right">{{item.quantidade | currency:'':''}}</span>
                                    </td>
                                    <td><span class="underline">{{item.artigo?.codUnidade}}</span></td>
                                    <td><span class="underline text-right">{{item.preco | currency:'':''}}</span></td>
                                    <td><span class="underline text-right">{{item.descontoPerc | currency:'':''}}
                                        %</span></td>
                                    <td><span class="underline text-right">{{item.desconto | currency:'':''}}</span>
                                    </td>
                                    <td><span class="underline text-right">{{item.total | currency:'':''}}</span></td>
                                    <td>
                                        <span class="underline text-right">
                                            {{ getImposto(item.idImposto)?.taxa | currency:'':''}}%
                                        </span>
                                    </td>
                                    <td><span class="underline text-right">{{item.imposto | currency:'':''}}</span></td>
                                    <td><span
                                            class="underline text-right">{{item.total | sum: item.imposto | currency:'':''}}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </mat-card>
        </form>
    </section>
</div>
