import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Empresa } from 'app/shared/models/empresa/empresa';
import { Documentos } from 'app/shared/models/financas/documentos/documentos';
import { Util } from 'app/shared/models/generico/util';
import { CalculosFinancasService } from 'app/shared/services/Calculos/calculos-financas.service';

@Component({
  selector: 'app-tabela-itens-fin',
  templateUrl: './tabela-itens-fin.component.html',
  styleUrls: ['./tabela-itens-fin.component.scss']
})
export class TabelaItensFinComponent implements OnInit {
  empresa: Empresa = {};
  facturaForm: FormGroup;
  conversaoDirecta = false;

  get Itens(): FormArray {
    return this.facturaForm.get("Itens") as FormArray;
  }
  constructor(private fb: FormBuilder,  public _doccalculoFront: CalculosFinancasService,
    private currencyPipe: CurrencyPipe,) { }

  ngOnInit(): void {
    this.inicializarFormulario()
  }
  inicializarFormulario(documento?: Documentos): void {
    this.facturaForm = this.fb.group({
      Itens: this.fb.array([]),})
  }

  async calcularValoresItem(index: number) {
    const itens = await this.alterarValoresIdItem(this.Itens.value);

    const Totais = {
      ConversaoDirecta: this.conversaoDirecta,
      TaxaCambio: this.facturaForm.value.TaxaCambio,
    };

    const modelo = {
      item: itens[index],
      itens,
      Totais,
    };
    this.calcularValoresItemTotaisFront(modelo, index);
  }

  calcularValoresItemTotaisFront(modelo:any, index:number) {
    const resultado = this._doccalculoFront.CalcularValoresItem(modelo);
    const dados = resultado.totais;
    const linha = resultado.item;
    this.alterarLinhaItem(linha, index);
    if (linha.descontoPerc > 100) {
      this.Itens.controls[index]
        .get("DescontoPerc")
        .setValue(
          this.currencyPipe.transform(Util.convertToFloat(100), "", "")
        );
    }
  }

  
  alterarLinhaItem(item: any, index: number) {
    this.Itens.controls[index]
      .get("Desconto")
      .setValue(
        this.currencyPipe.transform(Util.convertToFloat(item.desconto), "", "")
      );
    this.Itens.controls[index]
      .get("imposto")
      .setValue(
        this.currencyPipe.transform(Util.convertToFloat(item.imposto), "", "")
      );
    this.Itens.controls[index]
      .get("Total")
      .setValue(
        this.currencyPipe.transform(Util.convertToFloat(item.total), "", "")
      );
    this.Itens.controls[index]
      .get("TotalGeral")
      .setValue(
        this.currencyPipe.transform(
          Util.convertToFloat(item.totalGeral),
          "",
          ""
        )
      );
    this.Itens.controls[index]
      .get("SubTotal")
      .setValue(
        this.currencyPipe.transform(Util.convertToFloat(item.subTotal), "", "")
      );
  }

  async alterarValoresIdItem(itens: any): Promise<any> {
    itens.forEach((element, index) => {
      this.Itens.controls[index].get("IdItem").setValue(index + 1);
    });

    return this.Itens.value;
  }
}
