<div [formGroup]="campo" class="pt-05 pb-1 ml-1" fxLayout="column"
     style="margin-top: 10px;">
    <div fxLayout="row wrap">
        <!-- Tipo de dados -->
        <div fxFlex="160px" fxFlex.sm="90" fxFlex.xs="100" class="pr-1">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Tipo de dados</mat-label>
                <mat-select [formControlName]="formNames.tipo"
                            (selectionChange)="campo.get(formNames.min).reset();campo.get(formNames.max).reset()"
                            [disabled]="action === actions.detalhes">
                    <mat-option *ngFor="let t of tipos" [value]="t">
                        {{ t.nome }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!-- Select -->
        <div fxFlex="110px" fxFlex.xs="100" class="pl-1 mr-1"
             style="padding-top: 10px">
            <mat-slide-toggle class="font-weight-bold text-primary"
                              [formControlName]="formNames.select"
                              [class.mat-override]="action === actions.detalhes"
                              [disabled]="action === actions.detalhes"
                              (change)="updateSelectFields($event, campo)"
                              color="primary">
                Seleção
            </mat-slide-toggle>
        </div>
        <!-- Usa Código -->
        <div *ngIf="campo.value.select" fxFlex="120px" fxFlex.xs="100" class="pl-1 mr-1"
             style="padding-top: 10px">
            <mat-slide-toggle class="font-weight-bold text-primary"
                              [formControlName]="formNames.usaCodigo"
                              [class.mat-override]="action === actions.detalhes"
                              [disabled]="action === actions.detalhes"
                              color="primary">
                Usa Código
            </mat-slide-toggle>
        </div>
        <!-- Inserção Múltipla -->
        <div fxFlex="172px" class="pl-1 mr-1"
             style="padding-top: 10px">
            <mat-slide-toggle class="font-weight-bold text-primary"
                              [formControlName]="formNames.insercaoMultipla"
                              [class.mat-override]="action === actions.detalhes"
                              [disabled]="action === actions.detalhes"
                              (change)="resetNumInsercoes(campo)"
                              color="primary">
                Inserção Múltipla
            </mat-slide-toggle>
        </div>
        <!-- Nome -->
        <div fxFlex="210px" class="pr-1">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>
                    Nome {{ isPlural(campo) ? 'Singular' : '' }}</mat-label>
                <input matInput autocomplete="false"
                       placeholder="Nome {{ isPlural(campo) ? 'Singular' : '' }}"
                       maxlength="60"
                       [formControlName]="formNames.nome"
                       [readonly]="action === actions.detalhes"/>
            </mat-form-field>
        </div>
        <!-- Nome Plural -->
        <div *ngIf="isPlural(campo)" fxFlex="210px"
             class="pr-1">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Nome Plural</mat-label>
                <input matInput autocomplete="false" placeholder="Nome"
                       maxlength="60"
                       [formControlName]="formNames.nomeP"
                       [readonly]="action === actions.detalhes"/>
            </mat-form-field>
        </div>
        <ng-container [ngSwitch]="campo.value.tipo?.codigo" *ngIf="false">
            <ng-container *ngSwitchCase="types.INTEIRO">
                <!-- Min -->
                <div fxFlex="130px"
                     fxFlex.sm="90"
                     fxFlex.xs="100" class="pr-1">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Valor Mínimo</mat-label>
                        <input matInput autocomplete="false" type="number" step="1"
                               [readonly]="action === actions.detalhes"
                               [formControlName]="formNames.min"
                               placeholder="Min"/>
                    </mat-form-field>
                </div>
                <!-- Max -->
                <div fxFlex="137px"
                     fxFlex.sm="90"
                     fxFlex.xs="100" class="pr-1">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Valor Máximo</mat-label>
                        <input matInput autocomplete="false" type="number" step="1"
                               [readonly]="action === actions.detalhes"
                               [formControlName]="formNames.max"
                               placeholder="Max"/>
                    </mat-form-field>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="types.DATE">
                <!-- Data Início -->
                <div fxFlex="170px" fxFlex.xs="100" class="pr-1">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Data Mínima</mat-label>
                        <input matInput [matDatepicker]="dataInicio"
                               [appInputFormat]="formats.Data"
                               [formControlName]="formNames.min"
                               [max]="campo.get(formNames.max).value ? campo.get(formNames.max).value : null"
                               [readonly]="action === actions.detalhes"/>
                        <mat-datepicker-toggle *ngIf="action !== actions.detalhes" matSuffix
                                               [for]="dataInicio">
                        </mat-datepicker-toggle>
                        <mat-datepicker #dataInicio></mat-datepicker>
                    </mat-form-field>
                </div>
                <!-- Data Fim -->
                <div fxFlex="170px" fxFlex.xs="100" class="pr-1">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Data Máxima</mat-label>
                        <input matInput [matDatepicker]="dataFim"
                               [appInputFormat]="formats.Data"
                               [formControlName]="formNames.max"
                               [min]="campo.get(formNames.min).value ? campo.get(formNames.min).value : null"
                               [readonly]="action === actions.detalhes"/>
                        <mat-datepicker-toggle *ngIf="action !== actions.detalhes" matSuffix
                                               [for]="dataFim">
                        </mat-datepicker-toggle>
                        <mat-datepicker #dataFim></mat-datepicker>
                    </mat-form-field>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="types.TEXT">
                <!-- Max -->
                <div fxFlex="137px"
                     fxFlex.sm="90"
                     fxFlex.xs="100" class="pr-1">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Tamanho</mat-label>
                        <input matInput autocomplete="false" type="number" step="1"
                               [readonly]="action === actions.detalhes"
                               [formControlName]="formNames.max"
                               placeholder="n caracteres"/>
                    </mat-form-field>
                </div>
            </ng-container>
        </ng-container>
        <!-- Num inserções -->
        <ng-container *ngIf="campo.value.select && campo.value.insercaoMultipla">
            <!-- Inserção Min -->
            <div fxFlex="173px"
                 fxFlex.sm="90"
                 fxFlex.xs="100" class="pr-1">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Inserções Mínima</mat-label>
                    <input matInput autocomplete="false" type="number" step="1"
                           [readonly]="action === actions.detalhes"
                           [formControlName]="formNames.numMin"
                           placeholder="Min"/>
                </mat-form-field>
            </div>
            <!-- Inserção Max -->
            <div fxFlex="173px"
                 fxFlex.sm="90"
                 fxFlex.xs="100" class="pr-1">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Inserções Máxima</mat-label>
                    <input matInput autocomplete="false" type="number" step="1"
                           [readonly]="action === actions.detalhes"
                           [formControlName]="formNames.numMax"
                           placeholder="Max"/>
                </mat-form-field>
            </div>
        </ng-container>
        <!-- Disponível Lista -->
        <div fxFlex="155px" class="pr-1 mr-1" style="padding-top: 10px">
            <div class="pl-16 pr-16">
                <mat-slide-toggle class="font-weight-bold text-primary"
                                  [formControlName]="formNames.disponivelLista"
                                  [disabled]="action === actions.detalhes"
                                  color="primary">
                    Disponível Lista
                </mat-slide-toggle>
            </div>
        </div>
        <!-- Obrigatório -->
        <div fxFlex="140px" fxFlex.xs="100" class="pr-1"
             style="padding-top: 10px">
            <div class="pl-16 pr-16">
                <mat-slide-toggle class="font-weight-bold text-primary"
                                  [formControlName]="formNames.obrigatorio"
                                  [class.mat-override]="action === actions.detalhes"
                                  [disabled]="action === actions.detalhes"
                                  color="primary">
                    Obrigatório
                </mat-slide-toggle>
            </div>
        </div>
        <!-- Activo -->
        <div fxFlex="100px" class="pr-1 mr-1" style="padding-top: 10px">
            <div class="pl-16 pr-16">
                <mat-slide-toggle class="font-weight-bold text-primary"
                                  [formControlName]="formNames.activo"
                                  [disabled]="action === actions.detalhes"
                                  color="primary">
                    Activo
                </mat-slide-toggle>
            </div>
        </div>
        <!--Adicionar enum-->
        <div *ngIf="campo.value.select" fxFlex="100px"
             class="pb-0 pt-1 mt-03">
            <btn-add icon="add" (click)="adicionarOpcoes(campo)">Valores
                ({{campo.value.valores?.length ?? 0}})
            </btn-add>
        </div>
    </div>
</div>
