import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'inserirQuebraLinha'
})
export class InserirQuebraLinhaPipe implements PipeTransform {
 rgx = new RegExp('\n', 'gi');
 public transform(valor: any): any {
     if (typeof(valor) === 'string') {
         const strNova = valor.replace(this.rgx, '<br/>');
         return strNova;
     } else {
        return valor;
     }
  }

}
