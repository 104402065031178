import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedMaterialModule} from '../shared-material.module';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {SearchModule} from '../search/search.module';
import {SharedPipesModule} from '../pipes/shared-pipes.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SharedDirectivesModule} from '../directives/shared-directives.module';
// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import {HeaderSideComponent} from './header-side/header-side.component';
import {SidebarSideComponent} from './sidebar-side/sidebar-side.component';
// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import {HeaderTopComponent} from './header-top/header-top.component';
import {SidebarTopComponent} from './sidebar-top/sidebar-top.component';
// ONLY FOR DEMO
import {CustomizerComponent} from './customizer/customizer.component';
// ALWAYS REQUIRED
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {SidenavComponent} from './sidenav/sidenav.component';
import {FooterComponent} from './footer/footer.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {AppComfirmComponent} from '../services/app-confirm/app-confirm.component';
import {AppLoaderComponent} from '../services/app-loader/app-loader.component';
import {ButtonLoadingComponent} from './button-loading/button-loading.component';
import {EgretSidebarComponent, EgretSidebarTogglerDirective} from './egret-sidebar/egret-sidebar.component';
import {BottomSheetShareComponent} from './bottom-sheet-share/bottom-sheet-share.component';
import {EgretNotifications2Component} from './egret-notifications2/egret-notifications2.component';
import {ExportExcelComponent} from './modais/export-excel/export-excel.component';
import {ExportPdfComponent} from './modais/export-pdf/export-pdf.component';
import {ProgressComponent} from './progress/progress.component';
import {TabAccoesMultiplas} from './tab-accoes-multiplas/tab-accoes-multiplas';
import {ModalSessaoExpirada} from './modal-sessao-expirada/modal-sessao-expirada';
import {BtnSmComponent} from './botoes/btn-sm';
import {ModalBaseComponent} from './modais/modal-base/modal-base.component';
import {CardAddFicheiroComponent} from './cards/card-add-ficheiro';
import {InputAutocompleteComponent} from './input-autocomplete/input-autocomplete.component';
import {MatIconModule} from '@angular/material/icon';
import {AvisoComponent} from './modal-aviso/aviso/aviso.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {IntervaloComponent} from './intervalo/intervalo.component';
import {AutoCompleteComponent} from './novos-componentes/auto-complete/auto-complete.component';
import {IntervaloDataComponent} from './novos-componentes/intervalo-data/intervalo-data.component';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {IntervaloValoresComponent} from './novos-componentes/intervalo-valores/intervalo-valores.component';
import {ModalCargaDescarga} from '../../views/facturacao/modais/modal-carga-descarga/modal-carga-descarga';
import {TelefoneComponent} from './novos-componentes/telefone/telefone.component';
import {ModalRemoverComponent} from './notifications/modais/modal-remover/modal-remover.component';
import {NgxMaskModule} from 'ngx-mask';
import {SelectRetecoesCheckBoxComponent} from './novos-componentes/select-retecoes-check-box/select-retecoes-check-box.component';
import {ContactoComponent} from './novos-componentes/contacto/contacto.component';
import {TabelaItensFinComponent} from './Tabelas/tabela-itens-fin/tabela-itens-fin.component';
import {FiltarArtigoPipe} from 'app/views/facturacao/facturacao/saft/pipes/filtar-artigo.pipe';
import {Autocomplete} from './autocomplete/autocomplete';
import {PosLayoutComponent} from './layouts/pos-layout/pos-layout.component';
import {PosNavegacaoComponent} from '../../views/pos/componentes/pos-navegacao/pos-navegacao.component';
import {PosContainer} from '../../views/pos/componentes/pos-container/pos-container';
import {KCardComponent} from '@shared/components/k-card/k-card.component';
import {KCardColumnComponent} from '@shared/components/k-card-column/k-card-column.component';
import {KTiposArquivosComponent} from '@shared/components/k-tipos-arquivos/k-tipos-arquivos.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ArtigosModule} from '../../views/artigos/artigos.module';
import {KArquivosComponent} from '@shared/components/k-arquivos/k-arquivos.component';
import {KArquivoComponent} from '@shared/components/k-arquivo/k-arquivo.component';
import { PhoneComponent } from '@shared/components/telefone/app-phone';

const components = [
    HeaderTopComponent,
    SidebarTopComponent,
    SidenavComponent,
    NotificationsComponent,
    SidebarSideComponent,
    HeaderSideComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    PosLayoutComponent,
    PosNavegacaoComponent,
    PosContainer,
    BreadcrumbComponent,
    AppComfirmComponent,
    AppLoaderComponent,
    EgretNotifications2Component,
    CustomizerComponent,
    ButtonLoadingComponent,
    EgretSidebarComponent,
    FooterComponent,
    EgretSidebarTogglerDirective,
    BottomSheetShareComponent,
    ExportExcelComponent,
    ExportPdfComponent,
    ProgressComponent,
    TabAccoesMultiplas,
    ModalSessaoExpirada,
    BtnSmComponent,
    ModalBaseComponent,
    CardAddFicheiroComponent,
    InputAutocompleteComponent,
    AvisoComponent,
    IntervaloComponent,
    AutoCompleteComponent,
    IntervaloDataComponent,
    IntervaloValoresComponent,
    ModalCargaDescarga,
    TelefoneComponent,
    ModalRemoverComponent,
    SelectRetecoesCheckBoxComponent,
    ContactoComponent,
    TabelaItensFinComponent,
    FiltarArtigoPipe,
    Autocomplete,
    KCardComponent,
    KCardColumnComponent,
    KTiposArquivosComponent,
    KArquivosComponent,
    KArquivoComponent,
    PhoneComponent
];

@NgModule({
    imports: [
        MatIconModule,
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        FlexLayoutModule,
        PerfectScrollbarModule,
        SearchModule,
        SharedPipesModule,
        SharedDirectivesModule,
        SharedMaterialModule,
        ReactiveFormsModule,
        ScrollingModule,
        OverlayPanelModule,
        NgxDaterangepickerMd.forRoot(),
        NgxMaskModule,
        NgxDatatableModule
    ],
    declarations: components,
    // entryComponents: [AppComfirmComponent, AppLoaderComponent, BottomSheetShareComponent],
    exports: components
})
export class SharedComponentsModule {
}
