import {Injectable} from '@angular/core';
import {
    CalcularItensTotalDto,
    DocumentoCalculoRetencoesDto,
    DocumentoCalculoTotaisDto,
    FADocumentosItensDto,
    FINRetencoesDto
} from './Dtos/DtosCalculoFin';
import {Util} from 'app/shared/models/generico/util';

@Injectable({
    providedIn: 'root',
})
export class CalculosFinancasService {
    constructor() {
    }

    CalcularRetencoes(dadosCalcular: any): DocumentoCalculoRetencoesDto {
        const dadosCalcularDto = converterParaCamelCase(dadosCalcular);

        let Total: number = 0;
        if (dadosCalcularDto.retencoesCLiente !== null) {
            for (let item of dadosCalcularDto.retencoesCLiente) {
                Total += this.CalcularRetencao(dadosCalcularDto.itens, item);
            }
        }
        dadosCalcularDto.valorRetencao = Total;
        return dadosCalcularDto;
    }

    private CalcularRetencao(itens: any, retencao: FINRetencoesDto): number {
        let itensTemp = itens.filter((i) => i.idRetencao === retencao.idRetencao);

        if (itensTemp.length <= 0) {
            return 0;
        }

        let TotalItem: number = itensTemp.reduce(
            (acc, curr) => acc + (Util.convertToFloat(curr.subTotal) ?? 0),
            0
        );
        let montate: number = 0;

        if (TotalItem > retencao.valorMinimo) {
            montate = TotalItem * (retencao.valorPercentual / 100);
        }

        return Math.round(montate * 100) / 100;
    }

    calcularTotais(dadosCalcularDto: DocumentoCalculoTotaisDto): any {
        let dadosCalcular = converterParaCamelCase(dadosCalcularDto);
        dadosCalcular.totalGeral = this.DuasCasasDecimais(
            dadosCalcular.itens.reduce((acc, item) => acc + (item.totalGeral || 0), 0)
        );
        dadosCalcular.totalIliq = this.DuasCasasDecimais(
            dadosCalcular.itens.reduce((acc, item) => acc + (item.subTotalIliq || 0), 0)
        );
        dadosCalcular.totalDescontos = this.DuasCasasDecimais(
            dadosCalcular.itens.reduce((acc, item) => acc + item.desconto, 0)
        );
        dadosCalcular.totalIva = this.DuasCasasDecimais(
            dadosCalcular.itens.reduce((acc, item) => acc + (item.imposto || 0), 0)
        );

        dadosCalcular.totalIliq += dadosCalcular.totalDescontos;

        if (dadosCalcular.conversaoDirecta) {
            return this.conversaoDirectaItem(dadosCalcular);
        } else {
            return this.ConversaoIndirectaItem(dadosCalcular);
        }
    }

    CalcularTotais(dadosCalcularDto: any): DocumentoCalculoTotaisDto {
        let dadosCalcular = converterParaCamelCase(dadosCalcularDto);

        dadosCalcular.totalGeral = this.DuasCasasDecimais(
            dadosCalcular.itens.reduce(
                (acc, curr) => acc + Util.convertToFloat(curr.totalGeral),
                0
            )
        );

        dadosCalcular.totalIliq = this.DuasCasasDecimais(
            dadosCalcular.itens.reduce(
                (acc, curr) => acc + Util.convertToFloat(curr.subTotalIliq),
                0
            )
        );

        dadosCalcular.totalDescontos = this.DuasCasasDecimais(
            dadosCalcular.itens.reduce(
                (acc, curr) => acc + Util.convertToFloat(curr.desconto),
                0
            )
        );
        dadosCalcular.totalIva = this.DuasCasasDecimais(
            dadosCalcular.itens.reduce(
                (acc, curr) => acc + Util.convertToFloat(curr.imposto),
                0
            )
        );


        dadosCalcular = dadosCalcular.conversaoDirecta == true
            ? this.conversaoDirecta(dadosCalcular)
            : this.ConversaoIndirecta(dadosCalcular);
        return dadosCalcular;
    }

    CalcularTotaisMoedaEstrangeira(
        dadosCalcularDto: any
    ): DocumentoCalculoTotaisDto {
        let dadosCalcular = converterParaCamelCase(dadosCalcularDto);

        dadosCalcular = dadosCalcular.conversaoDirecta
            ? this.conversaoDirecta(dadosCalcular)
            : this.ConversaoIndirecta(dadosCalcular);

        return dadosCalcular;
    }

    private conversaoDirecta(
        dadosCalcular: DocumentoCalculoTotaisDto
    ): DocumentoCalculoTotaisDto {
        if (
            !dadosCalcular.conversaoDirecta ||
            dadosCalcular.taxaCambio == null ||
            dadosCalcular.taxaCambio <= 0
        ) {
            return dadosCalcular;
        }

        let taxacambio: number = dadosCalcular.taxaCambio;

        dadosCalcular.totalIvaCliente = this.DuasCasasDecimais(
            dadosCalcular.totalIva * taxacambio
        );
        dadosCalcular.totalMoedaCliente = this.DuasCasasDecimais(
            dadosCalcular.totalIliq * taxacambio
        );
        dadosCalcular.totalIliqCliente =
            dadosCalcular.totalMoedaCliente + dadosCalcular.totalIvaCliente;

        return dadosCalcular;
    }

    private ConversaoIndirecta(
        dadosCalcular: DocumentoCalculoTotaisDto
    ): DocumentoCalculoTotaisDto {
        if (
            dadosCalcular?.conversaoDirecta ||
            dadosCalcular?.taxaCambio == null ||
            dadosCalcular?.taxaCambio <= 0
        ) {
            return dadosCalcular;
        }

        let taxacambio: number = dadosCalcular.taxaCambio;

        dadosCalcular.totalIvaCliente = this.DuasCasasDecimais(
            dadosCalcular.totalIva / taxacambio
        );
        dadosCalcular.totalMoedaCliente = this.DuasCasasDecimais(
            dadosCalcular.totalIliq / taxacambio
        );
        dadosCalcular.totalIliqCliente =
            dadosCalcular.totalMoedaCliente + dadosCalcular.totalIvaCliente;

        return dadosCalcular;
    }

    //itens
    CalcularValoresIten(item: FADocumentosItensDto): FADocumentosItensDto {
        item = this.CalcularItem(item);
        return item;
    }

    CalcularValoresItens(itens: FADocumentosItensDto[]): FADocumentosItensDto[] {
        const listaItem: FADocumentosItensDto[] = [];
        for (const item of itens) {
            listaItem.push(this.CalcularItem(item));
        }

        return listaItem;
    }

    CalcularItem(item: FADocumentosItensDto): FADocumentosItensDto {

        item.quantidade = this.DuasCasasDecimais(item.quantidade);
        item.impostoDedutivelPerc = this.DuasCasasDecimais(item.impostoDedutivelPerc);
        item.descontoPerc = this.DuasCasasDecimais(item.descontoPerc);
        item.taxaImposto = this.DuasCasasDecimais(item.taxaImposto);
        item.preco = this.DuasCasasDecimais(item.preco);
        item.desconto = this.CalcularDesconto(item);
        item.subTotal = this.DuasCasasDecimais(item.quantidade * item.preco - item.desconto);
        item.imposto = this.DuasCasasDecimais((item.taxaImposto / 100) * item.subTotal);
        item.total = this.DuasCasasDecimais(item.subTotal);
        item.totalGeral = this.DuasCasasDecimais(item.subTotal + item.imposto);
        item.subTotalIliq = this.DuasCasasDecimais(item.quantidade * item.preco);
        return item;
    }

    CalcularDesconto(item: FADocumentosItensDto): number {
        if (item.descontoPerc <= 100) {
            const desconto =
                Util.convertToFloat(item.quantidade) *
                Util.convertToFloat(item.preco) *
                (Util.convertToFloat(item.descontoPerc) / 100) || 0;
            return desconto;
        }
        const desconto =
            Util.convertToFloat(item.quantidade) *
            Util.convertToFloat(item.preco) *
            1 || 0;
        return desconto;
    }

    CalcularValoresItem(dadosCalcularDto: CalcularItensTotalDto): any {
        let calculo = converterParaCamelCase(dadosCalcularDto);

        const itemResp = this.CalcularValoresIten(calculo.item);
        const item = itemResp;
        const index = calculo.itens.findIndex((i) => i.idItem === item.idItem);
        calculo.itens[index] = itemResp;
        calculo.totais.itens = calculo.itens.filter((a) => a.idArtigo > 0);
        const totais = this.CalcularTotais(calculo.totais);

        const retorno = {item, totais: totais};
        return retorno;
    }

    CalcularValoresItensTotais(dadosCalcularDto: CalcularItensTotalDto): any {
        let calculo = converterParaCamelCase(dadosCalcularDto);
        const itens = this.CalcularValoresItens(calculo.itens);

        calculo.totais.itens = itens.filter((a) => a.idArtigo > 0);
        const totais = this.CalcularTotais(calculo.totais);
        const retorno = {itens, totais: totais};
        return retorno;
    }

    conversaoDirectaItem(dadosCalcular: DocumentoCalculoTotaisDto): any {
        if (
            !dadosCalcular.conversaoDirecta ||
            dadosCalcular.taxaCambio === null ||
            dadosCalcular.taxaCambio <= 0
        ) {
            return dadosCalcular;
        }

        const taxacambio = dadosCalcular.taxaCambio;

        dadosCalcular.totalIvaCliente = this.DuasCasasDecimais(
            dadosCalcular.totalIva * taxacambio
        );
        dadosCalcular.totalMoedaCliente = this.DuasCasasDecimais(
            dadosCalcular.totalIliq * taxacambio
        );
        dadosCalcular.totalIliqCliente =
            dadosCalcular.totalMoedaCliente + dadosCalcular.totalIvaCliente;

        return dadosCalcular;
    }

    ConversaoIndirectaItem(
        dadosCalcular: DocumentoCalculoTotaisDto
    ): DocumentoCalculoTotaisDto {
        if (
            !dadosCalcular.conversaoDirecta ||
            dadosCalcular.taxaCambio === null ||
            dadosCalcular.taxaCambio <= 0
        ) {
            return dadosCalcular;
        }

        const taxacambio = dadosCalcular.taxaCambio;

        dadosCalcular.totalIvaCliente = this.DuasCasasDecimais(
            dadosCalcular.totalIva / taxacambio
        );
        dadosCalcular.totalMoedaCliente = this.DuasCasasDecimais(
            dadosCalcular.totalIliq / taxacambio
        );
        dadosCalcular.totalIliqCliente =
            dadosCalcular.totalMoedaCliente + dadosCalcular.totalIvaCliente;

        return dadosCalcular;
    }

    retornarPrecoArtigo(precos: any[], posicaoPreco: number): number {
        if (precos?.length <= 0) {
            return 0;
        }
        let precoDef = precos[0];
        switch (posicaoPreco) {
            case 1:
                return retornarValorPosicao1(precoDef);
            case 2:
                return retornarValorPosicao2(precoDef);
            case 3:
                return retornarValorPosicao3(precoDef);
            default:
                return retornarValorPosicao1(precoDef);
        }
    }

    DuasCasasDecimaisTeste(valor?: number | string): number {
        if (typeof valor === 'string') {
            // Tenta converter a string em um número.
            valor = valor.replace(/\./g, '').replace(',', '.');
            valor = parseFloat(valor);
        }

        if (typeof valor !== 'number' || isNaN(valor)) {
            // Se o valor não é um número válido, retorne zero ou outro valor padrão.
            return 0;
        }
        return Math.round((valor || 0) * 100) / 100;
    }

    DuasCasasDecimais(valor?: number | string): number {
        if (typeof valor === 'string') {
            // Tenta converter a string em um número.
            valor = valor.replace(/\./g, '').replace(',', '.');
            valor = parseFloat(valor);
        }

        if (typeof valor !== 'number' || isNaN(valor)) {
            // Se o valor não é um número válido, retorne zero ou outro valor padrão.
            return 0;
        }
        return Math.round((valor || 0) * 100) / 100;
    }
}

function converterParaCamelCase(obj: any): any {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => converterParaCamelCase(item));
    }

    const camelCaseObj: { [key: string]: any } = {};

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
            camelCaseObj[camelCaseKey] = converterParaCamelCase(obj[key]);
        }
    }

    return camelCaseObj;
}

function retornarValorPosicao1(precoDef: any): number {
    return precoDef.preco1;
}

function retornarValorPosicao2(precoDef: any): number {
    return precoDef?.preco2 < 0 ? retornarValorPosicao1(precoDef) : precoDef.preco2;
}

function retornarValorPosicao3(precoDef: any): number {
    return precoDef?.preco3 < 0 ? retornarValorPosicao2(precoDef) : precoDef.preco3;
}
