<div class="sidebar-panel">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">
            
            <!-- App Logo -->
            <div class="branding">
                <img [src]="appLogo" [routerLink]="['/dashboard/default']" alt="KiamiSoft Icon" class="app-logo"
                     [class]="layoutConf.sidebarCompactToggle ? 'compactado' : 'expandido'">

                <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span>
                <div 
                class="sidebar-compact-switch"
                [ngClass]="{active: layoutConf.sidebarCompactToggle}"
                (click)="toggleCollapse()" 
                *ngIf="layoutConf.sidebarStyle !== 'compact'"><span></span></div>
            </div>

            <!-- Navigation -->
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
        </div>
    </div>
</div>
