import {Directive, HostListener, Input} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {Util} from '../models/generico/util';

export enum Format {
    Cod = 'codigo',
    Decimal = 'decimal',
    Data = 'data',
    Perc = 'perc',
    GPS = 'GPS',
    Tel = 'Tel'
}

@Directive({
    selector: '[appInputFormat]',
    providers: [CurrencyPipe]
})
export class InputFormatDirective {
    @Input('appInputFormat') format = '';

    constructor(private currencyPipe: CurrencyPipe) {
    }

    @HostListener('input', ['$event'])
    onInput(event: Event) {
        const target = event.target as HTMLInputElement;
        let position = target.selectionStart;
        let value = 0;
        switch (this.format) {
            case Format.Cod: {
                target.value = target.value.toUpperCase().replace(/[^a-zA-Z0-9\-\\\/.]+/g, '');
            }
                break;
            case Format.Decimal: {
                value = Math.round(Math.abs(Util.convertToFloat(target.value)) * 100) / 100;
                const txt = this.currencyPipe.transform(isNaN(value) ? 0 : value, '', '');
                if (txt.length < target.value.length) {
                    position--;
                } else if (txt.length > target.value.length && target.value.includes(',')) {
                    position++;
                }
                target.value = txt;
                target.selectionStart = position;
                target.selectionEnd = position;
            }
                break;
            case Format.Perc: {
                value = Util.clamp(Util.convertToFloat(target.value), 0, 100);
                target.value = this.currencyPipe.transform(isNaN(value) ? 0 : value, '', '');
                target.selectionStart = position;
                target.selectionEnd = position;
            }
                break;
            case Format.Data: {
                for (let i = 0; i < target.value.length; i++) {
                    if (i === 2 || i === 5) {
                        target.value = target.value[i] !== '/' ? target.value.slice(0, i) : target.value;
                    } else if (isNaN(Number(target.value[i]))) {
                        target.value = target.value.slice(0, i);
                    }
                }
            }
                break;
            case Format.GPS: {
                target.value = this.formatCoordinates(target.value.replace(/[^0-9.-]/g, ''));
            }
                break;
            case Format.Tel: {
                const val = target.value;
                target.value = val.replace(/[^0-9\s]/g, '');
                position -= Number(val.length !== target.value.length);
                target.selectionStart = position;
                target.selectionEnd = position;
            }
                break;
            default:
                break;
        }
    }

    formatCoordinates(value: string) {
        if (!value) {
            return '';
        }

        const [degreesPart, minutesPart] = value
            .replace(/[^0-9.]/g, '')
            .split('.');
        return `${value.includes('-') ? '-' : ''}${degreesPart}.${minutesPart || ''}`;
    }
}
