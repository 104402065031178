import {Directive, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[appSelectedActive]'
})
export class SelectedActiveDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {
  }
active: boolean;
  element = this.el.nativeElement;
  @HostListener('click', ['$event']) onClick(e) {
    this.active = !this.active;
    if (this.active) {
      this.renderer.addClass(this.element, 'filtroActivo');
    } else {
      this.renderer.removeClass(this.element, 'filtroActivo');
    }
  }
  ngOnInit() {

  }
}
