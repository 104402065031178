import { Injectable } from "@angular/core";
import { Permissoes } from "app/shared/models/aplicacao/permissoes";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PermissoesDataService {
  private listaPermissoes$ = new BehaviorSubject<Permissoes[]>([]);

  constructor() {}

  setListaPermissoes(permissoes: Permissoes[]) {
    this.listaPermissoes$.next(permissoes);
  }

  getListaPermissoes(): Observable<any> {
    return this.listaPermissoes$.asObservable();
  }
}
