import {Entidades} from '../clientes/entidades';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {Paises} from '../aplicacao/paises';
import {DAY} from 'app/views/artigos/renovavel-form/renovavel-form.component';
import {DataTypes} from 'app/views/gestao-arquivos/ga-tipo-campo/ga-tipo-campo.component';

export class Util {

    public OpcaoNaoUsado = 1;

    static timeStringToDecimal(timeString: string): number {
        const [hoursStr, minutesStr] = timeString.split(':');
        const hours = parseInt(hoursStr, 10);
        const minutes = parseInt(minutesStr, 10);
        return this.timeDecimal(hours, minutes);
    }

    static timeDecimal(hours: number, minutes: number) {
        const decimalHours = hours + (minutes / 60);
        return Math.round(decimalHours * 100) / 100;
    }

    static areObjectsEqual(obj1, obj2) {
        // Get the keys of the objects
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // Check if the number of properties is the same
        if (keys1.length !== keys2.length) {
            return false;
        }

        // Check the values of each property
        for (const key of keys1) {
            // Check if the property exists in both objects
            if (!(key in obj2)) {
                return false;
            }

            // Compare the values of the properties
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }

        // All properties have the same values
        return true;
    }

    static timeString(decimalTime) {
        const hours = Math.floor(decimalTime);
        const minutes = Math.round((decimalTime % 1) * 60);
        return `${hours}h:${minutes.toString().padStart(2, '0')}min`;
    }

    static UTCDate(inicio: Date, fim: Date) {
        if (inicio && fim) {
            const d0 = new Date(inicio);
            const d1 = new Date(fim);
            return [
                new Date(Date.UTC(d0.getFullYear(), d0.getMonth(), d0.getDate())),
                new Date(Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate()))
            ];
        }
        return [];
    }

    static _UTCDate(date: Date) {
        date = date ? new Date(date) : null;
        return date ? new Date(Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes())) : null;
    }

    static clamp(value: number, min: number, max: number) {
        return Math.min(Math.max(value, min), max);
    }

    static addDays(date: Date, qtd: number = 1): Date {
        return new Date(date.getTime() + qtd * DAY);
    }

    static isLeapYear(year: number): boolean {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    }

    static getDaysInMonth(year: number, month: number): number {
        return [31, (this.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    }

    static addMonths(date: Date, value: number = 1): Date {
        const n = date.getDate();
        const df = new Date(date);
        df.setDate(1);
        df.setMonth(df.getMonth() + value);
        df.setDate(Math.min(n, this.getDaysInMonth(df.getFullYear(), df.getMonth())));
        return df;
    }

    static getControlErrors(control: FormControl, name: string, errorMap: { [p: string]: string }) {
        let errorMessage = '';
        if (control.errors) {
            Object.keys(control.errors).forEach((errorKey: string) => {
                switch (errorKey) {
                    case 'min':
                        errorMessage += `${name}: ${errorMap[errorKey]}. Min: ${control.errors['min']['min']}. \n`;
                        break;
                    case 'max':
                        errorMessage += `${name}: ${errorMap[errorKey]}. Max: ${control.errors['max']['max']}. \n`;
                        break;
                    case 'lesserThan':
                        errorMessage += `${name}: ${errorMap[errorKey]}. Max: ${control.errors['lesserThan']['min']}. \n`;
                        break;
                    case 'greaterThan':
                        errorMessage += `${name}: ${errorMap[errorKey]}. Min: ${control.errors['greaterThan']['max']}. \n`;
                        break;
                    case 'lesserThanEqual':
                        errorMessage += `${name}: ${errorMap[errorKey]}. Max: ${control.errors['lesserThanEqual']['min']}. \n`;
                        break;
                    case 'greaterThanEqual':
                        errorMessage += `${name}: ${errorMap[errorKey]}. Min: ${control.errors['greaterThanEqual']['max']}. \n`;
                        break;
                    default:
                        errorMessage += `${name}: ${errorMap[errorKey]}. \n`;
                        break;
                }
            });
        }

        return errorMessage;
    }

    static getFormErrors(form: FormGroup | FormArray, formNameMap: { [key: string]: string }, errorMap: { [key: string]: string }): string {
        let errorMessage = '';

        // Iterate over all the controls in the form group
        Object.keys(form.controls).forEach((key: string) => {
            const control = form.controls[key];

            // If the control is a form group, recursively call the function
            if (control instanceof FormGroup || control instanceof FormArray) {
                const nestedErrorMessage = Util.getFormErrors(control, formNameMap, errorMap);

                if (nestedErrorMessage) {
                    errorMessage += `${formNameMap[key] ?? `[${parseInt(key, 10) + 1}]`}: ${nestedErrorMessage}\n`;
                }
            }

            // If the control has errors, concatenate the error messages
            if (control.errors) {
                Object.keys(control.errors).forEach((errorKey: string) => {
                    switch (errorKey) {
                        case 'min':
                            errorMessage += `${formNameMap[key]}: ${errorMap[errorKey]}. Min: ${control.errors['min']['min']}. \n`;
                            break;
                        case 'max':
                            errorMessage += `${formNameMap[key]}: ${errorMap[errorKey]}. Max: ${control.errors['max']['max']}. \n`;
                            break;
                        case 'lesserThan':
                            errorMessage += `${formNameMap[key]}: ${errorMap[errorKey]}. Max: ${control.errors['lesserThan']['min']}. \n`;
                            break;
                        case 'greaterThan':
                            errorMessage += `${formNameMap[key]}: ${errorMap[errorKey]}. Min: ${control.errors['greaterThan']['max']}. \n`;
                            break;
                        case 'lesserThanEqual':
                            errorMessage += `${formNameMap[key]}: ${errorMap[errorKey]}. Max: ${control.errors['lesserThanEqual']['min']}. \n`;
                            break;
                        case 'greaterThanEqual':
                            errorMessage += `${formNameMap[key]}: ${errorMap[errorKey]}. Min: ${control.errors['greaterThanEqual']['max']}. \n`;
                            break;
                        default:
                            errorMessage += `${formNameMap[key] ?? `[${parseInt(key, 10) + 1}]`}: ${errorMap[errorKey]}. \n`;
                            break;
                    }
                });
            }
        });

        return errorMessage;
    }

    static getErrorKey(form: FormGroup): string {
        for (const key of Object.keys(form.controls)) {
            const control = form.controls[key];

            // If the control is a form group, recursively call the function
            if (control instanceof FormGroup) {
                const errorKey = Util.getErrorKey(control);
                if (errorKey) {
                    return errorKey;
                }
            }

            // If the control has errors return the key
            if (control.errors) {
                const parentKey = this.findParentKey(control);
                return parentKey ? `${parentKey}.${key}` : key;
            }
        }

        return '';
    }

    static parseValue(value: string, type: string) {
        switch (type) {
            case DataTypes.BOOL:
                return value === 'true';
            case DataTypes.DATE:
                return new Date(value);
            default:
                return value;
        }
    }

    static collectTree(list: any[], filtered: any[], key: string, fk: string) {
        let result = [...filtered];
        for (const child of filtered) {
            result = [...result, ...this.collectTree(list, list.filter(a => child[key] === a[fk]), key, fk)];
        }
        return result;
    }

    static compareLists(list1: any[], list2: any[]) {
        const added = [];
        const removed = [];
        const modified = [];

        // Check for added elements
        for (const item of list2) {
            if (!list1.some((x) => x.id === item.id)) {
                added.push(item);
            }
        }

        // Check for removed elements
        for (const item of list1) {
            if (!list2.some((x) => x.id === item.id)) {
                removed.push(item);
            }
        }

        // Check for modified elements
        for (const item2 of list2) {
            const item1 = list1.find((x) => x.id === item2.id);
            if (item1 && JSON.stringify(item1) !== JSON.stringify(item2)) {
                modified.push({old: item1, new: item2});
            }
        }

        return {added, removed, modified};
    }

    static findParentKey(formControl: AbstractControl): string | null {
        const root = formControl.parent?.parent;
        if (!root) {
            return null;
        }

        return Object.keys(root.controls).find(key => root.controls[key] === formControl.parent) || null;
    }

    static getLabelProvincia(codPais: string, paises?: Paises[]): string {
        return paises?.find((p) => p.codPais === codPais)?.idOpcCodigoPostal < 2
            ? 'Distrito'
            : 'Província';
    }

    static getLabelMunicipio(codPais: string, paises?: Paises[]): string {
        return paises?.find((p) => p.codPais === codPais)?.idOpcCodigoPostal < 2
            ? 'Concelho'
            : 'Munícipio';
    }

    public static isHttpRequest(url: string): boolean {
        const regex = /^(http:\/\/|https:\/\/)/;
        return regex.test(url);
    }

    public static filterNonOverlappingDates(objects: any[]) {
        return objects.filter((obj, index, arr) => {
            const {dataInicio, dataFim} = obj;

            // Check if there is any overlap with previous objects
            for (let i = 0; i < index; i++) {
                const prevObj = arr[i];
                if (dataInicio <= prevObj.dataFim && dataFim >= prevObj.dataInicio) {
                    return false; // Overlapping dates, exclude the object
                }
            }

            return true; // Non-overlapping dates, include the object
        });
    }

    public static strToDate(valor: string) {
        if (valor) {
            const dataString = valor
                .split('-')
                .reverse()
                .toString()
                .replace(',', '-')
                .replace(',', '-');
            const dataConvertida = new Date(dataString).getTime();
            return dataConvertida;
        }

        return '';
    }

    public static strParaDataISO(dataStr: any) {
        if (dataStr) {
            return Util.FromStrToDate(
                new Intl.DateTimeFormat('pt-PT').format(Date.parse(dataStr))
            );
        } else {
            return '';
        }
    }

    public static FromStrToDate(valor: any) {
        return valor
            ? valor
                .split('/')
                .reverse()
                .toString()
                .replace(',', '-')
                .replace(',', '-')
            : '';
    }

    public static convertToFloat(valor: string | number): number {
        if (typeof (valor) === 'number') {
            return this.DuasCasasDecimais(valor);
        }
        return this.DuasCasasDecimais(parseFloat(
            String(valor)
                .replaceAll('.', '')
                .replace(',', '.')
                .replace(/[^0-9.,]/g, ''))
        );
    }

    static DuasCasasDecimais(valor?: number | string): number {
        if (typeof valor === 'string') {
            // Tenta converter a string em um número.
            valor = valor.replace('.', '');
            valor = parseFloat(valor.replace(',', '.'));
        }

        if (typeof valor !== 'number' || isNaN(valor)) {
            // Se o valor não é um número válido, retorne zero ou outro valor padrão.
            return 0;
        }

        return Math.round((valor || 0) * 100) / 100;
    }


    public static replaceSliceInStrToDate(valor: any) {
        if (valor) {
            return valor.split('/').toString().replace(',', '-').replace(',', '-');
        }
        return '';
    }

    public static getData(dia: number, mes: number, ano: any): string {
        const mesActual = mes + 1;
        const data =
            ano.toString() +
            '-' +
            (mesActual < 10 ? '0' + mesActual.toString() : mesActual.toString()) +
            '-' +
            (dia < 10 ? '0' + dia.toString() : dia.toString());
        return data;
    }

    public static momToDate(momentDate: any): string {
        if (momentDate) {
            if (momentDate._isAMomentObject) {
                const mesActual = momentDate._i.month + 1;
                const data =
                    momentDate._i.year.toString() +
                    '-' +
                    (mesActual < 10 ? '0' + mesActual.toString() : mesActual.toString()) +
                    '-' +
                    (momentDate._i.date < 10
                        ? '0' + momentDate._i.date.toString()
                        : momentDate._i.date.toString());
                return data;
            } else {
                const rgx = new RegExp('/', 'gi');
                const date = Intl.DateTimeFormat('en-GB').format(new Date(momentDate));
                return date.replace(rgx, '-');
            }
        } else {
            return;
        }
    }

    public static getAnoData(dia: number, mes: number, ano: any): string {
        return ano;
    }

    public static getLabelNome(entidade: Entidades) {
        return entidade.idOpcPriUltimoNome === 1 ? 'Nome' : 'Nome completo';
    }

    public static alterarFormatoData(data: string) {
        if (data) {
            return (
                data.substring(6, 11) +
                '-' +
                data.substring(3, 5) +
                '-' +
                data.substring(0, 2)
            );
        }
        return '';
    }

    public static getFormErros(form: AbstractControl) {
        if (form instanceof FormControl) {
            return form.errors ?? null;
        }
        if (form instanceof FormArray) {
            return form.errors ?? null;
        }
        if (form instanceof FormGroup) {
            const groupErrors = form.errors;
            const formErrors = groupErrors ? {groupErrors} : {};
            Object.keys(form.controls).forEach((key) => {
                const error = this.getFormErros(form.get(key));
                if (error !== null) {
                    formErrors[key] = error;
                }
            });
            return Object.keys(formErrors).length > 0 ? formErrors : null;
        }
    }

    public static formatarDecimal(strNum: any): string {
        const valor = strNum.toString();
        if (valor) {
            if (valor.includes('.')) {
                const valorComVirgula = valor.toString().replace('.', ',');
                const partesDoNumero = valorComVirgula.split(',');
                let numeroFormatado = '';
                if (partesDoNumero[1].length === 1) {
                    numeroFormatado = partesDoNumero[0] + ',' + partesDoNumero[1] + '0';
                } else {
                    numeroFormatado = partesDoNumero[0] + ',' + partesDoNumero[1];
                }
                return numeroFormatado;
            } else {
                return valor + ',00';
            }
        } else {
            return '0,00';
        }
    }

    public static reverterDecimal(valor: any) {
        if (valor && typeof valor === 'string' && valor.includes(',')) {
            const valorComPonto = valor.toString().replace(',', '.');
            return parseFloat(valorComPonto);
        } else {
            return valor;
        }
    }

    public static fromStrToDate(date: any) {
        const dtTime = new Intl.DateTimeFormat('pt-PT', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }).format(Date.parse(date));
        return dtTime;
    }

    public static StrToIntlDate(date: any) {
        const dtTime = new Intl.DateTimeFormat('pt-PT', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }).format(Date.parse(date));
        return dtTime;
    }

    public static DownloadXML(dados) {
        const element = document.createElement('a');
        const blob = new Blob([dados.objecto], {
            type: 'text/xml'
        });
        element.href = URL.createObjectURL(blob);
        element.setAttribute('download', dados.mensagem);
        document.body.appendChild(element);
        element.click();
    }

    public static downloadAnexo(anexo: string, nomeAnexo: string) {
        if (!anexo) {
            return;
        }
        const link = document.createElement('a');
        link.href = anexo;
        link.download = nomeAnexo;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    public static midpointRound(number) {
        return number < 0 ? -Math.round(-number * 2) / 2 : Math.round(number * 2) / 2;
    }

    public static CurrencyEnToPt(valor) {
        const delimiter = ',';
        const val = valor.split('.', 2);
        const d = val[1];
        let i = parseInt(val[0], 10);
        if (isNaN(i)) {
            return '';
        }
        let minus = '';
        if (i < 0) {
            minus = '-';
        }
        i = Math.abs(i);
        let n = String(i);
        const valTemp = [];
        while (n.length > 3) {
            const nn = n.substr(n.length - 3);
            valTemp.unshift(nn);
            n = n.substr(0, n.length - 3);
        }
        if (n.length > 0) {
            val.unshift(n);
        }
        n = valTemp.join(delimiter);
        if (d.length < 1) {
            valor = n;
        } else {
            valor = n + '.' + d;
        }
        valor = minus + valor;
        return valor;
    }

    public static validarCodigoLivre(classificacao, form: any, campo: any) {
        if (!classificacao) {
            const valorCodigo = form.get(campo).value;
            if (valorCodigo.trim().length < 1) {
                form.get(campo).setValue('');
                form.get(campo).setValidators(Validators.required);
                form.get(campo).updateValueAndValidity();
                form.get(campo).markAsTouched({onlySelf: true});
                return false;
            }
        }
    }

    // Função para formatação de casas decimais //

    public static formatarCasasDecimais(valor) {
        // Valor string //
        if (valor && typeof valor === 'string') {
            if (valor.includes(',')) {
                const decimalPart = valor.split(',', 2)[1];
                const integerPart = valor.split(',', 2)[0];
                if (decimalPart.length > 1) {
                    return valor;
                } else {
                    return integerPart + ',' + decimalPart + '0';
                }
            } else {
                return valor + ',00';
            }
        }

        // Valor numérico //
        if (valor && typeof valor === 'number') {
            const num = '' + valor;
            if (num.includes('.')) {
                const decimalPart = num.split('.', 2)[1];
                const integerPart = num.split('.', 2)[0];
                if (decimalPart.length > 1) {
                    return valor;
                } else {
                    return integerPart + ',' + decimalPart + '0';
                }
            } else {
                return valor + ',00';
            }
        }
    }

    public static DataConvertida(data: any): string {
        if (data) {
            const d = new Date(data);
            const novaData = `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(
                d.getDate()
            )}T${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
            return novaData;
        } else {
            return null;
        }
    }

    strParaDouble(valor: any) {
        if (valor) {
            return valor.replaceAll(/\./gi, '').replace(',', '.');
        } else {
            return 0;
        }
    }
}


function pad(value) {
    return value.toString().padStart(2, 0);
}
