import { CmsIdiomas } from "./../../models/cms/cms-idiomas.model";
import { CmsIdioma } from "./../../models/cms/cms-idioma.model";
import { env } from "./../../../../assets/env/env.config";
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, Observable, Subscription } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class CmsGeralService {
  idiomaSubject = new Subject<CmsIdiomas>();
  idiomas: CmsIdiomas;
  idiomaDefault: CmsIdioma;
  outrosIdiomas: any;
  sub: Subscription;
  setIdiomaDefault(obj: CmsIdiomas) {}

  baseUrl = `${environment.cmsUrl}`;
  constructor(private http: HttpClient) {}

  getIdiomasSubject() {
    if (this.idiomas) this.idiomaSubject.next(this.idiomas);
    else {
      this.getIdiomas().subscribe((dados) => {
        this.idiomas = dados;

        this.idiomaDefault = dados.objecto?.find((x) => x.default == true);
        this.outrosIdiomas = dados?.objecto?.filter((x) => x.default === false);
        this.idiomaSubject.next(dados);
      });
    }
  }

  activarIdioma(codIdioma: string): Observable<any> {
    return this.http
      .post(this.baseUrl + "gerais/activarDesativarIdioma/" + codIdioma, null)
      .pipe();
  }

  eliminarIdioma(codIdioma: string): Observable<any> {
    return this.http
      .post(this.baseUrl + "gerais/removerIdioma/" + codIdioma, null)
      .pipe();
  }

  gravarIdioma(modelo: any): Observable<any> {
    return this.http.post(this.baseUrl + "gerais/gravarIdioma", modelo).pipe();
  }

  getemaisEnvio(): Observable<any> {
    return this.http
      .get(this.baseUrl + "gerais/emaisEnvio")
      .pipe(catchError(null));
  }

  getIdiomas(): Observable<any> {
    return this.http
      .get(this.baseUrl + "gerais/idiomas")
      .pipe(catchError(null));
  }

  getIdiomaDefault(): Observable<any> {
    return this.http
      .get(this.baseUrl + "gerais/idiomaDefault")
      .pipe(catchError(null));
  }

  gravarConfiguracao(modelo: any): Observable<any> {
    return this.http.post(this.baseUrl + "gerais/configuracoes", modelo).pipe();
  }

  getConfiguracao(): Observable<any> {
    return this.http
      .get(this.baseUrl + "gerais/configuracoes")
      .pipe(catchError(null));
  }
  getMenusCMS(): Observable<any> {
    return this.http
      .get(this.baseUrl + "gerais/menusCms")
      .pipe(catchError(null));
  }
}

