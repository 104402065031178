import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Resposta} from '../../models/resposta';
import {environment} from '../../../../environments/environment';
import {GaArquivo, GaArquivoResponse} from '../../models/gestao-arquivos/ga-arquivo';
import {catchError, map} from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import {NotificacaoService} from '../notificacao/notificacao.service';

@Injectable({
    providedIn: 'root'
})
export class GaArquivoService {
    url = `${environment.apiArquivos}Arquivos`;

    constructor(private http: HttpClient, private _notificacao: NotificacaoService) {
    }

    getArquivos() {
        return this.http.get<Resposta<GaArquivoResponse[]>>(this.url).pipe(catchError(err => {
            console.error(err);
            return err.status === 404 ?
                of({objecto: [], statusCode: err.status, mensagem: ''} as Resposta<GaArquivoResponse[]>) : throwError(err);
        }));
    }

    getArquivo(id: number) {
        return this.http.get<Resposta<GaArquivoResponse>>(`${this.url}/${id}`);
    }

    download(caminho: string) {
        return this.http.post<Resposta<string>>(`${this.url}/download`, {caminho});
    }

    postArquivo(modelo: GaArquivo) {
        return this.http.post<Resposta<GaArquivo>>(this.url, modelo);
    }

    putArquivo(modelo: GaArquivo) {
        return this.http.post<Resposta<GaArquivo>>(`${this.url}/${modelo.id}`, modelo);
    }

    deleteArquivo(id: number) {
        return this.http.post<Resposta<GaArquivo>>(`${this.url}/remover/${id}`, {});
    }

    // TODO: Tipar o input
    filtrarArquivos(filtro: any) {
        return this.http.post<Resposta<GaArquivoResponse[]>>(`${this.url}/filtrar`, filtro).pipe(catchError(err => {
            console.error(err);
            return err.status === 404 ?
                of({objecto: [], statusCode: err.status, mensagem: ''} as Resposta<GaArquivoResponse[]>) : throwError(() => err);
        }));
    }

    getArquivosEspecifico(codArea: string, id: number) {
        return this.http.get<Resposta<GaArquivoResponse[]>>(`${this.url}/especifico`, {params: {codArea, id}});
    }

    postArquivoEspecifico(codArea: string, id: number, arquivo: GaArquivo) {
        return this.http.post<Resposta<GaArquivoResponse>>(`${this.url}/especifico`, arquivo, {params: {codArea, id}});
    }

    postArquivoLote(codArea: string, id: number, arquivos: GaArquivo[]) {
        return this.http.post<Resposta<GaArquivoResponse[]>>(`${this.url}/especifico/lote`, arquivos, {params: {codArea, id}})
            .pipe(map(res => {
                if (res.mensagem) {
                    this._notificacao.notificar(res.mensagem);
                }
                return res;
            }));
    }

    postArquivoLotes(codArea: string, objs: any[]) {
        return this.http.post<Resposta<GaArquivoResponse[]>>(`${this.url}/especifico/lotes`, objs, {params: {codArea}})
            .pipe(map(res => {
                if (res.mensagem) {
                    this._notificacao.notificar(res.mensagem);
                }
                return res;
            }));
    }

    deleteFicheiro(idArquivo: number, idFicheiro: number) {
        return this.http.post<Resposta<any>>(`${this.url}/remover/${idArquivo}/ficheiros/${idFicheiro}`, {});
    }

    getArmazens() {
        return this.http.get<Resposta<any[]>>(`${this.url}/armazens`);
    }
}
