import {Input, Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'labelProvincia'
})
export class LabelProvinciaPipe implements PipeTransform {
@Input() valorLabel;
    public transform(valor: any, ...args): any {
        if (valor && typeof(valor.trim()) === 'string' && valor.trim().length > 0 && valor.toUpperCase() === 'AO') {
            return 'Pronvícia';
        } else {
            return 'Distrito';
        }
    }

}
