import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Data} from '@angular/router';

@Component({
    selector: 'app-modal-confirmacao',
    templateUrl: './modal-confirmacao.component.html',
    styleUrls: ['./modal-confirmacao.component.scss']
})
export class ModalConfirmacaoComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<ModalConfirmacaoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: Data) {
    }

    ngOnInit(): void {
    }
}
