<div #se>
<div class="full-width" [formGroup]='form' >
    <mat-form-field #matform class="full-width" appearance="outline">

        <mat-label>{{placeholder}}</mat-label>
        <input #input1 (focus)="setIconVisible()"  (blur)="ocultarIcon(se,matform, componetKey)"
        matInput formControlName="valor" [placeholder]="placeholder" [readonly]="false" [matAutocomplete]="componetKey"
            (input)="filtrarArtigoClassificacao($event)" />
            <!-- *ngIf="mostrarIcon" -->
           <mat-icon matSuffix [ngStyle]="{'cursor': 'pointer'}"
             (click)="closeList(se, matform, icon)" #icon>arrow_drop_down</mat-icon>
    </mat-form-field>
    <mat-autocomplete #componetKey="matAutocomplete" class="full-width"  
        (optionSelected)="aoSelecClassificacaoPorAutoComplete($event)" style="min-width: 250px!important;">
        <div class="content-height" >
            
            <!-- <mat-option #close class="position-0" >
                <div id="btn-close" flexLayout="row" fxLayoutAlign="end center">
                    <button class="btn-filter" mat-raised-button  type="reset" color="primary" (mousedown)="activar()"
                        (click)="prevDefault($event)">
                        <mat-icon>close</mat-icon>

                    </button>
                </div>
            </mat-option> -->
            <!-- (click)="todos(t, !todosbol)" -->
            <mat-option [value]="" flaxLayout="row" #todosOption class="position-1 padding-option">
                <div flaxLayout="row" 
                (click)="defi(matform, 'option-00-input')"
                  class="padding-div-content">
                    <span (click)="activarSemaforo()" class="flex border-b-light height-0" ><small class="height-0">
                            <mat-checkbox id="option-00" (change)="checkAll($event)" color="primary" class="height-0" #t></mat-checkbox>
                        </small></span>
                    <span class="flex border-b-light px-16" fxFlex="98">
                        <small class="font">Todos</small>
                    </span>
                </div>
            </mat-option>
            <mat-option [value]="" flaxLayout="row" #options class="padding-option"
            *ngFor="let item of lista; let i=index">
                <div (click)="defi(matform, 'option-'+i+'-input')" flaxLayout="row" class="padding-div-content">
                    <span (click)="activarSemaforo()" class="flex border-b-light span-position height-0"><small class="height-0">
                            <mat-checkbox id="option-{{i}}" (change)="selecionar($event, item, t)" color="primary"  class="checkbox-position height-0" #d></mat-checkbox>
                        </small></span>
                    <span class="flex border-b-light px-16" fxFlex="98"><small class="font">
                            {{item[''+chave+'']}}</small></span>
                </div>
            </mat-option>
        </div>
    </mat-autocomplete>
</div>
</div>