import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FontSizeDirective} from './font-size.directive';
import {ScrollToDirective} from './scroll-to.directive';
import {AppDropdownDirective} from './dropdown.directive';
import {DropdownAnchorDirective} from './dropdown-anchor.directive';
import {DropdownLinkDirective} from './dropdown-link.directive';
import {EgretSideNavToggleDirective} from './egret-side-nav-toggle.directive';
import {EgretSidenavHelperDirective, EgretSidenavTogglerDirective} from './egret-sidenav-helper/egret-sidenav-helper.directive';
import {EgretHighlightDirective} from './egret-highlight.directive';
import {SelectedActiveDirective} from './selected-active.directive';
import {TwoDecimalsDirective} from './two-decimals.directive';
import {DecimalPlacesDirective} from './decimal-places.directive';
import {InputDecimalsDirective} from './input-decimals.directive';
import {FormatDecimalsDirective} from './format-decimals.directive';
import {FixTopDirective} from './fix-top.directive';
import {BlockSpaceDirective} from './block-space.directive';
import {MaxDigitosDirective} from './max-digitos.directive';
import {FundoCardDirective} from './fundo-card.directive';
import {CurrencyI18nDirective} from './currency-i18n.directive';
import {InputFormatDirective} from './input-format.directive';
import {OptionSelectionDirective} from './option-selection.directive';
import {ToggleOpenDirective} from './toggle-open.directive';
import {FormatarHoraDirective} from './formatar-hora.directive';
import {AddressDirective} from './address.directive';
import {EstadoBgColorDirective} from './estado-bg-color.directive';
import {SectionDirective} from '@directives/section.directive';
import {KCardColumnDirective} from '@directives/k-card-column.directive';

const DIRECTIVES = [
    FontSizeDirective,
    ScrollToDirective,
    AppDropdownDirective,
    DropdownAnchorDirective,
    DropdownLinkDirective,
    EgretSideNavToggleDirective,
    EgretSidenavHelperDirective,
    EgretSidenavTogglerDirective,
    EgretHighlightDirective,
    SelectedActiveDirective,
    TwoDecimalsDirective,
    InputDecimalsDirective,
    DecimalPlacesDirective,
    FormatDecimalsDirective,
    FixTopDirective,
    BlockSpaceDirective,
    MaxDigitosDirective,
    FundoCardDirective,
    CurrencyI18nDirective,
    InputFormatDirective,
    OptionSelectionDirective,
    ToggleOpenDirective,
    FormatarHoraDirective,
    KCardColumnDirective,
    SectionDirective,
    AddressDirective, EstadoBgColorDirective
];

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: DIRECTIVES,
    exports: DIRECTIVES
})
export class SharedDirectivesModule {
}

