import {Directive, HostListener, Input} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {Util} from '../models/generico/util';



@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[formatarHora]',
})
export class FormatarHoraDirective {
    @Input() valor = '';
    @HostListener('input', ['$event']) onInput(event: Event) {
        let target = event.target as HTMLInputElement;
        target.value = target.value.toUpperCase().replace(/[a-zA-Z\sç;?><_=+/ºª%$#"!|()&´`«»'}{*^~\t\n]/gi, '');
    }
}
