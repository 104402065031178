import {Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import {NavigationService} from '../../../shared/services/navigation.service';
import {ThemeService} from '../../services/theme.service';
import {Subscription} from 'rxjs';
import {ILayoutConf, LayoutService} from 'app/shared/services/layout.service';
import {JwtAuthService} from 'app/shared/services/auth/jwt-auth.service';
import { UtilizadorSessao } from 'app/shared/models/aplicacao/utilizador-sessao';
import { LoginService } from 'app/shared/services/aplicacao-service/login.service';

@Component({
    selector: 'app-sidebar-side',
    templateUrl: './sidebar-side.component.html'
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
    public menuItems: any[];
    public appLogo = 'assets/images/logo/kiami.png';
    public hasIconTypeMenuItem: boolean;
    public iconTypeMenuTitle: string;
    private menuItemsSub: Subscription;
    public layoutConf: ILayoutConf;

    constructor(
        private navService: NavigationService,
        public themeService: ThemeService,
        private layout: LayoutService,
        public jwtAuth: JwtAuthService,
        private loginService: LoginService
    ) {
    }

    ngOnInit() {
        this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
        this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
            this.menuItems = menuItem;
            // Checks item list has any icon type.
            this.hasIconTypeMenuItem = !!this.menuItems.filter(
                item => item.type === 'icon'
            ).length;
        });
        this.layoutConf = this.layout.layoutConf;

        let utilizador: UtilizadorSessao = this.loginService?.getUtilizadorSessao();
        this.filtrarMenu(utilizador?.sistema);
    }


    filtrarMenu( sistema: boolean) {
        if (!sistema){
           let menu: any= this.menuItems.find(menu => menu?.code === 'ADMINI');
           menu.sub = menu?.sub?.filter( sub => sub?.name !== 'Módulos');
        }
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        if (this.menuItemsSub) {
            this.menuItemsSub.unsubscribe();
        }
    }

    toggleCollapse() {
        if (
            this.layoutConf.sidebarCompactToggle
        ) {
            this.appLogo = 'assets/images/logo/kiami.png';
            this.layout.publishLayoutChange({
                sidebarCompactToggle: false
            });
        } else {
            this.appLogo = 'assets/images/logo/icon.png';
            this.layout.publishLayoutChange({
                // sidebarStyle: "compact",
                sidebarCompactToggle: true
            });
        }
    }
}
