import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterFn',
    pure: false
})
export class FilterFnPipe implements PipeTransform {
    transform(items: any[], predicate: (item: any, ...args: any[]) => boolean, ...args: any[]): any[] {
        if (!items || !predicate) {
            return items;
        }
        return items.filter(item => predicate(item, ...args));
    }

}
