import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[fundoCard]'
}) export class FundoCardDirective {
    @Input() setFundoCard: number;
   fundos: any[] = [
        {fundo: 'background: url(/assets/images/backgrounds/circles.png), linear-gradient(90deg, rgb(223 223 227) -19.83%, rgb(69,153,238) 189.85%);!important;' },
        {fundo: 'background: url(/assets/images/backgrounds/circles.png), linear-gradient(90deg, rgb(223 223 227) -19.83%, rgb(193,9,68) 189.85%);!important;' },
        {fundo: 'background: url(/assets/images/backgrounds/circles.png), linear-gradient(90deg, rgb(223 223 227) -19.83%, rgb(69,153,238) 189.85%);!important;' },
        {fundo: 'background: url(/assets/images/backgrounds/circles.png), linear-gradient(90deg, rgb(223 223 227) -19.83%, rgb(238,131,69) 189.85%);!important;' }
    ];

    constructor(private el: ElementRef) {
        el.nativeElement.style.background = this.fundos[this.setFundoCard]?.fundo;
    }
}