import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {Util} from '../models/generico/util';

@Directive({
    selector: '[maxDigitos]'
})
export class MaxDigitosDirective {
    @Input() valorMax: number;
    private regex: RegExp = new RegExp(/^\d*,?\d{0,2}$/g);
    private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {

        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        const current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        const next: string = [current.slice(0, position), event.key === ',' ? ',' : event.key, current.slice(position)].join('');
        if (Util.convertToFloat(next) >= this.valorMax) {
            console.log('Max Sao iguais: ', next);
            event.preventDefault();
        }

        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}
