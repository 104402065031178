import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import {
  HttpHeaders,
  HttpClient,
} from "@angular/common/http";
import { environment } from "environments/environment";
import "rxjs/add/operator/catch";

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Content-Type": "application/json; charset=utf-8",
  }),
};

const httpOptionsXml = {
  headers: new HttpHeaders({
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Content-Type": "text/html; charset=UTF-8",
  }),
};

@Injectable({
  providedIn: 'root'
})
export class ReferenciasService {
  baseUrl: string;
  baseUrlFinanca: string;

  constructor(private http: HttpClient) {
    this.baseUrlFinanca = `${environment.apiFinancas}referenciasBancarias/`;
  }

  getReferenciasDocumentosBanc(): Observable<any> {
    return this.http
      .get(this.baseUrlFinanca + "listar")
      .pipe();
  }

  getEstadoReferencias(): Observable<any> {
    return this.http
      .get(this.baseUrlFinanca + "estados")
      .pipe();
  }

  getEntidadesRefActivas(): Observable<any> {
    return this.http
      .get(this.baseUrlFinanca + "entidadesActivas",httpOptions)
      .pipe();
  }

  getEntidadesRefActivasEmpresa(idEmpresa): Observable<any> {
    return this.http
      .get(this.baseUrlFinanca + "entidadesActivas/"+idEmpresa,httpOptions)
      .pipe();
  }


  getReferenciaEntidade(idEntidade:number): Observable<any> {
    return this.http
      .get(this.baseUrlFinanca + "gerarReferencia/"+idEntidade,httpOptions)
      .pipe();
  }

  gravarEmailsDocumentosTe(dados:any): Observable<any> {
    return this.http
      .post(this.baseUrlFinanca + "documentosTesouraria/gravarEmailTesourariaCliente",dados)
      .pipe();
  }
 }



