import {CurrencyPipe} from '@angular/common';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FormatarQuantidadePipe} from 'app/shared/pipes/formatar-quantidade.pipe';
import {timer} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-intervalo-valores',
    templateUrl: './intervalo-valores.component.html',
    styleUrls: ['./intervalo-valores.component.scss'],
    providers: [CurrencyPipe, FormatarQuantidadePipe]
})
export class IntervaloValoresComponent implements OnInit, OnChanges {
    canOver = true;
    permitir = false;
    auxMin = '';
    auxMax = '';
    @Input('placeholder') placeholder: any;
    @Input('min') min: any;
    @Input('max') max: any;
    @Input('limpar') limpar: boolean;
    @Input() errorMessage = '';
    @Input('descricao') descricao: any;
    @Output('setValores') valores = new EventEmitter();

    FORM = {
        empresas: 'idEmpresas',
        movimentos: 'idMovimentos',
        tipoMovimentos: 'tipoMovimentos',
        armazens: 'idArmazens',
        artigos: 'idArtigos',
        dataInicio: 'dataInicio',
        dataFim: 'dataFim',
        min: 'min',
        max: 'max',
        montanteMin: 'montanteMin',
        montanteMax: 'montanteMax',
        minVer: 'minVer',
        maxVer: 'maxVer',
        montanteMinVer: 'montanteMinVer',
        montanteMaxVer: 'montanteMaxVer',
        minQuantidadeAux: 'minQuantidadeAux',
        maxQuantidadeAux: 'maxQuantidadeAux',
        minMontanteAux: 'minMontanteAux',
        maxMontanteAux: 'maxMontanteAux',
        caracterQuantidadeMinValido: 'caracterQuantidadeMinValido',
        caracterQuantidadeMaxValido: 'caracterQuantidadeMaxValido',
        caracterMontanteMinValido: 'caracterMontanteMinValido',
        caracterMontanteMaxValido: 'caracterMontanteMaxValido'
    };

    filterForm: FormGroup = this.fb.group({
        [this.FORM.empresas]: [],
        [this.FORM.movimentos]: [],
        [this.FORM.tipoMovimentos]: [],
        [this.FORM.armazens]: [],
        [this.FORM.artigos]: [],
        [this.FORM.dataInicio]: null,
        [this.FORM.dataFim]: null,
        [this.FORM.min]: [null],
        [this.FORM.max]: [null],
        [this.FORM.montanteMin]: [],
        [this.FORM.montanteMax]: [],
        [this.FORM.minVer]: [''],
        [this.FORM.maxVer]: [''],
        [this.FORM.montanteMinVer]: [''],
        [this.FORM.montanteMaxVer]: [''],
        [this.FORM.minQuantidadeAux]: [''],
        [this.FORM.maxQuantidadeAux]: [''],
        [this.FORM.minMontanteAux]: [''],
        [this.FORM.maxMontanteAux]: [''],
        [this.FORM.caracterQuantidadeMinValido]: [],
        [this.FORM.caracterQuantidadeMaxValido]: [],
        [this.FORM.caracterMontanteMinValido]: [],
        [this.FORM.caracterMontanteMaxValido]: []
    });

    constructor(
        private fb: FormBuilder,
        private formatarQuantidadePipe: FormatarQuantidadePipe
    ) {
        this.placeholder = 'texto default';
    }

    ngOnInit(): void {
        if (this.min === null) {
            this.filterForm.get(this.FORM.minVer).setValue('');

        }

        if (this.max === null) {
            this.filterForm.get(this.FORM.maxVer).setValue('');
        }
    }


    ngOnChanges(changes: SimpleChanges) {
        if (this.limpar) {
            this.auxMin = '';
            this.auxMax = '';
            this.filterForm.get(this.FORM.min).setValue(null);
            this.filterForm.get(this.FORM.minVer).setValue('');
            this.filterForm.get(this.FORM.max).setValue(null);
            this.filterForm.get(this.FORM.maxVer).setValue('');
        }


        if (this.min === null) {
            this.filterForm.get(this.FORM.min).setValue(this.min);
            this.filterForm.get(this.FORM.minVer).setValue('');

        }

        if (this.max === null) {
            this.filterForm.get(this.FORM.max).setValue(this.FORM.max);
            this.filterForm.get(this.FORM.maxVer).setValue('');
        }
    }

    get f() {
        return this.filterForm.controls;
    }

    delay(milliseconds: number) {
        return timer(milliseconds).pipe(take(1)).toPromise();
    }

    OnHidden(evento: any) {
        setTimeout(() => {

            this.canOver = true;
        }, 0);
    }

    async setState(evento: any) {
        // await this.delay(100);
        setTimeout(() => {

            this.canOver = false;
        }, 0);
        const auxMin = this.filterForm.get(this.FORM.minVer).value;
        const auxMax = this.filterForm.get(this.FORM.maxVer).value;
        const min: number = auxMin && auxMin !== '' ? parseFloat(auxMin.replace(/[.]/g, '').replace(/,/g, '.')) : null;
        const max: number = auxMax && auxMax !== '' ? parseFloat(auxMax.replace(/[.]/g, '').replace(/,/g, '.')) : null;
        this.filterForm.get(this.FORM.minVer).setErrors(null);
        this.filterForm.get(this.FORM.maxVer).setErrors(null);

        if (min && max) {
            min > max ? this.filterForm.get(this.FORM.minVer).setErrors({inválido: true}) : '';
            min > max ? this.filterForm.get(this.FORM.maxVer).setErrors({inválido: true}) : '';
        }

        this.filterForm.get(this.FORM.minVer).markAsTouched();
        this.filterForm.get(this.FORM.maxVer).markAsTouched();
    }

    validarEntrada(event: any, formControlEstado: FormControl) {
        this.permitir = true;
        const keyCode: number = event.charCode;

        if (keyCode === 8 || keyCode === 32 || keyCode === 45
            || (keyCode >= 48 && keyCode <= 57)) {
            formControlEstado.setValue(true);
            return;
        }
        formControlEstado.setValue(false);
    }


    async validarMin(
        valor: string,
        formControlVer: FormControl,
        formControl: FormControl,
        formControlAux: FormControl,
        formControlEstado: FormControl,
        from) {
        let sinalValido = true;
        valor = valor.replace(/\s/g, '');

        // Aqui
        if (valor.includes('-')) {
            sinalValido = valor.startsWith('-') && 1 >= valor.match(/-/g).length;
        }
        // Aqui
        const valido: boolean = formControlEstado.value;

        if ((!valido || !sinalValido) && this.permitir) {
            this.permitir = false;
            formControl.setValue(formControlAux.value);
            formControlVer.setValue(formControlAux.value);
            return;
        }


        this.permitir = false;
        await this.formatarQuantidadePipe.transform(
            (valor + '').replace(/^[0][,][0][0]/g, '')
                .replace(/^[0][,][0]/g, '')
                .replace(/^[0][,]/g, '')
                .replace(/\./g, '')
                .replace(/,/g, '')
        ).then(async (valorAux) => {

                await formControl.setValue(valorAux);
                await formControlVer.setValue(valorAux);
                await formControlAux.setValue(valorAux);

                if (from === 'min') {
                    this.auxMin = valorAux;

                } else if (from === 'max') {
                    this.auxMax = valorAux;

                }

                const min: number = this.auxMin && this.auxMin !== '' ? parseFloat(this.auxMin.replace(/[.]/g, '').replace(/,/g, '.')) : null;
                const max: number = this.auxMax && this.auxMax !== '' ? parseFloat(this.auxMax.replace(/[.]/g, '').replace(/,/g, '.')) : null;
                this.filterForm.get(this.FORM.minVer).setErrors(null);
                this.filterForm.get(this.FORM.maxVer).setErrors(null);
                if (this.auxMin && this.auxMax) {
                    min > max ? this.filterForm.get(this.FORM.minVer).setErrors({inválido: true}) : '';
                    min > max ? this.filterForm.get(this.FORM.maxVer).setErrors({inválido: true}) : '';

                }

                this.filterForm.get(this.FORM.minVer).markAsTouched();
                this.filterForm.get(this.FORM.maxVer).markAsTouched();


                this.valores.emit({
                    min,
                    max,
                    valido: (this.filterForm.get(this.FORM.minVer).valid && this.filterForm.get(this.FORM.maxVer).valid)
                });


            }
        );
    }

}
