<div class="sidenav-hold">
    <ul appDropdown class="sidenav">
        <ng-container *ngFor="let item of menuItems; let i = index">
            <ng-container   *ngIf="false">
                <li class="borda-menu" appDropdownLink routerLinkActive="open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <div class="lvl1">
                        <!-- Dashboard -->
                        <a routerLink="/{{item.state}}" appDropdownToggle matRipple
                            *ngIf="item.type === 'link' && !UsaDashboard">
                            <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                            <span class="item-name lvl1">{{item?.name| translate}}</span>
                            <span fxFlex></span>
                        </a>
                    </div>
                </li>
            </ng-container>

            <ng-container
                *ngIf="getMenuNaoDinamico(item.code, i) && item.type !== 'separator' && (EmDesenvolvimento || !item.desenvolvimento) && item.type !== 'icon' && listamenu.indexOf(item.code) !== -1; else MenusDinamicos">
                <li class="borda-menu  {{item.state && item.state.toLowerCase() === linkUrlRota && linkUrlRota.toLowerCase() ? 'open' : ''}}"
                    appDropdownLink routerLinkActive="open"
                    [ngClass]="{'open': item.state && item.state.toLowerCase() === linkUrlRota && linkUrlRota.toLowerCase() }"
                    [routerLinkActiveOptions]="{exact: true}">
                    <div *ngIf="item.code != codigoMenuTesouraria &&!item.disabled && item.type !== 'separator' && item.type !== 'icon'"
                        class="lvl1">
                        <a routerLink="/{{item.state}}" appDropdownToggle matRipple *ngIf="item.type === 'link'">
                            <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                            <span class="item-name lvl1"> {{ getMenu(item.code)?.menu | translate}}</span>
                            <span fxFlex></span>
                        </a>

                        <!-- DropDown -->
                        <a appDropdownToggle matRipple (click)="activarDropDown(0)"
                            *ngIf="item.type === 'dropDown' && (EmDesenvolvimento || !item.desenvolvimento)">
                            <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                            <span class="item-name lvl1 # {{item.state}} * {{linkUrlRota}}">  {{getMenu(item.code)?.menu
                                | translate}}</span>
                            <span fxFlex></span>
                            <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                        </a>

                        <!-- LEVEL 2 -->
                        <ul class="submenu lvl2 " appDropdown
                            *ngIf="item.type === 'dropDown' && (EmDesenvolvimento || !item.desenvolvimento)">
                            <li  *ngFor="let itemLvL2 of item.sub" appDropdownLink routerLinkActive="open">
                                <!-- Menu POS -->
                                <div *ngIf="itemLvL2.state === 'pos'" (click)="posUtilityService.openFullScreen(true)" class="text-white">
                                    <a routerLink="/pos-home"
                                       appDropdownToggle
                                       *ngIf="item.code !== 'RECHUM' &&
                                   itemLvL2.type !== 'dropDown' &&
                                   itemLvL2.state.toLowerCase() === 'pos' &&
                                   (EmDesenvolvimento || !itemLvL2.desenvolvimento) && itemLvL2.name !== 'Grupo'"
                                       matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                        <span fxFlex></span>
                                    </a>
                                </div>

                                <a routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}"
                                   appDropdownToggle
                                   *ngIf="item.code !== 'RECHUM' &&
                                   itemLvL2.type !== 'dropDown' &&
                                itemLvL2.state.toLowerCase() !== 'pos' &&
                                   (EmDesenvolvimento || !itemLvL2.desenvolvimento) && itemLvL2.name !== 'Grupo'"
                                   matRipple>
                                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                    <span fxFlex></span>
                                </a>

                                <a routerLink="{{itemLvL2.state}}" appDropdownToggle
                                    *ngIf="item.code === 'RECHUM' && itemLvL2.type !== 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && itemLvL2.name !== 'Grupo'"
                                    matRipple>
                                    <span class="item-name lvl2">  {{itemLvL2.name | translate}}</span>
                                    <span fxFlex></span>
                                </a>



                                <ng-container *ngFor="let grupo of gruposRegistos">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{grupo.idGrupo}}"
                                        appDropdownToggle
                                        *ngIf="itemLvL2.type !== 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && itemLvL2.name == 'Grupo'"
                                        matRipple>
                                        <span class="item-name lvl2">{{grupo.grupoP | translate}}</span>
                                        <span fxFlex></span>
                                    </a>
                                </ng-container>

                                <div *ngIf="item.code === 'ADMINI' && itemLvL2.name === 'Aplicação'">
                                    <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                        <span fxFlex></span>
                                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                    </a>

                                    <!-- LEVEL 3 -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                                                appDropdownToggle matRipple>
                                                <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div
                                    *ngIf="item.code === 'ADMINI' && (EmDesenvolvimento || !item.desenvolvimento) && usaMenuFacturacao  && itemLvL2.name !== 'Processos' && itemLvL2.name !== 'Artigos' && itemLvL2.name !== 'Aplicação' && itemLvL2.name !== 'Fornecedores'
                  && itemLvL2.name !== 'Entidades' && itemLvL2.name !== 'Gestor de Conteúdos' && itemLvL2.name !== 'Eventos'  && itemLvL2.name !== 'R. Humanos' && itemLvL2.name !== 'Equipamentos'">
                                    <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                        <span fxFlex></span>
                                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                    </a>

                                    <!-- LEVEL 3 -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                                                appDropdownToggle matRipple>
                                                <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div
                                    *ngIf="item.code === 'ADMINI' && (EmDesenvolvimento || !item.desenvolvimento) && usaMenuArtigo  && itemLvL2.name === 'Artigos'">
                                    <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                        <span fxFlex></span>
                                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                    </a>

                                    <!-- LEVEL 3 -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                                                appDropdownToggle matRipple
                                                *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                                                <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div *ngIf="item.code === 'ADMINI' && itemLvL2.name === 'Eventos'">
                                    <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name| translate}}</span>
                                        <span fxFlex></span>
                                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                    </a>

                                    <!-- LEVEL 3 -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                                                appDropdownToggle matRipple
                                                *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                                                <span class="item-name lvl3">{{itemLvL3.name| translate}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div
                                    *ngIf="item.code === 'ADMINI' && usaMenuEquipamento && itemLvL2.name === 'Equipamentos'">
                                    <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name| translate}}</span>
                                        <span fxFlex></span>
                                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                    </a>

                                    <!-- LEVEL 3 -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                                                appDropdownToggle matRipple
                                                *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                                                <span class="item-name lvl3">{{itemLvL3.name| translate}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div *ngIf="item.code === 'ADMINI' && usaMenuCliente && itemLvL2.name === 'Entidades'">
                                    <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                        <span fxFlex></span>
                                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                    </a>

                                    <!-- LEVEL 3 -->
                                    <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                                                appDropdownToggle matRipple
                                                *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                                                <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div
                                    *ngIf="item.code === 'ADMINI' && usaMenuCMS && itemLvL2.name === 'Gestor de Conteúdos' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                                    <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name| translate}}</span>
                                        <span fxFlex></span>
                                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                    </a>

                                    <!-- LEVEL 3 -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                                                appDropdownToggle matRipple
                                                *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                                                <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div
                                    *ngIf="item.code === 'ADMINI' && usaMenuRecursosHumanos && itemLvL2.name === 'R. Humanos'">
                                    <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                        <span fxFlex></span>
                                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                    </a>

                                    <!-- LEVEL 3 -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                                                appDropdownToggle matRipple>
                                                <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div
                                    *ngIf="item.code === 'ADMINI' && usaMenuProcesso  && itemLvL2.name === 'Processos'">
                                    <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                        <span fxFlex></span>
                                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                    </a>

                                    <!-- LEVEL 3 -->
                                    <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                                                appDropdownToggle matRipple>
                                                <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div
                                    *ngIf="item.code === 'GESCON' && usaMenuCMS && ConfiguracaoCMS?.usaFaqs && itemLvL2.name === 'Faqs'">
                                    <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                        <span fxFlex></span>
                                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                    </a>

                                    <!-- LEVEL 3 -->
                                    <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                                                appDropdownToggle matRipple>
                                                <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>


                                <ng-container *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && item.code !== 'ADMINI' && itemLvL2.name !== 'Financeira' && itemLvL2.name !== 'Faqs'
                && itemLvL2.name !== 'Artigos' && itemLvL2.name !== 'Aplicação' && itemLvL2.name && itemLvL2.name !== 'Equipamentos' && itemLvL2.name !== 'Fornecedores' && itemLvL2.name !== 'Entidades' && getMenuNaoDinamico(item.code);
                else SubMenuEntidades">
                                    <a appDropdownToggle matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                        <span fxFlex></span>
                                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                    </a>

                                    <!-- LEVEL 3 -->
                                    <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)  && itemLvL2.name !== 'Páginas' && itemLvL2.name !== 'Formulários' && itemLvL2.name !== 'Galerias' && itemLvL2.name !== 'Estrutura Organizativa'
                  && itemLvL2.name !== 'Módulos'">
                                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                            routerLinkActive="open">
                                            <ng-container [ngSwitch]="itemLvL2.name">
                                                <ng-container *ngSwitchCase="'Despesas'">
                                                    <a routerLink="/{{itemLvL3.state}}" appDropdownToggle matRipple
                                                        *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                                                        <span class="item-name lvl3">{{itemLvL3.name |
                                                            translate}}</span>
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    <a routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                                                        appDropdownToggle matRipple
                                                        *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                                                        <span class="item-name lvl3">{{itemLvL3.name |
                                                            translate}}</span>
                                                    </a>
                                                </ng-container>
                                            </ng-container>
                                        </li>
                                    </ul>

                                    <!-- LEVEL 3 Menu dinâmico da Estrutura -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && itemLvL2.name === 'Estruturas Organizativas'">
                                        <li *ngFor="let obj of listaEstuturasOrganizativas" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/listar-unidades/{{obj.idEstruturaOrganizativa}}"
                                                appDropdownToggle matRipple>
                                                <span class="item-name lvl3">{{obj.estruturaOrganizativa |
                                                    translate}}</span>
                                            </a>
                                        </li>
                                    </ul>

                                    <!-- LEVEL 3 Menu dinâmico da Estrutura Organizativa -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && itemLvL2.name === 'Estrutura Organizativa'">
                                        <li *ngFor="let obj of listaEstuturasOrganizativas" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/estrutura-organizativa/listar/{{obj.idEstruturaOrganizativa}}"
                                                appDropdownToggle matRipple>
                                                <span class="item-name lvl3">{{obj.estruturaOrganizativa |
                                                    translate}}</span>
                                            </a>
                                        </li>
                                    </ul>

                                    <!-- LEVEL 3 Menu dinâmico da Modulos Eventos -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown' && itemLvL2.name === 'Módulos'">
                                        <li *ngFor="let obj of listaModulosEventos" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/modulos/lista/{{obj.idModulo}}"
                                                appDropdownToggle matRipple>
                                                <span class="item-name lvl2">{{ obj.moduloP + " MModulo" |
                                                    translate}}</span>
                                            </a>
                                        </li>
                                    </ul>

                                    <!-- LEVEL 3  Menu de formulario dinâmico -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown'  && itemLvL2.name === 'Formulários'">
                                        <li *ngFor="let formulario of listaFormularios" appDropdownLink
                                            routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/formulario/lista/{{formulario.idFormulario}}"
                                                appDropdownToggle matRipple>
                                                <span class="item-name lvl3">{{formulario?.formularioP |
                                                    translate}}</span>
                                            </a>
                                        </li>
                                    </ul>

                                    <!-- LEVEL 3  Menu de Galerias dinâmico -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown'  && itemLvL2.name === 'Galerias'">
                                        <li *ngFor="let galeria of galerias  " appDropdownLink routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/galeria/{{galeria.idGaleria}}"
                                                appDropdownToggle matRipple>
                                                <span class="item-name lvl3">{{galeria?.galeria | translate}}</span>
                                            </a>
                                        </li>
                                    </ul>

                                    <!-- LEVEL 3  Menu Páginas dinâmicas-->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown' && itemLvL2.name === 'Páginas'">
                                        <li *ngFor="let pagina of paginas" appDropdownLink routerLinkActive="open">
                                            <a routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/pagina/lista/{{pagina.idPagina}}"
                                                appDropdownToggle matRipple>
                                                <span class="item-name lvl3">{{pagina.textoPagina | translate}}</span>
                                            </a>
                                        </li>
                                    </ul>

                                </ng-container>
                            </li>
                        </ul>
                    </div>

                    <div *ngIf="item.code == codigoMenuTesouraria && !item.disabled && item.type !== 'separator' && item.type !== 'icon'"
                        class="lvl1">
                        <!-- DropDown -->
                        <a appDropdownToggle matRipple (click)="activarDropDown(0)"
                            *ngIf="item.type === 'dropDown' && (EmDesenvolvimento || !item.desenvolvimento)">
                            <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                            <span class="item-name lvl1 # {{item.state}} * {{linkUrlRota}}"> {{getMenu(item.code)?.menu
                                | translate}}</span>
                            <span fxFlex></span>
                            <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                        </a>

                        <!-- LEVEL 2 -->
                        <ul class="submenu lvl2" appDropdown
                            *ngIf="item.type === 'dropDown' && (EmDesenvolvimento || !item.desenvolvimento)">
                            <li *ngFor="let itemLvL2 of item.sub" appDropdownLink routerLinkActive="open">
                                <a routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}"
                                    appDropdownToggle
                                    *ngIf="itemLvL2.name != 'Recebimentos' && itemLvL2.type !== 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && itemLvL2.name !== 'Grupo'"
                                    matRipple>
                                    <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                    <span fxFlex></span>
                                </a>

                                <a routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}"
                                    appDropdownToggle
                                    *ngIf="itemLvL2.name == 'Recebimentos' && itemLvL2.type !== 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) && itemLvL2.name !== 'Grupo'"
                                    matRipple>
                                    <span class="item-name lvl2">{{itemLvL2.name}}{{usaMenuCompra?" /
                                        Pagamento":""}}</span>
                                    <span fxFlex></span>
                                </a>

                                <ng-container
                                    *ngIf="itemLvL2.type === 'dropDown' && usaMenuCompra && itemLvL2.name == 'Despesas' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                                    <a appDropdownToggle matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                        <span fxFlex></span>
                                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                    </a>

                                    <!-- LEVEL 3 -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                            routerLinkActive="open">
                                            <ng-container [ngSwitch]="itemLvL2.name">
                                                <a routerLink="/{{itemLvL3.state}}" appDropdownToggle matRipple
                                                    *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                                                    <span class="item-name lvl3">{{itemLvL3.name|
                                                        translate}}</span>
                                                </a>
                                            </ng-container>
                                        </li>
                                    </ul>
                                </ng-container>

                                <ng-container
                                    *ngIf="itemLvL2.type === 'dropDown' && itemLvL2.name != 'Despesas' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                                    <a appDropdownToggle matRipple>
                                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                        <span fxFlex></span>
                                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                    </a>

                                    <!-- LEVEL 3 -->
                                    <ul class="submenu lvl3" appDropdown
                                        *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                            routerLinkActive="open">
                                            <ng-container [ngSwitch]="itemLvL2.name">
                                                <a routerLink="/{{itemLvL3.state}}" appDropdownToggle matRipple
                                                    *ngIf="(EmDesenvolvimento || !itemLvL3.desenvolvimento)">
                                                    <span class="item-name lvl3">{{itemLvL3.name|
                                                        translate}}</span>
                                                </a>
                                            </ng-container>
                                        </li>
                                    </ul>
                                </ng-container>
                            </li>
                        </ul>
                    </div>
                </li>
            </ng-container>

            <ng-template #MenusDinamicos>

                <ng-container
                    *ngIf="item.code === codigoMenuCliente && (EmDesenvolvimento || !item.desenvolvimento) && listamenu.indexOf(item.code) !== -1 && listaMenuClient">
                    <li class="borda-menu " [class.open]="rotaActual==='clientes'"
                        [ngClass]="{'open': item.code === 'CLIENT' && linkUrlRota && linkUrlRota ==='clientes' && entidade.idClassificacao === +idClassRota}"
                        *ngFor="let entidade of listaMenuClient; let i = index " appDropdownLink routerLinkActive="open"
                        [routerLinkActiveOptions]="{exact: true}">
                        <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
                            <ng-container>
                                <a routerLink="/clientes/lista/2" appDropdownToggle matRipple
                                    *ngIf="item.type === 'link'">
                                    <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                                    <span>client</span>
                                    <span class="item-name lvl1"> {{entidade.menu | translate}}</span>
                                    <span fxFlex></span>
                                </a>

                                <a routerLink="/clientes/lista/2" appDropdownToggle matRipple
                                    *ngIf="item.type === 'extLink'" target="_blank">
                                    <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                                    <span class="item-name lvl1"> {{entidade.menu| translate}}</span>
                                    <span fxFlex></span>
                                </a>
                                <!-- DropDown -->
                                <a *ngIf="item.type === 'dropDown'" matRipple
                                    (click)="activarDropDown(entidade.idClassificacao)">
                                    <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                                    <span class="item-name lvl1"
                                        [class.activo]="entidade.idClassificacao === idMenuActivo">{{entidade.menu |
                                        translate}}</span>
                                    <span fxFlex></span>
                                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                </a>
                                <!-- LEVEL 2 -->
                                <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'"
                                    [class.abrir]="entidade.idClassificacao == idMenuActivo">
                                    <li *ngFor="let itemLvL2 of item.sub" appDropdownLink routerLinkActive="open">
                                        <a routerLink="/clientes/lista/{{entidade.idClassificacao}}" appDropdownToggle
                                            *ngIf="itemLvL2.type !== 'dropDown' && itemLvL2.name !== 'Acessos'"
                                            matRipple>
                                            <span class="item-name lvl2">{{entidade.menu | translate}}</span>
                                            <span fxFlex></span>
                                        </a>
                                        <div
                                            *ngIf="itemLvL2.name == 'Acessos' && getCliente(entidade.idClassificacao)?.usaLogins">
                                            <a routerLink="/clientes/lista/acessos/{{entidade.idClassificacao}}"
                                                appDropdownToggle *ngIf="itemLvL2.type !== 'dropDown'" matRipple>
                                                <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                                <span fxFlex></span>
                                            </a>
                                        </div>

                                        <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
                                            <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                                            <span fxFlex></span>
                                            <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                                        </a>

                                        <!-- LEVEL 3 -->
                                        <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                                            <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink
                                                routerLinkActive="open">
                                                <div
                                                    *ngIf="getCliente(entidade.idClassificacao)?.idOpcTipos !== 1 && itemLvL3.name === 'Tipos'">
                                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + entidade.idClassificacao}}"
                                                        appDropdownToggle matRipple>
                                                        <span class="item-name lvl3">
                                                            {{itemLvL3.name | translate}}
                                                            {{getCliente(entidade.idClassificacao)?.entidadeP}}
                                                        </span>
                                                    </a>
                                                </div>

                                                <div
                                                *ngIf="getCliente(entidade.idClassificacao)?.idOpcSectoresActividade !== 1 && itemLvL3.name === 'Sectores Actividade'">
                                                <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + entidade.idClassificacao}}"
                                                    appDropdownToggle matRipple>
                                                    <span class="item-name lvl3">
                                                        {{itemLvL3.name | translate}}
                                                    </span>
                                                </a>
                                            </div>

                                                <div
                                                    *ngIf="getCliente(entidade.idClassificacao)?.usaCaracteristicas && itemLvL3.name === 'Caracteristicas'">
                                            <li *ngFor="let obj of getCliente(entidade.idClassificacao)?.caracteristica"
                                                appDropdownLink routerLinkActive="open">
                                                <a *ngIf="obj.valoresFixos"
                                                    routerLink="{{item.state ? '/'+item.state : ' '}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + entidade.idClassificacao + '/' + obj.idCaracteristica}}"
                                                    appDropdownToggle matRipple>
                                                    <span class="item-name lvl2">{{obj.caracteristicaP |
                                                        translate}}</span>
                                                </a>
                                            </li>
                        </div>

                        <div
                            *ngIf="getCliente(entidade.idClassificacao)?.usaArquivos && itemLvL3.name === 'Tipos de Arquivos'">
                            <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + entidade.idClassificacao}}"
                                appDropdownToggle matRipple>
                                <span class="item-name lvl3">{{itemLvL3.name |
                                    translate}}</span>
                            </a>
                        </div>

                        <div
                            *ngIf="getCliente(entidade.idClassificacao)?.idOpcTipoLocalizacao !== 1 && itemLvL3.name === 'Tipo de Localização'">
                            <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + entidade.idClassificacao}}"
                                appDropdownToggle matRipple>
                                <span class="item-name lvl3">{{itemLvL3.name |
                                    translate}}</span>
                            </a>
                        </div>
                        <div
                            *ngIf="itemLvL3.name !== 'Tipo de Localização' && itemLvL3.name !== 'Tipos de Arquivos' && itemLvL3.name !== 'Tipos' && itemLvL3.name !== 'Caracteristicas' && itemLvL3.name !== 'Sectores Actividade'">
                            <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + entidade.idClassificacao}}"
                                appDropdownToggle matRipple>
                                <span class="item-name lvl3">{{itemLvL3.name |
                                    translate}}</span>
                            </a>
                        </div>
                    </li>
    </ul>
    </li>
    </ul>
    </ng-container>
</div>
</li>
</ng-container>

<ng-container *ngIf="item.code === codMenuEquipamento && (EmDesenvolvimento || !item.desenvolvimento)">
    <li class="borda-menu open" *ngFor="let equipamento of listaMenuEquipamento; let i = index" appDropdownLink
        routerLinkActive="open"
        [ngClass]="{'open': item.code ===  codMenuEquipamento && linkUrlRota && linkUrlRota==='equipamentos' && equipamento.idClassificacao === +idClassRota}"
        [routerLinkActiveOptions]="{exact: true}">
        <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
            <!-- DropDown -->
            <a *ngIf="item.type === 'dropDown'" appDropdownToggle matRipple
                (click)="activarDropDown(equipamento.idClassificacao + 13 + i)">
                <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                <span class="item-name lvl1" [class.activo]="equipamento.idClassificacao + 13 + i === idMenuActivo">
                    {{equipamento.menu | translate}}
                </span>
                <span fxFlex></span>
                <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}"
                    *ngFor="let badge of item.badges">
                    {{ badge.value }}
                </span>
                <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
            </a>
            <!-- LEVEL 2 -->
            <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'"
                [class.abrir]="equipamento.idClassificacao + 13 + i  === idMenuActivo">
                <li *ngFor="let itemLvL2 of item.sub" appDropdownLink>
                    <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                        <span fxFlex></span>
                        <mat-icon class="menu-caret">
                            keyboard_arrow_right
                        </mat-icon>
                    </a>
                    <ng-container
                        *ngFor="let nat of naturezasActivas(getEquipamento(equipamento.idClassificacao)?.naturezasAdicionadas)">
                        <a *ngIf="itemLvL2.state === 'lista'" class="item-name lvl2"
                            routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state + '/' + equipamento.idClassificacao + '/naturezas/' + nat.idNatureza}}">
                            {{nat.natureza?.nomeP}}
                        </a>
                    </ng-container>
                    <!-- LEVEL 3 -->
                    <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                            <a routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL3.state + '/' + equipamento.idClassificacao}}"
                                appDropdownToggle matRipple>
                                <span class="item-name lvl3">
                                    {{itemLvL3.name.replace('Equipamentos', equipamento.menu) | translate}}
                                </span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </li>
</ng-container>

<ng-container
    *ngIf="item.code === codigoMenuArtigos && (EmDesenvolvimento || !item.desenvolvimento) && usaMenuArtigo && listaMenuArtigo">
    <ng-container *ngFor="let artigo of listaMenuArtigo; let i = index">
        <li class="borda-menu open" appDropdownLink
        routerLinkActive="open"
        [ngClass]="{'open': item.code === 'ARTIGO' && linkUrlRota && linkUrlRota==='artigos' && artigo.idClassificacao === +idClassRota}"
        [routerLinkActiveOptions]="{exact: true}">
            <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon' && (artigo.idClassificacao !== 7 && artigo.idClassificacao !== 6)"
                class="lvl1">
                <!-- DropDown -->
                <a *ngIf="item.type === 'dropDown'" appDropdownToggle matRipple
                    (click)="activarDropDown(artigo.idClassificacao + 10 + i)">
                    <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                    <span class="item-name lvl1" [class.activo]="artigo.idClassificacao + 10 + i === idMenuActivo">
                        {{artigo.menu | translate}} </span>
                    <span fxFlex></span>
                    <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}"
                        *ngFor="let badge of item.badges">{{
                        badge.value }}</span>
                    <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                </a>
                <!-- LEVEL 2 -->
                <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'"
                    [class.abrir]="artigo.idClassificacao + 10 + i  == idMenuActivo">
                    <li *ngFor="let itemLvL2 of item.sub" appDropdownLink>
                        <a routerLink="/artigos/lista/{{artigo.idClassificacao}}" appDropdownToggle
                            *ngIf="itemLvL2.type !== 'dropDown' && itemLvL2.name === 'Artigos'" matRipple>
                            <span class="item-name lvl2">{{artigo.menu | translate}}</span>
                            <span fxFlex></span>
                        </a>

                        <a routerLink="/artigos/lista/6" appDropdownToggle
                        *ngIf="vendaConfig?.usaAdministrativos && getArtigo(6)?.activo && getArtigo(artigo.idClassificacao)?.codNatureza === 'S' && itemLvL2.type !== 'dropDown' && itemLvL2.name === 'Administrativos'"
                        matRipple>
                            <span class="item-name lvl2">{{getArtigo(6)?.classificacaoP | translate}}</span>
                            <span fxFlex></span>
                        </a>


                        <a routerLink="/{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{artigo.idClassificacao}}"
                            appDropdownToggle
                            *ngIf="itemLvL2.type !== 'dropDown' && getArtigo(artigo.idClassificacao)?.eveUsaInscricoes && itemLvL2.name === 'Inscritos'"
                            matRipple>
                            <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                            <span fxFlex></span>
                        </a>

                        <a routerLink="/{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{artigo.idClassificacao}}"
                            appDropdownToggle
                            *ngIf="itemLvL2.type !== 'dropDown' && getArtigo(artigo.idClassificacao)?.eventosMultiplasRealizacoes && getArtigo(artigo.idClassificacao)?.codNatureza === 'E' &&  itemLvL2.name=='Realizações'"
                            matRipple>
                            <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                            <span fxFlex></span>
                        </a>

                        <a routerLink="/{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{artigo.idClassificacao}}"
                            appDropdownToggle
                            *ngIf="itemLvL2.type !== 'dropDown' && getArtigo(artigo.idClassificacao)?.eveUsaInscricoes && itemLvL2.name === 'Inscrições'"
                            matRipple>
                            <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                            <span fxFlex></span>
                        </a>

                        <a routerLink="/{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{artigo.idClassificacao}}"
                            appDropdownToggle
                            *ngIf="itemLvL2.type !== 'dropDown' && getArtigo(artigo.idClassificacao)?.codNatureza === 'E' &&  itemLvL2.name=='Pagamentos'"
                            matRipple>
                            <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                            <span fxFlex></span>
                        </a>

                        <a routerLink="/{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{artigo.idClassificacao}}"
                            appDropdownToggle
                            *ngIf="itemLvL2.type !== 'dropDown' && getArtigo(artigo.idClassificacao)?.idOpcModulos > 1 && itemLvL2.name === 'Módulos'"
                            matRipple>
                            <span class="item-name lvl2">{{getArtigo(artigo.idClassificacao)?.nomeModuloP |
                                translate}}</span>
                            <span fxFlex></span>
                        </a>

                        <a routerLink="/{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}/{{artigo.idClassificacao}}"
                            appDropdownToggle
                            *ngIf="itemLvL2.type !== 'dropDown' && getArtigo(artigo.idClassificacao)?.usaTurmas && itemLvL2.name === 'Turmas'"
                            matRipple>
                            <span class="item-name lvl2">{{getArtigo(artigo.idClassificacao)?.nomeTurmas |
                                translate}}</span>
                            <span fxFlex></span>
                        </a>

                        <a routerLink="/artigos/armazens/1" appDropdownToggle
                            *ngIf="itemLvL2.type !== 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) &&
                        getArtigo(artigo.idClassificacao)?.codNatureza === 'P' &&
                        getArtigo(artigo.idClassificacao)?.idOpcGereStocks !== 1 && itemLvL2.name === 'Armazéns' && gereStock" matRipple>
                            <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                            <span fxFlex></span>
                        </a>

                        <a *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) &&
                        getArtigo(artigo.idClassificacao)?.codNatureza === 'P' &&
                        getArtigo(artigo.idClassificacao)?.idOpcGereStocks !== 1 && itemLvL2.name === 'Inventário' && gereStock"
                            appDropdownToggle matRipple>
                            <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                            <span fxFlex></span>
                            <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                        </a>

                        <a *ngIf="itemLvL2.type === 'dropDown' &&
                                        (EmDesenvolvimento || !itemLvL2.desenvolvimento) &&
                                        getArtigo(artigo.idClassificacao)?.codNatureza === 'P' &&
                                        getArtigo(artigo.idClassificacao)?.idOpcGereStocks !== 1 &&
                                        gereStock &&
                                        itemLvL2.name === 'Stocks'; else SubMemuInventario" appDropdownToggle matRipple>
                            <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                            <span fxFlex></span>
                            <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                        </a>

                        <a appDropdownToggle
                        *ngIf="EmDesenvolvimento && getArtigo(6)?.activo && getArtigo(artigo.idClassificacao)?.codNatureza === 'S' && itemLvL2.type === 'dropDown' && itemLvL2.name === 'Administrativo'"
                        matRipple>
                            <span class="item-name lvl2">{{getArtigo(6)?.classificacaoP | translate}}</span>
                            <span fxFlex></span>
                            <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                        </a>

                        <ng-template #SubMemuInventario>
                            <a appDropdownToggle matRipple
                                *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento) &&
                                            itemLvL2.name !== 'Inventário' && itemLvL2.name !== 'Stocks' && itemLvL2.name !== 'Renováveis' && itemLvL2.name !== 'Administrativo'">
                                <span class="item-name lvl2">{{replaceConf(itemLvL2.name, getArtigo(artigo.idClassificacao)) | translate}}</span>
                                <span fxFlex></span>
                                <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                            </a>
                        </ng-template>

                        <!-- LEVEL 3 -->
                        <ul class="submenu lvl3" appDropdown
                            *ngIf="itemLvL2.type === 'dropDown' && (EmDesenvolvimento || !itemLvL2.desenvolvimento)">
                            <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                                <div
                                    *ngIf="getArtigo(artigo.idClassificacao)?.idOpcCategoria !== 1 && (EmDesenvolvimento || !itemLvL3.desenvolvimento) && itemLvL3.name === 'Categorias'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>

                                <a routerLink="/artigos/lista/6" appDropdownToggle
                                *ngIf="itemLvL3.type !== 'dropDown' && itemLvL3.name === 'Serviços'"
                                matRipple>
                                    <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    <span fxFlex></span>
                                </a>

                                <div *ngIf="getArtigo(artigo.idClassificacao)?.codNatureza === 'P' &&
                                        getArtigo(artigo.idClassificacao)?.idOpcGereStocks !== 1 &&
                                        gereStock && (EmDesenvolvimento || !itemLvL3.desenvolvimento) &&
                                        itemLvL3.name === 'Movimentos de Stock'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="getArtigo(artigo.idClassificacao)?.idOpcTipo !== 1 && (EmDesenvolvimento || !itemLvL3.desenvolvimento) && itemLvL3.name === 'Famílias'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">
                                            {{getArtigo(artigo.idClassificacao)?.nomeTipoP ?? 'Tipos' | translate}}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="getArtigo(artigo.idClassificacao)?.codNatureza === 'P' && (EmDesenvolvimento || !itemLvL3.desenvolvimento) && itemLvL3.name === 'Marca e Modelos'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="getArtigo(artigo.idClassificacao)?.codNatureza === 'E'  && getArtigo(artigo.idClassificacao)?.idOpcLocaisRealizacao !== 1 && itemLvL3.name === 'Locais'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="getArtigo(artigo.idClassificacao)?.eveUsaInscricoes  && itemLvL3.name === 'Tipos de Arquivos das inscrições'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="getArtigo(artigo.idClassificacao)?.codNatureza==='E'  && itemLvL3.name === 'E-mails'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="getArtigo(artigo.idClassificacao)?.codNatureza!=='E'  && itemLvL3.name === 'E-mails Automáticos'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="getArtigo(artigo.idClassificacao)?.usaTurmas && getArtigo(artigo.idClassificacao)?.codNatureza==='E' && itemLvL3.name === 'Turmas'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{getArtigo(artigo.idClassificacao)?.nomeTurmas
                                            | translate}}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="getArtigo(artigo.idClassificacao)?.codNatureza === 'E' && itemLvL3.name === 'Tipos de Colaboração'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>

                                <div *ngIf="itemLvL3.name === 'Estados'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}
                                            de {{ getArtigo(artigo.idClassificacao)?.classificacaoP
                                            }}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="getArtigo(artigo.idClassificacao)?.codNatureza === 'E' && itemLvL3.name === 'Tipos de Certificados'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="getArtigo(artigo.idClassificacao)?.usaRenovaveis && itemLvL3.name === 'Ciclos de Renovação'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="itemLvL3.name !== 'Categorias' && (EmDesenvolvimento || !itemLvL3.desenvolvimento) && itemLvL3.name !== 'Famílias' && itemLvL3.name !== 'Marca e Modelos'
                                                    && itemLvL3.name !== 'Locais' && itemLvL3.name !==  'Tipos de Arquivos das inscrições' && itemLvL3.name !== 'E-mails' && itemLvL3.name !== 'E-mails Automáticos' && itemLvL3.name !== 'Movimentos de Stock' && itemLvL3.name !== 'Renováveis'
                                                    && itemLvL3.name !== 'Turmas' && itemLvL3.name !== 'Tipos de Colaboração' && itemLvL3.name !== 'Estados' && itemLvL3.name !== 'Tipos de Certificados' && itemLvL3.name !== 'Ciclos de Renovação' && itemLvL3.name !== 'Serviços'">
                                    <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + artigo.idClassificacao}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </li>

                    <li appDropdownLink routerLinkActive="open" *ngIf="confAdm.type === 'dropDown' && vendaConfig?.usaAdministrativos && getArtigo(6)?.activo && getArtigo(artigo.idClassificacao)?.codNatureza === 'S'">
                        <a appDropdownToggle matRipple>
                            <span class="item-name lvl2">{{ confAdm.name.replace('{ART}', getArtigo(6)?.classificacaoP) | translate}}</span>
                            <span fxFlex></span>
                            <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                        </a>
                        <ul class="submenu lvl3" appDropdown>
                            <li *ngFor="let itemLvL3 of confAdm.sub" appDropdownLink routerLinkActive="open">
                                <div
                                    *ngIf="getArtigo(6)?.idOpcCategoria !== 1 && (EmDesenvolvimento || !itemLvL3.desenvolvimento) && itemLvL3.name === 'Categorias'">
                                    <a routerLink="{{'/artigos/' + itemLvL3.state + '/6'}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="getArtigo(6)?.idOpcTipo !== 1 && (EmDesenvolvimento || !itemLvL3.desenvolvimento) && itemLvL3.name === 'Famílias'">
                                    <a routerLink="{{'/artigos/' + itemLvL3.state + '/6'}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">
                                            {{getArtigo(6)?.nomeTipoP ?? 'Tipos' | translate}}</span>
                                    </a>
                                </div>

                                <div *ngIf="itemLvL3.name === 'Estados'">
                                    <a routerLink="{{'/artigos/' + itemLvL3.state + '/6'}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}
                                            de {{ getArtigo(6)?.classificacaoP
                                            }}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="getArtigo(6)?.usaRenovaveis && itemLvL3.name === 'Ciclos de Renovação'">
                                    <a routerLink="{{'/artigos/' + itemLvL3.state + '/6'}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>

                                <div
                                    *ngIf="itemLvL3.name !== 'Categorias' && (EmDesenvolvimento || !itemLvL3.desenvolvimento) && itemLvL3.name !== 'Famílias' && itemLvL3.name !== 'Marca e Modelos'
                                                    && itemLvL3.name !== 'Locais' && itemLvL3.name !==  'Tipos de Arquivos das inscrições' && itemLvL3.name !== 'E-mails' && itemLvL3.name !== 'E-mails Automáticos' && itemLvL3.name !== 'Movimentos de Stock' && itemLvL3.name !== 'Renováveis'
                                                    && itemLvL3.name !== 'Turmas' && itemLvL3.name !== 'Tipos de Colaboração' && itemLvL3.name !== 'Estados' && itemLvL3.name !== 'Tipos de Certificados' && itemLvL3.name !== 'Ciclos de Renovação'">
                                    <a routerLink="{{'/artigos/' + itemLvL3.state + '/6'}}"
                                        appDropdownToggle matRipple>
                                        <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </li>
    </ng-container>
</ng-container>

<ng-container *ngIf="item.code === codigoMenuProcessos && listamenu.indexOf(item.code) !== -1 && listaMenuProc">
    <li class="borda-menu" [class.open]="rotaActual==='/processos/lista/' + processo.idClassificacao"
        [ngClass]="{'open': item.code === 'PROCES' && linkUrlRota && linkUrlRota==='processos' && processo.idClassificacao === +idClassRota}"
        *ngFor="let processo of listaMenuProc; let i = index" appDropdownLink routerLinkActive="open"
        [routerLinkActiveOptions]="{exact: true}">

        <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
            <!-- DropDown -->
            <a *ngIf="item.type === 'dropDown'" appDropdownToggle matRipple
                (click)="activarDropDown(processo?.idClassificacao + 143 + i)">
                <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                <span class="item-name lvl1" [class.activo]="(processo.idClassificacao + 143 + i) === idMenuActivo">
                    {{processo.menu | translate}}</span>
                <span fxFlex></span>
                <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
            </a>

            <a routerLink="/processos/lista/2" appDropdownToggle matRipple *ngIf="item.type === 'link'">
                <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
                <span class="item-name lvl1"> {{processo.menu | translate}}</span>
                <span fxFlex></span>
            </a>

            <!-- LEVEL 2 -->
            <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'"
                [class.abrir]="(processo.idClassificacao + 143 + i) == idMenuActivo">
                <li *ngFor="let itemLvL2 of item.sub" appDropdownLink>
                    <a routerLink="/processos/lista/{{processo.idClassificacao}}" appDropdownToggle
                        *ngIf="itemLvL2.type !== 'dropDown'" matRipple>
                        <span class="item-name lvl2">{{getProcessos(processo.idClassificacao)?.classificacaoP | translate}}</span>
                        <span fxFlex></span>
                    </a>

                    <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
                        <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
                        <span fxFlex></span>
                        <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                    </a>

                    <!-- LEVEL 3 -->
                    <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
                        <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                            <div
                                *ngIf="getProcessos(processo.idClassificacao)?.idOPcTipoProcesso !== 1 && itemLvL3.name === 'Tipos'">
                                <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + processo.idClassificacao}}"
                                    appDropdownToggle matRipple>
                                    <span class="item-name lvl3">
                                        {{getProcessos(processo.idClassificacao)?.nomeTipoP |
                                        translate}}
                                    </span>
                                </a>
                            </div>

                            <div
                                *ngIf="getProcessos(processo.idClassificacao)?.idOpcCategorias !== 1 && itemLvL3.name === 'CategoriasPro'">
                                <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + processo.idClassificacao}}"
                                    appDropdownToggle matRipple>
                                    <span class="item-name lvl3">
                                        {{getProcessos(processo.idClassificacao)?.nomeCategoriaP}}
                                    </span>
                                </a>
                            </div>

                            <div
                                *ngIf="getProcessos(processo.idClassificacao)?.usaTarefas && itemLvL3.name === 'TiposTarefa'">
                                <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + processo.idClassificacao}}"
                                    appDropdownToggle matRipple>
                                    <span class="item-name lvl3">Tipo
                                        {{getProcessos(processo.idClassificacao).nomeTarefasP |
                                        translate}}</span>
                                </a>
                            </div>

                            <div
                                *ngIf="getProcessos(processo.idClassificacao)?.usaTarefas && itemLvL3.name === 'EstadosTarefa'">
                                <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + processo.idClassificacao}}"
                                    appDropdownToggle matRipple>
                                    <span class="item-name lvl3">Estados
                                        {{getProcessos(processo.idClassificacao).nomeTarefasP |
                                        translate}}</span>
                                </a>
                            </div>

                            <div
                                *ngIf="getProcessos(processo.idClassificacao)?.usaTarefas && itemLvL3.name === 'Atividades'">
                                <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' +processo.idClassificacao}}"
                                    appDropdownToggle matRipple>
                                    <span class="item-name lvl3">{{getProcessos(processo.idClassificacao).nomeTarefasP
                                        |
                                        translate}}</span>
                                </a>
                            </div>

                            <div
                                *ngIf="itemLvL3.name !== 'Tipos' && itemLvL3.name !== 'EstadosTarefa' && itemLvL3.name !== 'Atividades' && itemLvL3.name !== 'CategoriasPro' && itemLvL3.name !== 'TiposTarefa'">
                                <a routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state + '/' + processo.idClassificacao}}"
                                    appDropdownToggle matRipple>
                                    <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </li>
</ng-container>

</ng-template>

</ng-container>
<li><br><br><br></li>
</ul>
</div>
