import {AbstractControl, FormArray, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Util} from '../../generico/util';
import moment from 'moment';

export class CustomValidators {
    static greaterThanDate(startControl: AbstractControl): ValidatorFn {
        return (endControl: AbstractControl): ValidationErrors | null => {
            if (!startControl.value || !endControl.value) {
                return null;
            }
            const startDate: Date = moment.isMoment(startControl.value) ? startControl.value.toDate() : new Date(startControl.value);
            const endDate: Date = moment.isMoment(endControl.value) ? endControl.value.toDate() : new Date(endControl.value);
            if (startDate > endDate) {
                return {greaterThan: true};
            }
            return null;
        };
    }

    static lesserThanDate(startControl: AbstractControl): ValidatorFn {
        return (endControl: AbstractControl): ValidationErrors | null => {
            if (!startControl.value || !endControl.value) {
                return null;
            }
            const startDate: Date = moment.isMoment(startControl.value) ? startControl.value.toDate() : new Date(startControl.value);
            const endDate: Date = moment.isMoment(endControl.value) ? endControl.value.toDate() : new Date(endControl.value);
            if (startDate < endDate) {
                return {lesserThan: true};
            }
            return null;
        };
    }

    static greaterThan(startControl: AbstractControl, integer: boolean = false): ValidatorFn {
        return (endControl: AbstractControl): ValidationErrors | null => {
            if (startControl.value !== 0 && endControl.value !== 0 && (!startControl.value || !endControl.value)) {
                return null;
            }
            const start: number = integer ? parseInt(startControl.value, 10) : Util.convertToFloat(startControl.value);
            const end: number = integer ? parseInt(endControl.value, 10) : Util.convertToFloat(endControl.value);
            if (end > start) {
                return {greaterThan: {max: start, value: end}};
            }
            return null;
        };
    }

    static lesserThan(startControl: AbstractControl, integer: boolean = false): ValidatorFn {
        return (endControl: AbstractControl): ValidationErrors | null => {
            if (startControl.value !== 0 && endControl.value !== 0 && (!startControl.value || !endControl.value)) {
                return null;
            }
            const start: number = integer ? parseInt(startControl.value, 10) : Util.convertToFloat(startControl.value);
            const end: number = integer ? parseInt(endControl.value, 10) : Util.convertToFloat(endControl.value);
            if (start > end) {
                return {lesserThan: {min: start, value: end}};
            }
            return null;
        };
    }

    static greaterThanEqual(startControl: AbstractControl, integer: boolean = false): ValidatorFn {
        return (endControl: AbstractControl): ValidationErrors | null => {
            if (!startControl.value || !endControl.value) {
                return null;
            }
            const start: number = integer ? parseInt(startControl.value, 10) : Util.convertToFloat(startControl.value);
            const end: number = integer ? parseInt(endControl.value, 10) : Util.convertToFloat(endControl.value);
            if (end >= start) {
                return {greaterThanEqual: {max: startControl.value, value: endControl.value}};
            }
            return null;
        };
    }

    static lesserThanEqual(startControl: AbstractControl, integer: boolean = false): ValidatorFn {
        return (endControl: AbstractControl): ValidationErrors | null => {
            if (!startControl.value || !endControl.value) {
                return null;
            }
            const start: number = integer ? parseInt(startControl.value, 10) : Util.convertToFloat(startControl.value);
            const end: number = integer ? parseInt(endControl.value, 10) : Util.convertToFloat(endControl.value);
            if (start >= end) {
                return {lesserThanEqual: {min: startControl.value, value: endControl.value}};
            }
            return null;
        };
    }

    static notEmpty(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const formArray = control as FormArray;
            if (!formArray || formArray.length === 0) {
                return {empty: true};
            }
            return null;
        };
    }

    static notEmptyArray(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const array = control.value;
            if (!Array.isArray(array) || array?.length === 0) {
                return {empty: true};
            }
            return null;
        };
    }

    static greaterThanZero(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = Util.convertToFloat(control.value);
            if (isNaN(value) || value <= 0) {
                return {greaterThanZero: true};
            }
            return null;
        };
    }

    static destinatario(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value) {
                return null;
            }
            const regex = new RegExp(String.raw`^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$`);
            return value.split(/[,;]/).every(email => regex.test(email.trim())) ? null : {invalid: true};
        };
    }
}
