import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/shared/services/aplicacao-service/login.service';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { Utilizadores } from 'app/shared/models/aplicacao/utilizadores';
import { menuPOS } from 'app/views/pos/pos.deb/pos.deb';
import { PosUtilityService } from 'app/views/pos/pos-services/pos-utility.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'pos-navegacao',
  template: `
  <div class="bg-white text-white p-1 mat-elevation-z2" style="height: 55px">
    <div class="px-16" fxLayout="row" fxLayoutAlign="space-between">
      <div class="w-150px">
        <img src="assets/images/logo/Kiami.svg" alt="Kiami">
      </div>
      <div class="flex flex-y-center" >
        <div *ngIf="(posUtilityService?.terminalEmUso$ | async)?.nomeTerminal" class="text-primary px-16 py-1 pos-round-full">
          <h3 class="mb-0" >{{(posUtilityService?.terminalEmUso$ | async)?.nomeTerminal}}</h3>
        </div>
      </div>
      <div class="flex items-center">
        <span class="text-primary px-10 font-weight-bold">{{(posUtilityService?.terminalOperator$ | async)?.nome}}</span>
        <button (click)="openFullScreen()" class="card-control bg-menu pos-btn-icon mr-1" mat-button >
          <mat-icon class="text-primario">{{fullScreeOpen ? 'close_fullscreen': 'fit_screen'}} </mat-icon>
        </button>
        
        <button class="card-control bg-menu pos-btn-icon" mat-button [matMenuTriggerFor]="menuCardPOS">
          <mat-icon class="text-primario">more_vert</mat-icon>
        </button>
        <mat-menu #menuCardPOS="matMenu">
         <ng-container *ngFor="let item of menuPOS">
           <button  *ngIf="!item?.nav"  mat-menu-item (click)="authService.logout()">
             <mat-icon>{{item?.icon}}</mat-icon>
             <span>{{item?.titulo}}</span>
           </button>
           <button  *ngIf="item.nav" mat-menu-item (click)="posUtilityService.clearLocalTerminal()" [routerLink]="[item.rota]">
             <mat-icon>{{item.icon}}</mat-icon>
             <span>{{item.titulo}}</span>
           </button>
         </ng-container>
        </mat-menu>
      </div>
    </div>
  </div>
  `
}) export class PosNavegacaoComponent implements OnInit {
  fullScreeOpen: boolean = false;
  menuPOS: any[] = menuPOS;
  terminalActivo: any;
  user: Utilizadores;
 constructor(
   public posUtilityService: PosUtilityService,
   public authService: LoginService,
   public localStore: LocalStoreService) {
 }

  ngOnInit() {
      this.terminalActivo = JSON.parse(this.posUtilityService.getLocalTerminal());
      this.terminalActivo = JSON.parse(this.terminalActivo);
      this.posUtilityService.terminalEmUso$.next(this.terminalActivo);
      this.posUtilityService.getPOSOperatorFromLocal();
      this.posUtilityService.terminalOperator$.subscribe((value)=> {
      })
  }

  openFullScreen() {
    const body = document.querySelector('body');
    if (body) {
      if (!this.fullScreeOpen) {
        this.fullScreeOpen = true;
      this.posUtilityService.openFullScreen(true);
      } else {
        this.fullScreeOpen = false;
      this.posUtilityService.openFullScreen(false);
      }
    }
  }

}
