import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'toNormalCase'
})
export class StrNormalCasePipe implements PipeTransform {
constructor(protected sanitizer: DomSanitizer) {
}
 public transform(valor: any): any {
    if (valor) {
        const wholeStr = valor.toLowerCase();
        return wholeStr.substring(0, 1).toUpperCase() + wholeStr.substring(1);
    }
  }

}
