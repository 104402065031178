import {Injectable} from '@angular/core';
import {FormControl} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class DataIntervalService {

    private selData;
    private valido: boolean;
    private valorData: string;
    private digitouValorInvalido: boolean;

    constructor() {
        this.digitouValorInvalido = false;
        this.valido = true;
        this.valorData = '';
    }

    public getSetData() {
        return this.selData;
    }


    public setSetData(inicio: string | null, fim: string | null) {
        this.selData = {
            inicio: inicio,
            fim: fim
        };
    }

    public getValido(): boolean {
        return this.valido;
    }

    public setValido(valido: boolean): void {
        this.valido = valido;
    }

    public getValorData(): string {
        return this.valorData;
    }

    public setValorData(valorData: string): void {
        this.valorData = valorData;
    }

    public getDigitouValorInvalido(): boolean {
        return this.digitouValorInvalido;
    }

    public setDigitouValorInvalido(digitouValorInvalido: boolean): void {
        this.digitouValorInvalido = digitouValorInvalido;
    }


    public validarCaracter(evento: any) {
        let charCode: number = evento.charCode;
        if (!(
            (charCode >= 47 && charCode <= 57)
            || charCode === 45
            || charCode === 32 || charCode === 8)) {
            this.digitouValorInvalido = true;
        } else {
            this.digitouValorInvalido = false;

        }
    }

    // Ao alterar o valor do Input
    public validarData(evento: any, formControl: FormControl, idInput: string) {
        let valorAux: string = evento.target.value;
        if (this.digitouValorInvalido) {

            let inData: any = document.getElementById(idInput);
            inData.value = this.valorData + '';
        } else {
            this.valorData = evento.target.value;
        }

        this.digitouValorInvalido = false;

        let regexp = RegExp('^[0-3]?[0-9]/[0-1]?[0-9]/[0-9]{4} - ' +
            '[0-3]?[0-9]/[0-1]?[0-9]/[0-9]{4}$');

        if (regexp.test(valorAux)) {

            let data: string = valorAux;

            let valorNumDia1: number = parseInt(data.split('-')[0].split('/')[0]);
            let valorNumDia2: number = parseInt(data.split('-')[1].split('/')[0]);

            let valorNumMes1: number = parseInt(data.split('-')[0].split('/')[1]);
            let valorNumMes2: number = parseInt(data.split('-')[1].split('/')[1]);

            let valorNumAno1: number = parseInt(data.split('-')[0].split('/')[2]);
            let valorNumAno2: number = parseInt(data.split('-')[1].split('/')[2]);

            let intervaloValido: boolean = this.validarIntervalo(valorNumDia1, valorNumMes1, valorNumAno1, valorNumDia2, valorNumMes2, valorNumAno2);

            let max1: number = this.maxDia(valorNumMes1, valorNumAno1);
            let max2: number = this.maxDia(valorNumMes2, valorNumAno2);

            if (intervaloValido && ((valorNumDia1 >= 1 && valorNumDia2 >= 1))
                && ((valorNumDia1 <= max1
                    && valorNumDia2 <= max2))
                && ((valorNumMes1 >= 1 && valorNumMes2 >= 1))
                && ((valorNumMes1 <= 12 && valorNumMes2 <= 12))
            ) {

                formControl.setErrors(null);

                this.valido = true;
            } else {

                formControl.setErrors({
                    intervaloDeDataIncorrecto: false, emitEvent: true
                });

                this.valido = false;
            }

        } else if (valorAux === '') {
            formControl.setErrors(null);
        } else {
            formControl.setErrors({
                intervaloDeDataIncorrecto: false, emitEvent: true
            });

            this.valido = false;
        }

        formControl.markAsTouched({onlySelf: true});
    }

    public validarIntervalo(dia1: number, mes1: number, ano1: number, dia2: number, mes2: number, ano2: number): boolean {
        return (ano2 > ano1) || (((ano2 === ano1) && mes2 > mes1) || (((ano2 === ano1) && (mes2 === mes1))
            && dia2 >= dia1));
    }

    // Dia mãximo do mês y ...
    public maxDia(mes: number, ano?: number): number {

        if (mes === 1 || mes === 3 || mes === 5 || mes === 7 || mes === 8
            || mes === 10 || mes === 12) {
            return 31;
        } else if (mes === 4 || mes === 6 || mes === 9 || mes === 11) {
            return 30;
        } else if (mes === 2) {

            // Prevenir Ano bissexto
            if (ano && ano % 4 === 0) {
                return 29;
            }

            return 28;
        }
        return 1;
    }

    public formatarStrParaDataISO(data: string[]): string {
        data.reverse();
        return data[0] + '/' + data[1] + '/' + data[2];
    }

    public reverteFormatarStrParaDataISO(data: string[]): string {
        data;
        return data[1] + '-' + data[0] + '-' + data[2];
    }

    public reverteFormatarStrParaDataISOPt(data: string[]): string {
        data;
        return data[2] + '-' + data[1] + '-' + data[0];
    }

    public reverteFormatarStrParaDataISOPtMom(data: string[]): string {
        data;
        return data[0] + '-' + data[1] + '-' + data[2];
    }


    public validarData1(evento: any, formControl: FormControl, idInput: string) {
        let valorAux: string = evento.target.value;

        if (this.digitouValorInvalido) {

            let inData: any = document.getElementById(idInput);
            inData.value = this.valorData + '';
        } else {
            this.valorData = evento.target.value;
        }

        this.digitouValorInvalido = false;

        let regexp = RegExp('^[0-3]?[0-9]/[0-1]?[0-9]/(19|[2-9][0-9]){1}[0-9]{2}$');
        // let regexp = RegExp('^[0-3]?[0-9]/[0-1]?[0-9]/[1-9][0-9][0-9]{2}$');

        if (regexp.test(valorAux)) {

            let data: string = valorAux;

            let valorNumDia1: number = parseInt(data.split('/')[0]);

            let valorNumMes1: number = parseInt(data.split('/')[1]);

            let valorNumAno1: number = parseInt(data.split('/')[2]);

            let max1: number = this.maxDia(valorNumMes1, valorNumAno1);

            if ((valorNumDia1 >= 1)
                && ((valorNumDia1 <= max1))
                && ((valorNumMes1 >= 1))
                && ((valorNumMes1 <= 12))
            ) {

                formControl.setErrors(null);

                this.valido = true;
            } else {

                formControl.setErrors({
                    DataIncorrecto: false, emitEvent: true
                });

                this.valido = false;
            }

        } else {
            formControl.setErrors({
                DataIncorrecto: false, emitEvent: true
            });

            this.valido = false;
        }

        formControl.markAsTouched({onlySelf: true});
    }

}
