import {Directive, TemplateRef} from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[section]'
})
export class SectionDirective {
    constructor(public template: TemplateRef<any>) {
    }
}
