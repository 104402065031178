import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Accao} from '../../models/ENUM';
import {Format} from '@directives/input-format.directive';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificacaoService} from '@services/notificacao/notificacao.service';
import {Util} from '../../models/generico/util';
import {CustomValidators} from '../../models/artigos/formacoes/custom-validators';
import {GaArquivoService} from '@services/gestao-arquivos/ga-arquivo.service';
import {GaTipo} from '../../models/gestao-arquivos/ga-tipo';
import {CampoValor, GaArquivo} from '../../models/gestao-arquivos/ga-arquivo';
import {DatePipe} from '@angular/common';
import {DataTypes} from '../../../views/gestao-arquivos/ga-tipo-campo/ga-tipo-campo.component';
import {LoginService} from '@services/aplicacao-service/login.service';

const FormNames = {
    id: 'id',
    idTipo: 'idTipo',
    nome: 'nome',
    unidadeOrganizativa: 'idUnidadeOrganizativa',
    tipo: 'tipo',
    dataCriacao: 'dataCriacao',
    anexos: 'anexos',
    campos: 'campos',
    anexosRemovidos: 'anexosRemovidos',
    armazem: 'idArmazem',
    idCriador: 'idCriador'
};

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'k-arquivo',
    templateUrl: './k-arquivo.component.html',
    styleUrls: ['./k-arquivo.component.scss'],
    providers: [DatePipe]
})
export class KArquivoComponent implements OnInit, OnDestroy {
    readonly formats = Format;
    readonly actions = Accao;
    readonly today = new Date();
    readonly types = DataTypes;
    loading = false;
    multiplosValores: CampoValor[] = [];
    valoresRemovidos: number[] = [];
    form: FormGroup = this.fb.group({
        [FormNames.id]: 0,
        [FormNames.idTipo]: null,
        [FormNames.nome]: '',
        [FormNames.unidadeOrganizativa]: null,
        [FormNames.armazem]: null,
        [FormNames.tipo]: [null, Validators.required],
        [FormNames.dataCriacao]: new Date(),
        [FormNames.anexosRemovidos]: [[]],
        [FormNames.idCriador]: 0,
        [FormNames.anexos]: this.fb.array([], CustomValidators.notEmpty()),
        [FormNames.campos]: this.fb.array([])
    });
    readonly formNames: { [key: string]: string } = FormNames;
    readonly formNameMap: { [key: string]: string } = {
        nome: 'Nome',
        tipo: 'Tipo de arquivo',
        anexos: 'Anexos',
        campos: 'Campos',
        valor: 'Valor'
    };
    readonly errorMap: { [key: string]: string } = {
        required: 'É obrigatório',
        max: 'Excedeu o valor mínimo',
        maxlength: 'Excedeu o comprimento máximo',
        empty: 'Adicione algum ficheiro',
        matDatepickerParse: 'Formato inválido',
        matDatepickerMin: 'Excedeu o valor mínimo da data'
    };
    anexosRemovidos = new Set<number>();
    private subscriptions: Subscription[] = [];

    constructor(
        private fb: FormBuilder,
        private _notificacao: NotificacaoService,
        private arquivoService: GaArquivoService,
        private datePipe: DatePipe,
        private loginService: LoginService,
        private modalRef: MatDialogRef<KArquivoComponent>,
        @Inject(MAT_DIALOG_DATA) public dados: any
    ) {
    }

    get f() {
        return this.form.controls;
    }

    get anexos() {
        return this.form.get(this.formNames.anexos) as FormArray;
    }

    get campos() {
        return this.form.get(this.formNames.campos) as FormArray;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    ngOnInit(): void {
        this.form.patchValue(this.dados.arquivo);
        this.f[FormNames.tipo]
            .patchValue(this.dados?.tiposArquivos.find(t => t.id === this.dados.arquivo?.idTipo) ?? this.dados.arquivo?.tipo);
        this.f[FormNames.idTipo].patchValue(this.f[FormNames.tipo].value?.id);
        this.f[FormNames.tipo].value?.campos?.forEach(campo => {
            const v = this.dados.arquivo.campos.find(a => a.idCampo === campo.id);
            this.campos.push(this.criarFbCampo({
                id: v?.id,
                idArquivo: v?.idArquivo,
                idCampo: v?.idCampo,
                valor: campo.insercaoMultipla ? null : v?.valor,
                campo
            }));
            if (v && campo.insercaoMultipla) {
                this.multiplosValores = [
                    ...this.multiplosValores,
                    ...this.dados.arquivo.campos.filter(c => c.idCampo === v.idCampo)
                ];
            }
        });
        this.f[FormNames.idCriador].patchValue(this.dados?.arquivo?.idCriador ?? parseInt(this.loginService.decodedToken.nameid, 10));
        this.dados.arquivo?.anexos?.forEach(a => this.anexos.push(this.novoFicheiro(a)));
    }

    removerValor(val: CampoValor) {
        const i = this.multiplosValores.indexOf(val);
        this.valoresRemovidos.push(val.id);
        if (i > -1) {
            this.multiplosValores.splice(i, 1);
        }
    }

    carregarFicheiros(e: Event) {
        const ficheiros: FileList = (e.currentTarget as HTMLInputElement).files as FileList;
        Array.from(ficheiros).forEach(file => {
            const fr = new FileReader();
            fr.readAsDataURL(file);
            fr.onload = () => {
                this.anexos.push(this.novoFicheiro({
                    conteudo: fr.result as string,
                    tamanho: file.size
                }));
            };
        });
    }

    remover(anexo: any, i: number) {
        this.anexos.removeAt(i);
        this.anexosRemovidos.add(anexo.id);
    }

    adicionarValor(campo: AbstractControl) {
        this.multiplosValores.push({
            id: 0,
            valor: campo.value.valor,
            idCampo: campo.value.idCampo,
            idArquivo: campo.value.idArquivo,
            campo: campo.value.campo,
        });
        campo.get('valor').reset();
    }

    download(arquivo: GaArquivo, anexo: any) {
        this.loading = true;
        const titulo = anexo.nome ||
            `Sem título-${this.datePipe.transform(arquivo.dataCriacao, 'dd/MM/yyyy HH:mm:ss')}`;
        if (!Util.isHttpRequest(anexo.conteudo)) {
            Util.downloadAnexo(anexo.conteudo, titulo);
            this.loading = false;
            return;
        }
        this.subscriptions.push(
            this.arquivoService.download(anexo.conteudo)
                .subscribe(res => {
                        const blobUrl = res.objecto;
                        Util.downloadAnexo(blobUrl, titulo);
                        this.loading = false;
                    },
                    error => {
                        console.error(error);
                        this._notificacao.notificar(error.error?.mensagem || 'Falha ao carregar o ficheiro');
                        this.loading = false;
                    })
        );
    }

    setTipo(tipo: GaTipo) {
        this.campos.clear();
        this.f[FormNames.idTipo].patchValue(tipo?.id);
        tipo?.campos?.forEach(campo => this.campos.push(this.criarFbCampo({
            id: 0,
            idArquivo: 0,
            idCampo: campo.id,
            campo,
        } as CampoValor)));
        this.f[FormNames.unidadeOrganizativa].patchValue(tipo?.unidadesOrganizativas?.length === 1 ?
            tipo?.unidadesOrganizativas[0]?.idUnidadeOrganizativa : null);
    }


    criarFbCampo(v: CampoValor) {
        return this.fb.group({
            id: v.id ?? 0,
            idCampo: v.idCampo ?? v?.campo?.id,
            idArquivo: v.idArquivo ?? 0,
            valor: (v.campo?.select ? v.valor : Util.parseValue(v.valor, v.campo?.codTipo)) ?? null,
            nome: v.campo?.nome,
            codTipo: v.campo?.codTipo,
            select: v.campo?.select,
            insercaoMultipla: v.campo?.insercaoMultipla,
            usaCodigo: v.campo?.usaCodigo,
            obrigatorio: v.campo?.obrigatorio,
            activo: v.campo?.activo,
            nomeP: v.campo?.nomeP,
            min: v.campo?.min,
            max: v.campo?.max,
            numMin: v.campo?.numMin,
            numMax: v.campo?.numMax,
        });
    }

    adicionarArquivos() {
        if (this.form.invalid) {
            this._notificacao.notificar(Util.getFormErrors(this.form, this.formNameMap, this.errorMap));
            return;
        }

        if (this.dados.parentId <= 0) {
            this.f[FormNames.id]
                .patchValue(this.f[FormNames.id].value > 0 ? this.f[FormNames.id].value : Math.random() % 10 + Date.now());
            this.modalRef.close(this.form);
            return;
        }

        this.loading = true;
        const req = {...this.form.value, idTipo: this.f[FormNames.tipo].value?.id, anexosRemovidos: [...this.anexosRemovidos]};
        this.subscriptions.push(
            this.arquivoService.postArquivoEspecifico(this.dados.area, this.dados.parentId, req).subscribe({
                next: res => {
                    this.loading = false;
                    this.anexos.clear();
                    this.campos.clear();
                    this.form.patchValue(res.objecto);
                    res.objecto.anexos.forEach(a => this.anexos.push(this.novoFicheiro(a)));
                    this.f[FormNames.tipo].value?.campos?.forEach(campo => {
                        const v = res.objecto.campos.find(a => a.idCampo === campo.id);
                        this.campos.push(this.criarFbCampo({
                            id: v?.id,
                            idArquivo: v?.idArquivo,
                            idCampo: v?.idCampo,
                            valor: campo.insercaoMultipla ? null : v?.valor,
                            campo
                        }));
                        if (v && campo.insercaoMultipla) {
                            this.multiplosValores = [
                                ...this.multiplosValores,
                                ...res.objecto.campos.filter(c => c.idCampo === v.idCampo)
                            ];
                        }
                    });
                    this.modalRef.close(this.form);
                },
                error: err => {
                    this.loading = false;
                    console.error(err);
                    this._notificacao.notificar(err.error?.mensagem || 'Falha inesperada ao gravar arquivo');
                }
            })
        );
    }

    novoFicheiro(anexo?: any) {
        return this.fb.group({
            id: anexo?.id ?? 0,
            idArquivo: anexo?.idArquivo ?? 0,
            conteudo: anexo?.conteudo ?? '',
            nome: anexo?.nome ?? '',
            tamanho: anexo?.tamanho ?? 0
        });
    }
}
