import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Aplicacao} from 'app/shared/models/aplicacao/aplicacao';
import {environment} from 'environments/environment';
import {EmailLog} from 'app/shared/models/aplicacao/emailLog';
import {Perfis} from 'app/shared/models/aplicacao/perfis';
import {Pais} from 'app/shared/models/recursos-humanos/pais';
import {Provincia} from 'app/shared/models/recursos-humanos/provincia';
import {filter} from 'rxjs/operators';
import {Resposta} from 'app/shared/models/resposta';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token')
    })
};

@Injectable({
    providedIn: 'root'
})
export class AplicacaoService {
    private aplicacao$ = new BehaviorSubject<Aplicacao>(null);
    baseUrlAplicacao: string;
    baseUrlCliente: string;
    InstalacaoUrl: string;
    gereStockBS: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
    gereStock$: Observable<boolean | null> = this.gereStockBS.asObservable();

    codPais: string;

    constructor(
        private http: HttpClient,
    ) {
        this.baseUrlAplicacao = `${environment.apiAplicacao}`;
        this.baseUrlCliente = `${environment.apiClientes}`;
        this.InstalacaoUrl = `${environment.instalacao}`;
        const gereStockJson = localStorage.getItem('gereStocks');
        const gereStock = gereStockJson !== null ? JSON.parse(gereStockJson) : null;
        this.gereStockBS = new BehaviorSubject<boolean | null>(gereStock);
        this.gereStock$ = this.gereStockBS.asObservable();
        this.codPais = localStorage.getItem('codPais') ? localStorage.getItem('codPais') : '';
        this.gereStock$.subscribe(s => localStorage.setItem('gereStocks', String(s)));

    }

    public getCodPais(): string {
        return this.codPais;
    }

    public getGereStocks() {
        return this.gereStockBS.value;
    }

    getInstalacao(nomeInstalacao: string): Observable<any> {
        const modelo = {NomeLinkInstalacao: nomeInstalacao};
        return this.http.post(this.InstalacaoUrl, modelo);
    }

    //#region  Área feita antes de 01-04-2021
    getLogotipoApp(): Observable<any> {
        return this.http.get(this.baseUrlAplicacao + 'aplicacao/logotipo', httpOptions);
    }

    getAplicacao(): Observable<Aplicacao> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/aplicacao', httpOptions)
            .pipe(
                filter((res: any) => {

                    // Emitir um Evento para definir Dados da aplicação
                    if (res) {

                    }

                    return true;
                })
            );
    }

    getAplicacaoGeral(): Observable<Aplicacao> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/aplicacao', httpOptions)
            .pipe();
    }

    getMeses(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/meses', httpOptions)
            .pipe();
    }

    getMenus(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/menus/1', httpOptions)
            .pipe();
    }

    getMoedas(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/moedas', httpOptions)
            .pipe();
    }

    getTiposEmpregos(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/TiposEmprego', httpOptions)
            .pipe();
    }

    getIdiomas(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/idiomas', httpOptions)
            .pipe();

    }

    getTodosIdiomas(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/todosIdiomas', httpOptions)
            .pipe();
    }

    getIdiomaAplicacao(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/idiomas/utilizadores', httpOptions)
            .pipe();
    }

    getProeficiencia(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/proeficienciaidiomas', httpOptions)
            .pipe();
    }

    gravarPermissoes(modelo: any) {
        return this.http
            .post(this.baseUrlAplicacao + 'permissoes', modelo, httpOptions)
            .pipe();
    }

    getPermissoes(): Observable<any> {
        return this.http.get(this.baseUrlAplicacao + 'permissoes', httpOptions);
    }


    getPermissoesTipos(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'permissoes/tipos', httpOptions)
            .pipe();
    }

    // ECARVALHO - Retirar
    getPermissoesAplicacao(idUtilizador: number): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'permissoes/' + idUtilizador, httpOptions)
            .pipe();
    }

    getPermissoesUtilizador(idUtilizador: number): Observable<any> {
        return this.http
            .get(
                this.baseUrlAplicacao + 'permissoes/utilizador/' + idUtilizador,
                httpOptions
            )
            .pipe();
    }

    getPaises(): Observable<Pais[]> {
        return this.http
            .get<Pais[]>(this.baseUrlAplicacao + 'aplicacao/paises/true', httpOptions)
            .pipe();
    }

    getEstados(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/estados', httpOptions)
            .pipe();
    }

    getProvincias(idPais: string): Observable<Provincia[]> {
        if (idPais && idPais.length > 0) {
            return this.http
                .get<Provincia[]>(this.baseUrlAplicacao + 'aplicacao/provincias/' + idPais, httpOptions)
                .pipe();
        } else {
            return null;
        }
    }

    getGrausAcademicos(): Observable<any> {

        return this.http
            .get(this.baseUrlAplicacao + 'Aplicacao/GrausAcademicos', httpOptions)
            .pipe();
    }

    getMunicipios(codPais: string, idProvincia: number) {
        if (codPais && codPais.length > 0 && idProvincia > 0) {
            return this.http
                .get<any>(
                    this.baseUrlAplicacao + 'aplicacao/municipios/' + codPais + '/' + idProvincia,
                    httpOptions
                )
                .pipe();
        } else {
            return null;
        }
    }

    getZonas(idMunicipio: number): Observable<any> {
        return this.http
            .get(
                this.baseUrlAplicacao + 'aplicacao/zonas/' + idMunicipio,
                httpOptions
            )
            .pipe();
    }

    getSexos(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/sexos', httpOptions)
            .pipe();
    }

    getEstadosCivis(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/estadoscivis', httpOptions)
            .pipe();
    }

    getCategoriasContactos(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/categoriascontactos', httpOptions)
            .pipe();
    }

    getTiposContactos(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/tiposcontactos', httpOptions)
            .pipe();
    }

    getOpcoesInsercao(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/opcoesinsercao', httpOptions)
            .pipe();
    }

    getUnidades(idTipo: number): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/unidadesmedidas/' + idTipo, httpOptions)
            .pipe();
    }

    getEmails(
        activo: boolean,
        disponivelEmails: boolean
    ): Observable<any> {
        return this.http
            .get(
                this.baseUrlAplicacao +
                'emails/emailenvio/' +
                activo +
                '/' +
                disponivelEmails,
                httpOptions
            )
            .pipe();
    }

    getLogs() {
        return this.http.get<Resposta<EmailLog[]>>(`${this.baseUrlAplicacao}emails/logs`, httpOptions);
    }

    getLog(id: number): Observable<EmailLog> {
        return this.http
            .get(this.baseUrlAplicacao + 'emails/emaillog/' + id, httpOptions)
            .pipe();
    }

    removerLog(modelo: any) {
        return this.http
            .post(this.baseUrlAplicacao + 'emails/emaillog/remover', modelo, httpOptions);
    }

    removerLogLote(logs: number[]) {
        return this.http.post<Resposta<any>>(`${this.baseUrlAplicacao}emails/log/remover`, logs);
    }

    getEmail(idEmail: number): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'emails/emailenvio/' + idEmail, httpOptions)
            .pipe();
    }

    salvarEmail(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlAplicacao + 'emails/emailenvio/salvar', modelo, httpOptions)
            .pipe();
    }

    alterarSenhaEmail(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlAplicacao + 'emails/emailenvio/alterarSenhaEmail', modelo, httpOptions)
            .pipe();
    }

    enviarEmail(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlAplicacao + 'emails/enviaremail', modelo, httpOptions)
            .pipe();
    }

    enviarEmailTeste(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlAplicacao + 'emails/teste', modelo, httpOptions)
            .pipe();
    }

    editarAplicacao(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlAplicacao + 'aplicacao/editarAplicacao/', modelo, httpOptions)
            .pipe();
    }

    getAppIcones(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'aplicacao/icones', httpOptions)
            .pipe();
    }

    //#endregion
    //#region  Ária de Permissões do Perfil

    getPerfis(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'permissoes/perfil', httpOptions)
            .pipe();
    }

    getPerfil(id: number): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'permissoes/perfil/' + id, httpOptions)
            .pipe();
    }

    getPerfisPermissoes(): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'permissoes/perfil/permissoes', httpOptions)
            .pipe();
    }

    getPerfilPermissoes(id: number): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'permissoes/perfil/permissoes/' + id, httpOptions)
            .pipe();
    }

    getPerfilUtilizador(id: number): Observable<any> {
        return this.http
            .get(this.baseUrlAplicacao + 'permissoes/perfil/utilizador/' + id, httpOptions)
            .pipe();
    }

    gravarPerfil(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlAplicacao + 'permissoes/perfil', modelo, httpOptions)
            .pipe();
    }

    eliminarPerfil(idPerfil: number): Observable<Perfis> {
        return this.http
            .post(this.baseUrlAplicacao + 'permissoes/perfil/eliminar', idPerfil, httpOptions)
            .pipe();
    }

    eliminarPermissaoPerfil(idPerfilPermissao: number): Observable<Perfis> {
        return this.http
            .post(this.baseUrlAplicacao + 'permissoes/perfil/permissao/eliminar', idPerfilPermissao, httpOptions)
            .pipe();
    }

    gravarPerfilPermissoes(modelo: any) {
        return this.http
            .post(this.baseUrlAplicacao + 'permissoes/perfil/gravarPermissoes', modelo, httpOptions)
            .pipe();
    }

    gravarPerfilUtilizador(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlAplicacao + 'permissoes/perfil/utilizador', modelo, httpOptions)
            .pipe();
    }

    getmetodosPagRec(): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/comercial/metodosPagRec', httpOptions)
            .pipe();
    }

    getCondicoesPagamento(): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/comercial/condicoespagamento', httpOptions)
            .pipe();
    }


    getAreasPermissoes() {
        return this.http.get<Resposta<any[]>>(this.baseUrlAplicacao + 'aplicacao/areas');
    }

    //#endregion
}
