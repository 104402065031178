<section class="position-relative">
    <span (click)="cancelar()" class="cursor-pointer btn-close-modal">
        <mat-icon>close</mat-icon>
    </span>
    <div class="modal-header pt-1 pb-1">
        <h2 class="modal-title"><button mat-raised-button class="btn-filter"  color="primary" (click)="exportarExcel()"> Exportar </button> Export lista</h2>
    </div>

   <mat-dialog-content>
       <div class="modal-body" id="TabArquivos">
           <div  class="flex flex-x-center flex-y-center mb-1 mt-1"><span style="width:50px; height: 50px; display: flex"><img [src]="empresa?.logotipo" width="100%" alt=""></span></div>
           <div style="border-bottom: 1px solid rgba(0,0,0, .4)"><p class="text-center">{{empresa.nomeEmpresa}}</p></div>
           <table
                   class="default-table  mb-24 "
                   style="width: 100%"
           >
               <thead class="font-weight-bold ">
               <tr>
                   <th><b>Código</b></th>
                   <th nowrap><b>Nome</b></th>
                   <th nowrap><b>E-mail</b></th>
                   <th nowrap><b>NIF</b></th>
                   <th nowrap><b>País</b></th>
                   <th><b>Província</b></th>
                   <th nowrap><b>Estado</b></th>

               </tr>
               </thead>
               <tbody class="mt-1" *ngIf="clientes.length">
               <tr *ngFor="let item of clientes">
                   <td class="">
                       {{item.codigo}}
                   </td>
                   <td nowrap>{{ item?.nome }}</td>
                   <td nowrap>{{ item?.email }}</td>

                   <td nowrap>{{ item?.nif }}</td>
                   <td nowrap>{{ item?.pais }}</td>
                   <td nowrap>{{ item?.provincia }}</td>
                   <td nowrap>
                       {{ item?.idEstado ? 'Activo':'Activo'}}
                   </td>
               </tr>
               </tbody>

           </table>
       </div>


   </mat-dialog-content>
</section>
