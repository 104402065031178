import {Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList} from '@angular/core';
import {KCardColumnComponent} from '@shared/components/k-card-column/k-card-column.component';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'k-card',
    templateUrl: './k-card.component.html',
    styleUrls: ['./k-card.component.scss']
})
export class KCardComponent implements OnInit {
    @Input() header = '';
    @Input() footer = '';
    @Input() linkDetalhes!: any[] | string;
    @Input() list: any[] = [];
    @Input() linkEdicao!: any[] | string;
    @Output() removeEvent = new EventEmitter<any>();
    @ContentChildren(KCardColumnComponent)
    public columns: QueryList<KCardColumnComponent>;


    constructor() {
    }

    ngOnInit(): void {
    }

}
