<mat-progress-bar *ngIf="activarLoader" mode="indeterminate"></mat-progress-bar>
<section class="position-relative">
    <div class="modal-header">
    <span mat-dialog-close class="cursor-pointer btn-close-modal">
      <mat-icon>close</mat-icon>
    </span>
        <div class="modal-title">
            <div class="bg-blue flex-x-btw">
                <h2 mat-dialog-title color="primary" [innerText]="modoAdicaoCambio ? 'Inserir câmbio' : 'Pesquisar câmbio'"></h2>
                <button class="btn-filter" (click)="adicionarNovoCambio()" style="margin-right: 5%;" mat-raised-button color="primary" [innerText]="modoAdicaoCambio ? 'Voltar' : 'Inserir câmbio'"></button>
            </div>
        </div>
    </div>

    <div class="modal-body p-0">
        <div class="p-16">
            <form *ngIf="modoAdicaoCambio" [formGroup]="formAdicionarCambio" (ngSubmit)="gravarCambio()" class="k-form-crud">
                <div class="pt-1 pb-32">
                    <div fxLayout="row wrap">
                        <!-- Estado-->
                        <div fxFlex="100" fxFlex.xl="15" fxFlex.lg="15" fxFlex.gt="50" fxFlex.xs="100" class="pr-1">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Moeda de </mat-label>
                                <mat-select (selectionChange)="resetarMP($event)" formControlName="IdMoedaLocal">
                                    <mat-option *ngFor="let moeda of filtrarMoeda(moedas)" [value]="moeda.id">
                                     {{moeda.codigo}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div
                                fxFlex="100"
                                fxFlex.xl="15"
                                fxFlex.lg="15"
                                fxFlex.gt-xs="50"
                                fxFlex.xs="100"
                                class="pr-1"
                        >
                            <mat-form-field
                                    class="full-width"
                                    appearance="outline"
                            >
                                <mat-label>Moeda para </mat-label>
                                <mat-select formControlName="IdMoedaPretendida">
                                    <mat-option *ngFor="let moeda of moedas"
                                                [value]="moeda.id"
                                                [disabled]="formCambio.get('IdMoedaLocal').value == moeda.id"
                                    >
                                                {{moeda.codigo}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div
                                fxFlex="100"
                                fxFlex.xl="15"
                                fxFlex.lg="15"
                                fxFlex.gt-xs="50"
                                fxFlex.xs="100"
                                class="pr-1"
                        >
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Taxa de Câmbio</mat-label>
                                <input
                                        type="text"
                                        matInput
                                        mask="separator.6"
                                        thousandSeparator="."
                                        decimalMarker=","
                                        formControlName="TaxaCambio"
                                        autocomplete="off"
                                        placeholder="Taxa de câmbio"

                                />
                            </mat-form-field>
                        </div>

                        <div
                                fxFlex="20"
                                fxFlex.xl="20"
                                fxFlex.lg="20"
                                fxFlex.gt-xs="50"
                                fxFlex.xs="100"
                                class="pr-1"

                        >
                            <mat-form-field class="full-width date-icon"  style="background-image: url('assets/images/icons/calendar.svg')"  appearance="outline">
                                <mat-label>Data C&acirc;mbio</mat-label>
                                <input matInput name="dataInicio"
                                       class="cursor-pointer"
                                       ngxDaterangepickerMd
                                       [timePicker]="true"
                                       [timePickerSeconds]="true"
                                       [timePickerIncrement]="5"
                                       [timePicker24Hour]="true"
                                       [singleDatePicker]="true"
                                       (change)="aoSelecData($event)"
                                       startKey="inicio"
                                       [locale]="{applyLabel: 'Aplicar', format: 'DD/MM/YYYY HH:mm:ss', firstDay: 1}"
                                       [maxDate]="dataMax"
                                       autocomplete="off"
                                       formControlName="dataCambio"/>

                            </mat-form-field>
                        </div>

                        <div
                                fxFlex="10"
                                fxFlex.xl="10"
                                fxFlex.lg="10"
                                fxFlex.gt-xs="50"
                                fxFlex.xs="100"
                                style="padding-top: 6px;"
                                class="pr-1"
                        >
                            <button mat-button class="bg-primary" type="submit" [class.btn-disable]="desactivar">Gravar</button>
                        </div>

                        <div
                                fxFlex="10"
                                fxFlex.xl="10"
                                fxFlex.lg="10"
                                fxFlex.gt-xs="50"
                                fxFlex.xs="100"
                                class="pr-1"
                                style="padding-top: 6px;"
                        >
                            <button mat-button class="bg-secondary" (click)="adicionarNovoCambio()" type="button">Cancelar </button>
                        </div>
                    </div>
                </div>
            </form>


            <form *ngIf="!modoAdicaoCambio" [formGroup]="formCambio" (ngSubmit)="pesquisarCambio()" class="k-form-crud">
                <div class="pt-1 pb-16">
                    <div fxLayout="row wrap">
                        <!-- Moeda Local -->
                        <div fxFlex="100" fxFlex.xl="10" fxFlex.lg="10" fxFlex.gt="10" fxFlex.xs="20" class="pr-1">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Moeda de</mat-label>
                                <mat-select matNativeControl
                                            formControlName="IdMoedaLocal">
                                    <mat-option *ngFor="let moeda of moedas" [value]="moeda.id" >
                                        {{moeda.codigo}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div  fxFlex="100"
                              fxFlex.xl="10"
                              fxFlex.lg="10"
                              fxFlex.gt-xs="20"
                              fxFlex.xs="100"
                              class="pr-1 pl-1">
                           <div class=" text-center pt-02">
                               <button mat-mini-fab class="box-shadow-0" color="primary" type="button" (click)="inverterCambio()">
                                   <mat-icon>sync_alt</mat-icon>
                               </button>
                           </div>
                        </div>

                        <div
                                fxFlex="100"
                                fxFlex.xl="10"
                                fxFlex.lg="10"
                                fxFlex.gt-xs="20"
                                fxFlex.xs="20"
                                class="pr-1"
                        >
                            <mat-form-field
                                    class="full-width"
                                    appearance="outline"
                            >
                                <mat-label>Moeda para </mat-label>
                                <mat-select matNativeControl formControlName="IdMoedaPretendida">
                                    <mat-option
                                                *ngFor="let moeda of moedas"
                                                [value]="moeda.id">{{ moeda.codigo }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>


                        <app-intervalo-data 
                        [selData1]="ultimos30Dias"
                        (setData)="setDates($event)"
                        (go)="pesquisarCambio()"
                        [placeholder]="'Data do câmbio (desde, até)'"
                        fxFlex="10" fxFlex.xl="20" fxFlex.lg="23" fxFlex.md="20" fxFlex.xs="25"></app-intervalo-data>

                        <!-- <div   fxFlex="20"   fxFlex.xl="15"  fxFlex.lg="15"  fxFlex.gt-xs="20"  fxFlex.xs="100" class="pr-1">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Validade Início</mat-label>
                                <input (change)="getData($event, 1)"  matInput name="DataInicio"
                                ngxDaterangepickerMd  [locale]="{applyLabel: 'OK', format: 'DD-MM-YYYY'}"
                                [singleDatePicker]="true"  formControlName="DataInicio"/>
                                <mat-icon class="cursor-pointer" matSuffix>event</mat-icon>
                            </mat-form-field>
                        </div>
                       
                        <div   fxFlex="20"   fxFlex.xl="15"  fxFlex.lg="15"  fxFlex.gt-xs="20"  fxFlex.xs="100" class="pr-1">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Validade Fim</mat-label>
                                <input (change)="getData($event, 2)" ngxDaterangepickerMd
                                [locale]="{applyLabel: 'OK', format: 'DD-MM-YYYY'}"
                                [singleDatePicker]="true" matInput name="DataFim" formControlName="DataFim"/>
                                <mat-icon class="cursor-pointer" matSuffix>event</mat-icon>
                            </mat-form-field>
                        </div> -->

                        <div    fxFlex="20"
                                fxFlex.xl="20"
                                fxFlex.lg="20"
                                fxFlex.gt-xs="50"
                                fxFlex.xs="100"
                                class="flex-xy-center">
                            <mat-slide-toggle color="primary" formControlName="IncluiTaxaInversa">Incluir taxas indirectas!</mat-slide-toggle>
                        </div>
                        <div
                                fxFlex="20"
                                fxFlex.xl="10"
                                fxFlex.lg="10"
                                fxFlex.gt-xs="50"
                                fxFlex.xs="100"
                                class="pr-1 flex-xy-center text-left"

                        >
                            <button mat-button class="bg-primary mr-05" type="submit">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>

                    </div>
                </div>
            </form>
        </div>
        <div class="pb-1">
            <mat-divider></mat-divider>
        </div>
        <div *ngIf="!modoAdicaoCambio">
            <div class="tab-container relative ">
                <ng-container>
                    <div>
                        <div class=" w-100 bg-light border-b">
                            <div class="flex w-100 flex-even txt-bold pb-05 border-b">

                                <div class="grow-1 w-20">Moeda local</div>
                                <div class="grow-1 w-20">Moeda pretendida</div>
                                <div class="grow-1 w-20">Taxa de c&acirc;mbio</div>
                                <div class="grow-1 w-20">Data in&iacute;cio</div>
                                <div class="grow-1 w-20">Data fim</div>

                            </div>
                            <div class="flex w-100 flex-even pt-03 pb-03 border-b" *ngIf="false">

                                <ng-container >
                                    <div class="grow-1 w-15"><input type="search" placeholder="filtrar código" (input)="filtrarValores($event, 2)" class="tb-filtro-input border"></div>
                                    <div class="grow-4 w-15"><input type="search" placeholder="filtrar " (input)="filtrarValores($event, 3)" class="tb-filtro-input border"></div>
                                    <div class="grow-2 w-15"><input type="search" placeholder="filtrar " (input)="filtrarValores($event, 4)" class="tb-filtro-input border"></div>
                                    <div class="grow-4 w-15"><input type="search" placeholder="filtrar " (input)="filtrarValores($event, 5)" class="tb-filtro-input border"></div>
                                    <div class="grow-1 w-15"></div>
                                    <div class="grow-1 w-10"></div>
                                    <div class="grow-1 w-15"></div>
                                </ng-container>

                            </div>
                        </div>
                        <div class="tab-body">
                            <div class="flex w-100 flex-even hvr-accent " *ngFor="let cambio of cambios" (click)="selecCambio(cambio)">
                                <div class="grow-1 border-b w-20 cursor-pointer hvr-accent ellipsis" ><span>{{cambio.codMoedaLocal}}</span></div>
                                <div class="grow-4 border-b w-20"><span>{{cambio.codMoedaPretendida}}</span></div>
                                <div class="grow-2 border-b w-20"><span>{{cambio.taxaCambio | number : '1.1-6'}} </span> </div>
                                <div class="grow-1 border-b w-20"><span>
                                    {{cambio.dataCambio | date: 'dd/MM/YYYY'}} -
                                    {{cambio.dataCambio | date: 'HH:mm'}}
                                </span></div>
                                <div class="grow-1 border-b w-20"><span>
                                    {{cambio.dataValidade | date: 'dd/MM/YYYY'}} -
                                    {{cambio.dataValidade | date: 'HH:mm'}}
                                </span></div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div>
                    <ng-template #semDados>
                    </ng-template>
                </div>
            </div>

        </div>
    </div>
</section>
