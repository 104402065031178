import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'someFn'
})
export class SomeFnPipe implements PipeTransform {

    transform(items: any[], predicate: (item: any, ...args: any[]) => boolean, ...args: any[]): boolean {
        if (!items || !predicate) {
            return false;
        }
        return items.some(item => predicate(item, ...args));
    }

}
