<mat-progress-bar mode="indeterminate" *ngIf="activarLoader"></mat-progress-bar>
<section class="position-relative">
    <div class="modal-header">
        <span mat-dialog-close class="cursor-pointer btn-close-modal">
            <mat-icon>close</mat-icon>
        </span>
        <div class="modal-title">
            <div class="bg-blue">
                <h2 mat-dialog-title color="primary">Pesquisar</h2>
            </div>
        </div>
    </div>

    <div class="modal-body p-0">
        <mat-card class="default mx-0 box-shadow-0  rounded-0 mt-0">
            <mat-card-content class="p-0">
                <mat-list class="compact-list mb-0 pt-0 pb-0">
                    <mat-list-item class="border-b mat-list-head">
                        <div fxLayout="row" fxFlex="100" class="w-100">
                            <div fxFlex="15">
                                <h6 class="m-0 mr-16 font-weight-bold">
                                    <strong class="text-primary">Entidade</strong>
                                </h6>
                            </div>
                            <div fxFlex="30">
                                <h6 class="m-0 mr-16 font-weight-bold">
                                    <strong class="text-primary">C&oacute;digo</strong>
                                </h6>
                            </div>
                            <div class="font-weight-bold " fxFlex="20">
                                <strong class="text-primary">Nome </strong>
                            </div>
                            <div class="font-weight-bold " fxFlex="20">
                                <strong class="text-primary">E-mail </strong>
                            </div>
                            <div class="font-weight-bold text-left" fxFlex="20">
                                <strong class="text-primary">NIF</strong>
                            </div>
                        </div>
                    </mat-list-item>
                    <mat-list-item class="border-b mat-list-head" style="background: rgba(0, 0, 0, 0.08);">
                        <div fxLayout="row" fxFlex="100" class="w-100">


                            <div fxFlex="14">
                                <input (input)="filtrarValores($event, 5)" type="search"
                                    class="tb-filtro-input rounded border" placeholder="filtrar entidade" />
                            </div>

                            <div fxFlex="30">
                                <input (input)="filtrarValores($event, 1)" type="search"
                                    class="tb-filtro-input rounded border" placeholder="filtrar código" />
                            </div>
                            <div class="font-weight-bold " fxFlex="20">
                                <input (input)="filtrarValores($event, 2)" type="search"
                                    class="tb-filtro-input rounded border" placeholder="filtrar nome" />
                            </div>
                            <div class="font-weight-bold " fxFlex="20">
                                <input (input)="filtrarValores($event, 6)" type="search"
                                    class="tb-filtro-input rounded border" placeholder="filtrar e-mail" />
                            </div>

                            <div class="font-weight-bold text-left" fxFlex="20">
                                <input (input)="filtrarValores($event, 3)" type="search"
                                    class="tb-filtro-input rounded border" placeholder="filtrar NIF" />
                            </div>
                        </div>
                    </mat-list-item>
                    <div style="height: 65vh; overflow-y: auto">
                        <ng-container *ngFor="let cliente of listaClientes; index as i">
                            <mat-list-item (click)="selecCodigo(cliente)" style="height: 40px" class="border-b pb-0">
                                <div fxLayout="row" class="w-100">
                                    <div fxFlex="15" class="pr-1 flex-y-center">
                                        <div>
                                            <span>{{cliente?.entidadeS}}</span>
                                        </div>
                                    </div>
                                    <div fxFlex="30" class="pr-1">
                                        <div>
                                            <span>{{cliente?.codigo}}</span>
                                        </div>
                                    </div>
                                    <div fxFlex="20" class="pr-1">
                                        <div>
                                            <span>{{cliente?.nome}}</span>
                                        </div>
                                    </div>
                                    <div fxFlex="20" class="pr-1">
                                        <div>
                                            <span>{{cliente?.email}}</span>
                                        </div>
                                    </div>
                                    <div fxFlex="20" class="flex-xy-center text-left">
                                        <span>{{cliente?.nif}}</span>
                                    </div>

                                </div>
                            </mat-list-item>
                        </ng-container>
                    </div>
                </mat-list>
            </mat-card-content>
        </mat-card>

        <div class="pb-16 pt-1 px-1 border-t">
            <div class="flex flex-x-btw">
                <button mat-dialog-close class="bg-secondary mr-1" mat-button>
                    Cancelar
                </button>
            </div>
        </div>
    </div>
</section>