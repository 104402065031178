import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'labelMunicipio'
})
export class LabelMunicipioPipe implements PipeTransform {

    public transform(valor: any, ...args): any {
        if (valor && typeof(valor.trim()) === 'string' && valor.trim().length > 0 && valor.toUpperCase() === 'AO') {
            return 'Município';
        } else {
            return 'Freguesia';
        }
    }

}
