import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientesService } from '../../../../shared/services/clientes-service/clientes.service';
import { horasEvento } from '../../../../shared/fake.db/data';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MoradaCliente } from '../../../../shared/models/clientes/morada-cliente';
import { formatDate } from '@angular/common';
import { NotificacaoService } from '../../../../shared/services/notificacao/notificacao.service';
import { Armazem } from 'app/shared/models/artigos/armazem';
import moment from 'moment';
import { CargaDescarga } from '../../../../shared/models/factura/carga-descarga';
interface Hora {
 id: number;
 valor: string;
}
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'modal-carga-descarga',
  template: `
  <ki-modal-base [tituloModal]="'Dados de carga e descarga'" [verBtnGravar]="!dados?.modoConsulta" [accao]="dados?.modoConsulta ? 'D': 'C'" (aoGravar)="fecharModal()">
    <form [formGroup]="formCarga" class="py-1">
      <div fxLayout="row wrap" class="k-form-crud full-width">
      </div>
      <div fxLayout="row wrap" class="k-form-crud full-width">
      <div fxFlex="100" class="px-05">
        <mat-card formGroupName="carga" class="px-1">
          <h3 class="text-primario"><span>Carga</span></h3>
          <div class="py-1">
            <div fxLayout="row wrap">
              <div fxFlex="15" fxFlex.xs="50" class="px-05 mb-05 disabilitar" [class.campo-inactivo]="true" >
                <mat-form-field class="full-width" appearance="outline"  >
                  <mat-label>Cod. armazém</mat-label>
                  <input formControlName="codigoArmazem" class="disabilitar" readonly  matInput placeholder="Cod. armazém" >
                </mat-form-field>
              </div>

              <div fxFlex="25" fxFlex.xs="50" class="px-05 mb-05 disabilitar" [class.campo-inactivo]="true" >
                <mat-form-field class="full-width" appearance="outline" >
                  <mat-label>Nome do armazém</mat-label>
                  <input formControlName="designacao" readonly matInput placeholder="Nome armazém" >
                </mat-form-field>
              </div>

              <div fxFlex="20" fxFlex.xs="50" class="px-05 mb-05 disabilitar" [class.campo-inactivo]="true" >
                <mat-form-field class="full-width" appearance="outline" >
                  <mat-label>País</mat-label>
                  <input formControlName="pais" readonly matInput placeholder="País" >
                </mat-form-field>
              </div>

              <div fxFlex="20" fxFlex.xs="50" class="px-05 mb-05 disabilitar" [class.campo-inactivo]="true" >
                <mat-form-field class="full-width" appearance="outline" >
                  <mat-label>Província</mat-label>
                  <input formControlName="provincia" readonly matInput placeholder="Província" >
                </mat-form-field>
              </div>

              <div fxFlex="20" fxFlex.xs="50" class="px-05 mb-05 disabilitar" [class.campo-inactivo]="true">
                <mat-form-field class="full-width" appearance="outline" >
                  <mat-label>Município</mat-label>
                  <input formControlName="municipio" readonly matInput placeholder="Município" >
                </mat-form-field>
              </div>

              <div fxFlex="40" fxFlex.xs="50" class="px-05 mb-05" [class.campo-inactivo]="true">
                <mat-form-field class="full-width" appearance="outline" >
                  <mat-label>Morada</mat-label>
                  <textarea formControlName="morada" readonly rows="3" matInput placeholder="Morada"></textarea>
                </mat-form-field>
              </div>

              <div fxFlex="20" fxFlex.xs="50" class="px-05 mb-05" [class.campo-inactivo]="dados?.modoConsulta">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>*Data de carga</mat-label>
                  <input matInput  formControlName="dataCarga"  [readonly]="true" (dateChange)="aoSelecHoraCarga(this.formCarga.get('carga').value.dataCarga, $event)" autocomplete="off" [matDatepicker]="dataCarga" >
                  <mat-datepicker-toggle matSuffix [for]="dataCarga" [disabled]="dados?.modoConsulta"></mat-datepicker-toggle>
                  <mat-datepicker #dataCarga></mat-datepicker>
                </mat-form-field>
              </div>

              <div fxFlex="20" fxFlex.xl="20" fxFlex.lg="20" fxFlex.xs="50" class="px-05 mb-05"  [class.campo-inactivo]="dados?.modoConsulta">
                <mat-form-field class="full-width" appearance="outline" >
                  <mat-label>*Hora da carga</mat-label>
                  <input  formControlName="horaCarga" formatarHora  [readonly]="dados?.modoConsulta" (input)="filtrarHora($event, 1)" [matAutocomplete]="listaHoras"
                          (click)="activarListaHoras(1)"  matInput placeholder="*Hoda da carga" >
                  <mat-icon class="mat-icon material-icons text-primary cursor-pointer" matSuffix>arrow_drop_down
                  </mat-icon>
                </mat-form-field>
<!--                <div>-->
<!--                  <input  mask="Hh:m0" #hora  class="full-width" style="border: 1px solid rgba(0, 0, 0, 0.12)!important;line-height: 2.24; border-radius: 5px"-->
<!--                          [readonly]="dados?.modoConsulta" (input)="filtrarHora($event, 1)" [matAutocomplete]="listaHoras"-->
<!--                          (click)="activarListaHoras(1)"  placeholder="*Hoda da carga" >-->
<!--                </div>-->
                <mat-autocomplete #listaHoras="matAutocomplete">
                  <div class="bg-primary p-1 sticky-header">
                         <span class="flex flex-y-center ">
                             <small class="w-100 pl-1 disabilitar">Seleccione a hora</small>
                         </span>
                  </div>
                  <mat-option [disabled]="dados?.modoConsulta" *ngFor="let hora of listaHorasAutoComplete"  (click)="aoSelecHoraCarga(this.formCarga.get('carga').value.dataCarga, hora.valor)" [value]="hora.valor">
                      <span class="flex border-b-light">
                          <small class="w-100">{{hora.valor}}</small>
                      </span>
                  </mat-option>
                </mat-autocomplete>
              </div>

              <div fxFlex="20" fxFlex.xs="50" class="px-05 mb-05" [class.campo-inactivo]="dados?.modoConsulta">
                <mat-form-field class="full-width" appearance="outline" >
                  <mat-label>Matrícula</mat-label>
                  <input type="text" class="uppercase" autocomplete="off" [readonly]="dados?.modoConsulta" matInput formControlName="matriculaCarga" placeholder="Matrícula" >
                </mat-form-field>
              </div>

            </div>
          </div>
        </mat-card>
      </div>

      <div fxFlex="100" class="px-05">
          <mat-card formGroupName="descarga" class="px-1">
            <h3 class="text-primario"><span>Descarga</span></h3>
            <div class="py-1">
              <div fxLayout="row wrap">
                <div fxFlex="40" fxFlex.xs="100" class="px-05 mb-05" [class.campo-inactivo]="dados?.modoConsulta">
                  <mat-form-field class="full-width" appearance="outline" >
                    <mat-label>Endereço</mat-label>
                    <input formControlName="descricaoEndereco" [readonly]="dados?.modoConsulta" (input)="filtrarMorada($event)" [matAutocomplete]="listaMoradasCliente" (click)="activarLista()"  matInput placeholder="Designação do endereço" >
                  </mat-form-field>
                  <mat-autocomplete #listaMoradasCliente="matAutocomplete">
                    <div class="bg-primary p-1 sticky-header">
                         <span class="flex flex-y-center ">
                             <small class="w-100 pl-1 disabilitar">Designação do endereço</small>
                         </span>
                    </div>
                    <mat-option *ngFor="let morada of moradasCLIAutoComplete" (click)="aoSelecMorada(morada)" [value]="morada.moradaEndereco">
                      <span class="flex border-b-light">
                          <small *ngIf="morada.descricaoEndereco" class="w-100">{{morada.descricaoEndereco}}</small>
                          <small *ngIf="!morada.descricaoEndereco" class="w-100">{{ morada.moradaEndereco}}</small>
                      </span>
                    </mat-option>
                  </mat-autocomplete>
                </div>
                
                <div fxFlex="20" fxFlex.xs="50" class="px-05 mb-05" [class.campo-inactivo]="true" >
                  <mat-form-field class="full-width" appearance="outline" >
                    <mat-label>País</mat-label>
                    <input readonly formControlName="paisMorada" matInput placeholder="País" >
                  </mat-form-field>
                </div>

                <div fxFlex="20" fxFlex.xs="50" class="px-05 mb-05" [class.campo-inactivo]="true" >
                  <mat-form-field class="full-width" appearance="outline" >
                    <mat-label>Província</mat-label>
                    <input readonly formControlName="provinciaMorada" matInput placeholder="Província" >
                  </mat-form-field>
                </div>

                <div fxFlex="20" fxFlex.xs="50" class="px-05 mb-05" [class.campo-inactivo]="true" >
                  <mat-form-field class="full-width" appearance="outline" >
                    <mat-label>Município</mat-label>
                    <input readonly formControlName="municipioMorada" matInput placeholder="Município" >
                  </mat-form-field>
                </div>

                <div fxFlex="40" fxFlex.xs="100" class="px-05 mb-05" [class.campo-inactivo]="true">
                  <mat-form-field class="full-width" appearance="outline" >
                    <mat-label>Morada</mat-label>
                    <textarea matInput readonly formControlName="moradaEndereco" rows="3" placeholder="Morada do cliente" ></textarea>
                  </mat-form-field>
                </div>

                <div fxFlex="20" fxFlex.xs="50" class="px-05 mb-05" [class.campo-inactivo]="dados?.modoConsulta">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>*Data de descarga </mat-label>
                    <input matInput formControlName="dataDescarga" (dateChange)="aoSelecHoraDescarga(this.formCarga.get('descarga').value.dataDescarga, $event)" [readonly]="true" autocomplete="off" [matDatepicker]="dataDescarga" type="text">
                    <mat-datepicker-toggle matSuffix [for]="dataDescarga" [disabled]="dados?.modoConsulta"></mat-datepicker-toggle>
                    <mat-datepicker #dataDescarga></mat-datepicker>
                  </mat-form-field>
                </div>

                <div fxFlex="20" fxFlex.xl="20" fxFlex.lg="20" fxFlex.xs="50" class="px-05 mb-05" [class.campo-inactivo]="dados?.modoConsulta">
                  <mat-form-field class="full-width" appearance="outline" >
                    <mat-label><span>*</span>Hora da carga</mat-label>
                    <input formatarHora formControlName="horaDescarga" [readonly]="dados?.modoConsulta"
                            (input)="filtrarHora($event, 2)" [matAutocomplete]="listaDescargaHoras"
                            (click)="activarListaHoras(2)"  matInput placeholder="Hoda da carga" >
                    <mat-icon class="mat-icon material-icons text-primary cursor-pointer" matSuffix>arrow_drop_down
                    </mat-icon>
                  </mat-form-field>
                  <mat-autocomplete #listaDescargaHoras="matAutocomplete">
                    <div class="bg-primary p-1 sticky-header">
                         <span class="flex flex-y-center ">
                             <small class="w-100 pl-1 disabilitar">Seleccione a hora</small>
                         </span>
                    </div>
                    <mat-option [disabled]="dados?.modoConsulta" *ngFor="let hora of horasDescargaAutoComplete"
                                (click)="aoSelecHoraDescarga(this.formCarga.get('descarga').value.dataDescarga, $event)" [value]="hora.valor">
                      <span class="flex border-b-light">
                          <small class="w-100">{{hora.valor}}</small>
                      </span>
                    </mat-option>
                  </mat-autocomplete>
                </div>

              </div>
            </div>
          </mat-card>

        </div>
       </div>
      </form>

  </ki-modal-base>
  `,
styles: [`.campo-inactivo {pointer-events: none!important;}`]
// tslint:disable-next-line:component-class-suffix
}) export class ModalCargaDescarga implements OnInit {
  formCarga: FormGroup;
  limpar: any;
  moradasCliente: MoradaCliente[] = [];
  moradasCLIAutoComplete: MoradaCliente[] = [];
  listaHorasAutoComplete: Hora[] = [];
  horasCargaAutoComplete: Hora[] = [];
  horasDescargaAutoComplete: Hora[] = [];
  moradaSelecionada: MoradaCliente;
  modoConsulta: boolean;
  dataHoraCarga = new FormControl();
  dataHoraDescarga = new FormControl();
  matriculaCarga = new FormControl();
  cargaDescarga?: CargaDescarga;
  horas = horasEvento;
  armazem: Armazem;
  morada!: MoradaCliente;
  constructor(@Inject(MAT_DIALOG_DATA) public dados: any,
              private modalRef: MatDialogRef<ModalCargaDescarga>,
              private clienteService: ClientesService,
              private snackService: NotificacaoService,
              private fb: FormBuilder,
              private snack: NotificacaoService) {
    this.armazem = dados?.armazemCarga;
    this.morada = dados?.moradaCliente;
    this.moradasCliente = dados?.moradasCliente;
    this.modoConsulta = dados?.modoConsulta;
    if (dados && dados.cargaDescarga) {
      if (dados && dados.moradasCliente
        && dados.moradasCliente.length > 0
        && dados.cargaDescarga && dados.cargaDescarga.idEnderecoDescarga) {
        this.moradaSelecionada = dados.moradasCliente.find(x => x.idEnderecoCliente === dados.cargaDescarga.idEnderecoDescarga);
      }
      this.formCarga = this.updateForm(dados.cargaDescarga);
    } else {
      if (dados && dados.moradasCliente && dados.moradasCliente.length > 0 && dados.moradasCliente.length === 1) {
        this.moradaSelecionada = dados.moradasCliente[0];
      }
      this.formCarga = this.inicForm();
    }
  }

  ngOnInit() {
   // this.getMoradaSCliente();
  }

  inicForm() {
    return this.fb.group({
      carga: this.fb.group({
        dataCarga: [''],
        horaCarga: [''],
        matriculaCarga: [''],
        codigoArmazem: this.armazem && this.armazem.codigoArmazem,
        designacao: this.armazem.designacao,
        pais: this.armazem.pais,
        provincia: this.armazem.provincia,
        municipio: this.armazem.municipio,
        morada: this.armazem.morada,
      }),
      descarga: this.fb.group({
        dataDescarga: [''],
        horaDescarga: [''],
        descricaoEndereco: this.moradaSelecionada?.descricaoEndereco,
        idEnderecoCliente: this.moradaSelecionada?.idEnderecoCliente,
        pais: this.moradaSelecionada?.codPaisMorada,
        codPaisMorada: this.moradaSelecionada?.codPaisMorada,
        paisMorada: this.moradaSelecionada?.paisMorada,
        provinciaMorada: this.moradaSelecionada?.provinciaMorada,
        idProvinciaMorada: this.moradaSelecionada?.idProvinciaMorada,
        municipioMorada: this.moradaSelecionada?.municipioMorada,
        idMunicipioMorada: this.moradaSelecionada?.idMunicipioMorada,
        moradaEndereco: this.moradaSelecionada?.moradaEndereco,
      })
    });
  }

  updateForm(carga: CargaDescarga) {
    this.dataHoraCarga.setValue(carga.dataHoraCarga);
    this.dataHoraDescarga.setValue(carga.dataHoraDescarga);
    return this.fb.group({
      carga: this.fb.group({
        dataCarga: carga && carga.dataHoraCarga ? carga.dataHoraCarga : '',
        horaCarga: carga && carga.dataHoraCarga ? formatDate(carga.dataHoraCarga, 'HH:mm', 'pt') : '',
        matriculaCarga: carga && carga.matricula ? carga.matricula : '',
        codigoArmazem: carga && carga.codArmazem ? carga.codArmazem : '',
        designacao: carga && carga.nomeArmazem ? carga.nomeArmazem : '', // this.armazem.designacao,
        pais: carga && carga.paisArmazem ? carga.paisArmazem : '',
        provincia: carga && carga.provinciaArmazem ? carga.provinciaArmazem : '',
        // municipio: carga && carga. ? carga.provinciaArmazem : '',
        municipio: '',
        morada: carga && carga.moradaArmazem ? carga.moradaArmazem : '',
      }),
      descarga: this.fb.group({
        dataDescarga: carga && carga.dataHoraDescarga ? carga.dataHoraDescarga : '',
        horaDescarga: carga && carga.dataHoraDescarga ? formatDate(carga.dataHoraDescarga, 'HH:mm', 'pt') : '',
        descricaoEndereco: carga && carga.nomeEndereco ? carga.nomeEndereco : '',
        idEnderecoCliente: carga && carga.idEnderecoDescarga ? carga.idEnderecoDescarga : '',
        pais: carga && carga.paisDescarga ? carga.paisDescarga : '',
        codPaisMorada: carga && carga.paisDescarga ? carga.paisDescarga : '',
        paisMorada: carga && carga.paisDescarga ? carga.paisDescarga : '',
        provinciaMorada: carga && carga.provinciaDescarga ? carga.provinciaDescarga : '',
        idProvinciaMorada: null,
        municipioMorada: '',
        idMunicipioMorada: null,
        moradaEndereco: carga && carga.moradaDescarga ? carga.moradaDescarga : '',
      })
    });
  }

  get fbGroupCarga() {
    return this.formCarga.get('carga') as FormGroup;
  }

  get fbGroupDescarga() {
    return this.formCarga.get('descarga') as FormGroup;
  }

  aoSelecMorada(morada: MoradaCliente) {
    if (morada) {
      this.moradaSelecionada = morada;
      this.fbGroupDescarga.get('idEnderecoCliente').setValue(morada.idEnderecoCliente);
      this.fbGroupDescarga.get('pais').setValue(morada.codPaisMorada);
      this.fbGroupDescarga.get('codPaisMorada').setValue(morada.codPaisMorada);
      this.fbGroupDescarga.get('paisMorada').setValue(morada.paisMorada);
      this.fbGroupDescarga.get('provinciaMorada').setValue(morada.provinciaMorada);
      this.fbGroupDescarga.get('idProvinciaMorada').setValue(morada.idProvinciaMorada);
      this.fbGroupDescarga.get('municipioMorada').setValue(morada.municipioMorada);
      this.fbGroupDescarga.get('municipioMorada').setValue(morada.municipioMorada);
      this.fbGroupDescarga.get('idMunicipioMorada').setValue(morada.idMunicipioMorada);
      this.fbGroupDescarga.get('moradaEndereco').setValue(morada.moradaEndereco);
      this.fbGroupDescarga.get('descricaoEndereco').setValue(morada.descricaoEndereco);
    }
  }


  aoSelecHoraCarga(dataCarga: any, horaCarga?: any) {
    if (!this.formCarga.get('carga').value.dataCarga) {
      // this.snackService.notificar('Seleccione a data de carga', false, 6000);
      return;
    }
    const data = formatDate(dataCarga, 'yyy-MM-dd', 'pt');

    if (this.fbGroupCarga.value.horaCarga && this.fbGroupCarga.value.horaCarga.length > 0) {
      this.dataHoraCarga.setValue(data + 'T' + this.fbGroupCarga.value.horaCarga);
    }

    if (horaCarga && horaCarga.value && horaCarga.value.length > 0) {
      this.dataHoraCarga.setValue(data + 'T' + horaCarga.value);
    }

    if (data && !this.fbGroupCarga.value.horaCarga && horaCarga && !horaCarga.value) {
      this.dataHoraCarga.setValue(data + 'T' + '00');
    }

  }

  aoSelecHoraDescarga(dataDescarga: any, horaDescarga?: any) {
    if (!this.formCarga.get('descarga').value.dataDescarga) {
     // this.snackService.notificar('Seleccione a data de descarga', false, 6000);
      return;
    }

   const data = formatDate(dataDescarga, 'yyy-MM-dd', 'pt');
    if (this.fbGroupDescarga.value.horaDescarga && this.fbGroupDescarga.value.horaDescarga.length > 0) {
      this.dataHoraDescarga.setValue(data + 'T' + this.fbGroupDescarga.value.horaDescarga);
    }
    if (horaDescarga && horaDescarga.value && horaDescarga.value.length > 0) {
     this.dataHoraDescarga.setValue(data + 'T' + horaDescarga.value);
   }
    if (data && !this.fbGroupDescarga.value.horaDescarga && horaDescarga && !horaDescarga.value) {
     this.dataHoraDescarga.setValue(data + 'T' + '00');
   }
}
  filtrarHora(event: any, pontoOrigem: number) {
    const termo = event.target.value;
    if (termo && termo.trim().length > 0 && this.horas && this.horas.length > 0) {
       if (pontoOrigem === 1) {
         this.listaHorasAutoComplete = this.horas.filter((x) => (x.valor && x.valor.toLowerCase().includes(termo.toLowerCase())));
       }
      if (pontoOrigem === 2) {
        this.horasDescargaAutoComplete = this.horas.filter((x) => (x.valor && x.valor.toLowerCase().includes(termo.toLowerCase())));
      }
    } else {
     if (pontoOrigem === 1) {
       this.listaHorasAutoComplete = this.horas;
     }
      if (pontoOrigem === 2) {
        this.horasDescargaAutoComplete = this.horas;
      }
    }
  }

//
  filtrarMorada(event: any) {
    const termo = event.target.value;
    if (termo && termo.trim().length > 0 && this.moradasCliente && this.moradasCliente.length > 0) {
      this.moradasCLIAutoComplete = this.moradasCliente.filter(
        (x) =>
          (x.moradaEndereco &&
            x.moradaEndereco.toLowerCase().includes(termo.toLowerCase())) ||
          x.descricaoEndereco && x.descricaoEndereco.toLowerCase().includes(termo.toLowerCase()) ||
          x.codPaisMorada && x.codPaisMorada.toLowerCase().includes(termo.toLowerCase()) ||
          x.provinciaMorada && x.provinciaMorada.toLowerCase().includes(termo.toLowerCase()) ||
          x.municipioMorada && x.municipioMorada.toLowerCase().includes(termo.toLowerCase())
      );
    } else {
      this.moradasCLIAutoComplete = this.moradasCliente;
    }
  }
  activarLista() {
    this.moradasCLIAutoComplete = this.moradasCliente;
  }

  activarListaHoras(pontoOrigem: number) {
     if (pontoOrigem === 1) {
       this.listaHorasAutoComplete = this.horas;
     }
    if (pontoOrigem === 2) {
      this.horasDescargaAutoComplete = this.horas;
    }
  }

fecharModal() {
  if (!this.dataHoraCarga.value) {
    this.snack.notificar('Seleccione a data e hora de carga', false, 7000);
    return;
  }

  if (!this.fbGroupCarga.get('horaCarga').value) {
    this.snack.notificar('Seleccione a data e hora de carga', false, 7000);
    return;
  }

  if (this.moradasCliente && this.moradasCliente.length > 0 && !this.moradaSelecionada && !this.dados.cargaDescarga) {
    this.snack.notificar('Seleccione a morada', false, 7000);
    return;
  }

  if (!this.moradaSelecionada && !this.dados.cargaDescarga) {
    this.snack.notificar('Está em falta a morada do cliente', false, 7000);
    return;
  }

  if (!this.dataHoraDescarga.value) {
    this.snack.notificar('Seleccione a data e hora de descarga', false, 7000);
    return;
  }

  if (!this.fbGroupDescarga.get('horaDescarga').value) {
    this.snack.notificar('Seleccione a data e hora de descarga', false, 7000);
    return;
  }


    if (moment(this.dataHoraCarga.value).isSameOrAfter(moment(this.dataHoraDescarga.value))) {
      this.snack.notificar('A data de carga deve ser inferior a data de descarga');
      return;
    }


    this.modalRef.close({
      idEnderecoDescarga: this.formCarga.get('descarga').value.idEnderecoCliente,
      cargaDescarga: {
        'idArmazemCarga': this.armazem.idArmazem,
        'codArmazem': this.armazem.codigoArmazem,
        'nomeArmazem': this.armazem.designacao,
        'paisArmazem': this.armazem.pais,
        'provinciaArmazem': this.armazem.provincia,
        'zonaArmazem': '',
        'moradaArmazem': this.armazem.morada,
        'dataHoraCarga': this.dataHoraCarga.value,
        'matricula': this.formCarga.get('carga').value.matriculaCarga,
        'idEnderecoDescarga':  this.formCarga.get('descarga').value.idEnderecoCliente,
        'nomeEndereco': this.moradaSelecionada.descricaoEndereco,
        'paisDescarga': this.moradaSelecionada.paisMorada,
        'provinciaDescarga': this.moradaSelecionada.provinciaMorada,
        'zonaDescarga': '',
        'moradaDescarga': this.moradaSelecionada.moradaEndereco,
        'dataHoraDescarga': this.dataHoraDescarga.value,
      }
    });
}
}
