import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CompraTipoDocumentoDto } from 'app/shared/models/compras/documentos/compra-tipo-documento.dto';
import { Documentos } from 'app/shared/models/financas/documentos/documentos';
import { IdOpc } from '../enum/idOpc';

@Pipe({
  name: 'validarDocumentoCompra'
})
export class ValidarDocumentoCompraPipe implements PipeTransform {

  transform( formDocumento: FormGroup, 
            tipoDocSelecionado: CompraTipoDocumentoDto,
            
            ): unknown {

          let erroDocReferencia: boolean;
          if (tipoDocSelecionado.idOpcDocReferencia === IdOpc.Obrigatorio)  {
            // erroDocReferencia = 
          }


    return null;
  }

}
