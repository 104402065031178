import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getmenu',
  pure: true
}) export class GetMenuPipe implements PipeTransform {
public transform( codMenu: string, listaMenus: any[]): any {
    if (codMenu && codMenu.length > 0 && listaMenus && listaMenus.length > 0) {
      return listaMenus.find((m) => m.codMenu === codMenu).menu;
    }
    return '';
  }
}
