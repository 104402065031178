<section class="position-relative">
    <div class="modal-header" fxLayout="row wrap" fxLayout.lt-sm="column" style="justify-content: space-between">
        <div class="modal-title">
            <h1 mat-dialog-title>{{ data['header'] }}</h1>
        </div>
        <button mat-icon-button [mat-dialog-close]="false" class="btn-close-modal">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="p-05">
        <div class="modal-body">
            <div fxLayout="row wrap" fxLayout.lt-sm="column">
                <div fxFlex="100">
                    <p>{{data['message']}}</p>
                </div>

                <div fxFlex="100" class="mt-1">
                    <button mat-raised-button [mat-dialog-close]="true"
                            color="primary">{{ data['acceptLabel'] }}</button>
                    <span fxFlex></span>
                    <button mat-button class="bg-secondary" type="button"
                            [mat-dialog-close]="false">{{ data['rejectLabel'] }}</button>
                </div>
            </div>
        </div>
    </div>
</section>
