import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrarPessoaContactoRelacao',
  pure: true
})
export class FiltrarPessoaContactoRelacaoPipe implements PipeTransform {

  transform(pessoasContactosRelacoes:any[], value: string): any[] {
    const termo = value;
    if (pessoasContactosRelacoes.length > 0 && termo){
      pessoasContactosRelacoes = pessoasContactosRelacoes.filter( pessoaContactoRelacao => {
        return (pessoaContactoRelacao?.relacao.toLowerCase() as string)
        .includes( termo.toLowerCase());
       })
    }

    return pessoasContactosRelacoes;
  }

}
