import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'numOrdinal'
})
export class FormatarOrdinalPipe implements PipeTransform {
numeraisOrdinais = ['Segunda', 'Terceira', 'Quarta', 'Quinta', 'Sexta', 'Sétima',
    'Oitava', 'Nona', 'Décima', 'Décima Primeira', 'Décima Segunda', 'Décima Terceira', 'Décima Quarta',
    'Décima Quinta', 'Décima Sexta', 'Décima Sétima', 'Décima Oitava', 'Décima Nona', 'Vigésima', 'Vigésima Primeira',
    'Vigésima Segunda', 'Vigésima Terceira', 'Vigésima Quarta', 'Vigésima Quinta', 'Vigésima Sexta', 'Vigésima Sétima', 'Vigésima Oitava',
    'Vigésima Nona', 'Trigésima', 'Trigésima Primeira', 'Trigésima Segunda', 'Trigésima Terceira', 'Trigésima Quarta', 'Trigésima Quinta'];
 public transform(valor: any): any {
     if (valor && typeof(valor) === 'number' && valor < this.numeraisOrdinais.length) {
        return this.numeraisOrdinais[valor - 1];
     } else {}
  }

}
