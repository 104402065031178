import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {tabMessages} from 'app/shared/fake.db/data';
import {CampoValor, GaArquivo, GaArquivoResponse} from '../../models/gestao-arquivos/ga-arquivo';
import {Accao} from '../../models/ENUM';
import {EqFicheiro} from '../../models/equipamentos/eq-ficheiro';
import {Util} from '../../models/generico/util';
import {DatePipe} from '@angular/common';
import {Observable, Subscription} from 'rxjs';
import {GaArquivoService} from '@services/gestao-arquivos/ga-arquivo.service';
import {NotificacaoService} from '@services/notificacao/notificacao.service';
import {mergeMap} from 'rxjs/operators';
import {GaCampo, GaTipo} from '../../models/gestao-arquivos/ga-tipo';
import {MatDialog} from '@angular/material/dialog';
import {ModalConfirmacaoComponent} from '../../../views/equipamentos/componentes/modais/modal-confirmacao/modal-confirmacao.component';
import {DataTypes} from '../../../views/gestao-arquivos/ga-tipo-campo/ga-tipo-campo.component';
import {KArquivoComponent} from '@shared/components/k-arquivo/k-arquivo.component';
import {FormGroup} from '@angular/forms';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'k-arquivos',
    templateUrl: './k-arquivos.component.html',
    styleUrls: ['./k-arquivos.component.scss'],
    providers: [DatePipe]
})
export class KArquivosComponent implements OnInit, OnDestroy, OnChanges {
    loading = false;
    @Input() action = '';
    @Input() parentId = 0;
    @Input() area = '';
    @Input() tipo!: GaTipo;
    @Input() arquivos: GaArquivoResponse[] = [];
    @Output() deleteEvent = new EventEmitter<number>();
    @Output() updateEvent = new EventEmitter<any>();
    filteredArquivos: GaArquivoResponse[] = [];
    readonly actions = Accao;
    readonly messages = tabMessages;
    readonly types = DataTypes;
    private subscriptions: Subscription[] = [];

    constructor(
        private datePipe: DatePipe,
        private _notificacao: NotificacaoService,
        private dialog: MatDialog,
        private arquivoService: GaArquivoService) {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['arquivos']?.currentValue) {
            this.filteredArquivos = [...this.arquivos
                .filter(a => (a.idTipo ?? a.tipo?.id) === this.tipo.id)
                .sort((a, b) =>
                    Number(a.campos[0]?.valor) - Number(b.campos[0]?.valor))];
        }
    }

    ngOnInit(): void {
    }

    download(arquivo: GaArquivo, anexo: EqFicheiro) {
        this.loading = true;
        const titulo = anexo.nome ||
            `Sem título-${this.datePipe.transform(arquivo.dataCriacao, 'dd/MM/yyyy HH:mm:ss')}`;
        if (!Util.isHttpRequest(anexo.conteudo)) {
            Util.downloadAnexo(anexo.conteudo, titulo);
            this.loading = false;
            return;
        }
        this.subscriptions.push(this.arquivoService.download(anexo.conteudo)
            .subscribe(res => {
                    const blobUrl = res.objecto;
                    Util.downloadAnexo(blobUrl, titulo);
                    this.loading = false;
                },
                error => {
                    console.error(error);
                    this.loading = false;
                }));
    }

    removerFicheiro(arquivo: GaArquivoResponse, anexo: any) {
        const splice = () => {
            const index = arquivo.anexos.indexOf(anexo);
            if (index !== -1) {
                arquivo.anexos.splice(index, 1);
                arquivo.anexos = [...arquivo.anexos];
            }
        };

        if (this.action === Accao.cadastrar) {
            if (arquivo.anexos.length <= 1) {
                this._notificacao.notificar('Não é possível eliminar o último anexo do arquivo. Pelo menos um anexo deve ser mantido.');
                return;
            }
            splice();
            return;
        }

        this.loading = true;
        this.subscriptions.push(
            this.arquivoService.deleteFicheiro(arquivo.id, anexo.id).subscribe({
                next: res => {
                    this.loading = false;
                    splice();
                    this._notificacao.notificar(res.mensagem, true);
                },
                error: err => {
                    this.loading = false;
                    console.error(err);
                    this._notificacao.notificar(err.error?.mensagem || 'Falha inesperada ao remover o ficheiro');
                }
            })
        );
    }

    byCampo(item: CampoValor, campo: GaCampo) {
        return item.idCampo === campo.id;
    }

    editar(arquivo?) {
        const modalRef = this.dialog.open(KArquivoComponent, {
            width: '70vw',
            height: '85vh',
            data: {tiposArquivos: [this.tipo], parentId: this.parentId, area: this.area, arquivo, action: this.action}
        });

        modalRef.afterClosed().subscribe((res: FormGroup) => {
            if (res?.valid) {
                if (arquivo) {
                    this.updateEvent.emit(res.value);
                } else {
                    this.arquivos.push(res.value);
                    this.arquivos = [...this.arquivos];
                }
            }
        });
    }

    remover(row: GaArquivoResponse, index: number) {
        const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
            data: {
                header: 'Confirmação',
                acceptLabel: 'Confirmar',
                rejectLabel: 'Cancelar',
                message: `Tem a certeza que pretende remover este arquivo?`
            }
        });
        this.subscriptions.push(
            dialogRef.afterClosed().pipe(
                mergeMap(accepted => {
                    if (!accepted) {
                        return new Observable<any>();
                    }
                    if (this.action === Accao.cadastrar) {
                        this.deleteEvent.emit(row.id);
                        return new Observable<any>();
                    }
                    this.loading = true;
                    return this.arquivoService.deleteArquivo(row.id);
                })
            ).subscribe({
                next: res => {
                    this.deleteEvent.emit(row.id);
                    this._notificacao.notificar(res.mensagem, true);
                    this.loading = false;
                },
                error: err => {
                    console.error(err);
                    this._notificacao.notificar(err.error?.mensagem || `Falha ao remover o arquivo`);
                    this.loading = false;
                }
            })
        );
    }

    sort(e: any) {
        const campo = this.tipo.campos.find(c => c.nome === e.column.name);
        this.filteredArquivos = [...this.filteredArquivos
            .sort((a, b) => {
                const _a = Number(a.campos.find(c => c.idCampo === campo?.id)?.valor);
                const _b = Number(b.campos.find(c => c.idCampo === campo?.id)?.valor);
                return e.newValue === 'desc' ? _a - _b : _b - _a;
            })];
    }
}
