<table class="tb-title">
    <thead>
        <tr>
            <th class="w-30px">
                <span class="invisible">.</span>
            </th>
            <th class="w-30px">
                <div><span>#</span></div>
            </th>
            <th class="w-120px">
                <div><span>Referência</span></div>
            </th>
            <th class="w-300px text-center">
                <div class="w-100 ml-05"><span>Designação</span></div>
            </th>
            <th class="w-120px text-center">
                <div class="ml-05 w-100"><span>Qtd.</span></div>
            </th>
            <th class="w-50px text-center">
                <div><span>UN</span></div>
            </th>
            <th class="w-120px" nowrap>
                <div>Preço ({{ empresa.codMoeda1 }})</div>
            </th>
            <th class="w-60px text-center" nowrap>
                <div><span>Desc %</span></div>
            </th>
            <th class="w-120px text-center" nowrap>
                <div><span>Desc.</span></div>
            </th>
            <th class="w-130px text-center" nowrap>
                <div><span>Total</span></div>
            </th>
            <th class="w-70px text-center" nowrap nowrap>
                <div><span>IVA %</span></div>
            </th>
            <th class="w-150px text-center" nowrap>
                <div nowrap><span>IVA</span></div>
            </th>
            <th class="w-160px text-center" nowrap>
                <div class="text-left"><span>Total c/ IVA </span></div>
            </th>

        </tr>
    </thead>
    <!-- EJVC <tbody [ngClass]="{ disableDiv: !alterarOutrosCampos }"> -->
    <tbody>
        <ng-container *ngFor="let item of Itens.controls; let i = index"
            formArrayName="Itens">
            <tr [class.trans-y]="i !== 0" [formGroupName]="i">
                <td class="w-30px" [class.disableDiv]="!podeRemoverItem">
                    <div>
                        <button [matMenuTriggerFor]="menu" aria-label="Opções"
                            mat-icon-button type="button">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <ng-container *ngIf="!item.get('Separador').value">
                                <button *ngIf="i < (Itens.controls.length - 1)"
                                    (click)="removerItem(i, null)" mat-menu-item
                                    type="button">
                                    <span><small>Remover</small></span>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="item.get('Separador').value">
                                <button (click)="removerItem(i, true)" mat-menu-item
                                    type="button">
                                    <span><small>Remover</small></span>
                                </button>
                            </ng-container>

                            <ng-container *ngIf="!item.get('Separador').value">
                                <button
                                    *ngIf="!(this.tipoDocSelecionado && this.tipoDocSelecionado.codCategoriaDocumento === 'R' && this.modoDocPorRef)"
                                    (click)="duplicarItem(item, i, false)" mat-menu-item
                                    type="button">
                                    <span><small>Duplicar</small></span>
                                </button>
                                <button
                                    *ngIf="!(this.tipoDocSelecionado && this.tipoDocSelecionado.codCategoriaDocumento === 'R' && this.modoDocPorRef)"
                                    (click)="duplicarItem(item, i, true)" mat-menu-item
                                    type="button">
                                    <span><small>Inserir texto</small></span>
                                </button>
                            </ng-container>
                        </mat-menu>
                    </div>
                </td>
                <td class="w-30px">
                    <div>{{ i + 1 }}</div>
                </td>
                <!-- Ref Artigo -->
                <td *ngIf="!item.get('Separador').value"
                    class="w-120px cursor-pointer hvr-accent"
                    [ngClass]="{ disableDiv: !podeAdicionarItems }">
                    <div class="">
                        <mat-form-field class="full-width">
                            <mat-label></mat-label>
                            <input formControlName="CodArtigo" maxlength="15" matInput
                                placeholder="Ref" [readonly]="false"
                                (input)="filtrarArtigoFA($event, 1)"
                                (click)="aoClicarCodArtigo()"
                                [matAutocomplete]="artigosFA" />
                            <mat-icon (click)="pesquisarArtigos($event, i)"
                                class="mat-icon material-icons text-primary cursor-pointer"
                                matSuffix>folder
                            </mat-icon>
                        </mat-form-field>
                        <mat-autocomplete [panelWidth]="550" #artigosFA="matAutocomplete"
                            (optionSelected)="aoSelecArtigoPorAutoComplete($event, i)"
                            style="min-width: 250px!important;">

                            <div class="bg-primary p-1 sticky-header"
                                *ngIf="(artigosFAAutoComplete | filtarArtigo: codCategoriaDocumento).length > 0">
                                <span class="flex flex-y-center">
                                    <small class="w-22 pl-1 disabilitar"> Código</small>
                                    <small class="w-22 pl-1 disabilitar"> Tipo</small>
                                    <small class="w-50 pl-1 disabilitar">Nome</small>
                                    <small
                                        class="w-15 p disabilitar text-center">Reten.</small>
                                    <small class="w-20 pl-1 disabilitar"
                                        *ngIf="gereStocks">Gere stock</small>
                                    <button class="btn-autocomplete-plus mr-05"
                                        matTooltip="Criar artigo"
                                        matTooltipClass="bg-secondary"
                                        matTooltipPosition="above"
                                        (click)="adicionarArtigoFA(i)">
                                        <span>+</span>
                                    </button>
                                    <button class="btn-autocomplete-plus"
                                        matTooltip="Actualizar lista de artigos"
                                        matTooltipClass="bg-secondary"
                                        matTooltipPosition="above" (click)="getArtigosFA()">
                                        <span>
                                            <img src="assets/images/icons/refresh.png"
                                                class="cursor-pointer" alt="">
                                        </span>
                                    </button>
                                </span>
                            </div>

                            <mat-option
                                *ngFor="let artigo of artigosFAAutoComplete | filtarArtigo: codCategoriaDocumento"
                                [value]="artigo.codArtigo">
                                <span class="flex">
                                    <small
                                        class="w-22 pr-03 texto-ellipsis">{{artigo.codArtigo}}</small>
                                    <small class="w-22 pr-03 texto-ellipsis">{{artigo.tipo ?
                                        artigo.tipo : ''}}</small>
                                    <small
                                        class="w-50 texto-ellipsis pr-03">{{artigo.artigo}}</small>
                                    <small class="w-15 pr-03 text-center">
                                        <mat-icon style="font-size: 17px;"
                                            *ngIf="artigo.sujeitoARetencao"
                                            color="primary">check_small</mat-icon>
                                    </small>
                                    <small class="w-20 text-center" *ngIf="gereStocks">
                                        <mat-icon style="font-size: 17px;"
                                            *ngIf="artigo.geridoPorStocks">check_small</mat-icon>
                                    </small>
                                    <small class="w-10"></small>
                                </span>
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </td>
                <!-- Descrição do Artigo -->
                <td [attr.colspan]=" !item.get('Separador').value ? '1' : '12'"
                    [attr.data-separador]="!item.get('Separador').value ? '1' : '#item_' + i"
                    class="w-3750px" [ngClass]="{ disableDiv: !podeAdicionarItems }">

                    <div>
                        <mat-form-field class="full-width pl-05">
                            <textarea formControlName="Descricao" matInput rows="1"
                                class="nowrap area-flow"
                                [class.controlo-invalido]="item.get('Descricao').hasError('required')"
                                [class.separador]="item.get('Separador').value"
                                maxlength="200" placeholder="Descrição do item"></textarea>
                        </mat-form-field>

                        <mat-form-field class="full-width pl-05" *ngIf="false">
                            <mat-label></mat-label>
                            <textarea formControlName="Descricao" class="full-width"
                                [class.separador]="item.get('Separador').value" matInput
                                maxlength="200" rows="1"
                                placeholder="Descrição do item"></textarea>
                        </mat-form-field>
                    </div>
                </td>
                <!-- Quantidade -->
                <td *ngIf="!item.get('Separador').value">
                    <div class="pl-05 pr-05 text-right "
                        [class.disableDiv]="!podeRemoverItem">
                        <mat-form-field class="full-width pl-05" *ngIf="modoDocPorRef">
                            <mat-label></mat-label>
                            <input #quantidade (input)="calcularValoresItem(i)"
                                formControlName="Quantidade" decimalMarker=","
                                thousandSeparator="." class="text-right" matInput
                                [appInputFormat]="'decimal'" placeholder="0,00" />
                        </mat-form-field>

                        <mat-form-field class="full-width pl-05" *ngIf="!modoDocPorRef">
                            <mat-label></mat-label>
                            <input #quantidade (input)="calcularValoresItem(i)"
                                formControlName="Quantidade" decimalMarker=","
                                thousandSeparator="." matInput [appInputFormat]="'decimal'"
                                class="text-right" />
                        </mat-form-field>
                    </div>
                </td>
                <!-- Unidade do Artigo --->
                <td *ngIf="!item.get('Separador').value" class="w-50px text-center">
                    <div class="disableDiv text-center">
                        <mat-form-field class="full-width pl-05 text-center">
                            <mat-label></mat-label>
                            <input class="text-center" formControlName="CodUnidade"
                                matInput />
                        </mat-form-field>
                    </div>
                </td>
                <ng-container *ngIf="!item.get('Separador').value">
                    <td *ngIf="false" class="w-120px text-right"
                        [ngClass]="{ disableDiv: !podeAdicionarItems }">
                        <div>
                            <mat-form-field class="full-width pl-05">
                                <input #precoME formControlName="PrecoME" matInput min="0"
                                    type="text" />
                            </mat-form-field>
                        </div>
                    </td>
                </ng-container>
                <!-- ***** Preco Unitario ***** -->
                <td *ngIf="!item.get('Separador').value"
                    [class.disableDiv]="!podeRemoverItem">
                    <div class="pl-05 ">
                        <mat-form-field class="full-width pl-05 text-right">
                            <input
                                [class.controlo-invalido]="item.get('Preco').hasError('min')"
                                #precoUnit (input)="calcularValoresItem(i)"
                                formControlName="Preco" [appInputFormat]="'decimal'"
                                matInput type="text" />
                        </mat-form-field>
                    </div>
                </td>
                <!-- ***** Desconto em Percentagem ***** -->
                <td *ngIf="!item.get('Separador').value"
                    [ngClass]="{ disableDiv: !podeAdicionarItems }">
                    <div class="pl-05">
                        <mat-form-field class="full-width text-right pl-05">
                            <mat-label></mat-label>
                            <input #descontoPerc (input)="calcularValoresItem(i)" matInput
                                separatorLimit="11" [appInputFormat]="'decimal'"
                                formControlName="DescontoPerc" placeholder="" />
                        </mat-form-field>
                    </div>
                </td>
                <!-- ***** Desconto ***** -->
                <td *ngIf="!item.get('Separador').value"
                    [ngClass]="{ disableDiv: !podeAdicionarItems }">
                    <div *ngIf="false" class="ml-1 pl-05 text-right h-18px"
                        style="border-bottom: 1px solid #bdbbbb !important">
                        <span>{{ item.value.Desconto }}</span>
                    </div>
                    <div class="pl-05">
                        <mat-form-field [class.disabilitar]="false"
                            class="full-width pl-05 text-right">
                            <mat-label></mat-label>
                            <input
                                [class.controlo-invalido]="item.value.Desconto && item.value.Desconto >= (item.value.Quantidade * item.value.Preco)"
                                (input)="calcularValoresItem(i)" maxDigitos class="pr-1"
                                decimalMarker="," thousandSeparator="." matInput
                                formControlName="Desconto" type="text"
                                [valorMax]="item.value.Quantidade * item.value.Preco" />
                        </mat-form-field>
                    </div>
                </td>
                <!-- ***** SubTotal ***** -->
                <td *ngIf="!item.get('Separador').value" class="w-200px pr-1"
                    [ngClass]="{ disableDiv: !podeAdicionarItems }">
                    <div class="text-right pr-1">
                        <div class="pl-05">
                            <mat-form-field [class.disabilitar]="true"
                                class="full-width pl-05 text-right">
                                <mat-label></mat-label>
                                <input class="pr-1" decimalMarker=","
                                    formControlName="SubTotal" matInput placeholder=""
                                    thousandSeparator="." type="text" />
                            </mat-form-field>
                        </div>
                    </div>
                </td>
                <!-- ***** IVA % ***** -->
                <td *ngIf="!item.get('Separador').value"
                    [ngClass]="{ disableDiv: !podeAdicionarItems }">
                    <div class="text-left w-65px pr-1">
                        <mat-form-field class="full-width">
                            <mat-label></mat-label>
                            <mat-select formControlName="taxaImposto" class="relative"
                                [class.controlo-invalido]="item.get('taxaImposto').hasError('required')">
                                <mat-option class="border-b bg-primary sticky-header">
                                    <span class="flex border-b-light">
                                        <small class="w-40 flex-grow-1 pr-03">Taxa</small>
                                        <small class="w-40 pr-03"> Cod.</small>
                                        <small class="w-80">Descrição</small>
                                    </span>
                                </mat-option>
                                <mat-option class="break-space"
                                    (click)="aoMudarIvaItem(iva, i)"
                                    *ngFor="let iva of ivasActivos"
                                    [value]="iva.taxaImposto">
                                    <span class="flex">
                                        <small class="w-40 flex-grow-1 pr-03">
                                            {{ iva.taxaImposto }}
                                        </small>
                                        <span class="w-40 pr-03">
                                            <small>{{ iva.codImposto }}</small></span>
                                        <span class="w-80">
                                            <small>{{ iva.descricaoImposto
                                                }}</small></span>
                                    </span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </td>
                <!-- h-20px -->
                <!-- ***** IVA ***** -->
                <td *ngIf="!item.get('Separador').value"
                    [ngClass]="{ disableDiv: !podeAdicionarItems }">
                    <div class="text-right pl-05">
                        <div class="border-b-light  h-40px div-flex">
                            <span>{{facturaForm.value.Itens[i]?.imposto}}</span>
                        </div>
                    </div>
                </td>

                <td *ngIf="!item.get('Separador').value" class="text-right"
                    [ngClass]="{ disableDiv: !podeAdicionarItems }">
                    <div *ngIf="idTipoOperacao === 1" class="pl-05">
                        <div class="border-b-light h-40px div-flex">
                            <span
                                *ngIf="facturaForm.value.Itens[i]?.SubTotal;else valorNullo"
                                nowrap>
                                {{facturaForm.value.Itens[i]?.TotalGeral }}
                            </span>
                            <ng-template #valorNullo>
                                <span nowrap>{{ "0" | currency: " " }}</span>
                            </ng-template>
                        </div>
                    </div>
                    <div *ngIf="idTipoOperacao === 2"
                        class="border-b-light h-40px div-flex">
                        <span nowrap>{{
                            facturaForm.value.Itens[i]?.TotalGeral
                            }}</span>
                        <ng-template #valorNullo>
                            <span nowrap>{{ "0" | currency: " " }}</span>
                        </ng-template>
                    </div>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>