
export class CountryCod {

    constructor() { }

    str = 'dialCode: +1 % flag:	CA % countryName:	Canada %' +
        'dialCode: +1 % flag:	US % countryName:	United States %' +
        'dialCode: +1242 % flag:		BS % countryName:	Bahamas %' +
        'dialCode: +1246 % flag:		BB % countryName:	Barbados %' +
        'dialCode: +1264 % flag:	 	AI % countryName:	Anguilla %' +
        'dialCode: +1268 % flag:		AG % countryName:	Antigua and Barbuda %' +
        'dialCode: +1284 % flag:	 	VG % countryName:	Virgin Islands, British %' +
        'dialCode: +1340 % flag:		VI % countryName:	Virgin Islands, U.S. %' +
        'dialCode: +1441 % flag:	 	BM % countryName:	Bermuda %' +
        'dialCode: +1473 % flag:	 	GD % countryName:	Grenada %' +
        'dialCode: +1649 % flag:		TC % countryName:	Turks and Caicos Islands %' +
        'dialCode: +1664 % flag:		MS % countryName:	Montserrat %' +
        'dialCode: +1670 % flag:	 	MP % countryName:	Northern Mariana Islands %' +
        'dialCode: +1671 % flag:	 	GU % countryName:	Guam %' +
        'dialCode: +1684 % flag:		AS % countryName:	American Samoa %' +
        'dialCode: +1758 % flag:	 	LC % countryName:	Saint Lucia %' +
        'dialCode: +1767 % flag:	 	DM % countryName:	Dominica %' +
        'dialCode: +1784 % flag:		VC % countryName:	Saint Vincent and the Grenadines %' +
        'dialCode: +1849 % flag:	 	DO % countryName:	Dominican Republic %' +
        'dialCode: +1868 % flag:		TT % countryName:	Trinidad and Tobago %' +
        'dialCode: +1869 % flag:	 	KN % countryName:	Saint Kitts and Nevis %' +
        'dialCode: +1876 % flag:	 	JM % countryName:	Jamaica %' +
        'dialCode: +1939 % flag:		PR % countryName:	Puerto Rico %' +
        'dialCode: +20 	 % flag:	EG % countryName:	Egypt %' +
        'dialCode: +211 	 % flag:	SS % countryName:	South Sudan %' +
        'dialCode: +212 % flag: 	MA % countryName:	Morocco %' +
        'dialCode: +213 	 % flag:	DZ % countryName:	Algeria %' +
        'dialCode: +216 % flag:	TN % countryName:	Tunisia %' +
        'dialCode: +218 % flag:	LY % countryName:	Libyan Arab Jamahiriya %' +
        'dialCode: +220 % flag: 	GM % countryName:	Gambia %' +
        'dialCode: +221 % flag: 	SN % countryName:	Senegal %' +
        'dialCode: +222 % flag:	MR % countryName:	Mauritania %' +
        'dialCode: +223 % flag: 	ML % countryName:	Mali %' +
        'dialCode: +224 % flag:	 	GN % countryName:	Guinea %' +
        'dialCode: +225 % flag: 	CI % countryName:	Cote dIvoire %' +
        'dialCode: +226 	 % flag:	BF % countryName:	Burkina Faso %' +
        'dialCode: +227 % flag: 	NE % countryName:	Niger %' +
        'dialCode: +228 % flag: 	TG % countryName:	Togo %' +
        'dialCode: +229 % flag:	BJ % countryName:	Benin %' +
        'dialCode: +230 % flag: 	MU % countryName:	Mauritius %' +
        'dialCode: +231 % flag:	LR % countryName:	Liberia %' +
        'dialCode: +232 % flag: 	SL % countryName:	Sierra Leone %' +
        'dialCode: +233 % flag: 	GH % countryName:	Ghana %' +
        'dialCode: +234 % flag: 	NG % countryName:	Nigeria %' +
        'dialCode: +235 % flag: 	TD % countryName:	Chad %' +
        'dialCode: +236 % flag: 	CF % countryName:	Central African Republic %' +
        'dialCode: +237 	 % flag:	CM % countryName:	Cameroon %' +
        'dialCode: +238 % flag: 	CV % countryName:	Cape Verde %' +
        'dialCode: +239 % flag:	ST % countryName:	Sao Tome and Principe %' +
        'dialCode: +240 % flag:	GQ % countryName:	Equatorial Guinea %' +
        'dialCode: +241 % flag: 	GA % countryName:	Gabon %' +
        'dialCode: +242 % flag: 	CG % countryName:	Congo %' +
        'dialCode: +243 % flag: 	CD % countryName:	Congo, The Democratic Republic of the Congo %' +
        'dialCode: +244 % flag:	AO % countryName:	Angola %' +
        'dialCode: +245 % flag:	GW % countryName:	Guinea-Bissau %' +
        'dialCode: +246 % flag:	IO % countryName:	British Indian Ocean Territory %' +
        'dialCode: +248 % flag:	SC % countryName:	Seychelles %' +
        'dialCode: +249 % flag: 	SD % countryName:	Sudan %' +
        'dialCode: +250 % flag: 	RW % countryName:	Rwanda %' +
        'dialCode: +251 % flag: 	ET % countryName:	Ethiopia %' +
        'dialCode: +252 	 % flag:	SO % countryName:	Somalia %' +
        'dialCode: +253 % flag:	DJ % countryName:	Djibouti %' +
        'dialCode: +254 % flag:	KE % countryName:	Kenya %' +
        'dialCode: +255 	 % flag:	TZ % countryName:	Tanzania, United Republic of Tanzania %' +
        'dialCode: +256 % flag:	UG % countryName:	Uganda %' +
        'dialCode: +257 % flag: 	BI % countryName:	Burundi %' +
        'dialCode: +258 % flag: 	MZ % countryName:	Mozambique %' +
        'dialCode: +260 	 % flag:	ZM % countryName:	Zambia %' +
        'dialCode: +261 % flag: 	MG % countryName:	Madagascar %' +
        'dialCode: +262 % flag: 	TF % countryName:	French Southern Territories %' +
        'dialCode: +262 % flag:	YT % countryName:	Mayotte %' +
        'dialCode: +262 % flag: 	RE % countryName:	Reunion %' +
        'dialCode: +263 % flag: 	ZW % countryName:	Zimbabwe %' +
        'dialCode: +264 % flag: 	NA % countryName:	Namibia %' +
        'dialCode: +265 % flag: 	MW % countryName:	Malawi %' +
        'dialCode: +266 % flag:	LS % countryName:	Lesotho %' +
        'dialCode: +267 % flag: 	BW % countryName:	Botswana %' +
        'dialCode: +268 % flag: 	SZ % countryName:	Swaziland %' +
        'dialCode: +269 % flag: 	KM % countryName:	Comoros %' +
        'dialCode: +27 	 % flag:	ZA % countryName:	South Africa %' +
        'dialCode: +290 % flag: 	SH % countryName:	Saint Helena, Ascension and Tristan Da Cunha %' +
        'dialCode: +291 % flag: 	ER % countryName:	Eritrea %' +
        'dialCode: +297 	 % flag:	AW % countryName:	Aruba %' +
        'dialCode: +298 	 % flag:	FO % countryName:	Faroe Islands %' +
        'dialCode: +299 % flag: 	GL % countryName:	Greenland %' +
        'dialCode: +30 % flag: 	GR % countryName:	Greece %' +
        'dialCode: +31 % flag: 	NL % countryName:	Netherlands %' +
        'dialCode: +32 % flag: 	BE % countryName:	Belgium %' +
        'dialCode: +33 % flag: 	FR % countryName:	France %' +
        'dialCode: +34 % flag: 	ES % countryName:	Spain %' +
        'dialCode: +345 % flag: 	KY % countryName:	Cayman Islands %' +
        'dialCode: +350 % flag: 	GI % countryName:	Gibraltar %' +
        'dialCode: +351 % flag: 	PT % countryName:	Portugal %' +
        'dialCode: +352 % flag: 	LU % countryName:	Luxembourg %' +
        'dialCode: +353 % flag: 	IE % countryName:	Ireland %' +
        'dialCode: +354 % flag: 	IS % countryName:	Iceland %' +
        'dialCode: +355 % flag: 	AL % countryName:	Albania %' +
        'dialCode: +356 % flag: 	MT % countryName:	Malta %' +
        'dialCode: +357 % flag: 	CY % countryName:	Cyprus %' +
        'dialCode: +358 % flag: 	AX % countryName:	Åland Islands %' +
        'dialCode: +358 % flag: 	FI % countryName:	Finland %' +
        'dialCode: +359 % flag: 	BG % countryName:	Bulgaria %' +
        'dialCode: +36 % flag: 	HU % countryName:	Hungary %' +
        'dialCode: +370 	 % flag:	LT % countryName:	Lithuania %' +
        'dialCode: +371 % flag: 	LV % countryName:	Latvia %' +
        'dialCode: +372 % flag: 	EE % countryName:	Estonia %' +
        'dialCode: +373 % flag: 	MD % countryName:	Moldova %' +
        'dialCode: +374 % flag: 	AM % countryName:	Armenia %' +
        'dialCode: +375 % flag: 	BY % countryName:	Belarus %' +
        'dialCode: +376 % flag: 	AD % countryName:	Andorra %' +
        'dialCode: +377 % flag:	 	MC % countryName:	Monaco %' +
        'dialCode: +378 % flag: 	SM % countryName:	San Marino %' +
        'dialCode: +379 % flag: 	VA % countryName:	Holy See (Vatican City State) %' +
        'dialCode: +380 % flag: 	UA % countryName:	Ukraine %' +
        'dialCode: +381 % flag:	 	RS % countryName:	Serbia %' +
        'dialCode: +382 % flag: 	ME % countryName:	Montenegro %' +
        'dialCode: +383 % flag: 	XK % countryName:	Kosovo %' +
        'dialCode: +385 % flag: 	HR % countryName:	Croatia %' +
        'dialCode: +386 % flag:	SI % countryName:	Slovenia %' +
        'dialCode: +387 % flag: 	BA % countryName:	Bosnia and Herzegovina %' +
        'dialCode: +389 % flag: 	MK % countryName:	North Macedonia %' +
        'dialCode: +39 % flag: 	IT % countryName:	Italy %' +
        'dialCode: +40 % flag: 	RO % countryName:	Romania %' +
        'dialCode: +41 % flag:	CH % countryName:	Switzerland %' +
        'dialCode: +420 % flag: 	CZ % countryName:	Czech Republic %' +
        'dialCode: +421 % flag: 	SK % countryName:	Slovakia %' +
        'dialCode: +423 % flag:	LI % countryName:	Liechtenstein %' +
        'dialCode: +43 % flag: 	AT % countryName:	Austria %' +
        'dialCode: +44 % flag: 	GG % countryName:	Guernsey %' +
        'dialCode: +44 % flag:	 	IM % countryName:	Isle of Man %' +
        'dialCode: +44 % flag:	 	JE % countryName:	Jersey %' +
        'dialCode: +44 % flag:	 	GB % countryName:	United Kingdom %' +
        'dialCode: +45 % flag:	 	DK % countryName:	Denmark %' +
        'dialCode: +46 % flag: 	SE % countryName:	Sweden %' +
        'dialCode: +47 % flag:	 	BV % countryName:	Bouvet Island %' +
        'dialCode: +47 % flag: 	NO % countryName:	Norway %' +
        'dialCode: +47 % flag: 	SJ % countryName:	Svalbard and Jan Mayen %' +
        'dialCode: +48 % flag: 	PL % countryName:	Poland %' +
        'dialCode: +49 % flag: 	DE % countryName:	Germany %' +
        'dialCode: +500 % flag: 	FK % countryName:	Falkland Islands (Malvinas) %' +
        'dialCode: +500 % flag: 	GS % countryName:	South Georgia and the South Sandwich Islands %' +
        'dialCode: +501 % flag:	BZ % countryName:	Belize %' +
        'dialCode: +502 % flag: 	GT % countryName:	Guatemala %' +
        'dialCode: +503 % flag:	SV 	 % countryName: El Salvador %' +
        'dialCode: +504 % flag: 	HN % countryName:	Honduras %' +
        'dialCode: +505 % flag:	NI % countryName:	Nicaragua %' +
        'dialCode: +506 	 % flag:	CR % countryName:	Costa Rica %' +
        'dialCode: +507 % flag: 	PA % countryName:	Panama %' +
        'dialCode: +508 % flag: 	PM % countryName:	Saint Pierre and Miquelon %' +
        'dialCode: +509 % flag:	HT % countryName:	Haiti %' +
        'dialCode: +51 % flag:	PE % countryName:	Peru %' +
        'dialCode: +52 % flag: 	MX % countryName:	Mexico %' +
        'dialCode: +53 % flag: 	CU % countryName:	Cuba %' +
        'dialCode: +54 % flag: 	AR % countryName:	Argentina %' +
        'dialCode: +55 % flag: 	BR % countryName:	Brazil %' +
        'dialCode: +56 % flag: 	CL % countryName:	Chile %' +
        'dialCode: +57 % flag: 	CO % countryName:	Colombia %' +
        'dialCode: +58 % flag: 	VE % countryName:	Venezuela, Bolivarian Republic of Venezuela %' +
        'dialCode: +590 % flag: 	GP % countryName:	Guadeloupe %' +
        'dialCode: +590 	 % flag:	BL % countryName:	Saint Barthelemy %' +
        'dialCode: +590 	 % flag:	MF % countryName:	Saint Martin %' +
        'dialCode: +591 % flag:	BO % countryName:	Bolivia, Plurinational State of bolivia %' +
        'dialCode: +592 % flag: 	GY % countryName:	Guyana %' +
        'dialCode: +593 % flag: 	EC % countryName:	Ecuador %' +
        'dialCode: +594 % flag: 	GF % countryName:	French Guiana %' +
        'dialCode: +595 % flag: 	PY % countryName:	Paraguay %' +
        'dialCode: +596 % flag: 	MQ % countryName:	Martinique %' +
        'dialCode: +597 % flag: 	SR % countryName:	Suriname %' +
        'dialCode: +598 % flag: 	UY % countryName:	Uruguay %' +
        //   'dialCode: +599 % flag:	AN % countryName:	Netherlands Antilles %' +
        'dialCode: +60 % flag: 	MY % countryName:	Malaysia %' +
        'dialCode: +61 % flag: 	AU % countryName:	Australia %' +
        'dialCode: +61 % flag: 	CX % countryName:	Christmas Island %' +
        'dialCode: +61 % flag: 	CC % countryName:	Cocos (Keeling) Islands %' +
        'dialCode: +62 	 % flag:	ID % countryName:	Indonesia %' +
        'dialCode: +63 	 % flag:	PH % countryName:	Philippines %' +
        'dialCode: +64 % flag: 	NZ % countryName:	New Zealand %' +
        'dialCode: +64 % flag: 	PN % countryName:	Pitcairn %' +
        'dialCode: +65 % flag: 	SG % countryName:	Singapore %' +
        'dialCode: +66 % flag: 	TH % countryName:	Thailand %' +
        'dialCode: +670 % flag: 	TL % countryName:	Timor-Leste %' +
        'dialCode: +672 % flag:	AQ % countryName:	Antarctica %' +
        'dialCode: +672 % flag: 	HM % countryName:	Heard Island and Mcdonald Islands %' +
        'dialCode: +672 % flag: 	NF % countryName:	Norfolk Island %' +
        'dialCode: +673 % flag: 	BN % countryName:	Brunei Darussalam %' +
        'dialCode: +674 % flag:	NR % countryName:	Nauru %' +
        'dialCode: +675 	 % flag:	PG % countryName:	Papua New Guinea %' +
        'dialCode: +676 	 % flag:	TO % countryName:	Tonga %' +
        'dialCode: +677 	 % flag:	SB % countryName:	Solomon Islands %' +
        'dialCode: +678 	 % flag:	VU % countryName:	Vanuatu %' +
        'dialCode: +679 % flag:	FJ % countryName:	Fiji %' +
        'dialCode: +680 	 % flag:	PW % countryName:	Palau %' +
        'dialCode: +681 	 % flag:	WF % countryName:	Wallis and Futuna %' +
        'dialCode: +682 	 % flag:	CK % countryName:	Cook Islands %' +
        'dialCode: +683 	 % flag:	NU % countryName:	Niue %' +
        'dialCode: +685 	 % flag:	WS % countryName:	Samoa %' +
        'dialCode: +686 	 % flag:	KI % countryName:	Kiribati %' +
        'dialCode: +687 	 % flag:	NC % countryName:	New Caledonia %' +
        'dialCode: +688 	 % flag:	TV % countryName:	Tuvalu %' +
        'dialCode: +689 	 % flag:	PF % countryName:	French Polynesia %' +
        'dialCode: +690 	 % flag:	TK % countryName:	Tokelau %' +
        'dialCode: +691 	 % flag:	FM % countryName:	Micronesia, Federated States of Micronesia %' +
        'dialCode: +692 	 % flag:	MH % countryName:	Marshall Islands %' +
        'dialCode: +7 	 % flag:	KZ % countryName:	Kazakhstan %' +
        'dialCode: +7 	 % flag:	RU % countryName:	Russia %' +
        'dialCode: +81 % flag:	JP % countryName:	Japan %' +
        'dialCode: +82 % flag: 	KR % countryName:	Korea, Republic of South Korea %' +
        'dialCode: +84 % flag: 	VN % countryName:	Vietnam %' +
        'dialCode: +850 % flag: 	KP % countryName:	Korea, Democratic Peoples Republic of Korea %' +
        'dialCode: +852 % flag: 	HK % countryName:	Hong Kong %' +
        'dialCode: +853 % flag: 	MO % countryName:	Macao %' +
        'dialCode: +855 % flag: 	KH % countryName:	Cambodia %' +
        'dialCode: +856 % flag: 	LA % countryName:	Laos %' +
        'dialCode: +86 % flag: 	CN % countryName:	China %' +
        'dialCode: +880 % flag: 	BD % countryName:	Bangladesh %' +
        'dialCode: +886 % flag:	TW % countryName:	Taiwan %' +
        'dialCode: +90 % flag: 	TR % countryName:	Türkiye %' +
        'dialCode: +91 % flag: 	IN % countryName:	India %' +
        'dialCode: +92 % flag: 	PK % countryName:	Pakistan %' +
        'dialCode: +93 % flag: 	AF % countryName:	Afghanistan %' +
        'dialCode: +94 % flag: 	LK % countryName:	Sri Lanka %' +
        'dialCode: +95 % flag:	MM % countryName:	Myanmar %' +
        'dialCode: +960 % flag: 	MV % countryName:	Maldives %' +
        'dialCode: +961 % flag: 	LB % countryName:	Lebanon %' +
        'dialCode: +962 % flag: 	JO % countryName:	Jordan %' +
        'dialCode: +963 % flag: 	SY % countryName:	Syrian Arab Republic %' +
        'dialCode: +964 % flag: 	IQ % countryName:	Iraq %' +
        'dialCode: +965 % flag:	KW % countryName:	Kuwait %' +
        'dialCode: +966 % flag: 	SA % countryName:	Saudi Arabia %' +
        'dialCode: +967 % flag: 	YE % countryName:	Yemen %' +
        'dialCode: +968 % flag: 	OM % countryName:	Oman %' +
        'dialCode: +970 % flag: 	PS % countryName:	Palestinian Territory, Occupied %' +
        'dialCode: +971 % flag: 	AE % countryName:	United Arab Emirates %' +
        'dialCode: +972 % flag: 	IL % countryName:	Israel %' +
        'dialCode: +973 % flag: 	BH % countryName:	Bahrain %' +
        'dialCode: +974 % flag: 	QA % countryName:	Qatar %' +
        'dialCode: +975 % flag: 	BT % countryName:	Bhutan %' +
        'dialCode: +976 % flag: 	MN % countryName:	Mongolia %' +
        'dialCode: +977 % flag: 	NP % countryName:	Nepal %' +
        'dialCode: +98 % flag: 	IR % countryName:	Iran, Islamic Republic of Persian Gulf %' +
        'dialCode: +992 % flag: 	TJ % countryName:	Tajikistan %' +
        'dialCode: +993 	 % flag:	TM % countryName:	Turkmenistan %' +
        'dialCode: +994 % flag:	AZ % countryName:	Azerbaijan %' +
        'dialCode: +995 % flag:	GE % countryName:	Georgia %' +
        'dialCode: +996 	 % flag:	KG % countryName:	Kyrgyzstan %' +
        'dialCode: +998 % flag: 	UZ % countryName:	Uzbekistan'

    public getContryObject(): {
        dialCode: string,
        flag: string,
        countryName: string
    }[] {


        let arr: string[] = this.str
            .replace(/\t/g, '')
            .replace(/dialCode:/g, '')
            .replace(/flag:/g, '')
            .replace(/countryName:/g, '')
            .split('%');

        let obj: {
            dialCode: string,
            flag: string,
            countryName: string
        }[] = [];


        for (let i = 0; i < arr.length; i += 3) {
            obj[obj.length] = {
                dialCode: arr[i].replace(/\s/g, ''),
                flag: arr[i + 1].replace(/\s/g, ''),
                countryName: arr[i + 2]
            }
        }

        obj.sort((country1: Country, country2 : Country) => {
            return country1.countryName.localeCompare(country2.countryName);
        });

        return obj;

    }


}


export interface Country{
    dialCode: string,
    flag: string,
    countryName: string
}