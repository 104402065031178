<div [formGroup]="formContacto" fxLayout="row" fxFlex="100">
  <div formArrayName="contactos" fxFlex="100">
    <div *ngFor="let contacto of contactos['controls']; let i=index">
      <div [formGroupName]="i" fxLayout="row" fxFlex="100">
        <div class="px-4 pb-4" fxflex="100" fxFlex="100" fxFlex.xl="100" fxFlex.lg="100" fxFlex.gt-xs="100"
          fxFlex.xs="100" (mousemove)="mostrarDeleteIcon(i)" (mouseleave)="ocultarDeleteIcon()">
          <div fxLayout="row">
            <div fxFlex="29" class="pr-1">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Tipos de Contacto</mat-label>
                <mat-select formControlName="idTipoContacto" name="tel-select-component-{{i}}" (selectionChange)="trocarTipoInput($event.value,listaTiposContactos.length, i,
                                contacto,
                                $event.source._elementRef.nativeElement.name)">
                  <mat-option *ngFor="let obj of listaTiposContactos" [value]="obj.id">
                    {{obj.tipoContacto}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>


            <!-- Estes métodos devo pegar na nova implementação Início-->
            <!-- <div class="flex container-tel flex-tel pr-1" fxFlex="80" fxFlex.xl="80" fxFlex.lg="80"
                        Flex.gt-xs="50" fxFlex.xs="100" > -->



            <mat-select *ngIf="contacto.get('tipoContacto').value ==='Telefone'
                      || contacto.get('tipoContacto').value ==='Telemóvel' 
                      || contacto.get('tipoContacto').value ==='Fax'" fxFlex="9" #matSelect1
              id="tel-select-component-{{i}}" class="select-tel tel-select-component mt-7" (selectionChange)="changeCountryCode($event.source._id, $event.value, i)"
                        [value]="selTel" formControlName="selectedCountryCode"
                        [ngClass]="{
                            'select-tel-error': contacto.get('selectedCountryCode')?.invalid
                            || contacto.get('contacto')?.invalid,
                              'select-tel-over': overAll && !focusAll,
                              'select-tel-input': focusAll }">

              <mat-option *ngFor="let countryCode of  this.listaContriesFlag" [value]="countryCode">
                <span class="flag-icon mr-8 flag-icon-{{countryCode.flag.toLowerCase()}}"></span>
                &nbsp;
                <span class="country"> {{countryCode.countryName}} </span> <span>
                  {{countryCode.dialCode}} </span>
              </mat-option>
            </mat-select>
            <mat-form-field color="primary" appearance="outline" fxFlex="35" *ngIf="contacto.get('tipoContacto').value ==='Telefone'
                      || contacto.get('tipoContacto').value ==='Telemóvel'
                      || contacto.get('tipoContacto').value ==='Fax'" class="contacto pr-1">
              <mat-label>Contacto</mat-label>
              <!-- (focus)="focusAll=true"
                        (blur)="focusAll=false" -->
              <input matInput #inTel autocomplete="off" name="tel-select-component-{{i}}" class="" type="tel"
                placeholder="" formControlName="contacto" (keypress)="reconhecerCaracter($event, i)"
                (keydown)="isbackSpaceKeyBoard($event, i)" (input)="validarTel(
                            $event.target.name,
                            $event.target.value, i, contacto)" maxlength="17">
              <mat-error *ngIf="contacto.invalid">texto</mat-error>
            </mat-form-field>






            <!-- <div class="input-tel-flag-code flex flex-tel" fxLayout="row" fxFlex="100">
                            <div id="tel-component-1" class="tel-component" fxFlex="20">
                                <mat-select #matSelect1 id="tel-select-component-{{i}}"
                                    class="select-tel tel-select-component" (selectionChange)="
                                    changeCountryCode(
                                    $event.source._id,
                                    $event.value, i)" [value]="selTel" formControlName="selectedCountryCode"
                                    [ngClass]="{'select-tel-error': this.contactos[i]?.get('selectedCountryCode')?.invalid}">

                                    <mat-option *ngFor="let countryCode of  this.listaContriesFlag"
                                         [value]="countryCode">
                                        <span
                                            class="flag-icon mr-8 flag-icon-{{countryCode.flag.toLowerCase()}}"></span>
                                        &nbsp;
                                        <span class="country"> {{countryCode.countryName}} </span> <span>
                                            {{countryCode.dialCode}} </span>
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="w-phone-100" fxFlex="80">
                                <mat-form-field id="matForm-contacto" appearance="outline"
                                    class="div-in-tel flex flex-tel" fxFlex="80"
                                    >
                                    <mat-label>Telefone</mat-label>
                                    <input matInput #inTel autocomplete="off" name="tel-select-component-{{i}}" 
                                        class="" type="tel" placeholder="" formControlName="contacto"
                                        (keypress)="reconhecerCaracter($event, i)"
                                        (keydown)="isbackSpaceKeyBoard($event, i)" 
                                        (input)="validarTel(
                                            $event.target.name,
                                            $event.target.value,1)" maxlength="17">
                                </mat-form-field>
                            </div>
                        </div> -->
            <!-- </div> -->
            <!-- Estes métodos devo pegar na nova implementação Fim-->
            <div *ngIf="contacto.get('tipoContacto').value !=='Telefone'
                    && contacto.get('tipoContacto').value !=='Telemóvel'
                    && contacto.get('tipoContacto').value !=='Fax'" fxFlex="44" class="pr-1">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Contacto</mat-label>
                <input matInput [type]="contacto.tipoContacto === 'E-mail' ? 'email':
                            contacto.tipoContacto === 'Fax' ? 'tel': 'text' " name="tel-select-component-{{i}}"
                  autocomplete="false" (input)="aoEscrever(contacto, $event.target.name, i)" placeholder="Contacto"
                  formControlName="contacto" maxlength="40" />
              </mat-form-field>
            </div>

            <div fxflex="13" class="pr-1 pt-1" fxFlexAlign="center">
              <mat-checkbox formControlName="activo" class="example-margin" color="primary">
                Activo
              </mat-checkbox>
            </div>
            <!-- <div 
                    fxflex="13" 
                    
                    class="pr-1 pt-1" 
                    fxFlexAlign="center"
                  >

                  <mat-checkbox formControlName="default" class="example-margin"
                  color="primary">
                  Default
                  </mat-checkbox>
                  </div> -->

            <div fxflex="9" fxLayoutAlign="space-around center" class="pt-1 pr-1 icom-m-b"
              *ngIf="i > 0 && i === mostrarDelete">
              <mat-icon fxLayoutAlign="center float-right" (click)="removerContacto(contacto, i)"
                class="mat-icon notranslate mat-warn material-icons cursor-pointer">
                delete
              </mat-icon>
            </div>
            <!-- </div> -->
          </div>

        </div>
      </div>
    </div>
