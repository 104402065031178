import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ThemeService} from 'app/shared/services/theme.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'autocomplete',
    templateUrl: './autocomplete.html',
    styleUrls: ['./autocomplete.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
// tslint:disable-next-line:component-class-suffix
export class Autocomplete implements OnInit, AfterViewInit, OnChanges {

    form: FormGroup;
    @Input('lista') lista: any[];
    @Input('listaAux') listaAux: any[];
    todosbol = false;
    @Input('chave') chave = 'nome';
    @Input('placeholder') placeholder = 'nome';
    @Input('placeholderAux') placeholderAux = 'nome';
    @Output('selecionados') selecionados = new EventEmitter();
    @Input('itemsSelecionadosReset') itemsSelecionadosReset: any[];
    itemsSelecionados: any[];

    semaforo = 0;
    stateClose: any;
    letInputfocusInState: any;
    letInputfocusState: any;
    letInputblurState: any;

    letmatSonStaste: any;
    simaforoTodos = false;
    simaforooptionClick = false;
    mostrarIcon = true;
    OcultarIcon = true;
    visibilidadeIcon = 'hidden';
    // @ViewChild('close') close: any;
    @ViewChild('componetKey') componetKey: any;
    @ViewChild('todosOption') todosOption: any;
    @ViewChild('input1') input1: any;
    @ViewChild('t') t: any;
    @ViewChild('icon') icon: any;
    @ViewChild('matform') matform: any;
    @ViewChildren('options') options: QueryList<Autocomplete>;
    @ViewChildren('d') d: QueryList<Autocomplete>;

    constructor(
        private fb: FormBuilder,
        private themeService: ThemeService
    ) {
        this.form = this.fb.group({
            valor: ['']
        });
        this.lista = [];
        this.listaAux = [];
        this.init();
    }

    init() {

        // this.lista = [{ nome: 'a1' }, { nome: 'b1' }, { nome: 'c1' }, { nome: 'a2' }, { nome: 'b3' }, { nome: 'c2' }, { nome: 'a3' }, { nome: 'b4' }, { nome: 'c3' }, { nome: 'a4' }, { nome: 'b5' }, { nome: 'c4' }
        //   , { nome: 'a5' }, { nome: 'b6' }, { nome: 'c5' }, { nome: 'a6' }, { nome: 'b7' }, { nome: 'c6' }, { nome: 'a7' }, { nome: 'b8' }, { nome: '7' }, { nome: 'a8' }, { nome: 'b9' }, { nome: 'c8' }];

        this.itemsSelecionados = [];

    }

    async ngAfterViewInit() {
        await this.initOptionsState();

        // (this.input1.nativeElement as HTMLInputElement).onblur = ()=>{
        //   console.log('key', this.componetKey);
        //   if (this.componetKey._isOpen){
        //     console.log('key111222', this.componetKey);
        //     setTimeout(()=>{

        //       console.log('key111', this.componetKey);
        //       this.input1.nativeElement.focus({ focusVisible: true });
        //     }, 0);
        // (this.input1.nativeElement as HTMLInputElement).focus({ focusVisible: true });
        // }
        // };

        (this.componetKey._elementRef.nativeElement as HTMLInputElement).onclick = () => {
            // console.log('key55', this.componetKey)
        };
    }


    ngOnChanges(changes: SimpleChanges) {
        if (this.itemsSelecionadosReset?.length === 0) {
            this.itemsSelecionados = [];
            this.placeholder = this.placeholderAux;
            if (this.t) {
                this.t._checked = false;
            }
            this.form.get('valor').setValue('');
            const checkList: any[] = this.d?.toArray();

            checkList?.forEach((check) => {
                check._checked = false;
            });

            // this.filterForm.get(this.FORM.min).setValue(this.min);
            // this.filterForm.get(this.FORM.minVer).setValue('');

        }

    }

    async checkAll(evento) {
        // console.log('IIII000', this.semaforo);
        if (this.semaforo < 1) {
            // await this.input1.nativeElement.focus({ focusVisible: true });
            // (await this.input1.nativeElement as HTMLInputElement).onkeydown();
            (this.icon?._elementRef.nativeElement as HTMLElement).style.visibility = 'visible';
            const checkList: any[] = this.d.toArray();
            this.itemsSelecionados = [];
            if (evento.checked) {
                await checkList.forEach((check) => {
                    check._checked = true;
                });

                this.itemsSelecionados = this.listaAux;
                this.selecionados.emit(
                    {'selecionados': this.itemsSelecionados}
                );
            } else {
                await checkList.forEach((check) => {
                    check._checked = false;
                });
                this.selecionados.emit(
                    {'selecionados': this.itemsSelecionados}
                );
            }

            this.updateText();
        } else {
            this.semaforo = 0;
            evento.source._checked = !evento.source._checked;
        }
        this.semaforo++;
    }

    async defi(matform, idOption?: string) {
        //  console.log('lo2', this.semaforo)
        this.semaforo = 0;
        await this.input1.nativeElement.focus({focusVisible: true});
        (this.icon?._elementRef.nativeElement as HTMLElement).style.visibility = 'visible';
        await this.options.forEach(async (option: any) => {
            option._element.nativeElement.__zone_symbol__clickfalse = false;
        });

        if (this.semaforo === 0) {
            const el: HTMLElement = document.getElementById(idOption) as HTMLElement;
            el.click();
        }
    }

    async selecionar(evento, item, todosOption) {
        (this.icon?._elementRef.nativeElement as HTMLElement).style.visibility = 'visible';
        if (this.semaforo < 1) {

            if (evento.checked) {
                await this.itemsSelecionados.push(item);
            } else {

                this.itemsSelecionados = await this.itemsSelecionados.filter(item1 => {
                    return item1['' + this.chave + ''] !== item['' + this.chave + ''];
                });
            }

            this.selecionados.emit(
                {'selecionados': this.itemsSelecionados}
            );


            if (this.itemsSelecionados.length === this.listaAux.length) {
                todosOption._checked = true;
            } else {
                todosOption._checked = false;
            }

            this.updateText();
        } else {
            this.semaforo = 0;
            evento.source._checked = !evento.source._checked;
        }


        this.semaforo++;


    }

    config() {
        (this.componetKey._element.nativeElement as HTMLElement).style.position = 'unset !important';

    }

    async initOptionsState() {
        (this.icon._elementRef.nativeElement as HTMLElement).style.visibility = 'hidden';
        this.letInputfocusInState = this.input1.nativeElement.__zone_symbol__focusinfalse;
        this.letInputfocusState = this.input1.nativeElement.__zone_symbol__focusfalse;
        this.letInputfocusState = this.input1.nativeElement.__zone_symbol__focusfalse;
        // _elementRef.nativeElement.children[0].children[0].__zone_symbol__clickfalse

        // Blur Effect ...

        this.letmatSonStaste = this.matform._elementRef.nativeElement.children[0].children[0].__zone_symbol__clickfalse;
        this.todosOption._element.nativeElement.__zone_symbol__clickfalse = false;
        await this.options.forEach(async (option: any) => {
            option._element.nativeElement.__zone_symbol__clickfalse = false;
        });


    }

    ngOnInit(): void {

    }

    async ocultarIcon(se, matform, componetKey) {
        // this.mostrarIcon = true;
        // this.visibilidadeIcon

        // console.log('OOOOO', !componetKey._isOpen);
        if (this.simaforooptionClick) {
            // this.visibilidadeIcon = 'hidden';
            // if(!componetKey._isOpen){

            //   this.input1.nativeElement.focus({ focusVisible: false });
            // } else {
            //   this.input1.nativeElement.focus({ focusVisible: true });

            // }
            this.input1.nativeElement.__zone_symbol__focusinfalse = [];
            this.input1.nativeElement.__zone_symbol__focusfalse = [];
            matform._elementRef.nativeElement.children[0].children[0].__zone_symbol__clickfalse = [];
            await (se as HTMLElement).click();

            if (this.itemsSelecionados.length === 1) {
                this.placeholder = this.itemsSelecionados[0]['' + this.chave + ''];
            } else if (this.itemsSelecionados.length > 1) {
                this.placeholder = '(' + this.itemsSelecionados.length + ')  selecionados';
            }

            setTimeout(() => {
                // console.log('ERRR', componetKey)

                this.mostrarIcon = true;
                this.simaforooptionClick = true;
                // this.visibilidadeIcon = 'hidden';
                (this.icon?._elementRef.nativeElement as HTMLElement).style.visibility = 'hidden';
                this.input1.nativeElement.__zone_symbol__focusinfalse = this.letInputfocusInState;
                this.input1.nativeElement.__zone_symbol__focusfalse = this.letInputfocusState;
                matform._elementRef.nativeElement.children[0].children[0].__zone_symbol__clickfalse = this.letmatSonStaste;
            }, 100);
        } else {

            setTimeout(async () => {

                // console.log('SEEE 2:::');
                // console.log('ERRR', componetKey, 'componetKey._isOpen', componetKey._isOpen)
                if (await this.OcultarIcon && !componetKey._isOpen) {
                    (this.icon?._elementRef.nativeElement as HTMLElement).style.visibility = 'hidden';
                }

                this.OcultarIcon = true;
            }, 150);

            // console.log('IIIJJJ',)

        }


    }

    filtrarArtigoClassificacao(event: any) {
        // console.log('Che', this.d);
        // let authis.d._results

        const termo: string = event.target.value;
        this.lista = this.listaAux;
        if (termo && termo !== '') {
            this.lista = this.lista.filter(item1 => {
                return (item1['' + this.chave + ''] as string)?.toLowerCase().includes(termo.toLowerCase()
                );
            });
        }

        this.lista;
        setTimeout(() => {

            const checkList: any[] = this.d.toArray();
            // checkList.forEach( (item: any) => {
            this.itemsSelecionados.forEach((selecionado, i) => {

                this.lista.forEach((item, j) => {
                    if (selecionado['' + this.chave + ''] === item['' + this.chave + '']) {
                        checkList[j]._checked = true;
                    }

                });
                // })
            });
            if (this.itemsSelecionados.length === this.listaAux.length) {
                this.t._checked = true;
            }
        }, 0);

    }


    // A1
    async adicionarClassificacao() {

    }

    activar() {

        // this.close._element.nativeElement.__zone_symbol__clickfalse = this.stateClose;
        // this.input1._element.nativeElement.focus({ focusVisible: false });
    }


    setIconVisible() {
        (this.icon._elementRef.nativeElement as HTMLElement).style.visibility = 'visible';
    }

    async closeList(se, matform, icon) {
        this.placeholder = this.placeholderAux;
        this.input1.nativeElement.__zone_symbol__focusinfalse = [];
        this.input1.nativeElement.__zone_symbol__focusfalse = [];
        matform._elementRef.nativeElement.children[0].children[0].__zone_symbol__clickfalse = [];

        // Blur
        // this.input1.nativeElement.__zone_symbol__blurfalse = this.letInputblurState;

        (se as HTMLElement).click();

        if (this.itemsSelecionados.length === 1) {
            this.placeholder = this.itemsSelecionados[0]['' + this.chave + ''];
        } else if (this.itemsSelecionados.length > 1) {
            this.placeholder = '(' + this.itemsSelecionados.length + ')  selecionados';
        }

        await setTimeout(() => {
            this.mostrarIcon = true;
            // this.visibilidadeIcon = 'hidden';

            // Blur ...
            // (this.input1.nativeElement as HTMLInputElement).blur();
            // Blur
            // this.input1.nativeElement.__zone_symbol__blurfalse = [];

            (icon._elementRef.nativeElement as HTMLElement).style.visibility = 'hidden';
            this.input1.nativeElement.__zone_symbol__focusinfalse = this.letInputfocusInState;
            this.input1.nativeElement.__zone_symbol__focusfalse = this.letInputfocusState;
            matform._elementRef.nativeElement.children[0].children[0].__zone_symbol__clickfalse = this.letmatSonStaste;
            this.updateText();
        }, 0);
    }


    private updateText() {
        this.placeholder = this.placeholderAux;

        let valor = '';
        this.itemsSelecionados.forEach((item, i) => {
            valor += item['' + this.chave + ''];
            if ((i + 1) < this.itemsSelecionados.length) {
                valor += ', ';
            }
        });
        this.form.get('valor').setValue(valor);
        valor;


        // if (this.itemsSelecionados.length === 1) {
        //   this.placeholder = this.itemsSelecionados[0]['' + this.chave + ''];
        // } else if (this.itemsSelecionados.length > 1) {
        //   this.placeholder = '(' + this.itemsSelecionados.length + ') selecionados';
        // }
    }

    async prevDefault(evento: any) {
        setTimeout(async () => {
                // this.close._element.nativeElement.__zone_symbol__clickfalse = false;
            }, 0
        );
    }

    activarSemaforo() {
        // this.input1.nativeElement.focus({ focusVisible: true });
        this.simaforoTodos = true;
    }

    async todos(valor: any, todosbol: boolean) {
        this.mostrarIcon = false;
        this.simaforooptionClick = false;
        this.OcultarIcon = false;
        this.visibilidadeIcon = 'visible';
        // await this.input1.nativeElement.focus({ focusVisible: true });
        // this.close._element.nativeElement.__zone_symbol__mousedownfalse = false;
        // this.close._element.nativeElement.__zone_symbol__clickfalse = false;
        this.todosOption._element.nativeElement.__zone_symbol__clickfalse = false;
        await this.options.forEach(async (option: any) => {
            option._element.nativeElement.__zone_symbol__clickfalse = false;
        });

        if (!this.simaforoTodos) {

            valor._checked = await !valor._checked;
        }
        const checkList: any[] = this.d.toArray();
        if (todosbol) {
            await checkList.forEach((check) => {
                check._checked = true;
            });
            this.itemsSelecionados = await this.lista;
            this.selecionados.emit(
                {'selecionados': this.itemsSelecionados}
            );
            this.todosbol = true;

        } else if (!todosbol) {
            await checkList.forEach((check) => {
                check._checked = false;
            });

            this.itemsSelecionados = [];
            this.selecionados.emit(
                {'selecionados': this.itemsSelecionados}
            );
            this.todosbol = false;
        }

        this.updateText();
        this.simaforoTodos = false;
    }

    async definirIdModulo(item, todosOption: any, valor?: any, index?: number, idOption?: string) {

        this.mostrarIcon = false;
        this.simaforooptionClick = false;
        this.OcultarIcon = false;
        await this.input1.nativeElement.focus({focusVisible: true});
        // this.visibilidadeIcon = 'visible';
        (this.icon?._elementRef.nativeElement as HTMLElement).style.visibility = 'visible';
        // this.close._element.nativeElement.__zone_symbol__mousedownfalse = false;
        // this.close._element.nativeElement.__zone_symbol__clickfalse = false;
        this.todosOption._element.nativeElement.__zone_symbol__clickfalse = false;
        await this.options.forEach(async (option: any) => {
            option._element.nativeElement.__zone_symbol__clickfalse = false;
        });

        const checkList: any[] = this.d.toArray();
        let bol: boolean = checkList[index]._checked;
        todosOption._checked = false;
        this.todosbol = false;

        if (await !this.simaforoTodos) {
            checkList[index]._checked = await !valor._checked;
            bol = await !checkList[index]._checked;
        }

        if (!bol) {
            await this.itemsSelecionados.push(item);
            this.selecionados.emit(
                {'selecionados': this.itemsSelecionados}
            );
        } else if (bol) {
            this.itemsSelecionados = await this.itemsSelecionados.filter(item1 => {
                return item1['' + this.chave + ''] !== item['' + this.chave + ''];
            });
            this.selecionados.emit(
                {'selecionados': this.itemsSelecionados}
            );
        }

        if (this.itemsSelecionados.length === this.listaAux.length) {
            todosOption._checked = true;
        }

        this.updateText();
        this.simaforoTodos = false;

    }

    aoSelecClassificacaoPorAutoComplete(event: any) {


    }

    @HostListener('click')
    fg() {
        // console.log('JJJJJ')
        // this.visibilidadeIcon = 'hidden';
    }

}
