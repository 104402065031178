import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Entidades } from 'app/shared/models/clientes/entidades';
import {
    HttpClient,
    HttpHeaders,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Titulos } from 'app/shared/models/aplicacao/titulos';
import { TiposEnderecoCliente } from 'app/shared/models/clientes/tiposenderecoCliente';
import { environment } from 'environments/environment';
import { Tipos } from 'app/shared/models/clientes/tipos';
import { TiposArquivos } from 'app/shared/models/clientes/tiposarquivos';
import { TiposDocumentosCliente } from 'app/shared/models/clientes/tiposdocumentos';
import { ClienteAcesso } from 'app/shared/models/clientes/clienteacessos';
import { Codigo } from 'app/shared/models/clientes/codigo';
import { ConfCodigosClientes } from 'app/shared/models/clientes/confcodigos';
import { EntidadesRelacoes } from 'app/shared/models/clientes/entidadesrelacoes';
import { Estados } from 'app/shared/models/clientes/estados';
import { TiposContactos } from 'app/shared/models/clientes/tipos-contactos';
import { PessoasContactosRelacoes } from 'app/shared/models/clientes/pessoas-contactos-relacoes';
import {Resposta} from '../../models/resposta';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
    }),
};

@Injectable({
    providedIn: 'root',
})
export class ClientesGeraisService {
    baseUrlCliente: string;
    baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = `${environment.apiAplicacao}`;
        this.baseUrlCliente = `${environment.apiClientes}`;

    }

    getEntidades(): Observable<any> {
        return this.http
            .get<Entidades[]>(this.baseUrlCliente + 'entidades', httpOptions);
        // .get(this.baseUrl + "clientesEntidades", httpOptions)
    }

    getEntidade(idEntidade: number): Observable<any> {
        return this.http.get(this.baseUrlCliente + 'entidades/' + idEntidade, httpOptions);
    }

    getEntidadesRelacoesFilhos(
        idEntidadeFilho: number
    ): Observable<any> {
        return this.http
            .get(
                this.baseUrlCliente +
                'entidades/relacoesEntidadesFilhos/' +
                idEntidadeFilho, httpOptions
            )
            .pipe(catchError(null));
    }

    getTitulos(): Observable<Titulos[]> {
        return this.http
            .get(this.baseUrl + 'aplicacao/titulos', httpOptions)
            .pipe(catchError(null));
    }

    getAcessos(idEntidade: number): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/acessos/' + idEntidade, httpOptions)
            .pipe(catchError(null));
    }

    getAcessosCliente(id: number): Observable<ClienteAcesso[]> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/acessosclientes/' + id, httpOptions)
            .pipe(catchError(null));
    }

    getAcesso(id: number, idCliente: number): Observable<ClienteAcesso> {
        return this.http
            .get(
                this.baseUrlCliente + 'clientes/acessos/' + id + '/' + idCliente,
                httpOptions
            )
            .pipe(catchError(null));
    }

    getEstados(idEntidade: number): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/estados/' + idEntidade, httpOptions)
            .pipe(catchError(null));
    }

    getTodosEstados(): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/estados', httpOptions)
            .pipe(catchError(null));
    }

    getEstado(id: number): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/estados/estadoprincipal/' + id, httpOptions)
            .pipe(catchError(null));
    }

    getEstadoDetalhe(idEstado: number): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/estados/estado/' + idEstado, httpOptions)
            .pipe(catchError(null));
    }

    getDetalheEstado(idEstado: number, idEntidade: number): Observable<any> {
        return this.http
            .get(
                this.baseUrlCliente + 'clientes/estados/' + idEstado + '/' + idEntidade,
                httpOptions
            )
            .pipe(catchError(null));
    }

    ActivarEstado(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientes/estados/activarEstado/', modelo, httpOptions)
            .pipe();
    }

    TornarEstadoDefault(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientes/estados/estadoprincipal/', modelo, httpOptions)
            .pipe();
    }

    gravarEstado(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientes/estados/', modelo, httpOptions)
            .pipe();
    }

    EliminarEstado(idEstado: any): Observable<any> {
        return this.http
            .delete(this.baseUrlCliente + 'clientes/estados/eliminar/' + idEstado, httpOptions)
            .pipe();
    }

    getTipos(idEntidade: number): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientesTipos/entidade/' + idEntidade, httpOptions)
            .pipe(catchError(null));
    }

    getTipoCliente(idTipo: number): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientesTipos/' + idTipo, httpOptions)
            .pipe(catchError(null));
    }

    // Tipo de Documentos

    gravarTipoDocumento(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientes/tipos-documentos/', modelo, httpOptions)
            .pipe(catchError(this.handleError));
    }

    editarTipoDocumento(modelo: any): Observable<any> {
        return this.http
            .post(
                this.baseUrlCliente + 'clientes/tipos-documentos/editar',
                modelo,
                httpOptions
            )
            .pipe(catchError(this.handleError));
    }

    removerTipoDocumento(modelo: any): Observable<any> {
        return this.http
            .post(
                this.baseUrlCliente + 'clientes/tipos-documentos/remover/',
                modelo,
                httpOptions
            )
            .pipe(catchError(this.handleError));
    }

    activarTipoDocumento(modelo: any): Observable<any> {
        return this.http
            .post(
                this.baseUrlCliente + 'clientes/tipos-documentos/activar/',
                modelo,
                httpOptions
            )
            .pipe(catchError(this.handleError));
    }

    getTiposDocumentos(idEntidade: number): Observable<any> {
        return this.http
            .get(
                this.baseUrlCliente + 'clientes/tipos-documentos/entidade/' + idEntidade,
                httpOptions
            )
            .pipe(catchError(null));
    }

    getTodosTiposDocumentos(): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/tipos-documentos/', httpOptions)
            .pipe(catchError(null));
    }

    getTipoDocumento(
        idTipo: number,
        idEntidade: number
    ): Observable<any> {
        return this.http
            .get(
                this.baseUrlCliente + 'clientes/tipos-documentos/' + idTipo + '/' + idEntidade,
                httpOptions
            )
            .pipe(catchError(null));
    }

    getTiposDocumentosEntidade(
        idEntidade: Number
    ): Observable<any> {
        return this.http
            .get(
                this.baseUrlCliente + 'clientes/tipos-documentos/entidade/' + idEntidade,
                httpOptions
            )
            .pipe(catchError(null));
    }


    getTiposArquivos(idEntidade: number): Observable<any> {
        return this.http.get(this.baseUrlCliente + 'clientesTiposArquivos/entidade/' + idEntidade, httpOptions)
            .pipe();
    }

    getTiposContactos(): Observable<any> {
        return this.http.get(this.baseUrlCliente + 'clientes/contactos/tipos/', httpOptions)
            .pipe();
    }

    getPessoasContactosRelacao(idEntidade: number): Observable<any> {
        return this.http.get(this.baseUrlCliente + 'clientes/relacao/' + idEntidade, httpOptions)
            .pipe();
    }

    gravarPessoasContactosRelacaoFuncao(modelo: any): Observable<any> {
        return this.http.post(this.baseUrlCliente + 'clientes/relacao/gravar', modelo, httpOptions)
    }
    gravarPessoasContactosRelacao(modelo: any): Observable<any> {
        return this.http.post(this.baseUrlCliente + 'clientes/gravar/', modelo, httpOptions)
    }


    getTipoArquivo(id: number): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientesTiposArquivos/' + id, httpOptions)
            .pipe(catchError(null));
    }

    getTiposEnderecos(idEntidade: number): Observable<TiposEnderecoCliente[]> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/tiposenderecos/' + idEntidade, httpOptions)
            .pipe(catchError(null));
    }

    getTipoEndereco(id: number): Observable<TiposEnderecoCliente> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/tiposenderecos/' + id, httpOptions)
            .pipe(catchError(null));
    }

    getCodigo(idEntidade: number, idTipo: number): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/codigos/' + idEntidade + '/' + idTipo, httpOptions);
    }

    getCodigoEntidade(idEntidade?: number, idTipo?: number, dataRegisto?: any): Observable<any> {
        // return this.http.get(this.baseUrlCliente + 'clientes/proximoCodigo/' + idEntidade + '/' + idTipo + '/' + dataRegisto);
        return this.http.post(this.baseUrlCliente + 'clientes/proximoCodigo', {
            idEntidade,
            idTipo,
            dataRegisto
        }, httpOptions).pipe(catchError(null));
    }


    getConfCodigo(
        idEntidade: number,
        idTipo: number
    ): Observable<ConfCodigosClientes> {
        return this.http
            .get(
                this.baseUrlCliente +
                'clientes/codigotipoinsercao/' +
                idEntidade +
                '/' +
                idTipo,
                httpOptions
            )
            .pipe(catchError(null));
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }

    /*Post*/
    salvarEstado(model: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientes/estados/', model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    removerEstado(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientes/estados/remover/', modelo, httpOptions)
            .pipe(catchError(this.handleError));
    }

    salvarTipos(model: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientesTipos', model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    removerTipos(model: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientesTipos/remover', model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    salvarTiposArquivos(model: any): Observable<any> {
        return this.http
            .post(this.baseUrlCliente + 'clientesTiposArquivos', model, httpOptions)
            .pipe();
    }

    removerTiposArquivos(model: any): Observable<any> {
        return this.http
            .post(
                this.baseUrlCliente + 'clientes/tiposarquivos/remover',
                model,
                httpOptions
            )
            .pipe();
    }

    salvarAcessos(model: any) {
        return this.http
            .post(this.baseUrlCliente + 'clientes/acessos/', model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    apagarAcesso(model: any) {
        return this.http
            .post(this.baseUrlCliente + 'clientes/acessos/remover/', model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    editarAcesso(model: any) {
        return this.http
            .post(this.baseUrlCliente + 'clientes/acessos/editar/', model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    editarPasswordAcesso(model: any) {
        return this.http
            .post(
                this.baseUrlCliente + 'clientes/editar/'+ model?.Id + '/password/',model,httpOptions
            )
            .pipe(catchError(this.handleError));
    }

    // Caracteristicas
    getCaracteristicas(idClassificacao: number, activo: boolean): Observable<any> {
        return this.http
            .get(this.baseUrlCliente + 'clientes/caracteristicas/' + idClassificacao + '/' + activo, httpOptions)
            .pipe(catchError(null));
    }

    getTiposDadosCaracteristica(): Observable<any> {
        return this.http.get(this.baseUrlCliente + 'clientes/caracteristicas/tiposDados', httpOptions);
    }

    // parametrizacao
    getParametrizacao(): Observable<any> {
        return this.http
            //  .get(this.baseUrlCliente + 'clientes/parametrizacao/', httpOptions)
            .get(this.baseUrlCliente + 'entidades/', httpOptions)
            .pipe(catchError(null));
    }

    eliminarEntidade(idEntidade: number): Observable<any> {
        return this.http
            //  .get(this.baseUrlCliente + 'clientes/parametrizacao/', httpOptions)
            .delete(this.baseUrlCliente + 'entidades/' + idEntidade, httpOptions)
            .pipe();
    }

    getParametrizacaoEntidade(idEntidade: number): Observable<any> {
        return this.http
            //   .get(this.baseUrlCliente + 'clientes/parametrizacao/' + idEntidade, httpOptions)
            .get(this.baseUrlCliente + 'entidades/' + idEntidade, httpOptions)
            .pipe(catchError(null));
    }

    salvarParametrizacao(modelo: any): Observable<any> {
        return this.http
            //EJVC      .post(this.baseUrlCliente + 'clientes/parametrizacao',modelo, httpOptions)
            .post(this.baseUrlCliente + 'entidades', modelo, httpOptions)
            .pipe();
    }

    mudarActivacao(idLogin: number, idCliente: number) {
        return this.http.post<boolean>(`${this.baseUrlCliente}clientes/${idCliente}/acessos/${idLogin}/activacao`, {});
    }

    getNaturezas(): Observable<any> {
        return this.http.get(this.baseUrlCliente + 'entidades/naturezas/', httpOptions);
    }

    getSectores() {
        return this.http.get<Resposta<any[]>>(`${this.baseUrlCliente}/sectores-actividade`);
    }
}
