import {Injectable} from '@angular/core';
import {IVAs} from 'app/shared/models/artigos/IVAs';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from 'environments/environment';
import {catchError} from 'rxjs/internal/operators/catchError';
import {throwError} from 'rxjs/internal/observable/throwError';
import {Estados} from '../../models/financas/documentos/estados';
import {MetodosPagRec} from '../../models/financas/metodosPagRec';
import {CondicoesPagamento} from '../../models/financas/condicoesPagamento';
import {Documentos} from 'app/shared/models/financas/documentos/documentos';
import {FinRetencao} from '../../models/financas/finRetencao';
import {Series} from 'app/shared/models/financas/documentos/series';
import {ConfiguracaoEmpresa} from 'app/shared/models/financas/documentos/configuracaoempresa';
import {ValidarSerie} from 'app/shared/models/financas/documentos/validarSerie';
import {EstadosMotivos} from 'app/shared/models/financas/documentos/estadosmotivos';
import {SaftXml} from 'app/shared/models/financas/documentos/saftxml';
import 'rxjs/add/operator/catch';
import {FiltroDocumentoReferencia} from '../../models/financas/filtro-documento-referencia';
import {EntidadeTesouraria} from 'app/shared/models/financas/area-tesouraria';
import {Resposta} from 'app/shared/models/resposta';
import {LoginService} from '../aplicacao-service/login.service';
import {FiltroMetodoPagamentoDto} from '../../models/financas/filtro-metodo-pagamento.dto';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json'
    })
};

type HttpOptions = {
    headers?: HttpHeaders | { [header: string]: string | string[]; };
    observe?: 'body'; params?: HttpParams | { [param: string]: string | string[]; };
    reportProgress?: boolean; responseType?: 'json' /* or "text" as "json" */;
    withCredentials?: boolean;
}

let get_http_options_text = (): HttpOptions => {
    return {
        headers: {'Content-Type': 'text/plain'},
        observe: 'body',
        responseType: 'text' as 'json',  // @see https://github.com/angular/angular/issues/18586
        withCredentials: true
    };
};


const httpOptionsXml = {
    headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'text/html; charset=UTF-8'
    })
};

@Injectable({
    providedIn: 'root'
})
export class FinancasService {
    baseUrl: string;
    baseUrlFinanca: string;

    constructor(private http: HttpClient, private loginService: LoginService) {
        this.baseUrlFinanca = `${environment.apiFinancas}`;
    }

    getEntidadeTesouraria(): Observable<Resposta<EntidadeTesouraria[]>> {
        return this.http.get<Resposta<EntidadeTesouraria[]>>(
            this.baseUrlFinanca + 'tesouraria/areas',
            httpOptions
        );
    }

    getIVAs(codPais?: string): Observable<IVAs[]> {
        return this.http
            .get(this.baseUrlFinanca + 'ivas/' + codPais, httpOptions)
            .pipe(catchError(null));
    }

    getTaxasCambioMomento(idMoedaLocal: number, idMoedaPretendida: number, dataCambio: string): Observable<any> {
        return this.http
            .get(this.baseUrlFinanca + 'financas/taxasCambio/' + idMoedaLocal + '/' + idMoedaPretendida + '/' + dataCambio, httpOptions)
            .pipe();
    }


    getTiposIVAs(): Observable<any[]> {
        return this.http
            .get(this.baseUrlFinanca + 'tiposTaxasIva/', httpOptions)
            .pipe(catchError(null));
    }

    getEmpresasFi() {
        return this.http.get<any>(
            this.baseUrlFinanca + 'financas/empresas',
            httpOptions
        );
    }

    getEmpresaFi(idEmpresa) {
        return this.http.get(
            this.baseUrlFinanca + 'financas/empresas/' + idEmpresa,
            httpOptions
        );
    }

    getItemsDoc(idDoc: number): Observable<any> {
        return this.http
            .get(this.baseUrlFinanca + 'documentosFa/itens/' + idDoc, httpOptions)
            .pipe();
    }

    gerarSaft(modelo: any): Observable<SaftXml> {
        return this.http
            .post(this.baseUrlFinanca + 'vendas/saftao/', modelo)
            .pipe(catchError(this.handleError));
    }


    gerarFicheiroSaft(
        idEmpresa: any,
        dataInicio: any,
        dataFim: any
    ): Observable<any> {
        return this.http.get(
            this.baseUrlFinanca +
            'Saft/getXmlFile/' +
            'AO' +
            '/' +
            idEmpresa +
            '/' +
            dataInicio +
            '/' +
            dataFim
        );
    }

    getCabecalhosDocumentos(): Observable<Documentos[]> {
        return this.http
            .get(this.baseUrlFinanca + 'vendas')
            .pipe(catchError(null));
    }

    getDocumentosPendentes() {
        return this.http.get(
            this.baseUrlFinanca + 'documentosTesouraria/pendentes'
        );
    }

    getDocumentosFA(filtro: any): Observable<any> {
        return this.http.post(this.baseUrlFinanca + 'vendas', filtro, httpOptions).pipe();
    }

    getDocumentosFAInscricoes(filtro: any): Observable<any> {
        return this.http.post(this.baseUrlFinanca + 'vendas/realizacoesInscricoes', filtro, httpOptions).pipe();
    }

    // Email do Documento
    getEmailDocumento(idDocumento: number): Observable<any> {
        return this.http
            .get(this.baseUrlFinanca + 'vendas/email/' + idDocumento, httpOptions)
            .pipe();
    }

    enviarEmailFactura(value: any): Observable<any> {
        return this.http
            .post(this.baseUrlFinanca + 'vendas/email', value, httpOptions)
            .pipe();
    }

    // Email de tesouraria

    getEmailTesouraria(idRecibo: number): Observable<any> {
        return this.http
            .get(this.baseUrlFinanca + 'documentosTesouraria/email/' + idRecibo, httpOptions)
            .pipe();
    }

    // Anexo
    getAnexoDocumento(idDocumento: number): Observable<any> {
        return this.http
            .get(this.baseUrlFinanca + 'vendas/anexo/' + idDocumento, httpOptions)
            .pipe();
    }

    getDocumentosFAListagemPDF(filtro: any): Observable<any> {
        return this.http
            .post(this.baseUrlFinanca + 'vendas/ListagemPDF', filtro, httpOptions)
            .pipe();
    }

    getItensCompras(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlFinanca + 'compras/itens', modelo, httpOptions)
            .pipe();
    }

    getItensDocumentosFA(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlFinanca + 'vendas/Itens', modelo, httpOptions)
            .pipe();
    }

    getDetalhesDocumentoFA(idDocumento: number) {
        return this.http
            .get(this.baseUrlFinanca + 'vendas/' + idDocumento, httpOptions)
            .pipe();
    }

    getDocumentoFAImpressao(idDocumento: number, codFormatoImpressao?: string) {
        return this.http
            .get(
                this.baseUrlFinanca + 'vendas/imprimirDocumento/' + idDocumento+"/"+codFormatoImpressao, httpOptions
            )
            .pipe();
    }

    getDocumentoComprasImpressao(idDocumento: number) {
        return this.http
            .get(
                this.baseUrlFinanca + 'compras/imprimirDocumento/' + idDocumento, httpOptions
            )
            .pipe();
    }

    getDocumentosFAPrevisualizar(documento: any): Observable<any> {
        return this.http
            .post(this.baseUrlFinanca + 'vendas/previsualizarDocumento', documento, httpOptions)
            .pipe();
    }


    getDocumentoFA(
        numeroDocumento: number,
        idTipo: number,
        idSerie: number,
        idEmpresa: number
    ): Observable<Documentos> {
        return this.http.get(
            this.baseUrlFinanca +
            'vendas/' +
            idEmpresa +
            '/' +
            idSerie +
            '/' +
            idTipo +
            '/' +
            numeroDocumento, httpOptions
        );
    }

    getTiposDocumentos(): Observable<any> {
        return this.http
            .get(this.baseUrlFinanca + 'vendas/tipos', httpOptions)
            .pipe(catchError(null));
    }

    getTipoDocumento(idTipoDocumento: number): Observable<any> {
        return this.http
            .get(this.baseUrlFinanca + 'vendas/tipo/' + idTipoDocumento, httpOptions)
            .pipe(catchError(null));
    }

    criartipoDocumento(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlFinanca + 'vendas/tipo/', modelo, httpOptions)
            .pipe();
    }

    eliminartipoDocumento(idTipoDocumento: number): Observable<any> {
        return this.http
            .delete(
                this.baseUrlFinanca + 'vendas/tipo/remover/' + idTipoDocumento, httpOptions)
            .pipe(catchError(null));
    }

    editartipoDocumento(modelo: any): Observable<any> {
        return this.http
            .post(this.baseUrlFinanca + 'vendas/tipo/actualizar', modelo, httpOptions)
            .pipe();
    }

    getEstadosDocumentos(): Observable<Estados[]> {
        return this.http
            .get(this.baseUrlFinanca + 'documentos/estados', httpOptions)
            .pipe(catchError(null));
    }

    getEstadosPagamento(): Observable<any> {
        return this.http
            .get(this.baseUrlFinanca + 'documentos/estadosPagamento', httpOptions)
            .pipe();
    }

    getCategoriasDocumentos(): Observable<Estados[]> {
        return this.http
            .get(this.baseUrlFinanca + 'documentos/categorias', httpOptions)
            .pipe(catchError(null));
    }

    getFormatosImpressao(): Observable<any> {
        return this.http
            .get(this.baseUrlFinanca + 'vendas/formatosImpressao', httpOptions)
            .pipe();
    }

    getMovimentosDocumentos(): Observable<Estados[]> {
        return this.http
            .get(this.baseUrlFinanca + 'financas/movimentos', httpOptions)
            .pipe(catchError(null));
    }

    getEstadoDocumento(id: number): Observable<Estados> {
        return this.http
            .get(this.baseUrlFinanca + 'documentos/estado/' + id, httpOptions)
            .pipe(catchError(null));
    }

    getProximoNumero(
        idEmpresa: number,
        idSerie: number,
        idTipoDocumento: number
    ): Observable<any> {
        return this.http.get(
            this.baseUrlFinanca +
            'vendas/numerodocumento/' +
            idEmpresa +
            '/' +
            idSerie +
            '/' +
            idTipoDocumento, httpOptions
        ).pipe();
    }

    getMetodosPagRec(usadaPagamentos: boolean): Observable<any> {
        return this.http.get(
            this.baseUrlFinanca + 'metodosPagRec/' + usadaPagamentos ? 'pag' : '', httpOptions
        );
    }

    getMetodoPagRec(id: number): Observable<MetodosPagRec> {
        return this.http
            .get(this.baseUrlFinanca + 'MetodosPagRec/' + id, httpOptions)
            .pipe(catchError(null));
    }

    getMetodosPagamento(usadaPagamentos?: boolean): Observable<any> {
        const opcao = usadaPagamentos ? '/pag' : '';
        return this.http.get(this.baseUrlFinanca + 'metodosPagRec' + opcao, httpOptions);
    }

    getMetodosPagamentos(path: string) {
        return this.http.get<any[]>(`${this.baseUrlFinanca}metodosPagRec/${path}`, httpOptions);
    }

    getMetodosPagamentosTemp(filtro: FiltroMetodoPagamentoDto) {
        return this.http.post<any>(`${this.baseUrlFinanca}metodosPagRec/obter`, filtro);
    }

    getPendentesFiltro(): Observable<any> {
        return this.http
            .get(this.baseUrlFinanca + 'compras/filtroPendente/', httpOptions)
            .pipe();
    }

    getCondicoesPagamentos() { // : Observable<CondicoesPagamento[]>
        return this.http.get<Resposta<any[]>>(this.baseUrlFinanca + 'condicoesPagamento', httpOptions);
    }

    getCondicaoPagamento(id: number): Observable<CondicoesPagamento> {
        return this.http
            .get(this.baseUrlFinanca + 'condicaopagamento/' + id, httpOptions)
            .pipe(catchError(null));
    }

    activarCondicaoPagamento(opcao: string, idCondicaoPagamento: number) {
        return this.http.post(
            this.baseUrlFinanca +
            'condicoesPagamento/' +
            opcao +
            '/' +
            idCondicaoPagamento,
            httpOptions
        );
    }

    getDataVencimentoDoc(dataDocumento: any, idCondicaoPagamento) {
        return this.http.post<Resposta<any>>(this.baseUrlFinanca + 'condicoesPagamento/dataVencimento',
            {
                dataDocumento,
                idCondicaoPagamento
            }, httpOptions
        );
    }

    activarMetodoPag(acccao: string, recPag: string, codMetodoPagRec: string) {
        return this.http.post(
            this.baseUrlFinanca +
            'metodosPagRec/' +
            acccao +
            '/' +
            recPag +
            '/' +
            codMetodoPagRec,
            httpOptions
        );
    }

    getEmpresasTiposConta(idEmpresa?: number, idTipoConta?: number): any {
        return this.http.get(
            this.baseUrlFinanca +
            'bancosContas/contasEmpresaTipoConta/' +
            idEmpresa +
            '/' +
            idTipoConta, httpOptions
        );
    }

    getTiposContaEmpresa(idEmpresa?: number, idTipoConta?: number): any {
        return this.http.get(
            this.baseUrlFinanca +
            'bancosContas/contasEmpresaTipoConta/' +
            idEmpresa +
            '/' +
            idTipoConta, httpOptions
        );
    }

    getContasEmpresa(idEmpresa: number): Observable<any> {
        return this.http.get(
            this.baseUrlFinanca +
            'bancosContas/contasEmpresa/' +
            idEmpresa, httpOptions
        ).pipe();
    }


    gravarDocumentoFA(modelo: any): Observable<any> {
        return this.http.post(
            this.baseUrlFinanca + 'vendas/Gravar',
            modelo,
            httpOptions
        );
    }

    // gerarToken(model: any) {
    //   return this.http
    //     .post(this.baseUrlFinanca + 'gerartoken/', model)
    //     .pipe(catchError(null));
    // }

    getRetencoes(codPais: string): Observable<any> {
        return this.http
            .get(this.baseUrlFinanca + 'retencoes/' + codPais, httpOptions)
            .pipe();
    }

    getRetencoesFA(idCliente, codPais, dataDocumento): Observable<FinRetencao> {
        return this.http
            .get(
                this.baseUrlFinanca +
                'Clientes/ClienteRetencoes/' +
                idCliente +
                '/' +
                codPais +
                '/' +
                dataDocumento, httpOptions
            )
            .pipe(
                catchError((err: any) => {
                    return throwError(err);
                })
            );
    }

    getRetencao(codPais: string, idRetencao: number): Observable<FinRetencao> {
        return this.http
            .get(this.baseUrlFinanca + 'retencoes/' + codPais + '/' + idRetencao, httpOptions)
            .pipe(catchError(null));
    }

    getClienteFA(idCliente, codArea): Observable<any> {
        return this.http
            .get(this.baseUrlFinanca + 'clientes/' + idCliente + '/' + codArea, httpOptions)
            .pipe();
    }

    // Series
    getSeriesTodas(): Observable<Series[]> {
        return this.http.get(this.baseUrlFinanca + 'series').pipe(catchError(null));
    }

    // Series
    getSeriesTodasActivas(): Observable<Series[]> {
        return this.http.get(this.baseUrlFinanca + 'series/activas').pipe(catchError(null));
    }

    //AC eliminar metodo
    // getSeries(): Observable<any> {
    //   return this.http.get<any>(this.baseUrlFinanca + 'series');
    // }

    getTodasSeries(modelo: any): Observable<any> {
        return this.http.post<any>(this.baseUrlFinanca + 'series/obter', modelo, httpOptions);
    }


    criarSerie(modelo: any, idSerie) {
        if (idSerie) {
            return this.http.post(
                this.baseUrlFinanca + 'series/actualizar/' + idSerie,
                modelo,
                httpOptions
            );
        } else {
            return this.http.post(
                this.baseUrlFinanca + 'series',
                modelo,
                httpOptions
            );
        }
    }

    eliminarSerie(idSerie: any) {
        return this.http.post(
            this.baseUrlFinanca + 'series/eliminar/' + idSerie,
            httpOptions
        );
    }

    activarSerie(opcao: any, idSerie: number) {
        return this.http.post(
            this.baseUrlFinanca + 'series/' + opcao + '/' + idSerie,
            httpOptions
        );
    }

    actualizarSerie(idSerie: any) {
        return this.http.post(
            this.baseUrlFinanca + 'series/actualizar/' + idSerie,
            httpOptions
        );
    }

    getTiposConta() {
        return this.http.get(
            this.baseUrlFinanca + 'bancosContas/tiposContas',
            httpOptions
        );
    }

    verificarSeriesAnoCorrente(
        idEmpresa: number,
        idTipoDoc: number
    ): Observable<ValidarSerie> {
        return this.http
            .get(
                this.baseUrlFinanca +
                'documento/verificarSeriesAnoCorrente/' +
                idEmpresa +
                '/' +
                idTipoDoc, httpOptions
            )
            .pipe(catchError(this.handleError));
    }

    getConfiguracaoEmpresa(idEmpresa: number): Observable<ConfiguracaoEmpresa> {
        return this.http
            .get(this.baseUrlFinanca + 'documentos/configuracaoEmpresa/' + idEmpresa, httpOptions)
            .pipe(catchError(null));
    }

    getEstadosPeloTipoDocumento(idTipoDocumento: number): Observable<Estados[]> {
        return this.http
            .get(
                this.baseUrlFinanca +
                'documentos/estadoTipoDocumento/' +
                idTipoDocumento, httpOptions
            )
            .pipe(catchError(null));
    }

    getEstadosMotivos(codEstadoDocumento?: string): Observable<EstadosMotivos[]> {
        return this.http
            .get(
                this.baseUrlFinanca + 'vendas/motivosEstado/' + codEstadoDocumento, httpOptions
            )
            .pipe(catchError(null));
    }

    getDocumentosMotivos(): Observable<EstadosMotivos[]> {
        return this.http
            .get(
                this.baseUrlFinanca + 'vendas/motivosDocumento', httpOptions
            )
            .pipe(catchError(null));
    }


    gravarMotivo(modelo: any) {
        return this.http
            .post(
                this.baseUrlFinanca + 'vendas/motivosEstado',
                modelo,
                httpOptions
            )
            .pipe(catchError(this.handleError));
    }

    alterarEstadoDocumentoFA(doc: any): Observable<any> {
        let modelo = {
            IdDocumento: doc.idDocumento,
            IdMotivo: doc.idMotivo,
            IdUtilizador: doc.idUtilizador,
            Utilizador: null,
        };
        return this.http
            .post(
                this.baseUrlFinanca +
                'vendas/anular/', modelo,
                httpOptions
            )
            .pipe(catchError(this.handleError));
    }

    validarSerie(idEmpresa: number, idSerie: number, data: string) {
        return this.http
            .get(
                this.baseUrlFinanca +
                'documento/validarSerie/' +
                idEmpresa +
                '/' +
                idSerie +
                '/' +
                data, httpOptions
            )
            .pipe(catchError(this.handleError));
    }

    filtrarCabecalhos(modelo: any) {
        return this.http
            .post(this.baseUrlFinanca + 'documentos/filtrados' + modelo, httpOptions)
            .pipe(catchError(null));
    }

    //endPoints para obterEntidade das áres ... Início ...
    getEntidadesCliente() {
        return this.http.get<any>(this.baseUrlFinanca + 'clientes', httpOptions);
    }

    getEntidadesClientePorArea(codAreaTesouraria: string) {
        return this.http.get<any>(this.baseUrlFinanca + 'clientes/' + codAreaTesouraria, httpOptions);
    }


    getEntidadesFornecedor() {
        return this.http.get<any>(this.baseUrlFinanca + 'clientes/fornecedores', httpOptions);
    }

    getEntidadesEstado() {
        return this.http.get<any>(this.baseUrlFinanca + 'clientes/estado', httpOptions);
    }

    getEntidadesColaborador() {
        return this.http.get<any>(this.baseUrlFinanca + 'clientes/colaboradores', httpOptions);
    }

//endPoints para obterEntidade das áres ... Fim ...

    getTaxasCambio(filtro: any): Observable<any> {
        return this.http.post(this.baseUrlFinanca + 'financas/obterTaxasCambio', filtro, httpOptions);
    }

    gravarTaxasCambio(modelo: any) {
        return this.http.post(
            this.baseUrlFinanca + 'financas/inserirTaxaCambio',
            modelo,
            httpOptions
        );
    }

    getConfigTaxaCambio(
        idMoedaLocal?: number,
        idMoedaPretendida?: number,
        dataInicio?: any,
        dataFim?: any
    ): Observable<any> {
        return this.http.get(
            this.baseUrlFinanca +
            'financas/taxasCambio/' +
            idMoedaLocal +
            '/' +
            idMoedaPretendida +
            '/' +
            dataInicio +
            '/' +
            dataFim, httpOptions
        );
    }

    getNumeracaoDocumentos(): Observable<any> {
        return this.http.get(
            this.baseUrlFinanca + 'vendas/numeracaoDocumentos',
            httpOptions
        );
    }

    getConfigEmpresas(): Observable<any> {
        return this.http.get(
            this.baseUrlFinanca + 'financas/empresas',
            httpOptions
        );
    }

    getConfigEmpresa(idEmpresa) {
        return this.http.get(
            this.baseUrlFinanca + 'financas/empresas/' + idEmpresa,
            httpOptions
        );
    }

    getDocsTePendentesRetencao(idEmpresa: number, idCliente: number) {
        return this.http
            .get(
                this.baseUrlFinanca +
                'documentosTesouraria/pendentesRetencao/' +
                idEmpresa +
                '/' +
                idCliente,
                httpOptions
            )
            .pipe();
    }

    getTiposDocumentosTe(): Observable<any> {
        return this.http.get(this.baseUrlFinanca + 'documentosTesouraria/tipos', httpOptions);
    }

    getDocumentosTEPendentes(filtroTe: any) {
        return this.http.post(this.baseUrlFinanca + 'documentosTesouraria/pendentes', filtroTe, httpOptions);
    }

    getDocumentosTEPdf(filtroTe: any) {
        return this.http.post(this.baseUrlFinanca + 'documentosTesouraria/pdf', filtroTe, httpOptions).pipe();
    }

    getTipoDocumentoTe(idTipo: number): Observable<any> {
        return this.http
            .get(
                this.baseUrlFinanca + 'documentosTesouraria/tipo/' + idTipo,
                httpOptions
            )
            .pipe();
    }

    getProximoNumeroTe(
        idEmpresa: number,
        idSerie: number,
        codTipoDocumento: string
    ) {
        return this.http.get(
            this.baseUrlFinanca +
            'documentosTesouraria/proximoNumero/' +
            idEmpresa +
            '/' +
            idSerie +
            '/' +
            codTipoDocumento, httpOptions
        );
    }

    gravarDocumentoTe(documemto: any) {
        return this.http.post(
            this.baseUrlFinanca + 'documentosTesouraria/gravar',
            documemto,
            httpOptions
        );
    }


    getDocumentosTe(filtro: any): Observable<any> {
        return this.http.post(
            this.baseUrlFinanca + 'documentosTesouraria',
            filtro,
            httpOptions
        );
    }

    getDocumentoTe(idDocumento: number): Observable<any> {
        return this.http.get(
            this.baseUrlFinanca + 'documentosTesouraria/' + idDocumento,
            httpOptions
        );
    }

    getDocumentoFAPagar(idDocumento: number): Observable<any> {
        return this.http
            .get(
                this.baseUrlFinanca + 'documentosTesouraria/pagar/' + idDocumento, httpOptions
            )
            .pipe();
    }

    pesquisarDocumentoTe(
        idEmpresa: number,
        idSerie: number,
        idTipo: number,
        numeroDoc: number
    ): Observable<Documentos> {
        return this.http.get(
            this.baseUrlFinanca +
            'documentosTesouraria/' +
            idEmpresa +
            '/' +
            idSerie +
            '/' +
            idTipo +
            '/' +
            numeroDoc, httpOptions
        );
    }

    // anular/{idUtilizador}/{idDocumento}/{idMotivo}
    alterarDocumentoTe(
        idUtilizador: number,
        idDocumento: number,
        idMotivo: number
    ) {
        return this.http.post(
            this.baseUrlFinanca +
            'documentosTesouraria/anular/' +
            idUtilizador +
            '/' +
            idDocumento +
            '/' +
            idMotivo,
            httpOptions
        );
    }

    anularDocumento(filtro: any) {
        return this.http.post(this.baseUrlFinanca + 'documentosTesouraria/anular', filtro);
    }

    getMotivosAnulacaoTe() {
        return this.http.get(
            this.baseUrlFinanca + 'documentosTesouraria/motivosAnulacao',
            httpOptions
        );
    }

    gravarMotivoAnulacaoTe(motivoAnulacao: string) {
        return this.http.post(
            this.baseUrlFinanca +
            'documentosTesouraria/motivosAnulacao/' +
            motivoAnulacao,
            httpOptions
        );
    }

    // Novos endpoints //
    getMotivosFA() {
        return this.http.get(
            this.baseUrlFinanca + 'vendas/motivos',
            httpOptions
        );
    }

    getMotivosEstadosFA() {
        return this.http.get(
            this.baseUrlFinanca + 'vendas/motivosEstado',
            httpOptions
        );
    }

    gravarMotivosEstadosFA() {
        return this.http.get(
            this.baseUrlFinanca + 'vendas/motivosEstado',
            httpOptions
        );
    }

    getMotivoEstadoFA(codEstado: string) {
        return this.http.get<Resposta<any[]>>(
            this.baseUrlFinanca + 'vendas/motivosEstado/' + codEstado,
            httpOptions
        );
    }

    actualizarNumeroImpressoes(idDocumento?) {
        return this.http.get(
            this.baseUrlFinanca + 'vendas/actualizarImpressoes/' + idDocumento,
            httpOptions
        );
    }

    actualizarNumeroImpressoesTe(idDocumento?) {
        return this.http.post(
            this.baseUrlFinanca +
            'documentosTesouraria/actualizarImpressoes/' +
            idDocumento,
            httpOptions
        );
    }

    getBancos(): Observable<any> {
        return this.http.get<any>(
            this.baseUrlFinanca + 'bancosContas/bancos',
            httpOptions
        );
    }

    getBanco(idBanco) {
        return this.http.get(
            this.baseUrlFinanca + 'bancosContas/bancos/' + idBanco,
            httpOptions
        );
    }

    gravarBanco(modelo: any, idBanco?: any) {
        if (!idBanco) {
            return this.http.post(
                this.baseUrlFinanca + 'bancosContas/bancos',
                modelo,
                httpOptions
            );
        } else {
            return this.http.post(
                this.baseUrlFinanca + 'bancosContas/bancoUpdate',
                modelo,
                httpOptions
            );
        }
    }

    removerBanco(idBanco) {
        return this.http.get(
            this.baseUrlFinanca + 'bancosContas/bancoDelete/' + idBanco,
            httpOptions
        );
    }

    getContas(): Observable<any> {
        return this.http.get<any>(
            this.baseUrlFinanca + 'bancosContas/contas',
            httpOptions
        );
    }

    getConta(idConta) {
        return this.http.get(
            this.baseUrlFinanca + 'bancosContas/contas/' + idConta,
            httpOptions
        );
    }

    gravarConta(modelo: any) {
        return this.http.post(
            this.baseUrlFinanca + 'bancosContas/contas',
            modelo,
            httpOptions
        );

        // if (!idBanco) {

        // } else {
        //     return this.http.post(
        //         this.baseUrlFinanca + 'bancosContas/contaUpdate',
        //         modelo,
        //         httpOptions
        //     );
        // }
    }

    removerConta(idConta) {
        return this.http.post(
            this.baseUrlFinanca + 'bancosContas/contasDelete/' + idConta,
            httpOptions
        );
    }

    // Contas Correntes //
    getContaCorrenteCliente(filtro: any) {
        return this.http.post(this.baseUrlFinanca + 'tesouraria/contaCorrente', filtro, httpOptions);
    }

    getTiposDocumentosTeClientes() {
        return this.http.get(this.baseUrlFinanca + 'documentosTesouraria/tipos', httpOptions);
    }

    imprimirDocTE(idDocumento: number): Observable<any> {
        return this.http.get(
            this.baseUrlFinanca + 'documentosTesouraria/imprimirRecibo/' + idDocumento, httpOptions
        );
    }

    getAnexoRecibo(idRecibo: number): Observable<any> {
        return this.http.get(
            this.baseUrlFinanca + 'documentosTesouraria/anexo/' + idRecibo, httpOptions
        );
    }

    enviarEmailrRecibo(value: any): Observable<any> {
        return this.http
            .post(this.baseUrlFinanca + 'documentosTesouraria/email', value, httpOptions)
            .pipe();
    }

    handleError(error: HttpErrorResponse) {
        let erroMsg = null;
        if (error.error instanceof ErrorEvent) {
            erroMsg = error;
        } else {
            erroMsg = error;
        }
        return throwError(erroMsg);
    }

// Saldos
    getSaldos(filtro): Observable<any> {
        return this.http.post(
            this.baseUrlFinanca + 'tesouraria/saldos', filtro, httpOptions
        );
    }

    getTodasPermissoes(filtro: any) {
        return this.http.post(this.baseUrlFinanca + 'permissoes/obter', filtro, httpOptions);
    }

    getPermissoesUtilizador(filtro) {
        return this.http.post(this.baseUrlFinanca + 'permissoes/obterUtilizadores', filtro, httpOptions);
    }

    editarPermissoesFAUtilizador(permissoesAdicionar: any) {
        return this.http.post(this.baseUrlFinanca + 'permissoes/editarUtilizadores', permissoesAdicionar, httpOptions);
    }

    // Documentos Pendentes ara geracao de Referencia //
    pesquisarDocsReferencia(filtroPesquisa: FiltroDocumentoReferencia) {
        return this.http.post(this.baseUrlFinanca + 'vendas/pesquisaDocumentosReferencia', filtroPesquisa, httpOptions);
    }

    getArmazens(): Observable<any> {
        return this.http.post(this.baseUrlFinanca + 'artigos/armazens', this.loginService.getUtilizadorSessao(), httpOptions);
    }

    getConfiguracao() {
        return this.http.get<Resposta<any>>(`${this.baseUrlFinanca}configuracoes/vendas`);
    }
}
