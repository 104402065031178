import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginService } from '../aplicacao-service/login.service';

@Injectable( { providedIn: 'root' })
export class AuthGuard implements CanActivate {
  public authToken;
  private isAuthenticated = true;

  constructor(private router: Router, private loginService: LoginService) {}
  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.loginService.loggedIn()) {
      return true;
    } else {
      this.router.navigate(['/sessoes/login']);
    return false;
    }

  }
}
