import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {Util} from '../models/generico/util';

@Directive({
  selector: '[appBlockSpace]'
})
export class BlockSpaceDirective implements  OnInit {
  private regex: RegExp = new RegExp(/^\d*\,?\d{0,2}$/g);
  private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', '-', 'Space', ];
@Input() valorMax;
valorActual: any;
  constructor(private el: ElementRef) {
    this.valorActual = this.el.nativeElement.value;
  }

  ngOnInit() {
    console.log('Valor do Input agora: ',  this.valorActual)
  }
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    console.log('Pressed Key',  event.key);
    console.log('Key',  event.key);
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }


    console.log('Valor do input: ', this.valorActual);
    const position = this.el.nativeElement.selectionStart;
   // const next: string = [valorActual.slice(0, position), event.key === ',' ? ',' : event.key, valorActual.slice(position)].join('');
  //  if (Util.convertToFloat(next)) {console.log('Max: ', next); event.preventDefault(); }
   // if (next && !String(next).match(this.regex)) { event.preventDefault(); }
  }
}
