import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import localePt from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {rootRouterConfig} from './app.routing';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';

import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ErrorHandlerService} from '@services/error-handler.service';
import {TokenInterceptor} from './shared/interceptors/token.interceptor';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {QuillModule} from 'ngx-quill';
import {NgxMaskModule} from 'ngx-mask';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AgmCoreModule} from '@agm/core';
import * as Quill from 'quill';
const parchment = Quill.import('parchment');
const queryBlock = parchment.query('block');
queryBlock.tagName = 'DIV';
Quill.register(queryBlock, true);
registerLocaleData(localePt);

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,

        PerfectScrollbarModule,
        // JwtModule.forRoot({ config: { tokenGetter, }, }),
        QuillModule.forRoot(),
        NgxMaskModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBjXZ16g0f8IcIOq4A1GxxYJHjOlvfLOxI'
        }),
        TranslateModule.forRoot(
            {
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                }
            }
        ),
        NgxDaterangepickerMd.forRoot(),
        RouterModule.forRoot(rootRouterConfig, {useHash: false, relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload'}),
        TooltipModule.forRoot(),
    ],
    declarations: [AppComponent],
    providers: [
        {provide: ErrorHandler, useClass: ErrorHandlerService},
        // { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
        {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
        // REQUIRED IF YOU USE JWT AUTHENTICATION
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: CacheInterceptor,
        //   multi: true,
        // },
        {provide: MAT_DATE_LOCALE, useValue: 'pt-PT'},
        {provide: LOCALE_ID, useValue: 'pt'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
