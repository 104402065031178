import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';
import {TiposEmpregos} from 'app/shared/models/recursos-humanos/tiposempregos';
import {GrausAcademicos} from 'app/shared/models/recursos-humanos/grausacademicos';
import {Areas} from 'app/shared/models/recursos-humanos/areas';
import {Funcoes} from 'app/shared/models/recursos-humanos/funcoes';
import {Cargos} from 'app/shared/models/recursos-humanos/cargos';
import {Candidaturas} from 'app/shared/models/recursos-humanos/candidaturas';
import {TiposOutrasInformacoes} from 'app/shared/models/recursos-humanos/tiposoutrasinformacoes';
import {Vagas} from '../../models/recrutamento/vagas';
import {Idioma} from '../../models/recursos-humanos/idioma';
import {EstadosVagas} from 'app/shared/models/recrutamento/estadosvagas';
import {Empregadores} from 'app/shared/models/recrutamento/empregadores';
import {Candidatos} from 'app/shared/models/recrutamento/candidatos';
import {EstadosCandidaturas} from 'app/shared/models/recrutamento/estadoscandidatura';
import {LoginService} from '../aplicacao-service/login.service';
import {rhService} from './rh.service';
import {Posicao} from 'app/shared/models/recursos-humanos/posicao';
import {Resposta} from 'app/shared/models/resposta';
import {Unidadeorganizativa} from 'app/shared/models/recursos-humanos/unidadeOrganizativa';
import {Pais} from 'app/shared/models/recursos-humanos/pais';
import {EstruturaOrganizativa} from 'app/shared/models/recursos-humanos/EstruturaOrganizativa';
import {TipoUnidadeOrganizativa} from 'app/shared/models/recursos-humanos/tipo-unidade-organizativa';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
    }),
};

@Injectable({
    providedIn: 'root',
})
export class RecursosHumanosService extends rhService<any> {
    baseUrl: string;
    baseUrlRH: string;
    baseUrlAPL: string;
    utilizadorSessao: any = {};

    constructor(
        private http: HttpClient,
        private _loginService: LoginService,
        private rhservice: rhService<any>
    ) {
        super(http);
        this.baseUrlRH = `${environment.apiRHumanos}`;
        this.baseUrlAPL = `${environment.apiAplicacao}`;
        this.utilizadorSessao.utilizadorSessao = this._loginService.getUtilizadorSessao();
        this.utilizadorSessao = {utilizadorSessao: this._loginService.getUtilizadorSessao()};
    }


    obterPosicoes(): Observable<Resposta<Posicao[]>> {
        return this.rhservice.listar<Resposta<Posicao[]>>('posicoes');
    }

    obterPosicao(id: number): Observable<Resposta<Posicao>> {
        return this.rhservice.obterPeloId<Resposta<Posicao>>('posicoes', id);

    }

    adicionarPosicao(posicao: Posicao): Observable<Resposta<null>> {
        return this.rhservice.adicionar<Resposta<null>>('posicoes', posicao);
    }

    removerPosicao(id: number): Observable<Resposta<null>> {
        return this.rhservice.remover<Resposta<null>>('posicoes/remover', id);
    }


    //  Estrutura Organizativa Linear
    obterUnidadeOrganizativaPorIdEstrutura(id: number): Observable<Resposta<Unidadeorganizativa[]>> {
        return this.rhservice.obterPeloId<Resposta<Unidadeorganizativa[]>>(
            'unidadesOrganizativas/unidadesOrganizativasPorEstrutura', id);

    }

    //  Para o Organograma ...
    obterOrganogramaPorEstrutura(id: number): Observable<Resposta<Unidadeorganizativa[]>> {
        return this.rhservice.obterPeloId<Resposta<Unidadeorganizativa[]>>(
            'unidadesOrganizativas/organogramaPorEstrutura', id);

    }


    getTiposEmpregos() {
        return this.http.get<TiposEmpregos[]>(this.baseUrlRH + 'tiposempregos', httpOptions);
    }

    getGrausAcademicos() {
        return this.http.get<GrausAcademicos[]>(this.baseUrlRH + 'grausacademicos', httpOptions);
    }

    getNivelAcademicos(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'grausParentesco/niveisEscolaridade', httpOptions);
    }

    getGrauParentesco(): Observable<any> {
        return this.http
            .get(this.baseUrlRH + 'grausParentesco', httpOptions);
    }

    getTitulos() {
        return this.http.get<Areas[]>(this.baseUrlAPL + 'aplicacao/titulos', httpOptions);
    }

    getAreas() {
        return this.http.get<Areas[]>(this.baseUrlRH + 'areas', httpOptions);
    }

    getIdiomas() {
        return this.http.get<Idioma[]>(this.baseUrlRH + 'idiomas', httpOptions);
    }

    getEstadosVagas() {
        return this.http.get<EstadosVagas[]>(this.baseUrlRH + 'estadosvaga', httpOptions);
    }

    getFuncoes() {
        return this.http.get<Funcoes[]>(this.baseUrlRH + 'funcoes', httpOptions);
    }

    //  obter os cargos
    obterCargos(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'cargo', httpOptions);
    }

    obterCargo(idCargo: number): Observable<any> {
        return this.http.get(this.baseUrlRH + 'cargo/' + idCargo, httpOptions);
    }


    //  Adiciona e editar
    adicionarCargo(cargo: Cargos) {
        return this.http.post(this.baseUrlRH + 'cargo', cargo, httpOptions);
    }


    removerCargo(idCargo: number): Observable<any> {
        return this.http.post<any>(this.baseUrlRH + 'cargo/remover/' + idCargo, httpOptions);
    }


    //  obter os graus Parentescos
    obterGrausParentescos() {
        return this.http.get<any>(this.baseUrlRH + 'grausParentesco', httpOptions);
    }

    obterGrauParentesco(idGrauParentesco: number): Observable<any> {
        return this.http.get(this.baseUrlRH + 'grausParentesco/' + idGrauParentesco, httpOptions);
    }


    //  Adiciona e editar
    adicionarGrauParentesco(grauParentesco: any) {
        return this.http
            .post(this.baseUrlRH + 'grausParentesco', grauParentesco, httpOptions);
    }


    removerGrauParentesco(idGrauParentesco: number) {

        return this.http.post<Resposta<any>>(this.baseUrlRH + 'grausParentesco/remover/' + idGrauParentesco, httpOptions);
    }


    getTiposOutrasInformacoes() {
        return this.http.get<TiposOutrasInformacoes[]>(this.baseUrlRH + 'tiposoutrasinformacoes', httpOptions);
    }

    removerTipoUnidadeOrganizativa(idTipoUnidadeOrg: number) {
        return this.http
            .post(this.baseUrlRH + 'tiposUnidadesOrganizativas/remover/'
                + idTipoUnidadeOrg, httpOptions);
    }

    getCandidaturas() {
        return this.http.get<Candidaturas[]>(this.baseUrlRH + 'candidaturas', httpOptions);
    }

    getCandidatura(idCandidato: number, idVaga: number): Observable<Candidaturas> {
        return this.http
            .get(
                this.baseUrlRH + 'candituracandidato/' + idCandidato + '/' + idVaga,
                httpOptions
            );
    }

    getEmpregadores() {
        return this.http.get<Empregadores[]>(this.baseUrlRH + 'empregadores', httpOptions);
    }

    getCandidatos() {
        return this.http.get<Candidatos[]>(this.baseUrlRH + 'candidatos', httpOptions);
    }

    getEstadosCandidaturas() {
        return this.http.get<EstadosCandidaturas[]>(this.baseUrlRH + 'estadoscandidatura', httpOptions);
    }

    getEstadoCandidaturaDefault() {
        return this.http.get<EstadosCandidaturas>(this.baseUrlRH + 'estadocandidaturadefault', httpOptions);
    }

    getVagas(model: any) {
        return this.http.post<Vagas[]>(this.baseUrlRH + 'vagas/', model, httpOptions);
    }

    salvarVagas(model: any) {
        return this.http
            .post(this.baseUrlRH + 'salvarvagas/', model, httpOptions);
    }

    salvarCandidatura(model: any) {
        return this.http
            .post(this.baseUrlRH + 'candidaturas/', model, httpOptions);
    }

    //  Metodos modulo RH

    getColaboradores(objectoListagem: any): Observable<any> {
        return this.http.post<any>(this.baseUrlRH + 'Colaboradores/listagem', objectoListagem, httpOptions);
    }

    async getColaboradorById(obj: any): Promise<Observable<any>> {
        // let result = this.http.post(this.baseUrlRH + 'colaboradores/detalhe', obj).
        return await this.http.post(this.baseUrlRH + 'colaboradores/detalhe', obj);


        // pipe(
        //   catchError( (error) => {
        //     console.log('HttpError', error);
        //     return new Observable<any>();
        //   })
        // )
    }

    //  --------------  modelo para filtros-----------------

    getColaboradoresFiltro(model: any): Observable<any> {
        return this.http.post(this.baseUrlRH + 'filtros', model).pipe();
    }

    getColaboradoresFiltroC(model: any): Observable<any> {
        return this.http.post(this.baseUrlRH + 'Colaboradores', model).pipe();
    }

    addColaboradores(val: any): any {
        console.log('ADD COLABORAD .:', val);

        return this.http.post(this.baseUrlRH + 'Colaboradores', val, this.utilizadorSessao).pipe();
    }

    updateColaboradores(val: any): Observable<any> {
        return this.http.put(this.baseUrlRH + 'Colaboradores...::: ', val).pipe();
    }

    deleteColaboradores(id: number): Observable<any> {

        return this.http.post<any>(this.baseUrlRH + 'colaboradores/remover/' + id, this.utilizadorSessao).pipe();
    }

    //  ---------------------------- pegar dados externos para modulo RH ---------------------------------
    getListaGrauParentesco(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'parentesco').pipe();
    }

    getGrauParentescoById(id: number): Observable<any> {
        return this.http.get(this.baseUrlRH + 'parentesco/' + id).pipe();
    }

    addGrauParentesco(obj: any): Observable<any> {
        return this.http.post(this.baseUrlRH + 'parentesco', obj).pipe();
    }

    deleteGrauParentesco(id: number): Observable<any> {
        return this.http.delete(this.baseUrlRH + 'parentesco?id=' + id).pipe();
    }

    getListaGrausAcademicos(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'grausAcademicos').pipe();
    }

    addGrauAcademicos(obj: any): Observable<any> {
        return this.http.post(this.baseUrlRH + 'grausAcademicos', obj).pipe();
    }

    deleteGrauAcademicos(id: number): Observable<any> {
        return this.http.delete(this.baseUrlRH + 'grausAcademicos?id=' + id).pipe();
    }

    getListaRhTiposDocumento(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'TiposDocumentos').pipe();
    }

    getRhTipoDocumentoById(id: number): Observable<any> {
        return this.http.get(this.baseUrlRH + 'TiposDocumentos/' + id).pipe();
    }

    addRhTiposDocumento(obj: any): Observable<any> {
        return this.http.post(this.baseUrlRH + 'TiposDocumentos', obj).pipe();
    }

    deleteRhTiposDocumento(id: number): Observable<any> {
        return this.http.delete(this.baseUrlRH + 'TiposDocumentos?id=' + id).pipe();
    }

    getListaRhTiposContacto(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'TiposContactos').pipe();
    }

    addRhTiposContacto(obj: any): Observable<any> {
        return this.http.post(this.baseUrlRH + 'TiposContactos', obj).pipe();
    }

    deleteRhTiposContacto(id: number): Observable<any> {
        return this.http.delete(this.baseUrlRH + 'TiposContactos?id=' + id).pipe();
    }

    getListaOpcoesInsercao(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'opcoesInsercao').pipe();
    }

    getListaOpcoesInsercaoByID(id: number): Observable<any> {
        return this.http.get(this.baseUrlRH + 'opcoesInsercao/' + id).pipe();
    }

    getListaPaises(): Observable<Pais[]> {
        return this.http.get<Pais[]>(this.baseUrlRH + 'paises').pipe();
    }

    getConfiguracao(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'configuracao').pipe();
    }

    addConfiguracao(obj: any): Observable<any> {
        return this.http.post(this.baseUrlRH + 'configuracao', obj).pipe();
    }

    getListaTitulos(): Observable<any> {
        return this.http.get(this.baseUrlAPL + 'aplicacao/titulos', httpOptions).pipe();
    }

    getListaMunicipios(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'municipios').pipe();
    }

    getListaProvincias(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'provincias').pipe();
    }

    getListaZonas(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'zonas').pipe();
    }

    getListaTiposContacto(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'tiposContactos').pipe();
    }

    getListaTiposDocumento(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'tipoDocumento').pipe();
    }

    getListaGeneros(): Observable<any> {
        console.log('Generos..:: ', this.baseUrlAPL);
        return this.http.get(this.baseUrlAPL + 'aplicacao/sexos', httpOptions).pipe();
    }

    getListaEstadosCivis(): Observable<any> {
        return this.http.get(this.baseUrlAPL + 'aplicacao/estadoscivis').pipe();
    }

    getListaNiveisAcademicos(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'niveisAcademicos');
    }

    //  ---------------------------------------- Estruturas Organizativas CRUD
    getListaEstruturasOrgnizativas() {
        // return null;
        return this.http.get<Resposta<EstruturaOrganizativa[]>>(this.baseUrlRH + 'estruturasOrganizativas').pipe();
    }

    getListaEstruturasOrgnizativasById(id?: number): Observable<any> {
        return this.http.get(this.baseUrlRH + 'estruturasOrganizativas/' + id).pipe();
    }

    addEstruturasOrgnizativas(obj: any): Observable<any> {

        return this.http.post(this.baseUrlRH + 'estruturasOrganizativas', obj).pipe();
    }

    deleteEstruturasOrgnizativas(id: number): Observable<any> {
        return this.http.post(this.baseUrlRH + 'estruturasOrganizativas/remover/' + id, []).pipe();
    }

    //  ---------------------------------------------- Empresas


    getListaEmpresas(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'empresas').pipe();
    }

    getEmpresaById(id: number): Observable<any> {
        return this.http.get(this.baseUrlRH + 'empresas/' + id).pipe();
    }

    //  ------------------------------------------------ Tipos Unidades Organizativas

    getListaTiposUnidadesOrganizativas(): Observable<Resposta<TipoUnidadeOrganizativa[]>> {
        return this.listar<Resposta<TipoUnidadeOrganizativa[]>>
        ('tiposUnidadesOrganizativas').pipe();
    }

    obterTipoUnidadesOrganizativasPeloId(id: number):
        Observable<Resposta<TipoUnidadeOrganizativa>> {
        return this.obterPeloId<Resposta<TipoUnidadeOrganizativa>>
        ('tiposUnidadesOrganizativas', id).pipe();
    }

    addTiposUnidadesOrganizativas(obj: any): Observable<any> {
        return this.http.post(this.baseUrlRH + 'tiposUnidadesOrganizativas', obj);
    }

    removeTiposUnidadesOrganizativas(id: number): Observable<any> {
        return this.http.delete(this.baseUrlRH + 'tiposUnidadesOrganizativas?id=' + id).pipe();
    }

    // ---------------------------------------- Unidades organizativas

    getListaUnidadesOrganizativas(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'unidadesOrganizativas').pipe();
    }

    getListaUnidadesOrganizativasById(id: number): Observable<any> {
        return this.http.get(this.baseUrlRH + 'unidadesOrganizativas/' + id).pipe();
    }

    addUnidadesOrganizativas(obj: any): Observable<any> {
        return this.http.post(this.baseUrlRH + 'unidadesOrganizativas', obj);
    }

    removeUnidadesOrganizativas(id: number): Observable<any> {
        return this.http.delete(this.baseUrlRH + 'unidadesOrganizativas?id=' + id).pipe();
    }


    obterEstruturasOrganizativas(): Observable<any> {
        return this.http.get(this.baseUrlRH + 'estruturasOrganizativas').pipe();
    }

    obterEstruturaOrganizativa() {
        return this.http.get<Resposta<any[]>>(this.baseUrlRH + 'unidadesOrganizativas/estruturaOrganizativa');
    }

    obterEstruturaOrganizativaLinear(): Observable<Resposta<Unidadeorganizativa[]>> {
        return this.rhservice.listar<Resposta<Unidadeorganizativa[]>>('unidadesOrganizativas');

    }


    guardarEstruturaOrganizativa(estruturaOrganizativa: Unidadeorganizativa[]): Observable<any> {
        return this.http.post(this.baseUrlRH + 'unidadesOrganizativas', estruturaOrganizativa).pipe();
    }


    apagarEstruturaOrganizativa(idEstruturaOrganizativa: number): Observable<any> {
        return this.http.post(this.baseUrlRH + 'unidadesOrganizativas/remover/'
            + idEstruturaOrganizativa, []).pipe();
    }
}
