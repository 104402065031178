import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Country, CountryCod } from 'app/shared/models/artigos/country-code-json/country-cod';

@Component({
  selector: 'app-telefone',
  templateUrl: './telefone.component.html',
  styleUrls: ['./telefone.component.scss']
})
export class TelefoneComponent implements OnInit {
  isoCountryCode: Country = null;
  indicativoActual: string = '';
  telInvalido: boolean = true;
  selected = [];
  listaContriesFlag: any[] = [];
  selTel: Country = null;
  countryCode: string;
  strSelect1: string;
  strSelect2: string;
  @Input('accao') accao='';
  @Input('telefone') telefone='';
  @Output('changePhone') changePhone = new EventEmitter();
  formGroupTel: FormGroup;
  constructor(
    public countryCod: CountryCod,
    private fb: FormBuilder
  ) { 
    this.listaContriesFlag = this.countryCod.getContryObject();
    this.initStrSelect();
    this.countryCode = '';
  }

  ngOnInit(): void {
  }

  initStrSelect() {
    this.strSelect1 = '<div class="mat-select-value ng-tns-c143-30" ng-reflect-ng-switch="false" id="mat-select-value-17"><!--bindings={\n  "ng-reflect-ng-switch-case": "true"\n}--><span class="mat-select-value-text ng-tns-c143-30 ng-star-inserted" ng-reflect-ng-switch="false" style=""><span class="mat-select-min-line ng-tns-c143-30 ng-star-inserted">'
    this.strSelect2 = '</span><!--container--><!--bindings={\n"ng-reflect-ng-switch-case": "true"\n}--></span><!--bindings={\n"ng-reflect-ng-switch-case": "false"\n}--></div><div class="mat-select-arrow-wrapper ng-tns-c143-30"><div class="mat-select-arrow ng-tns-c143-30"></div></div>'
}

isbackSpaceKeyBoard(evento) {
  let keyCode: number = evento.keyCode;
  let fg = this.formGroupTel as FormGroup;

  if (keyCode === 8) {
      fg.get('caracterValido').setValue(true);
  }

}

validarTel(idInPut: string, value: any, index: number) {
  let bool: boolean = true;
  if (value.length > 1) {
      if ((value as string)
          .substring(1, (value as string).length)
          .includes('+')) {
          bool = false;
      }
  }

  let fg = this.formGroupTel as FormGroup;
  let valido: boolean = fg.get('caracterValido').value && bool;

  if (!valido) {
      fg?.get('contacto').setValue(
          fg?.get('valorAux').value
      );
      return
  }
  // this.aoEscrever(formControl, idInPut, index);
  const regExp = RegExp('(([+]|[00])([1-9]([0-9]|[0-9]{0}){3})[ ]([0-9]|[0-9]{0}){7})|(([+]|[00])([1-9]([0-9]|[0-9]{0}){3})([0-9]|[0-9]{0}){7})');
  
  // const regExp = RegExp('(([+]|[00])([1-9]([0-9]|[0-9]{0}){3})[ ][0-9]{4}([0-9]|[0-9]{0}){7})|(([+]|[00])([1-9]([0-9]|[0-9]{0}){3})[0-9]{4}([0-9]|[0-9]{0}){7})');
 

  if (regExp.test(value)) {
      this.telInvalido = false;
  } else {
      this.telInvalido = true;
  }

  fg?.get('valorAux').setValue(
      fg?.get('contacto').value
  );

  let native: HTMLElement = document.getElementById(idInPut).children[0] as HTMLElement;

  //Usar os primeiros 5 caracteres, para cada uns dos 5... buscar o indicativo.
  let codISO: string;
  if (value.split(' ').length > 1) {
      if ((value.split(' ')[0] as string)
          .startsWith('00')) {
          codISO = (value.split(' ') as string)[0]
              .replace('00', '+');

      } else {

          codISO = value.split(' ')[0];
      }
  } else {
      if ((value.split(' ')[0] as string)
          .startsWith('00')) {
          value = (value.split(' ') as string)[0]
              .replace('00', '+');

      }
      const valor: string = value;
      const sub04: string = valor && valor.length > 4 ? valor.substring(0, 5) : null;
      const sub03: string = valor && valor.length > 3 ? valor.substring(0, 4) : null;
      const sub02: string = valor && valor.length > 2 ? valor.substring(0, 3) : null;
      const sub01: string = valor && valor.length > 1 ? valor.substring(0, 2) : null;

      let listaContriesFlagAux: Country[] = this.countryCod.getContryObject();

      listaContriesFlagAux.sort((a, b) => {
          return parseInt(b.dialCode) - parseInt(a.dialCode);
      });

      for (let i = 0; i < listaContriesFlagAux.length; i++) {

          if (sub04) {
              //Condição para quebrar ... Se cumprir quebrar ...
              if (listaContriesFlagAux[i].dialCode === sub04) {
                  codISO = listaContriesFlagAux[i].dialCode;
                  break;
              }
          }

          if (sub03) {
              if (listaContriesFlagAux[i].dialCode === sub03) {
                  codISO = listaContriesFlagAux[i].dialCode;
                  break;
              }
          }

          if (sub02) {
              if (listaContriesFlagAux[i].dialCode === sub02) {
                  codISO = listaContriesFlagAux[i].dialCode;
                  break;
              }
          }

          if (sub01) {
              if (listaContriesFlagAux[i].dialCode === sub01) {
                  codISO = listaContriesFlagAux[i].dialCode;
                  break;
              }
          }

      }
  }

  let control: FormControl = fg
      .get('contacto') as FormControl;

      let country: Country[] = this.listaContriesFlag.filter((country: Country) => {
          return country.dialCode === codISO
      });
      
      
      
      if (country.length > 0) {
      this.indicativoActual = codISO;
      fg?.get('selectedCountryCode')
          .setValue(country[0]);
      native.innerHTML = this.strSelect1 + country[0].flag + this.strSelect2;
      this.changePhone.emit({
        telefone: country[0].flag
      })
  } else {
      this.indicativoActual = '';
      // fg?.get('contacto').setErrors({
      //     Inválido: true
      // }, { emitEvent: true });
      native.innerHTML = this.strSelect1 + '' + this.strSelect2;
      this.changePhone.emit({
        telefone: ''
      })
      fg?.get('selectedCountryCode')
          .setValue(null);
  }
  // control.markAsTouched({ onlySelf: true });
  // fg?.get('contacto').markAsTouched({ onlySelf: true })



}

reconhecerCaracter(evento: any) {

  let charCode: number = evento.charCode;
  const str: string = evento.target.value;

  let fg = this.formGroupTel as FormGroup;
  if ((
      (charCode >= 48 && charCode <= 57)
      || charCode === 43
      || charCode === 32 || charCode === 8)) {
      fg.get('caracterValido').setValue(true);
  } else {
      fg.get('caracterValido').setValue(false);

  }

}

changeCountryCode(idInPut: string, country: Country) {
  this.isoCountryCode = null;

  let fg = this.formGroupTel as FormGroup;

  let native: HTMLElement = document
      .getElementById(idInPut)
      .children[0] as HTMLElement;

  let valor: string[] = [];
  let contacto: string = '';

  if ((fg
      .get('contacto')
      .value.split(' ') as [])
      .length > 1
  ) {
      valor = fg
          .get('contacto')
          .value.split(' ');
  } else {
      contacto = (fg
          .get('contacto')
          .value as string).replace(this.indicativoActual, '');
  }

  let valorAux = ''
  for (let i = 1; i < valor.length; i++) {

      valorAux += ' ' + valor[i]
  }

  native.innerHTML = this.strSelect1 + country.flag + this.strSelect2;

  let countryCode: string = '';

  this.indicativoActual = country.dialCode;
  if (valor.length > 1) {
      countryCode = valor.length > 1 ? country.dialCode + valorAux :
          country.dialCode + ' ';
  } else {
      countryCode = country.dialCode + contacto;
  }


  fg
      .get('contacto').setValue(countryCode);

  fg
      .get('valorAux').setValue(this.countryCode);
}

getformGroupTel(countryCod?: string) {
  // , [Validators.pattern('[+]([1-9]([0-9]|[0-9]{0}){3})[ ][0-9]{4}([0-9]|[0-9]{0}){7}')]
  return this.fb.group({
      contacto: [''],
      caracterValido: [false],
      valorAux: [countryCod ? countryCod : ''],
      selectedCountryCode: []
  })
}

  @ViewChild('matSelect1') matSelect1: any;
  async initContacto(idComponent: string) {
      let fg = this.formGroupTel as FormGroup;
      if (document.getElementById(idComponent)) {
          //??
          let native: HTMLElement = await document
              .getElementById(idComponent)
              .children[0] as HTMLElement;

          let country: Country[] = null;
          if (this.isoCountryCode && this.isoCountryCode.dialCode) {
              country = await this.listaContriesFlag.filter((country: Country) => {
                  return country.dialCode.toLowerCase() === this.isoCountryCode.dialCode
                      .toLowerCase()
              });


          } else {
              country = await this.listaContriesFlag.filter((country: Country) => {
                  return country.flag.toLowerCase() === localStorage
                      .getItem('__cod__Pais')
                      .replace(/\s/g, '').toLowerCase()
              });
          }

          let countryCode: string = country.length > 0 ?
              country[0].dialCode + ' ' : '';

          if (this.isoCountryCode && this.telefone) {
              countryCode = this.isoCountryCode.dialCode + ' ' + this.telefone;

          } else if (this.telefone) {

              //Verificar se começa com 00
              if ((this.telefone.split(' ')[0] as string)
                  .startsWith('00')) {
                  this.telefone = this.telefone.replace('00', '+');

              }
              // if (this.isoCountryCode && this.isoCountryCode.dialCode) {
              const valor: string = this.telefone;
              let codISO: string = '';
              const sub04: string = valor && valor.length > 4 ? valor.substring(0, 5) : null;
              const sub03: string = valor && valor.length > 3 ? valor.substring(0, 4) : null;
              const sub02: string = valor && valor.length > 2 ? valor.substring(0, 3) : null;
              const sub01: string = valor && valor.length > 1 ? valor.substring(0, 2) : null;

              let listaContriesFlagAux: Country[] = this.countryCod.getContryObject();

              listaContriesFlagAux.sort((a, b) => {
                  return parseInt(b.dialCode) - parseInt(a.dialCode);
              });

              for (let i = 0; i < listaContriesFlagAux.length; i++) {

                  if (sub04) {
                      //Condição para quebrar ... Se cumprir quebrar ...
                      if (listaContriesFlagAux[i].dialCode === sub04) {
                          codISO = listaContriesFlagAux[i].dialCode;
                          break;
                      }
                  }

                  if (sub03) {
                      if (listaContriesFlagAux[i].dialCode === sub03) {
                          codISO = listaContriesFlagAux[i].dialCode;
                          break;
                      }
                  }

                  if (sub02) {
                      if (listaContriesFlagAux[i].dialCode === sub02) {
                          codISO = listaContriesFlagAux[i].dialCode;
                          break;
                      }
                  }

                  if (sub01) {
                      if (listaContriesFlagAux[i].dialCode === sub01) {
                          codISO = listaContriesFlagAux[i].dialCode;
                          break;
                      }
                  }

              }

              this.indicativoActual = codISO;
              country = this.listaContriesFlag.filter((country: Country) => {
                  return country.dialCode === codISO
              });
              if (country) {
                  fg?.get('selectedCountryCode')
                      .setValue(country[0]);

              }
              countryCode = this.telefone;



              // } else {
              //     countryCode = country.length > 0 ?
              //         country[0].dialCode + ' ' + this.telefone : '';
              // }

          }

          this.indicativoActual = countryCode;
          fg.get('contacto').setValue(countryCode);
          fg?.get('valorAux').setValue(
              countryCode
          );

          if (this.accao !== 'D') {
              fg.get('valorAux').setValue(this.countryCode);
          }
          if (country.length > 0) {

              fg.get('selectedCountryCode')
                  .setValue(country[0]);
              native.innerHTML = this.strSelect1 + country[0].flag + this.strSelect2;
          } else {
              native.innerHTML = this.strSelect1 + '' + this.strSelect2;
          }
      }

  }

}
