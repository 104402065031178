<div id="div-data-1" [formGroup]="form" *ngIf="true" fxFlex="100" class="pr-1">
    <mat-form-field class="full-width date-icon test" appearance="outline"
        style="background-image: url('assets/images/icons/calendar.svg');">
        <mat-label>{{placerholder}}</mat-label>
        <input #inData1 id="in-data-1" matInput ngxDaterangepickerMd [opens]="'right'" [ranges]="ranges"
            startKey="inicio" [readonly]="false" endKey="fim" [(ngModel)]="selData1" autocomplete="off"
            [showDropdowns]="true" [alwaysShowCalendars]="true" [linkedCalendars]="true" [showCancel]="true" 
            [locale]="{cancelLabel: 'Cancelar', applyLabel: 'Aplicar',  format: 'DD/MM/YYYY'}" formControlName="data"
            name="data" 
            (keypress)="validarCaracter($event)" 
            (input)="validarData($event, inData1.id, inData1.name)"
            (change)="getDataInterval($event, inData1.id)"  />
    </mat-form-field>

</div>