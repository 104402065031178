import {Component, OnDestroy, OnInit} from '@angular/core';
import {Aplicacao} from '../../models/aplicacao/aplicacao';
import {AplicacaoService} from '../../services/aplicacao-service/aplicacao.service';
import {AppStoreService} from '../../services/aplicacao-service/data/app-store.service';
import {Subscription} from 'rxjs';
import { I } from '@angular/cdk/keycodes';
import { DefinirDadosDoFooterService } from './definir-dados-do -footer/definir-dados-do-footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  aplicacao: Aplicacao;
  licencaEmpresa = '...';
  subscricao: Subscription[] = [];


  constructor(
    private definirDadosDoFooterService: DefinirDadosDoFooterService
    ) {}


  ngOnInit() {
     this.definirDadosDoFooterService.definirDadosDoFooterService.subscribe({
      next: (obj: any) => {
        if (obj) {
          this.aplicacao = obj.aplicacao;
          this.licencaEmpresa = obj?.licencaEmpresa;
        }
      }
      });
  }

  ngOnDestroy() {
    if (this.subscricao.length > 1) {
      this.subscricao.forEach((sub: Subscription) => {
        sub.unsubscribe();
      });
    }
  }
}
