
import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-aviso',
  templateUrl: './aviso.component.html',
  styleUrls: ['./aviso.component.scss']
})
export class AvisoComponent implements OnInit {
  avisos: any[]=[]
  aviso: boolean = false
  title: string;
  textoConfirmar: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public modalRef: MatDialogRef<AvisoComponent>,
  ) { 
    this.textoConfirmar =  this.data?.textoConfirmar
    this.data.avisos?.forEach((element, index) => {
      console.log('Elemento', element);
      this.avisos.push({
        indice: index,
        aviso: element
      })
    });

  }

  ngOnInit(): void {
    this.aviso = this.data.aviso;
    
    

    console.log("Avisos:",this.avisos)

    if (this.data.accao === 'ANULAR') {

      this.title = this.aviso ? "Avisos" : "Anular "+this.data.title;
    } else {

      this.title = this.aviso ? this.data.avisos.length > 1? "Avisos" : 'Aviso' : "Remover "+this.data.title;
    }
  }

  feichar(){
    this.modalRef.close(null);
  }

  cancelar() {
    this.modalRef.close(null);
  }

  remover() {
    this.modalRef.close(this.data);
  }

}
