import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';


@Injectable({
  providedIn: 'root'
}) export class OnDestroyService {
  constructor() {
  }
  destruir(listaSubscricoes: Subscription[]){
    if(listaSubscricoes && listaSubscricoes.length){
      listaSubscricoes.forEach((subs: Subscription)=> {
        subs.unsubscribe();
      })
    }
  }
}
