import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { model } from 'app/shared/fake.db/data';
import { Aplicacao } from 'app/shared/models/aplicacao/aplicacao';
import { UtilizadorSessao } from 'app/shared/models/aplicacao/utilizador-sessao';
import { Entidades } from 'app/shared/models/clientes/entidades';
import { AplicacaoService } from 'app/shared/services/aplicacao-service/aplicacao.service';
import { LoginService } from 'app/shared/services/aplicacao-service/login.service';
import { ArtigosService } from 'app/shared/services/artigos/artigos.service';
import { ClientesService } from 'app/shared/services/clientes-service/clientes.service';
import { NotificacaoService } from 'app/shared/services/notificacao/notificacao.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-clientes-inscricao',
  templateUrl: './modal-clientes-inscricao.component.html',
  styleUrls: ['./modal-clientes-inscricao.component.scss']
})
export class ModalClientesInscricaoComponent implements OnInit {

  checkBox = new FormControl();
  processos: any[];
  model = model;
  param;
  clientes: any[];
  listaDados: any[] = [];

  listaClientes: any[] = [];
  entidade: Entidades;
  todosArtigos = [];
  selected = [];
  checked: number;
  aplicacao: Aplicacao;
  subs: Subscription[] = [];
  activarLoader = false;
  utilizadorSessao: UtilizadorSessao

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public modalRef: MatDialogRef<ModalClientesInscricaoComponent>,
    private aplicacaoService: AplicacaoService,
    private loginService: LoginService,
    private artigosService: ArtigosService
  ) { }

  ngOnInit(): void {
    this.utilizadorSessao = this.loginService.getUtilizadorSessao();

    if ( this.data?.listaEntidades) {
      this.listaClientes = this.data?.listaEntidades;
      this.listaDados = this.data?.listaEntidades;
    } else  {
      this.getClientes();
    }
   
  }

  getClientes() {
    this.activarLoader = true;
    let clienteFiltro = {
      utilizador: this.utilizadorSessao
    }
    this.artigosService.getEntidades(clienteFiltro).subscribe((res) => {
      this.activarLoader = false;
      if (this.data?.classificacao?.classificacaoEntidades?.length > 0) {
        res.objecto.forEach(cliente => {
          this.data.classificacao.classificacaoEntidades.forEach(classificacaoEntidade => {
            if (cliente.idEntidade === classificacaoEntidade.idEntidade) {
              this.listaClientes.push(cliente);
              this.listaDados.push(cliente);
            }
          });
        });
      } 
    }, (erro: any) => {
      this.activarLoader = false;
    });
  }

  filtrar(valor: string, lista?: any): any[] {
    const valorFiltro = valor.toLowerCase();
    if (valorFiltro.length > 0) {
      return lista.filter((item) =>
        item.codigo.toLowerCase().includes(valorFiltro)
      );
    } else {
      return lista;
    }
  }

  getAplicacao() {
    this.aplicacaoService.getAplicacao().subscribe((res) => {
      this.aplicacao = res;
    });
  }

  filtrarValores(event, opcao: number) {
    const termo = event.target.value;
    let lista = [];
    setTimeout(() => {
      if (termo) {
        switch (opcao) {
          // Filtrar codigo //
          case 1: {
            lista = this.listaDados.filter((x) => {
              return x.codigo !== null &&
                x.codigo.toLowerCase().includes(termo.toLowerCase())
            }

            );
            break;
          }
          // Filtrar nome //
          case 2: {
            lista = this.listaDados.filter(
              (x) =>
                x.nome !== null &&
                x.nome.toLowerCase().includes(termo.toLowerCase())
            );
            break;
          }
          // Filtrar NIF //
          case 3: {
            lista = this.listaDados.filter(
              (x) =>
                x.nif !== null && x.nif.toString().includes(termo.toLowerCase())
            );
            break;
          }
          // Filtrar Estado //
          case 4: {
            lista = this.listaDados.filter(
              (x) =>
                x.estado !== null &&
                x.estado.toLowerCase().includes(termo.toLowerCase())
            );
            break;
          }

          // Filtrar Entidade //
          case 5: {
            lista = this.listaDados.filter(
              (inscricao) =>
                inscricao.entidadeS !== null &&
                inscricao.entidadeS.toLowerCase().includes(termo.toLowerCase())
            );
            break;
          }

          // Filtrar E-mail //
          case 6: {
            lista = this.listaDados.filter(
              (inscricao) =>
                inscricao.entidadeS !== null &&
                inscricao.email.toLowerCase().includes(termo.toLowerCase())
            );
            break;
          }
        }
        this.listaClientes = lista;
      } else {
        this.listaClientes = [];
        this.listaClientes = [...this.listaDados];
      }
    }, 1000);
  }

  selecCodigo(cliente?: any) {
    this.modalRef.close(cliente);
  }


  ngOnDestroy() {
    if (this.subs && this.subs.length) {
      this.subs.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

}
