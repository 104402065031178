import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'all',
    pure: true,
})
export class AllPipe implements PipeTransform {

    transform(items: any[], predicate: (item: any) => boolean): boolean {
        if (!items || !predicate) {
            return false;
        }
        return items.every(item => predicate(item));
    }

}
