import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculoDeVaga'
})
export class CalculoDeVagaPipe implements PipeTransform {

  transform( realizacao:  any): string| number {
    realizacao?.vagas === 'Ilimitadas' ? 'Ilimitadas' : (parseInt(realizacao?.vagas, 10) - realizacao.qtdInscritos);

    const regex = RegExp('^[0-9]+$');

    if (realizacao?.vagas ) {


      return realizacao?.vagas === 'Ilimitadas' ? 'Ilimitadas' : regex.test(realizacao?.vagas) && regex.test(realizacao.qtdInscritos)?    
      (parseInt(realizacao?.vagas, 10) - realizacao.qtdInscritos): '';
    } else {
      return '';
    }
  }

}
