import {environment} from 'environments/environment';

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {LocalStoreService} from '../local-store.service';
import {Utilizadores} from 'app/shared/models/aplicacao/utilizadores';
import {BehaviorSubject, Observable} from 'rxjs';
import {Permissoes} from 'app/shared/models/aplicacao/permissoes';
import {PermissoesDataService} from './data/permissoes-data.service';
import {ModalSessaoExpirada} from '../../components/modal-sessao-expirada/modal-sessao-expirada';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AplicacaoService} from './aplicacao.service';
import { PosUtilityService } from 'app/views/pos/pos-services/pos-utility.service';


@Injectable({
    providedIn: 'root',
})
export class LoginService {
    jwtHelper = new JwtHelperService();
    decodedToken: any;
    utilizadorDto: any;

    permissoesUtilizador: Permissoes[] = [];

    mudarPassord: any;
    baseUrl: string;
    token: string;
    isAuthenticated: boolean;
    user: Utilizadores;
    user$ = (new BehaviorSubject<Utilizadores>(null));
    signingIn: boolean;
    return: string;
    APP_USER = '_utilizador';


    constructor(
        private http: HttpClient,
        private router: Router,
        private aplicacaoService: AplicacaoService,
        private posUtilityService: PosUtilityService,
        private localSore: LocalStoreService, private permissoesDataService: PermissoesDataService, private modal: MatDialog,) {
        this.baseUrl = `${environment.apiAplicacao}`;
    }

    login(model: any): Observable<any> {
        return this.http.post(this.baseUrl + 'aplicacao/login', model).pipe(
            map(
                (response: any) => {
                    const user = response.objecto;
                    if (user?.token) {
                        localStorage.setItem('token', user.token);
                        this.decodedToken = this.jwtHelper.decodeToken(user.token);
                        this.utilizadorDto = user.utilizador;
                        localStorage.setItem('@u53r1d$', this.decodedToken.nameid);
                        this.mudarPassord = user.mudarPass;
                        localStorage.setItem('utilizadorSessao', JSON.stringify(user.utilizadorSessao));
                    }
                },
                (error) => {
                    console.log('Error', error);
                    this.mudarPassord = false;
                }
            )
        );
    }

    getPermissoes(): any {
        if (localStorage.getItem('permissoesUtilizador') != null) {
            return JSON.parse(localStorage.getItem('permissoesUtilizador'));
        }
        return [];
    }

    getUtilizadorSessao(): any {
        if (localStorage.getItem('utilizadorSessao') != null) {
            return JSON.parse(localStorage.getItem('utilizadorSessao'));
        }
        return null;
    }

    getSessaoUtilizador() {
        if (window.localStorage && localStorage.getItem('utilizadorSessao')) {
            return JSON.parse(localStorage.getItem('utilizadorSessao'));
        }
        return null;
    }

    loggedIn() {
        if (localStorage.getItem('token') != null) {
            const token = localStorage.getItem('token');
            return !this.jwtHelper.isTokenExpired(token);
        }
        return false;
    }

    setUserAndToken(token: string, user: Utilizadores, isAuthenticated: boolean) {
        this.isAuthenticated = isAuthenticated;
        this.token = token;
        this.user = user;
        this.user$.next(user);
        this.localSore.setItem(this.APP_USER, user);
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('artigos');
        localStorage.removeItem('processos');
        localStorage.removeItem('financas');
        localStorage.removeItem('gereStocks');
        localStorage.removeItem('clean_cache');
        localStorage.removeItem('dashLoaderActivo');
        this.posUtilityService.clearLocalTerminal();
        this.aplicacaoService.gereStockBS.next(null);
        this.router.navigate([localStorage.getItem('urlAtual')]);
    }

    terminarSessao(erro) {
        const modalRef = this.modal.open(ModalSessaoExpirada, {
            minWidth: '300px',
            minHeight: '100px',
            data: {mensagem: erro.error},
            disableClose: true
        });
    }
}
