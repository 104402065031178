<section class="position-relative">
    <div class="modal-header">
          <span (click)="cancelar()" class="cursor-pointer btn-close-modal">
          <mat-icon>close</mat-icon>
      </span>
    </div>
    <div class="modal-title">
        <h1 mat-dialog-title>Valores de seleção</h1>
    </div>
    <div class="p-05" fxLayout="row wrap" fxLayout.lt-sm="column">
        <div class="modal-body" fxFlex="100">
            <div class="mb-1">
                <app-valor [form]="form" [action]="data.action" [campo]="data.campo?.value"></app-valor>
                <!-- Adicionar campo -->
                <div fxFlex="10" style="padding-top: 9px !important;">
                    <button type="button"
                            mat-raised-button
                            [disabled]="form.invalid"
                            [color]="form.invalid ? '' : 'primary'"
                            class="btn-filter mr-10" (click)="addValor()">
                        Adicionar valor
                    </button>
                </div>
            </div>
            <mat-card-title class="font-weight-bold text-whi bg-primary mb-0 pb-1 pt-1">
            </mat-card-title>
            <div class="pt-05 pb-1">
                <div *ngFor="let v of data.campo?.get('valores')?.controls; index as i"
                     style="border-top: 1px solid rgb(10 10 10 / 13%)" class="mb-1 mt-1">
                    <app-valor [form]="v" [action]="data.action" [campo]="data.campo?.value"></app-valor>
                    <!-- Remover característica -->
                    <button (click)="removerValor(i)" mat-icon-button fxFlex="60px"
                            type="button"
                            *ngIf="data.action !== actions.detalhes"
                            style="padding: 3px;margin-top: 7px">
                        <mat-icon class="mat-icon notranslate mat-warn material-icons">
                            delete
                        </mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
