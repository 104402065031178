import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pos-layout',
  templateUrl: './pos-layout.component.html'
})
export class PosLayoutComponent implements OnInit {
  ngOnInit() {
  }

}
