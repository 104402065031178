export const menuPOS = [
  {
    titulo: 'Bloquear terminal',
    icon: 'lock',
    rota: '',
    nav: false,
  },
  {
    titulo: 'Trocar de operador',
    icon: 'switch_accounts',
    rota: 'sessoes/logout',
    nav: false,
  },
  {
    titulo: 'Voltar ao Kiami',
    icon: 'undo',
    rota: '/dashboard',
    nav: true,
  },
  {
    titulo: 'Sair',
    icon: 'logout',
    rota: 'sessoes/logout',
    nav: false,
  }
]
