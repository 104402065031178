import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, share } from "rxjs/operators";
import { environment } from "environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Content-Type": "application/json",
  }),
};
const httpOptionsPost = {
  headers: new HttpHeaders({
    Authorization: "Bearer " + localStorage.getItem("token"),
  }),
};

@Injectable({
  providedIn: "root",
})
export class UtilizadoresService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiAplicacao}`;
  }

  getUtilizadores() {
    return this.http
      .get<any>(this.baseUrl + "aplicacao/utilizadores", httpOptions)
      .pipe(catchError(null), share());
  }

  getUtilizadoresBase() {
    return this.http
      .get<any>(this.baseUrl + "aplicacao/utilizadores/listabase", httpOptions)
      .pipe(catchError(null), share());
  }

  getUtilizadoresEstados(): Observable<any> {
    return this.http
      .get(this.baseUrl + "aplicacao/utilizadores/estados", httpOptions)
      .pipe(catchError(null), share());
  }

  getUtilizador(id: number): Observable<any> {
    return this.http.get(
      this.baseUrl + "aplicacao/utilizadores/" + id,
      httpOptions
    );
  }

  removerUtilizador(model: any): Observable<any> {
    return this.http
      .post(
        this.baseUrl + "aplicacao/utilizadores/remover/",
        model,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  alterarPasswordLogin(model: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "aplicacao/utilizadores/alterarPassword/login",
      model,
      httpOptions
    );
  }

  alterarPasswordPerfil(model: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "aplicacao/utilizadores/alterarPassword/perfil",
      model,
      httpOptions
    );
  }

  alterarPassword(model: any): Observable<any> {
    return this.http.post(
      this.baseUrl + "aplicacao/utilizadores/alterarPassword/",
      model,
      httpOptions
    );
  }

  salvarUtilizador(model: any): Observable<any> {
    return this.http
      .post(this.baseUrl + "aplicacao/utilizadores/", model, httpOptionsPost)
      .pipe();
  }

  removerPermissoesutilizador(model: any): Observable<any> {
    return this.http
      .post(this.baseUrl + "aplicacao/utilizadores/removerPermissoesutilizador", model, httpOptionsPost)
      .pipe();
  }

  adicionarPermissoesutilizador(model: any): Observable<any> {
    return this.http
      .post(this.baseUrl + "aplicacao/utilizadores/adicionarPermissoesutilizador", model, httpOptionsPost)
      .pipe();
  }

  editarUtilizador(model: any) {
    return this.http
      .post(
        this.baseUrl + "aplicacao/utilizadores/editar/",
        model,
        httpOptionsPost
      )
      .pipe(catchError(this.handleError));
  }

  editarPerfil(model: any): Observable<any> {
    return this.http
      .post(this.baseUrl + "aplicacao/perfil/editar/", model, httpOptionsPost)
      .pipe(catchError(this.handleError));
  }

  editarLayoutUtilizador(modelo: any): Observable<any> {
    return this.http
      .post(
        this.baseUrl + "aplicacao/utilizadores/editarLayout",
        modelo,
        httpOptions
      )
      .pipe();
  }

  recuperarSenha(email: string): Observable<any> {
    return this.http
      .post(
        this.baseUrl + "aplicacao/utilizadores/recuperarSenha/" + email,
        null
      )
      .pipe();
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
