import { Pipe, PipeTransform } from '@angular/core';
import { TipoDocumento } from 'app/shared/models/financas/documentos/documentosTipos';

@Pipe({
  name: 'tipoDocumento'
})
export class TipoDocumentoPipe implements PipeTransform {

  transform( tiposDocumentos: TipoDocumento [], codAreaTesouraria: string): TipoDocumento [] {

    if ( tiposDocumentos?.length > 0 ) {
      
      tiposDocumentos = tiposDocumentos?.filter(tipoDocumento => 
      tipoDocumento?.codAreaTesouraria?.toLowerCase() === codAreaTesouraria?.toLowerCase());
    }
    return tiposDocumentos;
  }

}
