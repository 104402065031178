import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'tipoFicheiro',
    pure: true
})
export class TipoFicheiroPipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {
    }

    public transform(valor: any): any {
        let extensaoFicheiro = '';
        let metaDadosFicheiro = '';
        let partesLinkFicheiro;
        if (valor) {
            if (valor.split(';')[0] && valor.split(';')[0].length > 0) {
                console.log('', valor.split(';')[0]);
                metaDadosFicheiro = valor.split(';')[0];
                partesLinkFicheiro = metaDadosFicheiro.split('/');
            } else {
                console.log('', valor.split('.'));
                if (valor.split('.') && valor.split('.').length > 0) {
                    partesLinkFicheiro = valor.split('.');
                }
            }

            if (partesLinkFicheiro?.length > 0) {
                const parts = partesLinkFicheiro[partesLinkFicheiro.length - 1].split('.');
                extensaoFicheiro = parts[parts.length - 1];
            }

            switch (extensaoFicheiro) {
                case 'pdf':
                    return 'assets/images/icons/pdf-icon.svg';
                case 'msword':
                    return 'assets/images/file-types/005-documents.svg';
                case 'docx':
                    return 'assets/images/file-types/005-documents.svg';
                case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
                    return 'assets/images/icons/word-icon.svg';
                // Ficheiro Excel :: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' => 'sheet'
                case 'sheet':
                    return 'assets/images/icons/xcel-icon.svg';
                case 'xlsx':
                    return 'assets/images/icons/xcel-icon.svg';
                // Ficheiro PowerPoint
                case 'vnd.openxmlformats-officedocument.presentationml.presentation':
                    return 'assets/images/icons/powerpoint-icon.svg';
                case 'pptx':
                    return 'assets/images/icons/powerpoint-icon.svg';
                case 'jpeg':
                    return valor;
                case 'jpg':
                    return valor;
                case 'png':
                    return valor;
                case 'svg':
                    return valor;
                case 'webp':
                    return valor;
                default:
                    return 'assets/images/icons/default_ficheiro.svg';
            }

        }

    }

}
