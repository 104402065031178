import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatarQuantidade'
})
export class FormatarQuantidadePipe implements PipeTransform {

  async transform(valor1: string): Promise<string> {
    let valor: string = valor1.replace(/\s/g, '');
    let sinal: string = '';
    
    if (valor) {
      if ( valor.includes('-')){
        
        if (valor.length === 1) {
          return '-';
        } else if (valor === '') {
          return '0,00'
        }

        valor = valor.split('-')[1];
        valor = parseInt(valor)+'';
        valor = await valor.replace(/^[0][0]/g, '').replace(/^[0]/g, '');
        sinal = '-';
      } 
      else {
        valor = parseInt(valor)+'';
        if ( valor === '0'){
          return '0,00';
        }
      }

      if (valor.length > 2) {
        let str1: string = '';
        let str2: string = '';
        let strAux: string = '';
        if (!valor.includes('.')) {
          str1 = valor.substring((valor.length - 2), valor.length);
          str2 = valor.substring(0, (valor.length - 2));
        } else {
          str1 = valor.split('.')[1];
          str2 = valor.split('.')[0];
        }
        
        
        for (let i = str2.length; i > 3; i -= 3) {
          strAux = '.' + str2.substring((i - 3), i) + strAux;
          if (i - 3 < 4) {
            strAux = str2.substring(0, (i - 3)) + strAux;

          }
        }
        if (strAux !==''){
          strAux = sinal+' '+strAux + ',' + str1;
        }else {
          strAux = sinal+' '+str2 + ',' + str1;
          
        }
        return strAux;
      } else {
        if (valor.length === 2) {
          valor = sinal +' 0,'+valor;

        } else
        if (valor.length === 1){

          valor = sinal +' 0,0'+valor;
        } else {

          valor = valor !==''? sinal +' 0,'+valor: '0,00';
        }
      }
    }

    return valor;
  }

}
