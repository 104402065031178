<ngx-datatable #table class="material fullscreen bg-white" [style.height]="height"
               [messages]="{ emptyMessage: 'Sem tipos de arquivos para mostrar', totalMessage: 'Total' }"
               [rows]="tipos" columnMode="flex" [headerHeight]="42.5" [footerHeight]="50"
               [rowHeight]="45" [scrollbarV]="true" [scrollbarH]="true">
    <ngx-datatable-column [sortable]="false" [resizeable]="false"
                          [draggable]="false"
                          [canAutoResize]="false" [width]="50">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <span class="btn-table-expandir"
                  [class.datatable-icon-right]="!expanded"
                  [class.datatable-icon-down]="expanded"
                  [class.bg-primary]="expanded"
                  title="Expandir/Colapsar"
                  (click)="table.rowDetail.toggleExpandRow(row)"
            ></span>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-row-detail rowHeight="350">
        <ng-template let-row="row" ngx-datatable-row-detail-template>
            <k-arquivos [action]="action" (deleteEvent)="deleteEvent.emit($event)"
                        (updateEvent)="updateEvent.emit($event)"
                        [tipo]="row"
                        [area]="area"
                        [arquivos]="arquivos" [parentId]="parentId"></k-arquivos>
        </ng-template>
    </ngx-datatable-row-detail>

    <ngx-datatable-column name="Nome" [flexGrow]="2">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.nome }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Guarda nome do ficheiro" [flexGrow]="2">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.guardaNomeFicheiro ? 'Sim' : 'Não' }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Sistema" [flexGrow]="2">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.sistema ? 'Sim' : 'Não' }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Nº Arquivos" [flexGrow]="2">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{ (arquivos | filterFn: byTipo: row).length }}
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
