export class FiltrosClientes {
  constructor(
    public IdEntidade?: number,
    public CodigoFiltro?: number,
    public Codigo?: string,
    public Email?: string,
    public Nome?: string,
    public NIF?: string,
    public DataRegistoDe?: string,
    public DataRegistoAte?: string,
    public IdTipos?: number[],
    public IdEstados?: number[],
    public CodPaises?: string[],
    public CodIdiomas?: string[],
    public Utilizador?:any,
    public FiltrarData?: boolean
  ) {}
}
