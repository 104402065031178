import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { PosModalNotifiy } from 'app/views/pos/componentes/modais/pos-modal-notifiy/pos-modal-notifiy';
import { Utilizadores } from 'app/shared/models/aplicacao/utilizadores';

@Injectable({
  providedIn: 'root'
})
export class PosUtilityService {
  artigosParaVenda$ =  new BehaviorSubject<any[]>([]);
  terminalEmUso$ = new BehaviorSubject<any>(null);
  terminalOperator$ = new BehaviorSubject<Utilizadores>(null);
  localTerminalKey: string  = 'pos_terminal';
  constructor(private  snack: MatSnackBar, private dialog: MatDialog) {}

  openFullScreen(fullScreeOpen: boolean) {
    const body = document.querySelector('body');
    if (body) {
      if (fullScreeOpen) {
        body.requestFullscreen({navigationUI: 'hide'});
      } else {
        document.exitFullscreen();
      }
    }
  }

  getPOSOperatorFromLocal(){
    if(localStorage.getItem('_utilizador')){
      this.terminalOperator$.next(JSON.parse(localStorage.getItem('_utilizador')));
    }
  }
  getLocalTerminal() {
    return localStorage.getItem(this.localTerminalKey);
  }
  setLocalTerminal(value: any) {
     localStorage.setItem(this.localTerminalKey, JSON.stringify(value));
  }
  clearLocalTerminal() {
    localStorage.removeItem(this.localTerminalKey);
  }

  posSnackToast(mensagem: string, res?: boolean, duracao?: number) {
    this.snack.open(
      mensagem,
      res ? '✔️' : '❌',
      {
        verticalPosition: 'top',
        duration: duracao <= 0 || !duracao ? 50000 : duracao,
        panelClass: 'pos-notify'
      }
    );
  }

  posDialogToast(titulo?: string, info?: string, res?: boolean) {
    this.dialog.open(
      PosModalNotifiy, {
        width: '30vw',
        panelClass: 'pos-dialog-toast',
        data: {titulo, info, res},
      }
    );
  }
}
