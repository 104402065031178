import {Component, Input} from '@angular/core';
@Component({
// tslint:disable-next-line:component-selector
  selector: 'card-add-ficheiro',
  template: `
 <div class="card-plus">
   <div class="card-plus-inner">
     <div class="card-plus-icon" >
        <img *ngIf="isImg" src="assets/images/icons/__img_icon.svg" alt="icon plus">
        <img *ngIf="isFile" src="assets/images/icons/default_ficheiro.svg" alt="icon plus">
     </div>
       <span class="font-weight-normal text-primario">{{titulo}}</span>
    </div>
</div>
        `,
  styles: []
})
export class CardAddFicheiroComponent {
  @Input() titulo;
  @Input() isFile;
  @Input() isImg;

}
