import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Accao} from '../../../shared/models/ENUM';
import {Util} from '../../../shared/models/generico/util';

@Component({
    selector: 'app-modal-add-valores',
    templateUrl: './modal-add-valores.component.html',
    styleUrls: ['./modal-add-valores.component.scss']
})
export class ModalAddValoresComponent implements OnInit {
    form: FormGroup = this.fb.group({
        'id': 0,
        'idCampo': 0,
        'valor': [null, Validators.required],
        'codigo': null,
        'activo': true
    });
    readonly actions = Accao;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        public modalRef: MatDialogRef<ModalAddValoresComponent>) {
    }

    get f() {
        return this.form.controls;
    }

    ngOnInit(): void {
        this.f['codigo'].addValidators(this.data.campo.value.usaCodigo ? Validators.required : null);
    }

    cancelar() {
        this.modalRef.close(null);
    }

    addValor() {
        (this.data.campo.get('valores') as FormArray).push(this.newValor(this.form));
        this.form.reset({activo: true, id: 0});
    }

    removerValor(index: number) {
        const vs: FormArray = this.data.campo.get('valores');
        (this.data.campo.get('valoresRemovidos').value as number[]).push(vs.at(index).value.id);
        vs.removeAt(index);
    }

    private newValor(form: FormGroup) {
        return this.fb.group({
            'id': form.value.id,
            'idCampo': form.value.idCampo ?? 0,
            'valor': [Util.parseValue(form.value.valor, this.data.campo.value?.codTipo), Validators.required],
            'codigo': [form.value.codigo?.toUpperCase(), form.get('codigo').validator],
            'activo': form.value.activo
        });
    }
}
