import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

interface IMenuItem {
    type: string; // Possible values: link/dropDown/icon/separator/extLink
    name?: string; // Used as display text for item and title for separator type
    state?: string; // Router state
    icon?: string; // Material icon name
    code?: string; // Material icon name
    svgIcon?: string; // UI Lib icon name
    tooltip?: string; // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    desenvolvimento?: boolean;
    dev?: boolean;
}

interface IChildItem {
    type?: string;
    name: string; // Display text
    state?: string; // Router state
    icon?: string;  // Material icon name
    svgIcon?: string; // UI Lib icon name
    sub?: IChildItem[];
    desenvolvimento?: boolean;
    dev?: boolean;
}

interface IBadge {
    color: string; // primary/accent/warn/hex color codes(#fff000)
    value: string; // Display text
}

@Injectable()
export class NavigationService {
    iconMenu: IMenuItem[] = [
        {
            name: 'DASHBOARD',
            code: 'DASHBO',
            type: 'link',
            tooltip: 'Dashboard',
            svgIcon: 'ulb_server',
            icon: 'dashboard',
            state: 'dashboard'
        },

        // Menu Recursos Humanos
        {
            name: 'Recursos Humanos',
            code: 'RECHUM',
            svgIcon: 'group_add',
            type: 'dropDown',
            tooltip: 'Material',
            icon: 'group_add',
            state: 'rh',
            sub: [
                {
                    name: 'Colaboradores',
                    state: 'clientes/lista/5'
                    //state: 'rh/colaboradores'
                },

                {
                    name: 'Estruturas Organizativas',
                    state: 'estrutura',
                    type: 'dropDown',
                    desenvolvimento: true
                },
                {
                    name: 'Configurações',
                    type: 'dropDown',
                    sub: [
                        {name: 'Cargos', state: 'configuracoes/cargos'},
                        {
                            name: 'Dados Colaborador',
                            state: 'configuracoes/dados-colaborador'
                        },
                        {
                            name: 'Estruturas Organizativas',
                            state: 'configuracoes/configuracao-estruturas-organizativas'
                        },
                        // {name: 'Estrutura Organizativa',state: 'configuracoes/estrutura-organizativa'},
                        {name: 'Funções', state: 'configuracoes/funcoes'},
                        {
                            name: 'Grau Parentesco',
                            state: 'configuracoes/grau-parentesco'
                        },
                        {name: 'Posições', state: 'configuracoes/posicoes'},
                        // {name: 'Tipos de Colaboração', state: 'configuracoes/tipos-colaboracao'},
                        {
                            name: 'Tipos de Contacto',
                            state: 'configuracoes/tipos-contactos'
                        },
                        {
                            name: 'Tipos de Documento',
                            state: 'configuracoes/tipos-documentos'
                        },
                        {
                            name: 'Tipos de Informação',
                            state: 'configuracoes/tipos-informacao'
                        }
                    ]
                }
            ]
        },

        // Menu Clientes
        {
            name: 'Clientes',
            code: 'CLIENT',
            svgIcon: 'group_add',
            type: 'dropDown',
            tooltip: 'Clientes',
            icon: 'group_add',
            state: 'clientes',
            sub: [
                {
                    name: 'Clientes',
                    state: 'lista'
                },
                {
                    name: 'Acessos',
                    state: 'lista/acessos'
                },
                {
                    name: 'Configurações',
                    type: 'dropDown',
                    sub: [
                        {name: 'Estados', state: 'configuracoes/estados'},
                        {name: 'Caracteristicas', state: 'configuracoes/caracteristicas'},
                        {name: 'Sectores Actividade', state: 'configuracoes/sectores-actividade'},
                        {name: 'Tipos', state: 'configuracoes/tipos'},
                        {name: 'Tipos de Documentos', state: 'configuracoes/documentos'},
                        {name: 'Tipos de Arquivos', state: 'configuracoes/tipos-arquivos'},
                        {
                            name: 'Email´s Manuais',
                            state: 'configuracoes/emails-manuais-lista'
                        },
                        {
                            name: 'Email´s Automáticos',
                            state: 'configuracoes/emailsautomaticos'
                        }
                    ]
                }
            ]
        },

        // Menu Fornecedor
        {
            name: 'Fornecedores',
            code: 'FORNEC0',
            type: 'dropDown',
            tooltip: 'Fornecedores',
            svgIcon: 'local_shipping',
            icon: 'local_shipping',
            state: 'fornecedores',
            sub: [
                {
                    name: 'Fornecedores',
                    state: 'lista'
                },
                {
                    name: 'Configuração',
                    type: 'dropDown',
                    sub: [
                        {name: 'Estados', state: 'configuracao/estados/lista'},
                        {name: 'Categorias', state: 'configuracao/categorias/lista'},
                        {name: 'Tipos Fornecedor', state: 'configuracao/tipos/lista'}
                    ]
                }
            ]
        },

        {
            name: 'Artigos',
            code: 'ARTIGO',
            type: 'dropDown',
            tooltip: 'Artigos',
            icon: 'shopping_basket',
            state: 'artigos',
            sub: [
                {name: 'Artigos', state: 'lista'},
                {name: 'Administrativos', state: 'lista/6'},
                {name: 'Realizações', state: 'realizacoes/lista'},
                {name: 'Inscrições', state: 'inscricoes/lista', desenvolvimento: true},
                {name: 'Pagamentos', state: 'documentos-inscricoes/lista'},
                {name: 'Turmas', state: 'realizacoesTurmas/lista', desenvolvimento: true},
                {name: 'Módulos', state: 'modulos/lista', desenvolvimento: true},
                {name: 'Armazéns', state: 'lista'},
                {
                    name: 'Stocks', type: 'dropDown',
                    sub: [
                        {name: 'Documentos', state: '/stock/documentos'},
                        {name: 'Stocks', state: '/stock'},
                        {name: 'Hst. Stocks', state: '/stock/historico'}
                    ]
                },
                {
                    name: 'Inventário',
                    type: 'dropDown', desenvolvimento: true,
                    sub: [
                        {name: 'Inventário', state: 'inventario/lista', desenvolvimento: true}
                        // { name: 'Armazéns', state: 'inventario/armazens' },
                    ]
                },
                {
                    name: 'Configurações',
                    type: 'dropDown',
                    sub: [
                        {name: 'Famílias', state: 'configuracoes/tipos'},
                        {name: 'Categorias', state: 'configuracoes/categoria'},
                        // {name: 'Marca e Modelos', state: 'configuracoes/marcas-modelos'},
                        {name: 'Variantes', state: 'configuracoes/variantes/lista'},
                        {name: 'Estados', state: 'configuracoes/estados'},
                        {name: 'Tipos de Arquivos', state: 'configuracoes/tipos-arquivos'},
                        {name: 'Tipos de Certificados', state: 'configuracoes/certificados/lista'},
                        {name: 'Movimentos de Stock', state: '/stocks/movimentos'},
                        {name: 'Tipos de Arquivos das inscrições', state: 'configuracoes/tipos-arquivos-inscricoes/lista'},
                        {name: 'Locais', state: 'configuracoes/locais/lista'},
                        {name: 'E-mails', state: 'configuracoes/emails/lista'},
                        {
                            name: 'E-mails Automáticos',
                            state: 'configuracoes/emails/automaticos'
                        },
                        {name: 'Turmas', state: 'configuracoes/turmas/lista'},
                        {name: 'Tipos de Colaboração', state: 'configuracoes/tipos-colaboracao/lista'},
                        {name: 'Ciclos de Renovação', state: 'configuracoes/ciclos'}
                    ]
                }
            ]
        },

        // Menu Recrutamento
        {
            name: 'Recrutamento',
            code: 'RECRUT',
            type: 'dropDown',
            tooltip: 'Recrutamento',
            icon: 'group_add',
            state: 'recrutamento',
            sub: [
                {
                    name: 'Vagas',
                    state: 'vagas'
                },
                {
                    name: 'Candidaturas',
                    state: 'candidaturas/lista'
                },
                {
                    name: 'Configurações',
                    type: 'dropDown',
                    sub: [
                        {name: 'Áreas', state: 'configuracoes/areas'},
                        {name: 'Funções', state: 'configuracoes/funcoes'},
                        {name: 'Cargos', state: 'configuracoes/cargos'},
                        {name: 'Graus Académicos', state: 'configuracoes/grausacademico'}
                    ]
                }
            ]
        },

        // Menu	Processos
        {
            name: 'Processos',
            code: 'PROCES',
            type: 'dropDown',
            tooltip: 'Material',
            icon: 'swap_calls',
            state: 'processos',
            sub: [
                {name: 'processos', state: 'lista'},
                {
                    name: 'Configurações',
                    type: 'dropDown',
                    sub: [
                        {name: 'Tipos', state: 'configuracoes/tipos'},
                        {name: 'CategoriasPro', state: 'configuracoes/categorias'},
                        {name: 'TiposTarefa', state: 'configuracoes/tipo-tarefa'},
                        {name: 'Atividades', state: 'configuracoes/tarefas'},
                        {name: 'Estados', state: 'configuracoes/estados'},
                        {name: 'EstadosTarefa', state: 'configuracoes/estados-tarefa'},
                        {
                            name: 'Tipos Arquivos',
                            state: 'configuracoes/tipos-arquivos'
                        },
                        {
                            name: 'Email´s Automáticos',
                            state: 'configuracoes/emailsautomaticos'
                        },
                        {
                            name: 'Localizações',
                            state: 'configuracoes/tiposlocalizacao'
                        },
                        {
                            name: 'Intervenientes',
                            state: 'configuracoes/intervenientes'
                        },
                        {
                            name: 'Equipamentos Intervenção',
                            state: 'configuracoes/equipamentos'
                        }
                    ]
                }
            ]
        },
        // Menu Equipamentos
        {
            name: 'Equipamentos',
            code: 'EQUATI',
            type: 'dropDown',
            tooltip: 'Equipamentos',
            icon: 'build',
            state: 'equipamentos',
            sub: [
                {name: 'Equipamentos', state: 'lista'},
                {
                    name: 'Configurações',
                    type: 'dropDown',
                    sub: [
                        {name: 'Localização', state: 'configuracoes/localizacoes'},
                        {name: 'Tipos', state: 'configuracoes/tipos'},
                        {
                            name: 'Estados',
                            state: 'configuracoes/estados'
                        },
                        {name: 'Marcas e Modelos', state: 'configuracoes/marcas'},
                        {name: 'Estados de Ocupação', state: 'configuracoes/estados/ocupacao'},
                        {
                            name: 'Tipos de Arquivos',
                            state: 'configuracoes/tipos-arquivos'
                        }
                    ]
                }
            ]
        },

        // Manutencao

        {
            name: 'Manutenção',
            code: 'MANUTE',
            type: 'dropDown',
            tooltip: 'Manutenção',
            icon: 'directions_car',
            state: 'manutencao',
            sub: [
                {
                    name: 'Manutenções',
                    state: 'lista'
                },

                {
                    name: 'Configurações',
                    type: 'dropDown',
                    sub: [
                        {name: 'Tipos de Manutenção', state: 'configuracoes/tipos'},
                        {name: 'Estados da Manutenção', state: 'configuracoes/estados'}
                    ]
                }
            ]
        },

        // Menu	Facturação
        {
            name: 'Vendas',
            code: 'FACTUR',
            type: 'dropDown',
            tooltip: 'Vendas',
            icon: 'store_mall_directory',
            state: 'facturacao',
            sub: [
                {name: 'Criar documento', state: 'criar-documento'},
                {
                    name: 'Consultar',
                    type: 'dropDown',
                    sub: [
                        {name: 'Documentos', state: 'documentos'},
                        {name: 'Itens', state: 'itens'}
                    ]
                },
                {name: 'Administrativos', state: 'pagamentos/6'},
                {
                    name: 'Renováveis', state: '',
                    type: 'dropDown',
                    sub: [
                        {name: 'Renováveis', state: 'renovaveis/1'},
                        {name: 'Renovações', state: 'renovacoes/1'}
                    ]
                },
                // {
                //     name: 'POS',
                //     type: 'dropDown',
                //     sub: [
                //         {
                //             name: 'Abrir POS',
                //             state: 'pos'
                //         },
                //     ]
                // },
                {
                    name: 'SAFT-AO',
                    type: 'dropDown',
                    sub: [
                        {name: 'Exportar', state: 'facturacao/saft/exportar'},
                        {name: 'Consultar', state: 'facturacao/saft/consultar', desenvolvimento: true}
                    ]
                },
                {
                    name: 'Referências Multicaixa',
                    type: 'dropDown',
                    sub: [
                        {name: 'Consultar', state: 'referencia-multicaixa/consultar'}
                    ]
                },
                {
                    name: 'Configuração',
                    type: 'dropDown',
                    sub: [
                        {name: 'Tipo Documentos', state: 'facturacao/configuracao/tipos-documentos'},
                        {name: 'Estados', state: 'facturacao/estados'},
                        {name: 'Numeração', state: 'facturacao/numeracao'}
                    ]
                },

            ]
        },

        // Menu	POS
        {
            name: 'POS',
            code: 'POS',
            type: 'dropDown',
            tooltip: 'POS',
            icon: 'add_shopping_cart',
            state: 'facturacao',
            sub: [
                {name: 'Abrir POS', state: 'pos'},
                {name: 'Caixas', state: 'pos/caixa'},
                {
                    name: 'Configuração',
                    type: 'dropDown',
                    sub: [
                        {name: 'Lojas', state: 'pos/lojas'},
                        {name: 'Salas e Mesas', state: 'pos/salas_Mesas'},
                        {name: 'Terminais', state: 'pos/terminais'}
                    ]
                },

            ]
        },


        // MENU COMPRAS //

        {
            name: 'Compras',
            code: 'COMPRA',
            type: 'dropDown',
            tooltip: 'Compras',
            icon: 'shopping_cart',
            state: 'compras',
            sub: [
                {
                    name: 'Criar documento',
                    state: 'criar-documento'
                },
                {
                    name: 'Consultar',
                    type: 'dropDown',
                    sub: [
                        {name: 'Documentos', state: 'documentos'},
                        {name: 'Itens', state: 'itens'},

                    ]
                },
                {
                    name: 'Configuração', type: 'dropDown',
                    sub: [
                        {name: 'Itens de Despesa', state: 'artigos/lista/7', desenvolvimento: false}
                    ]
                },
            ]
        },

        // Menu	-	Tesouraria
        {
            name: 'Tesouraria',
            code: 'TESOUR',
            type: 'dropDown',
            tooltip: 'Material',
            icon: 'attach_money',
            state: 'tesouraria',
            sub: [
                {name: 'Recebimentos', state: 'recebimentos/pagamentos'},
                {name: 'Criar Documento', state: 'clientes/criar-documento'},
                {name: 'Consultar Documentos', state: 'consultar-documentos'},
                {name: 'Pendentes', state: 'clientes/documentos-pendentes'},
                {name: 'Contas correntes', state: 'contas-correntes'},

                {name: 'Saldos', state: 'saldos', desenvolvimento: true},

                {
                    name: 'Bancos e Caixas',
                    type: 'dropDown',
                    sub: [
                        {
                            name: 'Contas Bancárias',
                            state: 'tesouraria/financeira/contas/contas',
                            //desenvolvimento: true
                        },
                        {
                            name: 'Caixas',
                            state: 'tesouraria/financeira/contas/caixas'
                        },
                        {
                            name: 'TPA',
                            state: 'tesouraria/financeira/contas/tpa'
                        }
                    ]
                },

                {
                    name: 'Configurações',
                    type: 'dropDown',
                    sub: [
                        // {
                        //     name: 'Tipos de Documentos',
                        //     state: 'tesouraria/configuracoes/tipos-documentos'
                        // },
                        // {
                        //     name: 'Estado Documentos',
                        //     state: 'tesouraria/configuracoes/estados-documentos'
                        // },
                    ]
                }
            ]
        },

        // Menu - Finanças
        // Menu	-	Tesouraria
        {
            name: 'Finanças ',
            code: 'FINANC',
            type: 'dropDown',
            tooltip: 'Material',
            icon: 'attach_money',
            state: 'financas',
            desenvolvimento: true,
            sub: [
                {
                    name: 'Obrigações Fiscais',
                    type: 'dropDown',
                    sub: [
                        {
                            name: 'IVA',
                            state: 'obrigacoes-fiscais/iva'
                        },
                        {
                            name: 'Retenções',
                            state: 'obrigacoes-fiscais/rnf'
                        },
                    ]
                }
            ]
        },


        // Gestao de Arquivos
        {
            name: 'Gestão de Arquivos',
            code: 'GESARQ',
            type: 'dropDown',
            tooltip: 'Material',
            icon: 'attach_money',
            state: 'gestao-arquivos',
            sub: [
                {name: 'Arquivos', state: 'arquivos'},
                {
                    name: 'Configurações',
                    type: 'dropDown',
                    sub: [
                        {name: 'Empresas', state: 'configuracoes/empresas'},
                        {name: 'Localizações de Arquivo', state: 'configuracoes/localizacaoArquivos/1'},
                        {name: 'Tipos de Arquivos', state: 'configuracoes/tipos-arquivos'}
                    ]
                }
            ]
        },

        // Menu	Eventos

        // Menu	•	Comunicação
        {
            name: 'Comunicação',
            code: 'COMUNI',
            type: 'dropDown',
            tooltip: 'Comunicação',
            icon: 'chat',
            state: 'comunicacao',
            sub: [
                {
                    name: 'Emails',
                    type: 'dropDown',
                    sub: [
                        {name: 'Log', state: 'emails/logs'},
                        {name: 'Configuração', state: 'emails/configuracao'}
                    ]
                }
                // { name: "Newsletters", state: "newsletters" },
                //  { name: "SMS’s", state: "sms" },
            ]
        },
        // Fim -	•	Comunicação
        // Menu	•	Equipas
        {
            name: 'Equipas',
            code: 'RESPON',
            type: 'dropDown',
            tooltip: 'Material',
            icon: 'groups',
            state: 'responsaveis',
            sub: [
                {
                    name: 'Equipas',
                    state: 'equipas/equipas-lista'
                }
            ]
        },

        // Menu	• Relatórios
        {
            name: 'Relatórios',
            code: 'RELATO',
            type: 'dropDown',
            tooltip: 'Material',
            icon: 'person_add',
            state: 'relatorios',
            sub: [
                {
                    name: 'XML',
                    type: 'dropDown',
                    sub: [
                        {name: 'Contratos', state: 'contratos'},
                        {name: 'Garantias', state: 'garantias'},
                        {name: 'Operações', state: 'operacoes'},
                        {name: 'Pessoas', state: 'pessoas'}
                    ]
                }
            ]
        },

        // Menu	• CMS
        {
            name: 'CMS',
            code: 'GESCON',
            type: 'dropDown',
            tooltip: 'Material',
            icon: 'directions_car',
            state: 'cms',
            sub: [
                {
                    name: 'Páginas',
                    type: 'dropDown',

                    sub: [{name: 'Pagina', state: 'pagina/lista/1'}]
                },
                {
                    name: 'Redes sociais',
                    state: 'redessociais/lista'
                },

                {
                    name: 'Contactos',
                    type: 'dropDown',
                    sub: [
                        {name: 'Contactos', state: 'contactos/lista/1'},
                        {name: 'Locais', state: 'contactos/locais/lista/1'}
                    ]
                },

                {
                    name: 'Formulários',
                    type: 'dropDown',
                    sub: [{name: 'Contactos', state: 'formulario/lista/1'}]
                },

                {
                    name: 'NewsLetters',
                    type: 'dropDown',
                    sub: [
                        {name: 'Registos', state: 'newsletters/registos/lista'},
                        {name: 'Areas', state: 'newsletters/areas/lista'}
                        // { name: 'Configurações', state: 'newsletters/configuracoes/C/1/1' },
                    ]
                },

                {
                    name: 'Galerias',
                    type: 'dropDown',
                    sub: [
                        {name: 'Galeria', state: 'galeria/2'}
                    ]
                },
                {
                    name: 'Repositório',
                    type: 'dropDown',
                    sub: [
                        {name: 'Arquivos', state: 'arquivos/lista'},
                        {name: 'Ícones', state: 'icones/lista'},
                        {name: 'Imagens', state: 'imagens/lista'}
                    ]
                },

                {
                    name: 'Termos & Políticas',
                    type: 'dropDown',
                    sub: [
                        {name: 'Política de Cookies', state: 'termos/cookies/lista'},
                        {
                            name: 'Política de privacidade',
                            state: 'termos/privacidade/lista'
                        }
                    ]
                },

                {
                    name: 'Faqs',
                    type: 'dropDown',

                    sub: [
                        {name: 'Areas', state: 'faqs/areas/lista'},
                        {name: 'Faqs', state: 'faqs/lista'}
                    ]
                },
                {
                    name: 'Links Úteis',
                    type: 'dropDown',

                    sub: [
                        {name: 'Areas', state: 'link-util/areas'},
                        {name: 'Links Úteis', state: 'links-uteis'}
                    ]
                },
                {
                    name: 'Equipas',
                    state: 'equipas'
                },
                {
                    name: 'Autores',
                    state: 'autores'
                },
                {
                    name: 'Notícias',
                    state: 'noticias'
                },
                //AC#
                {
                    name: 'Grupo',
                    state: 'Grupo'
                },
                {
                    name: 'Configurações',
                    type: 'dropDown',
                    sub: [
                        {name: 'Equipas', state: 'configuracoes/equipas'},
                        {name: 'Autores Funções', state: 'configuracoes/autoresfuncoes'},
                        {name: 'Contactos', state: 'configuracoes/contactos'},
                        {name: 'Idiomas', state: 'configuracoes/idiomas/lista/1'},
                        {name: 'Locais Caracteristicas', state: 'configuracoes/local-caracteristicas/lista'},
                        {name: 'Locais Tipos', state: 'configuracoes/tiposlocais/lista'},
                        {name: 'Logotipos', state: 'configuracoes/logotipos'},
                        {name: 'Configuração', state: 'configuracoes/configuracao'},
                        {name: 'Galerias', state: 'configuracoes/galerias'},
                        {name: 'Páginas', state: 'configuracoes/paginas'},
                        {name: 'Tipos Noticia', state: 'configuracoes/tiposnoticia'},
                        {name: 'Categorias Noticia', state: 'configuracoes/categoriasnoticia'}
                    ]
                }
            ]
        },
        // Fim Menu	• CMS

        // Menu	•	Utilizadores
        {
            name: 'Utilizadores',
            code: 'UTILIZ',
            type: 'dropDown',
            tooltip: 'Material',
            icon: 'person_add',
            state: 'utilizadores',
            sub: [
                {name: 'Utilizadores', state: 'lista'},
                {name: 'Perfis', state: 'perfis/lista'},
                {name: 'Permissões', state: 'permissoes'}
            ]
        },
        // Fim - Utilizadores

        // Menu	- Configurações
        {
            name: 'Configurações',
            code: null,
            type: 'dropDown',
            tooltip: 'Material',
            icon: 'settings_applications',
            state: 'material',
            sub: [
                {
                    name: 'Sistema',
                    type: 'dropDown',
                    sub: [{name: 'Dados Sistema', state: ''}]
                },
                {
                    name: 'Localização',
                    type: 'dropDown',
                    sub: [
                        {name: 'Paises', state: ''},
                        {name: 'Províncias', state: ''},
                        {name: 'Municípios', state: ''},
                        {name: 'Zonas', state: ''},
                        {name: 'Códigos Postais', state: ''}
                    ]
                },
                {
                    name: 'Moedas',
                    type: 'dropDown',
                    sub: [
                        {name: 'Moedas', state: ''},
                        {name: 'Moedas Sistema', state: ''}
                    ]
                },
                {
                    name: 'Idiomas',
                    type: 'dropDown',
                    sub: [{name: 'Idiomas', state: ''}]
                },
                {
                    name: 'Documentos',
                    type: 'dropDown',
                    sub: [{name: 'Tipos de Documentos Identificação', state: ''}]
                },
                {
                    name: 'Impostos',
                    type: 'dropDown',
                    sub: [
                        {name: 'Retenções', state: ''},
                        {name: 'Impostos', state: ''}
                    ]
                }
            ]
        },
        // Fim -	•	Configurações

        {
            name: 'Administração',
            code: 'ADMINI',
            type: 'dropDown',
            tooltip: 'Administração',
            icon: 'settings',
            state: 'administracao',
            sub: [
                {
                    name: 'Aplicação',
                    type: 'dropDown',
                    sub: [
                        {name: 'Aplicação', state: 'aplicacao/aplicacao'},
                        {name: 'Icones', state: 'aplicacao/icones'}
                    ]
                },
                {
                    name: 'Módulos',
                    state: 'modulo/lista'

                },
                {
                    name: 'Artigos',
                    type: 'dropDown',
                    sub: [
                        {name: 'Parametrização', state: 'artigo/parametrizacao'},
                        {name: 'Numeração', state: 'artigo/numeracao'},
                        {name: 'Estados', state: 'artigo/estados'},
                        {name: 'Naturezas de Artigo', state: 'artigo/tiposArtigo'},
                        {name: 'Tipos de Armazém', state: 'artigo/tiposArmazens', desenvolvimento: true}

                    ]
                },
                {
                    name: 'Eventos',
                    type: 'dropDown',
                    sub: [
                        {name: 'Parametrização', state: 'evento/parametrizacao'},
                        {name: 'Numeração', state: 'evento/numeracao'},
                        {name: 'Estados', state: 'evento/estados'},
                        {name: 'Naturezas de Evento', state: 'evento/familiasEvento'}
                    ]
                },
                {
                    name: 'Equipamentos',
                    type: 'dropDown',
                    sub: [
                        {name: 'Parametrização', state: 'equipamento/parametrizacoes'},
                        // {name: 'Numeração', state: 'equipamento/numeracao'},
                        {name: 'Estados', state: 'equipamento/estados'},
                        {name: 'Naturezas', state: 'equipamento/naturezas'},
                        {name: 'Tipos', state: 'equipamento/tipos'},
                        {name: 'Tipos de Transações', state: 'equipamento/transacoes'},
                        {name: 'Relações', state: 'equipamento/relacoes'}
                    ]
                },
                {
                    name: 'Processos',
                    type: 'dropDown',
                    sub: [{name: 'Parametrização', state: 'processos/parametrizacoes'}]
                },
                {
                    name: 'Empresas',
                    state: 'empresas'
                },
                {
                    name: 'Entidades',
                    type: 'dropDown',
                    sub: [
                        {name: 'Estados', state: 'entidades/estados-lista'},
                        {name: 'Documentos', state: 'entidades/documentos/lista'},
                        {name: 'Parametrização', state: 'entidades/parametrizacao'},
                        {name: 'Relação Entidades', state: 'entidades/relacaoentidades'},
                        {name: 'Numeração', state: 'entidades/numeracao-lista'}
                    ]
                },
                {name: 'Armazéns', state: 'armazens'},
                {
                    name: 'Gestor de Conteúdos',
                    type: 'dropDown',
                    sub: [
                        {name: 'Formulários', state: 'cms/formularios'},
                        {name: 'Grupos Registos', state: 'cms/gruposRegistos'},
                        {name: 'Grupos Tipos', state: 'cms/gruposTipos'},
                        {name: 'Tipos Arquivos', state: 'cms/tiposarquivo'}
                    ]
                },

                {
                    name: 'Fornecedores',
                    type: 'dropDown',
                    sub: [
                        {name: 'Parametrização', state: 'fornecedores/parametrizacao'}
                    ]
                },
                {
                    name: 'Financeira',
                    type: 'dropDown',
                    sub: [
                        {name: 'Bancos', state: 'financeira/bancos'},
                        //{name: 'Contas', state: 'financeira/contas'},
                        {name: 'Tipos Documentos', state: 'financeira/documentos-facturacao'},
                        {name: 'Séries', state: 'financeira/series-lista'},
                        {name: 'Métodos Pag/Rec', state: 'financeira/metodos-pagamento'},
                        {name: 'Condições Pag.', state: 'financeira/condicoes-pagamentos'},
                        {name: 'Moedas e Câmbios', state: 'financeira/moedas-cambios-lista'},
                        {name: 'IVA', state: 'financeira/IVA'},
                        {name: 'E-mail', state: 'financeira/email-facturacaoTesouraria'}
                    ]
                },
                {
                    name: 'R. Humanos', type: 'dropDown'
                    // sub: [
                    //   { name: 'R. Humanos', state: 'Eventos/parametrizacao' }
                    // ],
                }
            ]
        }

    ];
    // Icon menu TITLE at the very top of navigation.
    // This title will appear if any icon type item is present in menu.
    iconTypeMenuTitle = 'Frequently Accessed';
    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    constructor() {
    }

    // Customizer component uses this method to change menu.
    // You can remove this method and customizer component.
    // Or you can customize this method to supply different menu for
    // different user type.
    publishNavigationChange(menuType: string) {
        switch (menuType) {
            case 'separator-menu':
                this.menuItems.next(this.iconMenu);
                break;
            case 'icon-menu':
                this.menuItems.next(this.iconMenu);
                break;
            default:
                this.menuItems.next(this.iconMenu);
        }
    }
}
