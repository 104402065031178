import {Directive, ElementRef, HostListener} from '@angular/core';
import {FixedStoreService} from '../services/data/fixed-store.service';

@Directive({
  selector: '[appFixTop]'
})
export class FixTopDirective {
  constructor(private el: ElementRef, private fixStore: FixedStoreService) {

    this.el.nativeElement.addEventListener('scroll', () => {
      if (window.pageYOffset < this.el.nativeElement.offsetTop) {
          fixStore.setFixTop(true);
      } else {
        fixStore.setFixTop(false);
      }
      if (this.el.nativeElement.offsetHeight < 5) {
        fixStore.setFixTop(false);
      }
    })
  }


}
