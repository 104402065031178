import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DropdownLinkDirective } from './dropdown-link.directive';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[appToggleOpen]'
})
export class ToggleOpenDirective implements OnInit {
    protected navlinks: DropdownLinkDirective[] = [];

    private _router: Subscription;

    public closeOtherLinks(openLink: DropdownLinkDirective): void {
      this.navlinks.forEach((link: DropdownLinkDirective) => {
        if (link !== openLink) {
          link.open = false;
        }
      });
    }

  
    public getUrl() {
      return this.router.url;
    }
  
    public ngOnInit(): any {
      this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this.navlinks.forEach((link: DropdownLinkDirective) => {
          if (link.group) {
            const routeUrl = this.getUrl();
            const currentUrl = routeUrl.split('/');
            if (currentUrl.indexOf( link.group ) > 0) {
              link.open = true;
              this.closeOtherLinks(link);
            }
          }
        });
      });
    }

  @HostListener('click', ['$event']) onClick(event: any) {
    // event.stopPropagation();
      if (event.target !== event.currentTarget && this.el.nativeElement.classList.contains('open')) {
        // console.log(event)
      }
  }
  constructor(private router: Router, private el: ElementRef, private renderer: Renderer2) {}

}
