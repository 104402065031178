<div fxLayout="row" fxFlex="100" class="pt-16 k-form-crud pl-16">

    <mat-form-field [formGroup]="form"
                    appearance="outline" class="full-width" fxFlex="95">

        <mat-label class="label-texto">{{placeholder}}</mat-label>
        <!-- formControlName="idRetencao" -->
        <!-- multiple -->
        <!-- (selectionChange)="onSelect($event)" -->

        <mat-checkbox (click)="pevDefault($event)"
                      fxFlex="0"
                      id="00"
                      color="primary"
                      class="in-checkbox"
                      [class.d-none]="ocultarCheckBoxNoForm"
                      #check>

        </mat-checkbox>
        <mat-select id="select-retencao"
                    formControlName="selecionado"
                    #ref>

            <!-- (click)="setOption($event?.target?.id)" -->
            <mat-option *ngFor="let item of lista; let i=index"
                        [value]="item[chaveId]" fxFlex="100"
                        (click)="setOption($event?.target?.id)">
                <span *ngIf="i===0">{{item[chaveTexto]}}</span>
                <mat-checkbox *ngIf="i!==0"
                              (click)="setFromCheckBox(i)"
                              [id]="i"
                              color="primary"
                              fxFlex="100"
                              class="example-margin"
                              #check>
                    {{item[chaveTexto]}}
                </mat-checkbox>
            </mat-option>
            <!--
                                  <mat-option name="2" [value]="'2'" fxFlex="100"
                                        (click)="setOption($event?.target?.id)"

                                    #matOption>
                                        <mat-checkbox
                                            id="2"
                                            color="primary"
                                            fxFlex="100"
                                            class="example-margin"
                                            #check
                                        >
                                            Op 1
                                        </mat-checkbox>
                                    </mat-option>

                                  <mat-option name="3" [value]="'3'" fxFlex="100"
                                        (click)="setOption($event?.target?.id)"

                                    #matOption>
                                        <mat-checkbox
                                            id="3"
                                            color="primary"
                                            fxFlex="100"
                                            class="example-margin"
                                            #check
                                        >
                                            Op 2
                                        </mat-checkbox>
                                    </mat-option>
                                   -->

            <!-- <mat-option *ngFor="let retencao of retencoes" [value]="retencao.idRetencao">
                {{ retencao.descricao }}
            </mat-option> -->
        </mat-select>
    </mat-form-field>
    <!-- </div> -->
</div>
