import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'pos-container',
  template: '<div class="pos-container"></div>',
  styles: [`
   
  `]
// tslint:disable-next-line:component-class-suffix
}) export class PosContainer {

}
