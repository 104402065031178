<footer class="main-footer">
  <div fxLayout="wrap row" class="container-dynamic d-flex" style="justify-content: space-between">
   <div class="py-1" fxFlex="100" fxFlex.xl="33.33" fxFlex.lg="33.33" fxFlex.md="33.33" fxFlex.sm="50" fxFlex.xs="100">
     <p class="mb-0 txt-left">Licenciado a:
       <span class="text-secondary">{{licencaEmpresa}}</span>
     </p>
   </div>
    <div  fxFlex="100" fxFlex.xl="33.33" fxFlex.lg="33.33" fxFlex.md="33.33" fxFlex.sm="50" fxFlex.xs="100"  class="txt-center py-02">
      <ng-container *ngIf="aplicacao && aplicacao?.codTipoInstalacao && aplicacao?.codTipoInstalacao.toUpperCase() !== 'P'">
        <span class="uppercase font-weight-bold text-error ">Instalação de {{aplicacao?.tipoInstalacao}}</span>
      </ng-container>
    </div>
    <div fxFlex="100" fxFlex.xl="33.33" fxFlex.lg="33.33" fxFlex.md="33.33" fxFlex.sm="50" fxFlex.xs="100">
      <p class="mb-0 txt-right">
        Desenvolvido por &ensp;<a href="https://kiamisoft.ao" class="text-secondary">KiamiSoft</a>
      </p>
    </div>
  </div>
</footer>
