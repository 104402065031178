<section class="position-relative">
    <div class="modal-header" >
          <span (click)="onNoClick()" class="cursor-pointer btn-close-modal">
          <mat-icon>close</mat-icon>
      </span>
    </div>
    <div class="modal-title">
      <h1 mat-dialog-title >Remover {{titulo}} </h1>
    </div>
    <div class=" p-05">
     <div class="modal-body">
       <div fxLayout="row wrap" fxLayout.lt-sm="column">
         <div fxFlex="100">
           <p class="text-center" *ngIf="data.cliente?.fotografia"><img [src]="data?.cliente?.fotografia | safe: 'url'" style="width: 3rem"></p>
           <p>
                Confirma a remoção da caracteristica  <span class="font-weight-bold ">{{mensagem}}?</span>
           </p>
         </div>
  
         <div fxFlex="100" class="mt-1">
           <button mat-raised-button  (click)="removerCaracteristica()" color="primary" >Confirmar</button>
           <span fxFlex></span>
           <button mat-button class="bg-secondary" type="button" (click)="onNoClick(0)" >Cancelar</button>
         </div>
       </div>
     </div>
    </div>
  </section>
  