import {Injectable} from '@angular/core';
// import {ExportAsConfig, ExportAsService} from 'ngx-export-as';



@Injectable({
    providedIn: 'root'
})
export  class ExportFile {
constructor(private exportAs: any) {
}

     exportToFile(_type?: any, name?: string, id?: string, _orientation?: string) {
    // ExportAsConfig
        const exportAsConfig: any = {
            type: _type, // the type you want to download
            elementIdOrContent: id, // the id of html/table element,
            options: { // html-docx-js document options
                jsPDF: {
                    orientation: 'landscape'
                },
                orientation: _orientation,
                margins: {
                    top: '20'
                }
            }
        };
        this.exportAs.save(exportAsConfig, name).subscribe(res => console.log('Resultado da criacao', res));
    }


}
