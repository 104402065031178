import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'msgExpiracao',
  pure: true
})export class MsgExpiracaoPipe implements PipeTransform {
  public transform(valor: number): any {
    let msgExpiracao = '';
    switch (true) {
      case (valor > 1): {
        msgExpiracao = `<span> A sua licença expira em ${valor} dias!</span>`;
        break;
      }
      case (valor === 1): {
        msgExpiracao = `<span> A sua licença expira em ${valor} dia!</span>`;
        break;
      }
      case (valor === 0): {
        msgExpiracao = `<span> A sua licença expira hoje!</span>`;
        break;
      }
      case (valor === -1): {
        msgExpiracao = `<span> A sua licença expirou há ${Math.abs(valor)} dia!</span>`;
        break;
      }
      case (valor < -1): {
        msgExpiracao = `<span> A sua licença expirou há ${Math.abs(valor)} dias!</span>`;
        break;
      }
      default:  msgExpiracao = `<span> A sua licença expirou!</span>`;
        break;
    }
    return msgExpiracao;
  }
}
