export enum Genero {
    genMasculino = 1,
    genFeminino = 2
}

export enum Accao {
    editar = 'E',
    detalhes = 'D',
    cadastrar = 'C'
}

export enum Opc {
    NAO = 1,
    OPCIONAL = 2,
    OBRIGATORIO = 3
}
