<ki-modal-base [accao]="actions.cadastrar" [verBtnGravar]="true" tituloModal="Adicionar arquivo"
               (aoGravar)="adicionarArquivos()">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

    <form [formGroup]="form" class="modal-mat-tab-conteudo">
        <div fxLayout="row wrap" class="w-full k-form-crud">
            <mat-form-field style="min-width: 190px" appearance="outline">
                <mat-label>Tipo de arquivo</mat-label>
                <mat-select [formControlName]="formNames.tipo" (selectionChange)="setTipo($event.value)" required>
                    <mat-option [value]="null">Seleccione o tipo de arquivo</mat-option>
                    <mat-option *ngFor="let tipoArquivo of dados?.tiposArquivos"
                                [value]="tipoArquivo">{{ tipoArquivo?.nome }}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <div fxFlex="30" class="px-1">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput type="text" [formControlName]="formNames.nome">
                </mat-form-field>
            </div>

            <ng-container *ngFor="let campo of campos.controls; index as indexCampo" formArrayName="campos">
                <ng-container [formGroupName]="indexCampo">
                    <div [ngStyle]="{'width.ch': form.value?.tipo?.campos[indexCampo]?.nome?.length * 4, 'min-width.ch': 10}"
                         *ngIf="form.value?.tipo?.campos[indexCampo]?.codTipo === types.INTEIRO"
                         class="px-05">
                        <div fxLayout="row">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>{{ form.value?.tipo?.campos[indexCampo]?.nome }}</mat-label>
                                <input type="number" formControlName="valor" min="1" matInput
                                       [required]="form.value?.tipo?.campos[indexCampo]?.obrigatorio"
                                       *ngIf="!form.value?.tipo?.campos[indexCampo]?.select"
                                       [placeholder]="form.value?.tipo?.campos[indexCampo]?.nome">
                                <mat-select *ngIf="form.value?.tipo?.campos[indexCampo]?.select"
                                            formControlName="valor"
                                            [required]="form.value?.tipo?.campos[indexCampo]?.obrigatorio"
                                            [placeholder]="form.value?.tipo?.campos[indexCampo]?.nome">
                                    <mat-option [value]="null">Seleccione</mat-option>
                                    <ng-container
                                            *ngFor="let v of form.value?.tipo?.campos[indexCampo]?.valores">
                                        <mat-option *ngIf="v.activo"
                                                    [value]="v.codigo ?? v.valor">{{ v.valor }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>

                            <button *ngIf="form.value?.tipo?.campos[indexCampo]?.insercaoMultipla"
                                    class="btn-autocomplete-plus mr-05"
                                    matTooltip="Adicionar valor"
                                    matTooltipClass="bg-secondary"
                                    matTooltipPosition="above"
                                    type="button" style="margin-top: 10px"
                                    (click)="adicionarValor(campo)">
                                <span>+</span>
                            </button>
                        </div>

                        <div *ngIf="form.value?.tipo?.campos[indexCampo]?.insercaoMultipla"
                             class="pt-1">
                            <mat-chip-list>
                                <ng-container *ngFor="let val of multiplosValores">
                                    <mat-chip
                                            *ngIf="val.idCampo === form.value?.tipo?.campos[indexCampo]?.id"
                                            [disabled]="dados.action === actions.detalhes"
                                            (removed)="removerValor(val)">
                                        <span>{{ val.valor }}</span>
                                        <mat-icon *ngIf="dados.action !== actions.detalhes" matChipRemove>cancel
                                        </mat-icon>
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
                    </div>

                    <div [ngStyle]="{'width.ch': form.value?.tipo?.campos[indexCampo]?.nome?.length * 2, 'min-width.ch': 10}"
                         class="px-05"
                         *ngIf="form.value?.tipo?.campos[indexCampo]?.codTipo === types.BOOL">
                        <div class="flex items-center h-100">
                            <mat-checkbox formControlName="valor" class="text-primary" color="primary">
                                {{ form.value?.tipo?.campos[indexCampo]?.nome }}
                            </mat-checkbox>
                        </div>
                    </div>


                    <div [ngStyle]="{'width.ch': form.value?.tipo?.campos[indexCampo]?.nome?.length * 4, 'min-width.ch': 10}"
                         class="px-05"
                         *ngIf="form.value?.tipo?.campos[indexCampo]?.codTipo === types.DECIMAL">
                        <div fxLayout="row">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>{{ form.value?.tipo?.campos[indexCampo]?.nome }}</mat-label>
                                <input type="text" formControlName="valor" matInput
                                       [appInputFormat]="formats.Decimal"
                                       *ngIf="!form.value?.tipo?.campos[indexCampo]?.select"
                                       [required]="form.value?.tipo?.campos[indexCampo]?.obrigatorio"
                                       [placeholder]="form.value?.tipo?.campos[indexCampo]?.nome">
                                <mat-select *ngIf="form.value?.tipo?.campos[indexCampo]?.select"
                                            formControlName="valor"
                                            [required]="form.value?.tipo?.campos[indexCampo]?.obrigatorio"
                                            [placeholder]="form.value?.tipo?.campos[indexCampo]?.nome">
                                    <mat-option [value]="null">Seleccione</mat-option>
                                    <ng-container
                                            *ngFor="let v of form.value?.tipo?.campos[indexCampo]?.valores">
                                        <mat-option *ngIf="v.activo"
                                                    [value]="v.codigo ?? v.valor">{{ v.valor | currency:'':'' }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                            <button *ngIf="form.value?.tipo?.campos[indexCampo]?.insercaoMultipla"
                                    class="btn-autocomplete-plus mr-05"
                                    matTooltip="Adicionar valor"
                                    matTooltipClass="bg-secondary"
                                    matTooltipPosition="above"
                                    type="button" style="margin-top: 10px"
                                    (click)="adicionarValor(campo)">
                                <span>+</span>
                            </button>
                        </div>

                        <div *ngIf="form.value?.tipo?.campos[indexCampo]?.insercaoMultipla"
                             class="pt-1">
                            <mat-chip-list>
                                <ng-container *ngFor="let val of multiplosValores">
                                    <mat-chip
                                            *ngIf="val.idCampo === form.value?.tipo?.campos[indexCampo]?.id"
                                            [disabled]="dados.action === actions.detalhes"
                                            (removed)="removerValor(val)">
                                        <span>{{ val.valor }}</span>
                                        <mat-icon *ngIf="dados.action !== actions.detalhes" matChipRemove>cancel
                                        </mat-icon>
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
                    </div>

                    <div [ngStyle]="{'width.ch': form.value?.tipo?.campos[indexCampo]?.nome?.length * 3, 'min-width.ch': 10}"
                         class="px-05" fxLayout="column"
                         *ngIf="form.value?.tipo?.campos[indexCampo]?.codTipo === types.TEXT">
                        <div fxLayout="row">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>{{ form.value?.tipo?.campos[indexCampo]?.nome }}</mat-label>
                                <input *ngIf="!form.value?.tipo?.campos[indexCampo]?.select" type="text"
                                       formControlName="valor" matInput
                                       [placeholder]="form.value?.tipo?.campos[indexCampo]?.nome"
                                       [required]="form.value?.tipo?.campos[indexCampo]?.obrigatorio">
                                <mat-select *ngIf="form.value?.tipo?.campos[indexCampo]?.select"
                                            formControlName="valor"
                                            [required]="form.value?.tipo?.campos[indexCampo]?.obrigatorio"
                                            [placeholder]="form.value?.tipo?.campos[indexCampo]?.nome">
                                    <mat-option [value]="null">Seleccione</mat-option>
                                    <ng-container
                                            *ngFor="let v of form.value?.tipo?.campos[indexCampo]?.valores">
                                        <mat-option *ngIf="v.activo"
                                                    [value]="v.codigo ?? v.valor">{{ v.valor }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                            <button *ngIf="form.value?.tipo?.campos[indexCampo]?.insercaoMultipla"
                                    class="btn-autocomplete-plus mr-05"
                                    matTooltip="Adicionar valor"
                                    matTooltipClass="bg-secondary"
                                    matTooltipPosition="above"
                                    type="button" style="margin-top: 10px"
                                    (click)="adicionarValor(campo)">
                                <span>+</span>
                            </button>
                        </div>

                        <div *ngIf="form.value?.tipo?.campos[indexCampo]?.insercaoMultipla"
                             class="pt-1">
                            <mat-chip-list>
                                <ng-container *ngFor="let val of multiplosValores">
                                    <mat-chip
                                            *ngIf="val.idCampo === form.value?.tipo?.campos[indexCampo]?.id"
                                            [disabled]="dados.action === actions.detalhes"
                                            (removed)="removerValor(val)">
                                        <span>{{ val.valor }}</span>
                                        <mat-icon *ngIf="dados.action !== actions.detalhes" matChipRemove>cancel
                                        </mat-icon>
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
                    </div>

                    <div [ngStyle]="{'min-width.ch': (form.value?.tipo?.campos[indexCampo]?.nome?.length ?? 0) + 10}"
                         *ngIf="form.value?.tipo?.campos[indexCampo]?.codTipo === types.DATE"
                         class="px-05">
                        <div fxLayout="row">
                            <mat-form-field *ngIf="!form.value?.tipo?.campos[indexCampo]?.select" class="full-width"
                                            appearance="outline">
                                <mat-label>{{ form.value?.tipo?.campos[indexCampo]?.nome }}</mat-label>
                                <input matInput formControlName="valor" [appInputFormat]="formats.Data"
                                       [matDatepicker]="dataValidade"
                                       [readonly]="dados.action === actions.detalhes" type="text"
                                       [required]="form.value?.tipo?.campos[indexCampo]?.obrigatorio">
                                <mat-datepicker-toggle matSuffix
                                                       [for]="dataValidade"></mat-datepicker-toggle>
                                <mat-datepicker #dataValidade></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field *ngIf="form.value?.tipo?.campos[indexCampo]?.select" class="full-width"
                                            appearance="outline">
                                <mat-label>{{ form.value?.tipo?.campos[indexCampo]?.nome }}</mat-label>
                                <mat-select
                                        formControlName="valor"
                                        [required]="form.value?.tipo?.campos[indexCampo]?.obrigatorio"
                                        [placeholder]="form.value?.tipo?.campos[indexCampo]?.nome">
                                    <mat-option [value]="null">Seleccione</mat-option>
                                    <ng-container
                                            *ngFor="let v of form.value?.tipo?.campos[indexCampo]?.valores">
                                        <mat-option *ngIf="v.activo"
                                                    [value]="v.codigo ?? v.valor">
                                            {{ v.valor | date:'dd/MM/yyyy' }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                            <button *ngIf="form.value?.tipo?.campos[indexCampo]?.insercaoMultipla"
                                    class="btn-autocomplete-plus mr-05"
                                    matTooltip="Adicionar valor"
                                    matTooltipClass="bg-secondary"
                                    matTooltipPosition="above"
                                    type="button" style="margin-top: 10px"
                                    (click)="adicionarValor(campo)">
                                <span>+</span>
                            </button>
                        </div>

                        <div *ngIf="form.value?.tipo?.campos[indexCampo]?.insercaoMultipla"
                             class="pt-1">
                            <mat-chip-list>
                                <ng-container *ngFor="let val of multiplosValores">
                                    <mat-chip
                                            *ngIf="val.idCampo === form.value?.tipo?.campos[indexCampo]?.id"
                                            [disabled]="dados.action === actions.detalhes"
                                            (removed)="removerValor(val)">
                                        <span>{{ val.valor | date:'dd/MM/yyyy' }}</span>
                                        <mat-icon *ngIf="dados.action !== actions.detalhes" matChipRemove>cancel
                                        </mat-icon>
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <div fxFlex="100" class="py-1">
                <mat-divider></mat-divider>
            </div>
        </div>

        <div fxLayout="row wrap" class="w-full k-form-crud">
            <div class="pl-05" fxFlex="50" fxFlex.xl="14" fxFlex.lg="20" fxFlex.md="20">
                <div class="p-1 h-100">
                    <div class="kcard border-dashed rounded-4px flex-xy-center" style="height: 240px">
                        <div fxLayoutAlign="center center">
                            <div>
                                <label for="inputArquivo">
                                    <mat-chip class="bg-primary">Inserir anexos</mat-chip>
                                    <input style="display: none" type="file" id="inputArquivo" multiple
                                           (change)="carregarFicheiros($event)">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxFlex="50" fxFlex.xl="14" fxFlex.lg="20" fxFlex.md="20"
                 *ngFor="let anexo of anexos.controls; let i = index"
                 [formArrayName]="formNames.anexos">
                <div class="p-1" [formGroupName]="i">
                    <div class="equi-card-ficheiro">
                        <div class="bg-dark-gray">
                            <div class="equi-card-ficheiro-opcoes">
                               <span class="menu-opcoes">
                                 <button type="button" class="bg-lighter-gray text-white" mat-icon-button
                                         [matMenuTriggerFor]="menu">
                                     <mat-icon class="text-white">more_vert</mat-icon>
                                 </button>
                                 <mat-menu #menu="matMenu">
                                      <button (click)="remover(anexo.value, i)" type="button" mat-menu-item>
                                        <span>Remover</span>
                                      </button>
                                      <button type="button" mat-menu-item
                                              (click)="download(form.value, anexo.value)">
                                        <span>Download</span>
                                      </button>
                                 </mat-menu>
                               </span>
                            </div>
                            <div class="equi-card-ficheiro-img">
                                <img [src]="anexo?.value?.conteudo | tipoFicheiro |  safe: 'resourceUrl'"/>
                            </div>
                        </div>

                        <div class="p-1">
                            <mat-form-field class="w-full" appearance="outline"
                                            *ngIf="f[formNames.tipo].value?.guardaNomeFicheiro">
                                <textarea matInput rows="2" [formControlName]="formNames.nome"
                                          placeholder="Descrição"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ki-modal-base>
