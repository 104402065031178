import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {forkJoin, of, Subscription} from 'rxjs';
import {NotificacaoService} from 'app/shared/services/notificacao/notificacao.service';
import {FinancasService} from 'app/shared/services/financas/financas.service';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {LoginService} from 'app/shared/services/aplicacao-service/login.service';
import {Series} from 'app/shared/models/financas/documentos/series';
import {Format} from 'app/shared/directives/input-format.directive';
import {ReferenciasService} from 'app/shared/services/financas/referencias.service';
import {Resposta} from 'app/shared/models/resposta';
import {Util} from 'app/shared/models/generico/util';
import {PesquisarCambioComponent} from 'app/views/facturacao/modais/pesquisar-cambio/pesquisar-cambio.component';
import {ArtigosService} from 'app/shared/services/artigos/artigos.service';
import {Accao} from 'app/shared/models/ENUM';

const FormNames = {
    tipo: 'tipo',
    serie: 'serie',
    numero: 'numero',
    codDocumento: 'codDocumento',
    dataDocumento: 'dataDocumento',
    dataVencimento: 'dataVencimento',
    metodoPagamento: 'metodoPagamento',
    numRefBancaria: 'referenciaBancariaNum',
    idRefBancEntidade: 'idRefBancEntidade',
    idContaMetodoPagRec: 'idContaMetodoPagRec',
    idCondicaoPagamento: 'idCondicaoPagamento',
    observacoes: 'observacoes',
    taxaCambio: 'taxaCambio',
};

enum LoadIndex {
    SERIE,
    CODIGO,
    COND_PAG,
    METHOD_PAG,
    NUM_REF,
    DOC,
    CONTA
}

@Component({
    selector: 'app-reno-modal-facturacao',
    templateUrl: './reno-modal-facturacao.component.html',
    styleUrls: ['./reno-modal-facturacao.component.scss']
})
export class RenoModalFacturacaoComponent implements OnInit, OnDestroy {
    readonly formats = Format;
    contas: any[] = [];
    loadings: boolean[] = Array.from({length: 7}, () => true);
    form: FormGroup = this.fb.group({
        [FormNames.serie]: [null, Validators.required],
        [FormNames.tipo]: [null, Validators.required],
        [FormNames.numero]: 0,
        [FormNames.codDocumento]: '',
        [FormNames.metodoPagamento]: null,
        [FormNames.dataDocumento]: new Date(),
        [FormNames.dataVencimento]: new Date(),
        [FormNames.idCondicaoPagamento]: null,
        [FormNames.idRefBancEntidade]: null,
        [FormNames.numRefBancaria]: null,
        [FormNames.idContaMetodoPagRec]: null,
        [FormNames.observacoes]: null,
        [FormNames.taxaCambio]: null,
    });
    formNames = FormNames;
    minDate = new Date('1922-01-01');
    maxDate = new Date();
    series: Series[] = [];
    entidades: any[] = [];
    condicoesPagamento: any[] = [];
    totalIliq = 0;
    total = 0;
    totalImposto = 0;
    totalDescontos = 0;
    valorRetencao = 0;
    totalIliqCliente = 0;
    readonly formNameMap: { [key: string]: string } = {
        [FormNames.serie]: 'Série',
        [FormNames.numero]: 'Número',
        [FormNames.tipo]: 'Tipo de Documento',
        [FormNames.dataDocumento]: 'Data Documento',
        [FormNames.dataVencimento]: 'Data Vencimento',
        [FormNames.idRefBancEntidade]: 'Entidade',
        [FormNames.idContaMetodoPagRec]: 'Conta'
    };
    readonly errorMap: { [key: string]: string } = {
        required: 'É obrigatório',
        max: 'Excedeu o valor máximo',
        min: 'Excedeu o valor mínimo',
        matDatepickerMin: 'Excedeu o valor mínimo da data',
        matDatepickerMax: 'Excedeu o valor máximo da data',
        matDatepickerParse: 'Formato inválido',
        maxlength: 'Excedeu o comprimento máximo',
        greaterThanZero: 'Deve ser um valor positivo',
        empty: 'A coleção não pode estar vazia para submissão'
    };
    taxasCambio: any[] = [];
    sendMail = false;
    readonly actionToEvent: { [key: string]: string } = {
        [Accao.cadastrar]: 'CRIACAO',
        [Accao.editar]: 'RENOVACAO',
        [Accao.detalhes]: 'RENOVACAO'
    };
    private subscriptions: Subscription[] = [];

    constructor(
        private fb: FormBuilder,
        private _notificacao: NotificacaoService,
        private financaService: FinancasService,
        private loginService: LoginService,
        private referenciaService: ReferenciasService,
        private dialog: MatDialog,
        private artigoService: ArtigosService,
        public dialogRef: MatDialogRef<RenoModalFacturacaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    get f() {
        return this.form.controls;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    ngOnInit(): void {
        this.subscriptions.push(
            forkJoin([
                this.financaService.getTodasSeries({utilizador: this.loginService.getUtilizadorSessao()})
                    .pipe(catchError(err => {
                        console.error(err);
                        this.loadings[LoadIndex.SERIE] = false;
                        this._notificacao.notificar(err.error?.mensagem || 'Falha inesperada ao carregar as séries');
                        return of({objecto: []});
                    })),
                this.financaService.getCondicoesPagamentos().pipe(catchError(err => {
                    this.loadings[LoadIndex.COND_PAG] = false;
                    console.error(err);
                    this._notificacao.notificar(err.error?.mensagem || 'Falha inesperada as condições de pagamento');
                    return of({objecto: []} as Resposta<any[]>);
                })),
                this.referenciaService.getEntidadesRefActivasEmpresa(this.data.empresa?.idEmpresa)
            ]).pipe(switchMap(([ss, cps, es]) => {
                // Séries
                this.series = ss.objecto.filter(s => s.idEmpresa === this.data.empresa?.idEmpresa);
                this.f[FormNames.serie]
                    .patchValue(this.series.find(s => s.predefinida) ?? (this.series.length === 1 ? this.series[0] : null));
                this.f[FormNames.tipo].patchValue(this.data.tipos.find(t => t.codTipoDocumento === this.data.codTipo));
                this.getCodigo();
                // Codições pagamento
                this.condicoesPagamento = cps.objecto.filter(x => x.activo);
                // Entidades
                this.entidades = es.objecto;
                this.f[FormNames.metodoPagamento]
                    .patchValue(this.data.metodosPagamento.find(m => m.idMetodoPagRec === this.data.renovavel.idMetodoPagRec));
                this.f[FormNames.idCondicaoPagamento].patchValue(this.data.entidade?.dadosComerciais?.idCondicaoPagamento);
                this.onMethodSelect({value: this.f[FormNames.metodoPagamento].value});
                this.setDates();
                return this.data.empresa?.moedaPrincipal !== this.data.entidade?.codMoeda ? this.financaService.getTaxasCambio({
                    cambiosIndirectos: true,
                    utilizador: this.loginService.getUtilizadorSessao(),
                    idsMoedaLocal: [this.data.empresa?.idMoeda1],
                    idsMoedaPretendida: this.data.entidade?.idMoeda ? [this.data.entidade?.idMoeda] : [],
                    intervalosData: [
                        {
                            operacao: 'EQ',
                            dataInicio: this.f[FormNames.dataDocumento].value,
                            dataFim: this.f[FormNames.dataDocumento].value,
                        },
                    ],
                }).pipe(map(ts => ts ?? [])) : of([]);
            }))
                .subscribe({
                    next: ts => {
                        this.loadings = [...this.loadings.map(() => false)];
                        this.taxasCambio = ts;
                        this.f[FormNames.taxaCambio].patchValue(this.taxasCambio.length === 1 ? this.taxasCambio[0] : null);
                        this.calcular();
                    }, error: err => {
                        this.loadings = [...this.loadings.map(() => false)];
                        console.error(err);
                        this._notificacao.notificar(err.error?.mensagem || 'Falha inesperada ao carregar os dados');
                    }
                })
        );
    }

    some(value) {
        return value;
    }

    onMethodSelect({value}) {
        if (!value) {
            return;
        }

        if (value?.usaConta) {
            this.subscriptions.push(
                this.financaService.getTiposContaEmpresa(this.data.empresa?.idEmpresa, value?.tipoContaPag?.idTipoConta)
                    .subscribe({
                        next: res => {
                            this.loadings[LoadIndex.CONTA] = false;
                            this.loadings = [...this.loadings];
                            this.contas = res.objecto;
                            // this.formNameMap[FormNames.idContaMetodoPagRec] = value?.tipoContaPag?.tipoConta;
                            this.f[FormNames.idContaMetodoPagRec]
                                .patchValue(this.contas.length === 1 ? this.contas[0] : null);
                        },
                        error: err => {
                            this.loadings[LoadIndex.CONTA] = false;
                            this.loadings = [...this.loadings];
                            console.error(err);
                            this._notificacao.notificar(err.error?.mensagem || 'Falha inesperada ao carregar as contas');
                        }
                    })
            );
        } else {
            this.f[FormNames.idContaMetodoPagRec].clearValidators();
            this.f[FormNames.idContaMetodoPagRec].updateValueAndValidity();
        }

        if (value?.codMetodoPagRec === 'RB') {
            this.f[FormNames.idRefBancEntidade].addValidators(Validators.required);
            if (this.entidades.length === 1) {
                this.f[FormNames.idRefBancEntidade].patchValue(this.entidades[0]?.idRefBancEntidade);
                this.gerarReferencia({value: this.f[FormNames.idRefBancEntidade].value});
            }
        } else {
            this.f[FormNames.idRefBancEntidade].clearValidators();
            this.f[FormNames.idRefBancEntidade].reset();
            this.f[FormNames.numRefBancaria].reset();
        }

        this.f[FormNames.idRefBancEntidade].updateValueAndValidity();
    }

    getCodigo() {
        const {serie, tipo} = this.form.value;
        if (!serie || !tipo) {
            return;
        }
        this.loadings[LoadIndex.CODIGO] = true;
        this.loadings = [...this.loadings];
        this.subscriptions.push(
            this.financaService.getProximoNumero(this.data.empresa?.idEmpresa, serie?.idSerie, tipo?.idTipoDocumento)
                .subscribe({
                    next: res => {
                        this.loadings[LoadIndex.CODIGO] = false;
                        this.loadings = [...this.loadings];
                        this.form.patchValue({
                            [FormNames.numero]: res.objecto.numProximo,
                            [FormNames.codDocumento]: res.objecto.codProximo
                        });
                        this.minDate = this.data.empresa?.permiteVendasDataFutura ? new Date(res.objecto.dataDocActual) : null;
                        this.maxDate = this.data.empresa?.permiteVendasDataFutura ? null : new Date();
                    },
                    error: err => {
                        this.loadings[LoadIndex.CODIGO] = false;
                        this.loadings = [...this.loadings];
                        console.error(err);
                        this._notificacao.notificar(err.error?.mensagem || 'Falha inesperada ao gerar o código do documento');
                    }
                })
        );
    }

    gerarReferencia({value}) {
        if (!value) {
            this.f[FormNames.numRefBancaria].reset();
            return;
        }
        this.loadings[LoadIndex.NUM_REF] = true;
        this.loadings = [...this.loadings];
        this.subscriptions.push(
            this.referenciaService.getReferenciaEntidade(value)
                .subscribe({
                        next: res => {
                            this.f[FormNames.numRefBancaria].patchValue(res.objecto);
                            this.loadings[LoadIndex.NUM_REF] = false;
                            this.loadings = [...this.loadings];
                        },
                        error: err => {
                            this._notificacao.notificar(err.error?.mensagem || 'Falha inesperada ao gerar o número de referência bancária');
                            console.error(err);
                            this.loadings[LoadIndex.NUM_REF] = false;
                            this.loadings = [...this.loadings];
                        }
                    }
                )
        );
    }

    calcular() {
        this.totalIliq = this.data.itens.reduce((acc, i) => Util.convertToFloat(i.total) + acc, 0);
        this.totalImposto = this.data.itens.reduce((acc, i) => Util.convertToFloat(i.imposto) + acc, 0);
        this.totalDescontos = this.data.itens.reduce((acc, i) => Util.convertToFloat(i.desconto) + acc, 0);
        this.valorRetencao = this.data.entidade?.clientesRetencoes?.reduce((acc, ret) => {
            const total = this.data.itens.filter(i => i.idRetencao === ret.idRetencao).reduce((acc2, i) =>
                Util.convertToFloat(i.total) + acc2, 0);
            const val = total * ret.valorPercentual / 100;
            return (total > ret.valorMinimo ? Math.round(val * 100) / 100 : 0) + acc;
        }, 0);
        this.total = this.totalIliq + this.totalImposto;
        this.totalIliqCliente = (this.totalIliq + this.totalImposto) / (this.f[FormNames.taxaCambio].value?.taxaCambio ?? 1);
    }

    pesquisarCambio() {
        const d = this.f[FormNames.dataDocumento];
        const dv = this.f[FormNames.dataVencimento];
        const cliente = this.data.entidade;
        const empresa = this.data.empresa;
        if (d.invalid || dv.invalid) {
            this._notificacao.notificar(Util.getFormErrors(this.form, this.formNameMap, this.errorMap));
            return;
        }

        const ref = this.dialog.open(PesquisarCambioComponent, {
            width: '80vw',
            data: {
                idMoedaPretendida: cliente?.idMoeda === empresa?.idMoeda1 ? cliente?.idMoeda : empresa?.idMoeda1,
                idMoedaEmpresa:
                    cliente?.idMoeda !== empresa?.idMoeda1 ? cliente?.idMoeda : empresa?.idMoeda2,
                dataDoc: Util._UTCDate(d.value),
                dataVenc: Util._UTCDate(dv.value),
                conversaoDirecta: this.f[FormNames.taxaCambio].value?.conversaoDirecta,
                legendaTaxaCambio: `${cliente?.codMoeda}/${this.data.empresa?.moedaPrincipal}`,
            },
        });

        this.subscriptions.push(
            ref.afterClosed().subscribe(res => {
                if (res) {
                    this.f[FormNames.taxaCambio].patchValue(res.cambio);
                }
            })
        );
    }

    getImposto(id: number) {
        return this.data.empresa?.impostos?.find(i => i.idImposto === id);
    }

    submit() {
        if (this.form.invalid) {
            this._notificacao.notificar(Util.getFormErrors(this.form, this.formNameMap, this.errorMap));
            return;
        }

        this.loadings[LoadIndex.DOC] = true;
        this.loadings = [...this.loadings];
        const tipo = this.f[FormNames.tipo].value;
        const payload = {
            ...this.form.value,
            idEmpresa: this.data.empresa?.idEmpresa,
            idCliente: this.data.entidade?.idCliente,
            idTipoDocumento: tipo?.idTipoDocumento,
            idMetodoPagRec: this.f[FormNames.metodoPagamento].value?.idMetodoPagRec,
            idContaMetodoPagRec: this.f[FormNames.idContaMetodoPagRec].value,
            idMoeda: this.data.empresa?.idMoedaP,
            codArea: 'R',
            total: this.totalIliq,
            totalIva: this.totalImposto,
            totalRetencoes: tipo?.calculaRetencoes ? this.valorRetencao : 0,
            utilizador: this.loginService.getUtilizadorSessao(),
            pagamentos: [{
                idMetodoPagRec: this.f[FormNames.metodoPagamento].value?.idMetodoPagRec,
                idContaMetodoPagRec: this.f[FormNames.metodoPagamento].value?.usaConta &&
                this.f[FormNames.tipo].value?.codCategoriaDocumento === 'L' ? this.f[FormNames.idContaMetodoPagRec].value : null,
                valorPagamento: this.totalIliq + this.totalImposto
            }],
            itens: this.data.itens.map(i => this.itemRequest(i)),
            renovavel: this.data.renovavel,
        };
        payload.idContaMetodoPagRec = this.f[FormNames.metodoPagamento].value?.usaConta &&
        this.f[FormNames.tipo].value?.codCategoriaDocumento === 'L' ? payload.idContaMetodoPagRec : null;
        this.subscriptions.push(
            this.financaService.gravarDocumentoFA(payload)
                .pipe(mergeMap(res => {
                    this.loadings[LoadIndex.DOC] = false;
                    this.loadings = [...this.loadings];
                    this._notificacao.notificar(res.mensagem, true);
                    this.dialogRef.close(true);
                    return this.sendMail ?
                        this.artigoService.enviarEmail({
                            codigo: this.data.renovavel?.codigo,
                            idEmpresa: this.data.empresa?.idEmpresa,
                            evento: this.actionToEvent[this.data.action]
                        }).pipe(catchError(err => {
                            console.error(err.error?.mensagem);
                            this._notificacao.notificar(err.error?.mensagem || 'Falha inesperada ao enviar e-mail');
                            return of({objecto: null});
                        })) : of({objecto: null});
                }))
                .subscribe({
                    next: () => {
                    },
                    error: err => {
                        console.error(err.error?.mensagem);
                        this._notificacao.notificar(err.error?.mensagem || 'Falha inesperada ao gravar o documento');
                        this.loadings[LoadIndex.DOC] = false;
                        this.loadings = [...this.loadings];
                    }
                })
        );
    }

    setDates() {
        if (this.f[FormNames.tipo].value?.codCategoriaDocumento === 'L') {
            this.f[FormNames.idCondicaoPagamento].patchValue(1); // 1 => Pronto Pagamento
            this.f[FormNames.dataVencimento].patchValue(this.f[FormNames.dataDocumento].value);
        }
    }

    private itemRequest(i) {
        return {
            ...i,
            codArtigo: i.artigo?.codArtigo,
            descontoPerc: Util.convertToFloat(i.descontoPerc),
            desconto: Util.convertToFloat(i.desconto),
            quantidade: Util.convertToFloat(i.quantidade),
            preco: Util.convertToFloat(i.preco),
            imposto: Util.convertToFloat(i.imposto),
            total: Util.convertToFloat(i.total)
        };
    }
}
