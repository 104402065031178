import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Country } from '../../models/artigos/country-code-json/country-cod';

@Component({
  selector: 'app-phone',
  template: `
    <div style="display: inline-flex; align-items: center" id="telefone">
      <button type="button" mat-button class="label-indicativo" [matMenuTriggerFor]="menuPhone" >
        <span>{{paisSelected?.flag}} {{paisSelected?.dialCode}}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #menuPhone="matMenu" style="max-height: 200px; overflow-y: auto;" class="menu-tel-indicativo">
          <button type="button" mat-menu-item *ngFor="let pais of paises" (click)="aoSelectIndicativo(pais)">
             <span class="flag-icon mr-8 flag-icon-{{pais.flag.toLowerCase()}}">
             </span>  &nbsp;
             <span class="country">{{ pais.countryName }}</span>
             <span>{{ pais.dialCode }}</span>
          </button>
      </mat-menu>
      <mat-form-field id="matForm-1" appearance="outline" class="div-in-tel flex flex-tel">
        <mat-label>Telefone</mat-label>
           <input  autocomplete="off"   name="tel-select-component-1" matInput  class="in-tel-1"
                  type="tel" [formControl]="controlTelefone"  maxlength="17">
      </mat-form-field>
    </div>
  `,
  styles: [
        `
            .label-indicativo {
                height: 33px;
                border-radius: 0.4em 0 0 0.4em;
                border: 1px solid rgba(0, 0, 0, 0.15) !important;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background: #ffffff !important;
                padding-left:5px!important;
                padding-right:2px!important;
            }
         #telefone {
               transform: translateY(7px);
            }
            
        `
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneComponent),
      multi: true
    }
  ],
}) export class PhoneComponent implements ControlValueAccessor, OnChanges {
  @Input() paises: Country[] = [];
  paisSelected!: Country;
  controlTelefone = new FormControl();
  onChange: any = () => {};
  onTouch: any = () => {};
  set value(val: any) {
    console.log(val);
    if(val) {
      for(let item of this.paises) {
        if(val.startsWith(item.dialCode)) {
          this.paisSelected = item;
           this.controlTelefone.setValue(val);
            this.onChange(val);
            this.onTouch(val);
          console.log(item.dialCode);
          return;
        }
      }
      console.log('Indicativo:', this.extractCountryCode(val));
    }

  }

  writeValue(valor: any) {
    this.value = valor;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }
   registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['paises'] && changes['paises']?.currentValue) {
      this.paises = changes['paises'].currentValue;
      console.log(this.paises);
     if(!this.controlTelefone.value){
        this.paisSelected = changes['paises'].currentValue.find(p => p.flag === 'AO');
     }
    }
  }

  aoSelectIndicativo(pais: Country) {
    this.paisSelected = pais;
  }

     extractCountryCode(phoneNumber: string) {
      const regex = /^\+\d+/; // Matches '+' followed by one or more digits
      const match = regex.exec(phoneNumber);
      return match ? match[0] : null;
    }
}
