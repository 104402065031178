import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'pos-modal-notify',
  template: `
    <div class="relative">
      <div class="bg-secondary p-03">
        <div (click)="fecharSnack()" class="absolute right-0"><button type="button" class="bg-secondary" mat-icon-button><mat-icon>close</mat-icon></button></div>
        <div class="p-1 text-white">
          <h2 class="mb-0">{{dadosModal?.titulo ?? ''}} &nbsp;</h2>
        </div>
      </div>
      
      <div class="px-10 relative flex-y-center">
        <div class="py-6">
          <p class="py-6 text-primary" style="font-size: 17px">{{dadosModal?.info}}</p>
        </div>
      </div>
    </div>

  `,
// tslint:disable-next-line:component-class-suffix
}) export class PosModalNotifiy {
constructor(@Inject(MAT_DIALOG_DATA) public dadosModal: any,
            private dialogRef: MatDialogRef<PosModalNotifiy>) {}

  fecharSnack() {
    this.dialogRef.close();
  }
}
