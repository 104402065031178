import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MensagemDto } from 'app/shared/models/aplicacao/mensagemdto';


@Component({
  selector: 'app-modal-remover',
  templateUrl: './modal-remover.component.html',
  styleUrls: ['./modal-remover.component.scss']
})
export class ModalRemoverComponent implements OnInit {
  nome: string;
  titulo: string;
  mensagem: string;
  mensagemRetorno: MensagemDto;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public modalRef: MatDialogRef<ModalRemoverComponent>,
  ) {
    this.titulo = data?.titulo;
    this.mensagem = data?.mensagem;
  }

  ngOnInit() {

    }

  onNoClick(code?: number) {
    this.modalRef.close(code);
  }

  removerCaracteristica() {
    this.modalRef.close(this.data.idCaracteristica);
  }
}
