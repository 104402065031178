export interface ClienteFacturacao {
    idEntidade?: number;
    entidadeP?: string;
    idCliente?: number;
    idDadosFacturacao?: number;
    idMetodoPagRec?: number;
    idCondicaoPagamento?: number;
    metodoPagRec?: string;
    mesesDiaFixo?: number;
    diaFixo?: boolean;
    dias?: string;
    codCliente?: string;
    nome?: string;
    cidade?: string;
    email?: string;
    nif?: string;
    niffact?: string;
    idEstado?: number;
    estado?: string;
    idMoeda?: number;
    codMoeda?: string;
    nomeFact?: string;
    nifFact?: string;
    idPaisFact?: number;
    codPaisFact?: string;
    paisFact?: string;
    idProvinciaFact?: number;
    provinciaFact?: string;
    provicnciaFact?: string;
    idMunicipioFact?: number;
    municipioFact?: string;
    idZonaFact?: number;
    zonaFact?: string;
    moradaFact?: string;
    codPostalFact?: string;
    codPersonJuridica?: string;
    designacaoCodPostalFact?: string;
    clientesRetencoes?: any [];
    activoFact?: boolean;
    generico?: boolean;
    precoDefault?: number;
    entidadeCliente?: boolean;
    entidadeFornecedor?: boolean;
    entidadeEstado?: boolean;
    entidadeColaborador?: boolean
}


export function newClienteFat(c: any) {
    return {
        idEntidade: c.idEntidade,
        idCliente: c.idCliente,
        codCliente: c.codigo,
        nif: c.nif,
        nome: c.nome,
        idMetodoPagRec: c.idMetodoPagRec
    };
}
