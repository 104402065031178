import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'colName',
  pure: true
})
export class ColNamePipe implements PipeTransform {

 public transform(valor: any): any {
     let precoStr = '';
     if (typeof(valor) === 'string') {
         if (valor.includes(',')) {
             precoStr = valor.replace(',', '.');
            return parseFloat(precoStr);
         }
     } else {
        return parseFloat(valor);
     }
  }

}
