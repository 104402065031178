<section class="position-relative">
    <div class="modal-header">
      <span (click)="feichar()" class="cursor-pointer btn-close-modal">
        <mat-icon>close</mat-icon>
      </span>
    </div>
  
    <div class="modal-title">
      <h1 mat-dialog-title>{{title}}</h1>
    </div>
    <div class=" p-05">
      <div class="modal-body">
     
        <div fxLayout="row wrap">
          <div fxFlex="100">
            <p>
              <span *ngIf="!aviso" class="font-weight-bold ">{{data.message}}</span>
            </p>
          </div>
          <div *ngIf="aviso" class="w-100">
  
            <div *ngFor="let aviso of avisos" class="w-100">
              <mat-card>
                <h4 class="mb-0 w-100 text-primario">{{aviso.aviso}}</h4>
              </mat-card>
            </div>
            
          </div>
          <div fxFlex="100" class="mt-1">
            <button *ngIf="!aviso" mat-button class="bg-secondary" type="button" (click)="cancelar()">Cancelar</button>
            <span fxFlex></span>
            <button  mat-raised-button (click)="remover()" color="primary">{{textoConfirmar}}</button>
          </div>
        </div>
      </div>
    </div>
  </section>