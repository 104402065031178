import {Pipe, PipeTransform} from '@angular/core';

@Pipe(
    {
        name: 'bgFicheiro',
        pure: true
    }
)
export  class SetBgFicheiroPipe implements PipeTransform{
    public transform(valor: any, ...args): any {
        let extensao = '';
        if (valor) {
           let splittedString = valor.split('.');
           extensao = splittedString[splittedString.length - 1];
           if (extensao.toLowerCase() === 'svg') {
              return 'bg-light-gray';
           }
        }
    }
}
