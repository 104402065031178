import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from 'environments/environment';
import {ArtigoClassificacao} from '../../models/artigos/artigo-classificacao';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/internal/operators/catchError';
import {ArtigosTipos} from 'app/shared/models/artigos/artigostipos';
import {ArtigosVarianteOpcoes} from 'app/shared/models/artigos/artigovarianteopcoes';
import {ArtigosVariante} from 'app/shared/models/artigos/artigosvariantes';
import {ArtigosFacturacao} from 'app/shared/models/artigos/artigosfacturacao';
import {ArtigosConfiguracaoVariante} from 'app/shared/models/artigos/artigoconfiguracaovariante';
import {ArtigosTiposVariante} from 'app/shared/models/artigos/artigotiposvariantes';
import {ArtigosValores} from 'app/shared/models/artigos/artigosvalores';
import {Fornecedor} from 'app/shared/models/fornecedores/fornecedor';
import {ArtigoNatureza} from '../../models/artigos/artigoNatureza';
import {VarianteConunas} from 'app/shared/models/artigos/varianteartigocolunas';
import {Fornecedores} from 'app/shared/models/fornecedores/fornecedores';
import {ArParametrizacao} from 'app/shared/models/artigos/arparametrizacao';
import {TipoArmazem} from 'app/shared/models/artigos/tipoarmazem';
import {Armazem} from 'app/shared/models/artigos/armazem';
import {Resposta} from 'app/shared/models/resposta';
import {ArtigoLista} from '../../models/artigos/artigoLista';
import {LoginService} from '../aplicacao-service/login.service';
import {UtilizadorSessao} from '../../models/aplicacao/utilizador-sessao';
import {Util} from '../../models/generico/util';
import {Evento} from '../../models/artigos/emails/evento';
import {EmailAutomatico} from '../../models/artigos/emails/email-automatico';
import {EstadoFluxo} from '../../../views/artigos/renovavel-form/renovavel-form.component';
import {tap} from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
    })
};

export class Ciclo {
    static DIARIO = 1;
    static SEMANAL = 2;
    static QUINZENAL = 3;
    static MENSAL = 4;
    static BIMESTRAL = 5;
    static TRIMESTRAL = 6;
    static SEMESTRAL = 7;
    static ANUAL = 8;
    static BIENAL = 9;
    static QUINQUENAL = 10;
}

@Injectable({
    providedIn: 'root'
})
export class ArtigosService {
    url: string;
    artigosFA$ = new BehaviorSubject<any>(null);

    constructor(private http: HttpClient, private loginService: LoginService) {
        this.url = `${environment.apiArtigos}`;
    }

    getClassificacoesArtigos(): Observable<ArtigoClassificacao[]> {
        return this.http
            .get(this.url + 'classificacoes', httpOptions)
            .pipe(catchError(null));
    }

    getArtigosPorNatureza(natureza: string): Observable<any> {
        return this.http.get(`${this.url}artigo/natureza/${natureza}`);
    }

    removerClassificacao(idclassificacao: any) {
        return this.http
            .post(
                this.url + 'classificacoes/parametrizacao/' + idclassificacao,
                httpOptions
            )
            .pipe();
    }

    getParametrizacao(): Observable<ArParametrizacao[]> {
        return this.http
            .get(this.url + 'classificacoes/parametrizacoes', httpOptions)
            .pipe(catchError(null));
    }

    gravarParametrizacao(modelo: any): Observable<any> {
        return this.http
            .post(this.url + 'classificacoes/parametrizacao', modelo, httpOptions)
            .pipe();
    }

    getClassificacaoArtigo(id: number): Observable<ArtigoClassificacao> {
        return this.http
            .get(this.url + 'classificacoes/' + id, httpOptions)
            .pipe(catchError(null));
    }

    getVariantes(idClassificacao: number): Observable<ArtigosVariante[]> {
        return this.http
            .get(this.url + 'variantes/' + idClassificacao, httpOptions)
            .pipe(catchError(null));
    }

    getVariantesArtigo(idClassificacao: number): Observable<ArtigosVariante[]> {
        return this.http
            .get(this.url + 'variantes/artigo/' + idClassificacao, httpOptions)
            .pipe(catchError(null));
    }

    getVarianteColunas(idVariante: number): Observable<VarianteConunas> {
        return this.http
            .get(
                this.url + 'variantes/variante/colunas/' + idVariante,
                httpOptions
            )
            .pipe(catchError(null));
    }

    getValores(
        idClassificacao: number,
        codIdioma: number
    ): Observable<ArtigosValores[]> {
        return this.http
            .get(
                this.url + 'variantes/valores/' + idClassificacao + '/' + codIdioma,
                httpOptions
            )
            .pipe(catchError(null));
    }

    getVarianteOpcoes(
        idClassificacao: number
    ): Observable<ArtigosVarianteOpcoes[]> {
        return this.http
            .get(this.url + 'varianteOpcoes/' + idClassificacao, httpOptions)
            .pipe(catchError(null));
    }

    getVarianteTipos(
        idClassificacao: number
    ): Observable<ArtigosTiposVariante[]> {
        return this.http
            .get(
                this.url + 'variantes/varianteTipo/' + idClassificacao,
                httpOptions
            )
            .pipe(catchError(null));
    }

    getCategorias(idClassificacao: number, codIdioma: string): Observable<any> {
        return this.http
            .get(
                this.url + 'categorias/' + idClassificacao + '/' + codIdioma,
                httpOptions
            );
        // .pipe(catchError(null));
    }

    getCategoria(idCategoria: number): Observable<any> {
        return this.http
            .get(this.url + 'categorias/' + idCategoria, httpOptions)
            .pipe(catchError(null));
    }

    eliminarCategoria(idCategoria: number): Observable<any> {
        return this.http
            .post(this.url + 'categorias/eliminar', idCategoria, httpOptions)
            .pipe();
    }

    getCategoriasTexto(
        idClassificacao: number,
        idCategoria: number,
        codIdioma: string
    ): Observable<any> {
        return this.http
            .get(
                this.url +
                'categorias/' +
                idClassificacao +
                '/' +
                idCategoria +
                '/' +
                codIdioma,
                httpOptions
            )
            .pipe(catchError(null));
    }

    getArtigo(idArtigo: number, codIdioma: string) {
        return this.http
            .post<any>(this.url + 'artigo/' + codIdioma + '/' + idArtigo,
                this.loginService.getUtilizadorSessao(),
                httpOptions);
    }

    getArtigos(model: any): Observable<any> {
        model.utilizadorSessao = this.loginService.getUtilizadorSessao();
        return this.http.post(this.url + 'artigos/filtrar', model, httpOptions).pipe(catchError(err => {
            console.error(err);
            return err.status === 404 ?
                of({objecto: [], statusCode: err.status, mensagem: err.error?.mensagem || ''}) : throwError(() => err);
        }));
    }

    getTodosArtigos() {
        return this.http.get<Resposta<ArtigoLista[]>>(`${this.url}artigos/todos`);
    }

    getArtigoIva(CodPais: string): Observable<any> {
        return this.http
            .get(this.url + 'getIvaArtigo/' + CodPais, httpOptions)
            .pipe(catchError(null));
    }

    salvarCategoria(model: any) {
        return this.http
            .post(this.url + 'categorias/', model, httpOptions)
            .pipe(catchError(null));
    }

    // Tipo de Artigo
    getTipos(idClassificacao: number, codIdioma: string): Observable<any> {
        return this.http
            .get(
                this.url + 'tipo/tipos/' + idClassificacao + '/' + codIdioma,
                httpOptions
            )
            .pipe();
    }

    getTipo(idTipo: number, codIdioma: string): Observable<any> {
        return this.http
            .get(this.url + 'tipo/' + idTipo + '/' + codIdioma, httpOptions)
            .pipe(catchError(null));
    }

    getTipotexto(idTipo: number, codIdioma: string): Observable<any> {
        return this.http
            .get(this.url + 'tipo/texto/' + idTipo + '/' + codIdioma, httpOptions)
            .pipe(catchError(null));
    }

    salvarTipo(model: any): Observable<any> {
        return this.http.post(this.url + 'tipo/', model, httpOptions).pipe();
    }

    editarTipo(idTipo: number, model: ArtigosTipos) {
        return this.http
            .put(this.url + 'tipo/' + idTipo, model, httpOptions)
            .pipe(catchError(null));
    }

    removerTipoArtigo(idTipo: number) {
        return this.http.post<any>(this.url + 'tipo/eliminar/', idTipo, httpOptions);
    }

    removerTipo(idTipo: number) {
        return this.http
            .post(this.url + 'tipo/' + idTipo, httpOptions);
    }

    // salvaArtigo(model: any): Observable<any> {
    //   model.utilizadorSessao = JSON.parse(
    //     localStorage.getItem("utilizadorSessao")
    //   );
    //   return this.http.post(this.baseUrl + "artigo", model, httpOptions).pipe();
    // }

    salvaArtigo(model: any): Observable<any> {
        model.utilizadorSessao = JSON.parse(
            localStorage.getItem('utilizadorSessao')
        );
        return this.http.post(this.url + `artigo/${model.CodTipoArtigo}`, model, httpOptions).pipe();
    }

    gravarArtigo(model: any): Observable<any> {
        model.utilizadorSessao = JSON.parse(
            localStorage.getItem('utilizadorSessao')
        );
        return this.http.post(this.url + 'artigo', model, httpOptions).pipe();
    }

    editarArtigo(model: any): Observable<any> {
        model.utilizadorSessao = JSON.parse(
            localStorage.getItem('utilizadorSessao')
        );
        return this.http.post(this.url + 'deprecated/artigo', model, httpOptions).pipe();
    }

    removerArtigo(idArtigo: number): Observable<any> {
        return this.http
            .post(this.url + 'removerArtigo', {idArtigo, utilizadorSessao: this.loginService.getUtilizadorSessao()}, httpOptions);
    }

    getArtigosVendas(modelo) {
        return this.http.post<Resposta<ArtigosFacturacao[]>>(this.url + 'artigos/vendas', modelo);
    }

    getArtigosCompras(modelo) {
        return this.http.post<Resposta<ArtigosFacturacao[]>>(this.url + 'artigos/compras', modelo);
    }

    getArtigosFacturacao(codIdioma: number): Observable<ArtigosFacturacao[]> {
        return this.http
            .get(this.url + 'facturacao/' + codIdioma, httpOptions)
            .pipe(catchError(null));
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }

    salvarOpcaoVariante(model: any) {
        return this.http
            .post(this.url + 'variantes/varianteOpcoes/', model, httpOptions)
            .pipe(catchError(null));
    }

    salvarVariante(model: any) {
        return this.http
            .post(this.url + 'variantes/', model, httpOptions)
            .pipe(catchError(null));
    }

    getVarianteOpcao(idVarianteOpcao: number): Observable<ArtigosVarianteOpcoes> {
        return this.http
            .get(
                this.url + 'variantes/varianteOpcao/' + idVarianteOpcao,
                httpOptions
            )
            .pipe(catchError(null));
    }

    getVariante(idVariante: number): Observable<ArtigosVariante> {
        return this.http
            .get(this.url + 'variantes/variante/' + idVariante, httpOptions)
            .pipe(catchError(null));
    }

    getVarianteConfiguracoes(
        idVariante: number
    ): Observable<ArtigosConfiguracaoVariante[]> {
        return this.http
            .get(
                this.url + 'variantes/varianteConfiguracao/' + idVariante,
                httpOptions
            )
            .pipe(catchError(null));
    }

    // Fornecedores
    getFornecedores(codIdioma: string): Observable<Fornecedores[]> {
        return this.http
            .get(this.url + 'fornecedores/' + codIdioma, httpOptions)
            .pipe(catchError(null));
    }

    gravarArtigosFornecedor(model: any): Observable<any> {
        return this.http
            .post(this.url + 'fornecedores/', model, httpOptions)
            .pipe();
    }

    getArtigosFornecedor(
        idFornecedor: number,
        codIdioma: string
    ): Observable<Fornecedor[]> {
        return this.http
            .get(
                this.url + 'fornecedores/' + idFornecedor + '/' + codIdioma,
                httpOptions
            )
            .pipe(catchError(null));
    }

    removerArtigosFornecedor(modelo: any) {
        return this.http.post(
            this.url + 'fornecedores/remover/',
            modelo,
            httpOptions
        );
    }

    // Fim Fornecedores
    getArtigoVariante(idArtigo: number): Observable<any> {
        return this.http
            .get(
                this.url + 'variantes/GetArtigoVariante/' + idArtigo,
                httpOptions
            );
    }

    // gerarToken(model: any) {
    //     return this.http
    //         .post(this.baseUrl + 'gerartoken/', model)
    //         .pipe(catchError(null));
    // }

    getTiposArtigo(codNatureza: string): Observable<any> {
        return this.http.get(this.url + 'tiposArtigo/' + codNatureza, httpOptions);
    }

    getTodosTiposArtigo(): Observable<any> {
        return this.http.get(this.url + 'tipos-artigos/listar', httpOptions);
    }

    gravarTiposArtigo(modelo: any): Observable<any> {
        return this.http.post(this.url + 'tiposArtigo', modelo, httpOptions);
    }

    eliminarTiposArtigo(modelo: any): Observable<any> {
        return this.http
            .post(this.url + 'tipoArtigo/eliminar', modelo, httpOptions)
            .pipe();
    }

    getTipoArtigo(id: number) {
        return this.http.get<Resposta<ArtigoNatureza>>(this.url + 'tipoArtigo/' + id, httpOptions);
    }

    getCodigoArtigo(idClassificacao: number, data: string, IdTipoArtigo: number) {
        return this.http
            .get(
                this.url +
                'codigo/' +
                idClassificacao +
                '/' +
                data +
                '/' +
                IdTipoArtigo,
                httpOptions
            )
            .pipe(catchError(null));
    }

    //#region Armazem
    getTiposArmazem(): Observable<TipoArmazem[]> {
        return this.http
            .get(this.url + 'armazem/armazem/tipos-armazens', httpOptions)
            .pipe(catchError(null));
    }

    getTiposArmazemConf(): Observable<TipoArmazem[]> {
        return this.http
            .get(this.url + 'armazem/tipo-armazem-conf/listar', httpOptions)
            .pipe(catchError(null));
    }

    getTipoArmazem(id: number): Observable<TipoArmazem> {
        return this.http
            .get(this.url + 'armazem/armazem/tipoArmazem/' + id, httpOptions)
            .pipe(catchError(null));
    }

    gravarTipoArmazem(modelo: any): Observable<any> {
        return this.http
            .post(this.url + 'armazem/tipoArmazem', modelo, httpOptions)
            .pipe();
    }

    eliminarTipoArmazem(idTipoArmazem: any): Observable<any> {
        return this.http
            .post(
                this.url + 'armazem/tipoArmazem/eliminar',
                idTipoArmazem,
                httpOptions
            )
            .pipe();
    }

    getArmazens(modelo: any): Observable<Armazem[]> {
        return this.http
            .post(this.url + 'armazem/armazens', modelo, httpOptions)
            .pipe(catchError(null));
    }

    getArmazensLista(): Observable<any> {
        return this.http
            .get(this.url + 'armazem/armazem/armazens', httpOptions)
            .pipe();
    }

    getArmazem(idArmazem: number) {
        return this.http.get<Armazem>(this.url + 'armazem/armazem/' + idArmazem, httpOptions);
    }

    gravarArmazem(modelo: any) {
        return this.http.post<any>(this.url + 'armazem/armazem', modelo, httpOptions);
    }

    eliminarArmazem(idArmazem: any) {
        return this.http.post<any>(this.url + 'armazem/armazem/eliminar', idArmazem, httpOptions);
    }

    //#endregion
    getNatureza() {
        return this.http.get<any[]>(this.url + 'artigo/naturezas', httpOptions);
    }

    //#region stock
    getStockMovimento(): Observable<any> {
        return this.http
            .get(this.url + 'stock/stockmovimento', httpOptions)
            .pipe();
    }

    getStocksMovimentos(filtro: any): Observable<any> {
        return this.http
            .post(this.url + 'stock/movimentos', filtro, httpOptions)
            .pipe();
    }

    getArtigosStock(): Observable<any> {
        return this.http
            .get(this.url + 'stock/stock/artigos', httpOptions)
            .pipe();
    }

    getStock(idStock: number): Observable<Armazem> {
        return this.http
            .get(this.url + 'stock/stock/' + idStock, httpOptions)
            .pipe(catchError(null));
    }

    gravarStock(modelo: any): Observable<any> {
        return this.http
            .post(this.url + 'stock/stock', modelo, httpOptions)
            .pipe();
    }

    eliminarStock(idStock: any): Observable<any> {
        return this.http
            .post(this.url + 'stock/stock/eliminar', idStock, httpOptions)
            .pipe();
    }

    getTextoArtigo(idArtigo: number, codIdioma: string): Observable<any> {
        return this.http
            .get(
                this.url + 'artigo/texto/' + idArtigo + '/' + codIdioma,
                httpOptions
            )
            .pipe(catchError(null));
    }

    buscarUnidadeMedida(): Observable<any> {
        return this.http.get(this.url + 'unidadeMedida', httpOptions).pipe();
    }

    buscarArtigoVarianteComRegistoValores(
        idArtigo: number,
        idVariante: number
    ): Observable<any> {
        return this.http
            .get(
                this.url +
                'variantes2/varianteCamposOrganizado/' +
                idArtigo +
                '/' +
                idVariante,
                httpOptions
            )
            .pipe(catchError(null));
    }

    //#endregiongetStock

    setArtigosFAStore(artigos: any): any {
        this.artigosFA$.next(artigos);
    }

    getArtigosFAStore(): any {
        return this.artigosFA$.asObservable();
    }

// ---------------------------Seccao dos modulos -------------------------------------------

    getModulos(modelo: any): Observable<any> {
        return this.http.post(this.url + 'modulos/classificacao', modelo);
    }

    detalheModulo(idModulo): Observable<any> {
        return this.http.get(this.url + 'modulos/' + idModulo);
    }

    gravaModulo(modelo): Observable<any> {
        return this.http.post(this.url + 'modulos', modelo, httpOptions);
    }

    deletarModulo(idModulo): Observable<any> {
        return this.http.post(this.url + 'modulos/remover/' + idModulo, null);
    }

    // ---------------------------Fimm----------------------------------------------------------


// ------------------------ Secção de realizações----------------------------------------------

    getRealizacoes(): Observable<any> {
        return this.http.get(this.url + 'realizacoes');
    }

    listarRealizacoesPorClassificacao(idClassificacao: number): Observable<any> {
        return this.http.get(this.url + 'realizacoes/classificacao/' + idClassificacao);
    }

    listarRealizacoesMultiplas(modelo: number): Observable<any> {
        return this.http.post(this.url + 'realizacoes/classificacao/realizacoesMultiplas', modelo);
    }

    mostrarRealizacoesPeloId(IdRealizacao): Observable<any> {
        return this.http.get(this.url + 'realizacoes/' + IdRealizacao);
    }

    registarRealizacoes(modelo): Observable<any> {
        return this.http.post(this.url + 'realizacoes/adicionar', modelo, httpOptions);
    }

    editarRealizacoes(IdRealizacao, modelo): Observable<any> {
        return this.http.post(this.url + 'realizacoes/Editar/' + IdRealizacao, modelo);
    }

    eliminarRealizacoes(IdRealizacao): Observable<any> {
        return this.http.post(this.url + 'realizacoes/remover/' + IdRealizacao, null);
    }

    getRealizacoesTurmas(idClassificacao: number): Observable<any> {
        return this.http.get(this.url + 'realizacoes/realizacoesTurmas/' + idClassificacao);
    }

    getRealizacaoTurma(idRealizacaoTurma: number): Observable<any> {
        return this.http.get(this.url + 'realizacoes/realizacaoTurma/' + idRealizacaoTurma);
    }

// ---------------------------Fimm---------------------------------------------------------------------------

// ------------------------ Secção de inscricoes----------------------------------------------------------------

    getListEstadosInscricao(): Observable<any> {
        return this.http.get(this.url + 'inscricoes/estadosInscricoes');
    }

    getListEstadosPagamentos(): Observable<any> {
        return this.http.get(this.url + 'inscricoes/estadosPagamentos');
    }


    getListInscricao(modelo: any): Observable<any> {
        return this.http.post(this.url + 'inscricoes/classificacao', modelo);
    }

    getInscricoesParaSelect(idClassificacao: any): Observable<any> {
        return this.http.get(this.url + 'inscricoes/classificacao/' + idClassificacao);
    }

    listarInscricoesSemTurmas(modelo: any): Observable<any> {
        return this.http.post(this.url + 'inscricoes/semTurmas', modelo);
    }

    mostrarInscricaoPelolId(modelo: any): Observable<any> {
        return this.http.post(this.url + 'inscricoes/Inscricao', modelo, httpOptions);
    }

    inserirInscricoes(modelo: any): Observable<any> {
        return this.http.post(this.url + 'inscricoes/', modelo, httpOptions);
    }

    editarInscricoes(modelo: any): Observable<any> {
        return this.http.post(this.url + 'inscricoes/Editar/', modelo, httpOptions);
    }

    deletarInscricao(inscricao) {
        return this.http.post(this.url + 'inscricoes/remover/', inscricao);
    }

    getEntidades(modelo: any): Observable<any> {
        return this.http.post(this.url + 'inscricoes/ListarEntidades', modelo);
    }

    getInscritos(modelo: any): Observable<any> {
        return this.http.post(this.url + 'inscricoes/inscritos', modelo);
    }

    getInscrito(modelo: any): Observable<any> {
        return this.http.post(this.url + 'inscricoes/inscrito', modelo);
    }

    adicionarInscricoesTurma(modelo: any[]): Observable<any> {
        return this.http.post(this.url + 'inscricoes/turmas/adicionar/', modelo);
    }

    getInscricoesTurma(idRealizacaoTurma: number) {
        return this.http.get(this.url + 'inscricoes/turmas/listar/' + idRealizacaoTurma);
    }

    mudarEstadoInscricaoTurma(idRealizacoesInscricoesTurmas: number): Observable<any> {
        return this.http.post(this.url + 'inscricoes/turmas/mudarEstado/' + idRealizacoesInscricoesTurmas, null);
    }

    removerInscricaoTurma(idRealizacoesInscricoesTurmas: number): Observable<any> {
        return this.http.post(this.url + 'inscricoes/turmas/remover/' + idRealizacoesInscricoesTurmas, null);
    }

    getCertificado(idInscricao: number, fundo: boolean) {
        return this.http.get(this.url + 'inscricoes/certificado/' + idInscricao + '/' + fundo);
    }

    getNumero(idClassificacao: number): Observable<any> {
        return this.http.get(this.url + 'inscricoes/numero/' + idClassificacao);
    }

    // ------------------------------Secção dos locais----------------------------

    getLocais(modelo: any): Observable<any> {
        return this.http.post(this.url + 'locais/lista', modelo);
    }

    getLocal(idLocal: number): Observable<any> {
        return this.http.get(this.url + 'locais/' + idLocal);
    }

    gravarLocal(modelo: any): Observable<any> {
        return this.http.post(this.url + 'locais', modelo, httpOptions);
    }

    removerLocal(idLocal: any): Observable<any> {
        return this.http.post(this.url + 'locais/' + idLocal, null);
    }

    activarDesactivarLocal(idLocal: any): Observable<any> {
        return this.http.post(this.url + 'locais/activacao/' + idLocal, null);
    }

    getPaises(): Observable<any> {
        return this.http.get(this.url + 'locais/paises');
    }

    getProvincias(idPais: number): Observable<any> {
        return this.http.get(this.url + 'locais/provincias/' + idPais);
    }

    getMunicipios(idProvinca: number): Observable<any> {
        return this.http.get(this.url + 'locais/municipios/' + idProvinca);
    }

    getZonas(idMunicipio: number): Observable<any> {
        return this.http.get(this.url + 'locais/zonas/' + idMunicipio);
    }

    // ---------------------------Seccao dos tipo de arquivos das inscrições -----------------------------

    getTiposArquivos(idClassificacao: number): Observable<any> {
        return this.http.get(this.url + 'tipoArquivosInscricoes/lista/' + idClassificacao);
    }

    getTipoArquivo(idTipoArquivo: number): Observable<any> {
        return this.http.get(this.url + 'tipoArquivosInscricoes/' + idTipoArquivo);
    }

    gravarTipoArquivo(modelo: any): Observable<any> {
        return this.http.post(this.url + 'tipoArquivosInscricoes/', modelo);
    }

    removerTipoArquivo(idTipoArquivo: number): Observable<any> {
        return this.http.post(this.url + 'tipoArquivosInscricoes/remover/' + idTipoArquivo, null);
    }

    // ---------------------------Seccao dos e-mails -----------------------------

    getEmails(codIdioma: string, idClassificacao: number): Observable<any> {
        return this.http.get(this.url + 'emails/' + codIdioma + '/' + idClassificacao);
    }

    getEmail(idEmailInscricao: number): Observable<any> {
        return this.http.get(this.url + 'emails/detalhe/' + idEmailInscricao);
    }

    gravarEmails(modelo: any): Observable<any> {
        return this.http.post(this.url + 'emails/gravar/', modelo, httpOptions);
    }

    removerEmail(idEmailInscricao: number): Observable<any> {
        return this.http.post(this.url + 'emails/' + idEmailInscricao, null);
    }

    // ---------------------------Seccao das avaliações -----------------------------

    getAvaliacao(idRealizacaoInscricao: number): Observable<any> {
        return this.http.get(this.url + 'avaliacaoRealizacao/' + idRealizacaoInscricao);
    }

    salvarAvaliacao(modelo: any): Observable<any> {
        return this.http.post(this.url + 'avaliacaoRealizacao/', modelo);
    }

    getEstadosAvaliacao(): Observable<any> {
        return this.http.get(this.url + 'avaliacaoRealizacao/listarEstados');
    }


    getAvaliacoesModulos(idRealizacaoInscricao: number): Observable<any> {
        return this.http.get(this.url + 'avaliacoesModulos/lista/' + idRealizacaoInscricao);
    }

    salvarAvaliacoesModulos(modelo: any): Observable<any> {
        return this.http.post(this.url + 'avaliacoesModulos/', modelo);
    }


    // --------------------------- Secção dos renováveis -----------------------------
    getEstadosRenovavel(): Observable<any> {
        return this.http.get(`${this.url}renovavel/estados`).pipe(catchError(err => {
            console.error(err);
            return err.status === 404 ?
                of({objecto: [], statusCode: err.status, mensagem: ''}) : throwError(() => err);
        }));
    }

    getFluxos() {
        return this.http.get<Resposta<EstadoFluxo[]>>(`${this.url}renovavel/estados/fluxos`);
    }

    getCiclosRenovacao(): Observable<any> {
        return this.http.get(`${this.url}renovavel/ciclos`).pipe(catchError(err => {
            console.error(err);
            return err.status === 404 ?
                of({objecto: [], statusCode: err.status, mensagem: ''}) : throwError(() => err);
        }));
    }

    postRenovavel(servico: any) {
        return this.http.post<any>(`${this.url}renovavel`, servico);
    }

    postRenovavelLote(servico: any) {
        return this.http.post<any>(`${this.url}renovavel/lote`, servico);
    }

    getRenovaveis(idEquipamento: number = 0) {
        return this.http.post<any>(`${this.url}renovaveis`, this.loginService.getUtilizadorSessao(),
            {params: {idEquipamento}})
            .pipe(catchError(err => {
                console.error(err);
                return err.status === 404 ?
                    of({objecto: [], statusCode: err.status, mensagem: ''}) : throwError(() => err);
            }));
    }

    getRenovaveisPaginar(index: number = 1, size: number = 20) {
        return this.http.post<any>(`${this.url}renovaveis/paginas`, this.loginService.getUtilizadorSessao(),
            {params: {index, size}})
            .pipe(catchError(err => {
                console.error(err);
                return err.status === 404 ?
                    of({objecto: [], statusCode: err.status, mensagem: ''}) : throwError(() => err);
            }));
    }

    getRenovaveisPaginarListView(index: number = 1, size: number = 200) {
        return this.http.post<any>(`${this.url}renovaveis/paginas/view`, this.loginService.getUtilizadorSessao(),
            {params: {index, size}});
    }

    getRenovavel(id: number) {
        return this.http.post<any>(`${this.url}renovavel/${id}`, this.loginService.getUtilizadorSessao());
    }

    renovavelRunJob(id: number) {
        return this.http.post<any>(`${this.url}renovavel/${id}/jobs`, {});
    }

    eliminarRenovavel(id: number) {
        return this.http.post<any>(`${this.url}renovavel/eliminar/`, id, httpOptions);
    }

    editarRenovavel(payload) {
        return this.http.post<any>(`${this.url}renovavel/${payload.id}/editar`, payload);
    }

    filterRenovaveis(filtro: any) {
        filtro.utilizadorSessao = this.loginService.getUtilizadorSessao();
        return this.http.post<any>(`${this.url}renovaveis/filtrar`, filtro).pipe(catchError(err => {
            console.error(err);
            return err.status === 404 ?
                of({objecto: [], statusCode: err.status, mensagem: ''}) : throwError(() => err);
        }));
    }

    getNumeracaoRenovavel(idEmpresa: number) {
        return this.http.get<any>(`${this.url}renovavel/${idEmpresa}/codigo`);
    }

    alternarActivoSuspensoRenovavel(id: number, idTransicao: number) {
        return this.http.post<Resposta<number>>(`${this.url}renovaveis/${id}/estado/alternar`, idTransicao);
    }

    getRenovavelRenovacoes(id: number) {
        return this.http.get<any>(`${this.url}renovavel/${id}/renovacoes`);
    }

    calculateExpirationDate(ciclo, dataInicio: Date | string): Date | null {
        if (!dataInicio) {
            return null;
        }
        let d = new Date(dataInicio);
        switch (ciclo?.idCicloRenovacao) {
            case Ciclo.DIARIO:
                break;
            case Ciclo.SEMANAL:
                d = Util.addDays(d, 6);
                break;
            case Ciclo.QUINZENAL:
                d = Util.addDays(d, 14);
                break;
            case Ciclo.MENSAL:
                d = Util.addDays(Util.addMonths(d), -1);
                break;
            case Ciclo.BIMESTRAL:
                d = Util.addDays(Util.addMonths(d, 2), -1);
                break;
            case Ciclo.TRIMESTRAL:
                d = Util.addDays(Util.addMonths(d, 3), -1);
                break;
            case Ciclo.SEMESTRAL:
                d = Util.addDays(Util.addMonths(d, 6), -1);
                break;
            case Ciclo.ANUAL:
                d.setFullYear(d.getFullYear() + 1);
                d = Util.addDays(d, -1);
                break;
            case Ciclo.BIENAL:
                d.setFullYear(d.getFullYear() + 2);
                d = Util.addDays(d, -1);
                break;
            case Ciclo.QUINQUENAL:
                d.setFullYear(d.getFullYear() + 5);
                d = Util.addDays(d, -1);
                break;
            default:
                return null;
        }
        d.setHours(23);
        d.setMinutes(59);
        d.setSeconds(59);
        return d;
    }

    filtrarArtigos(event: any, artigos) {
        const termo = event.target.value;
        if (!termo) {
            return [];
        }

        return artigos.filter(a =>
            (a.nomeArtigo ?? a.artigo)?.toLowerCase()?.includes(termo.toLowerCase()) ||
            a.codArtigo && a.codArtigo.toLowerCase().includes(termo.toLowerCase())
        );
    }

    isArtigoValidoRenovacao(idCliente: number, idArtigo: number, idRenovavel: number = 0) {
        return this.http.get(`${this.url}renovavel/artigo/${idArtigo}`, {
            params: {idRenovavel, idCliente}
        });
    }

    // ---------------------------Seccao das turmas -----------------------------

    getTurmas(idClassificacao: number): Observable<any> {
        return this.http.get(this.url + 'turmas/lista/' + idClassificacao);
    }

    getTurma(idTurma: number): Observable<any> {
        return this.http.get(this.url + 'turmas/detalhe/' + idTurma);
    }

    gravarTurma(modelo: any): Observable<any> {
        return this.http.post(this.url + 'turmas/', modelo);
    }

    removerTurma(idTurma: number): Observable<any> {
        return this.http.post(this.url + 'turmas/' + idTurma, null);
    }


    // ---------------------------Seccao dos certificados -----------------------------

    getCertificados(idClassificacao: number): Observable<any> {
        return this.http.get(this.url + 'certificados/lista/' + idClassificacao);
    }

    detalharCertificado(idCertificado: number): Observable<any> {
        return this.http.get(this.url + 'certificados/' + idCertificado);
    }

    gravarCertificados(modelo: any): Observable<any> {

        return this.http.post(this.url + 'certificados', modelo);
    }

    removerCertificado(idCertificado: number): Observable<any> {
        return this.http.post(this.url + 'certificados/remover/' + idCertificado, null);
    }

    getTiposArtigoGeral() {
        return this.http.get(this.url + 'tiposArtigo', httpOptions);
    }

    // ---------------------------Seccao dos tipos de colaboração -----------------------------

    getTiposColaboracao(idClassificacao: number): Observable<any> {
        return this.http.get(this.url + 'tiposColaboracao/lista/' + idClassificacao);
    }

    getTipoColaboracao(idTipoColaboracao: number): Observable<any> {
        return this.http.get(this.url + 'tiposColaboracao/detalhe/' + idTipoColaboracao);
    }

    gravarTipoColaboracao(modelo: any): Observable<any> {
        return this.http.post(this.url + 'tiposColaboracao/', modelo);
    }

    removerTipoColaboracao(idTipoColaboracao: number): Observable<any> {
        return this.http.post(this.url + 'tiposColaboracao/remover/' + idTipoColaboracao, null);
    }

    // ---------------------------Seccao dos tipos de colaboração -----------------------------

    getAulas(idRealizacaoTurma: number): Observable<any> {
        return this.http.get(this.url + 'aulas/lista/' + idRealizacaoTurma);
    }

    getAula(idRealizacaoTurmaAula: number): Observable<any> {
        return this.http.get(this.url + 'aulas/detalhe/' + idRealizacaoTurmaAula);
    }

    registarAula(modelo: any): Observable<any> {
        return this.http.post(this.url + 'aulas/', modelo);
    }

    removerAula(idRealizacaoTurmaAula: number): Observable<any> {
        return this.http.post(this.url + 'aulas/' + idRealizacaoTurmaAula, null);
    }

    renovar(idCliente: number, artigos: any[]) {
        return this.http.post<any>(`${this.url}renovar/${idCliente}`, artigos);
    }

    getTiposDespesasART(utilizadorSessao: UtilizadorSessao) {
        return this.http.post(this.url + 'despesas', utilizadorSessao, httpOptions);
    }

    // E-mails ----------------------------------------------------------------
    getEmailEventos() {
        return this.http.get<Resposta<Evento[]>>(`${this.url}emails/automaticos/eventos`).pipe(catchError(err => {
            console.error(err);
            return err.status === 404 ?
                of({objecto: [], statusCode: err.status, mensagem: err.error?.mensagem || ''} as Resposta<Evento[]>) :
                throwError(() => err);
        }));
    }

    getEmailAutomaticos() {
        return this.http.get<Resposta<EmailAutomatico[]>>(`${this.url}emails/automaticos`).pipe(catchError(err => {
            console.error(err);
            return err.status === 404 ?
                of({objecto: [], statusCode: err.status, mensagem: err.error?.mensagem || 'N/A'} as Resposta<EmailAutomatico[]>) :
                throwError(() => err);
        }));
    }

    postEmailAutomatico(payload: EmailAutomatico) {
        return this.http.post<Resposta<EmailAutomatico>>(`${this.url}emails/automaticos`, payload);
    }

    getEmailAutomatico(id: number) {
        return this.http.get<Resposta<EmailAutomatico>>(`${this.url}emails/automaticos/${id}`);
    }

    putEmailAutomatico(payload: EmailAutomatico) {
        return this.http.post<Resposta<EmailAutomatico>>(`${this.url}emails/automaticos/${payload.id}`, payload);
    }

    getCiclo(id: number) {
        return this.http.get<Resposta<any>>(`${this.url}renovavel/ciclos/${id}`);
    }

    putCiclo(payload: any) {
        return this.http.post<Resposta<any>>(`${this.url}renovavel/ciclos/${payload.idCiclo}`, payload);
    }

    deleteEmailAutomatico(id: number) {
        return this.http.post<Resposta<EmailAutomatico>>(`${this.url}emails/automaticos/${id}/eliminar`, {});
    }

    getRenovacoes() {
        return this.http.get<Resposta<any[]>>(`${this.url}renovacoes`)
            .pipe(tap(res => res.objecto.forEach(r => r.documentos = [...r.documentos, ...r.documentosTe])));
    }

    filterRenovacoes(filtro: any) {
        return this.http.post<Resposta<any[]>>(`${this.url}renovacoes/filtrar`, filtro);
    }

    getEmpresas() {
        return this.http.get<Resposta<any[]>>(`${this.url}stock/empresas`);
    }

    putFornecedor(fornecedor: any) {
        return this.http.post<Resposta<any>>(`${this.url}artigo/${fornecedor.idArtigo}/fornecedor`, fornecedor);
    }

    eliminarFornecedor(id: number, idFornecedor: number) {
        return this.http.post(`${this.url}artigo/${id}/fornecedor/remover`, idFornecedor);
    }

    putLembrete(lembrete: any) {
        return this.http.post<Resposta<any>>(`${this.url}realizacoes/${lembrete.idRealizacao}/lembretes`, lembrete);
    }

    deleteLembrete(id: number) {
        return this.http.post<Resposta<any>>(`${this.url}realizacoes/lembretes/remover`, id);
    }

    putNumeracao(req: any) {
        return this.http.post<Resposta<any>>(`${this.url}classificacoes/numeracoes`, req);
    }

    getNumeracoes() {
        return this.http.get<Resposta<any[]>>(`${this.url}classificacoes/numeracoes`);
    }

    getNumeracao(id: number) {
        return this.http.get<Resposta<any>>(`${this.url}classificacoes/numeracoes/${id}`);
    }

    deleteNumeracao(id: number) {
        return this.http.post<Resposta<any>>(`${this.url}classificacoes/numeracoes/remover`, id);
    }

    getCodigo(idClassificacao: number) {
        return this.http.get<Resposta<string>>(`${this.url}artigos/codigo/${idClassificacao}`);
    }

    postArtigoLote(req: { ficheiro: string, idClassificacao: number }) {
        return this.http.post<Resposta<any[]>>(`${this.url}artigos/lote`, {...req, utilizador: this.loginService.getUtilizadorSessao()});
    }

    getTiposAdm() {
        return this.http.get<Resposta<any[]>>(`${this.url}tipo/administrativos`);
    }

    putEntidade(req: any) {
        return this.http.post<Resposta<any>>(`${this.url}artigos/${req.idArtigo}/entidades`, req);
    }

    eliminarEntidade(idArtigo: number, idEntidade: number) {
        return this.http.post(`${this.url}artigos/${idArtigo}/entidades/remover`, idEntidade);
    }

    getEntidadesAssociadas(idArtigo: number) {
        return this.http.get<Resposta<any[]>>(`${this.url}artigos/${idArtigo}/entidades`);
    }

    putPrecoAdm(value: any) {
        return this.http.post<Resposta<any>>(`${this.url}artigos/${value.idArtigo}/entidades/precos`, value);
    }

    getCicloAdm() {
        return this.http.get<Resposta<any[]>>(`${this.url}artigos/ciclos-administrativos`);
    }

    getAdministrativos() {
        return this.http.get<Resposta<any[]>>(`${this.url}artigos/administrativos`);
    }

    getPrecosAdm(idArtigo: number) {
        return this.http.get<Resposta<any>>(`${this.url}artigos/${idArtigo}/entidades/precos`);
    }

    gerarQuotas(req: any) {
        return this.http.post<Resposta<any[]>>(`${this.url}artigos/gerar-quotas`, {
            ...req,
            utilizador: this.loginService.getUtilizadorSessao()
        });
    }

    getQuotas(idArtigo: number) {
        return this.http.get<Resposta<any[]>>(`${this.url}artigos/${idArtigo}/quotas`);
    }

    getRenovacoesAdm() {
        return this.http.get<Resposta<any[]>>(`${this.url}artigos/quotas/renovacoes`);
    }

    filtrarRenocavoes(filtro: any) {
        return this.http.post<Resposta<any[]>>(`${this.url}artigos/quotas/renovacoes`, filtro);
    }

    enviarEmail(req: any) {
        return this.http.post<Resposta<any>>(`${this.url}renovaveis/enviar-email`, req);
    }

    enviarEmailTemplate(req: any) {
        return this.http.post<Resposta<any>>(`${this.url}renovaveis/testar-email`, req);
    }

    anularRenovacao(id: number) {
        return this.http.post<Resposta<any>>(`${this.url}renovacoes/anular`, id);
    }

    usaEquipamentos() {
        return this.http.get<Resposta<boolean>>(`${this.url}renovaveis/usa-equipamentos`);
    }
}
