import {Pipe, PipeTransform} from '@angular/core';
import {Util} from 'app/shared/models/generico/util';

@Pipe({
    name: 'convertToFloat'
})
export class ConvertToFloatPipe implements PipeTransform {

    transform(value: unknown, ...args: unknown[]): unknown {
        if (typeof (value) === 'number') {
            return Util.DuasCasasDecimais(value);
        }
        return Util.DuasCasasDecimais(parseFloat(
            String(value)
                .replaceAll('.', '')
                .replace(',', '.')
                .replace(/[^0-9.,]/g, ''))
        );
    }

}
