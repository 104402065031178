export const urlCliente = localStorage.getItem('clienteUrl');
//   export const urlCliente = 'https://kiami-qualidade-back.independent.co.ao/';

  //export const urlCliente = 'https://kiami-back.pwimoveis.com/';

//  export const urlInstalacoes = 'https://instalacoes-back.kiami.ao/api/v1/';
// export const urlCliente = 'https://kiami-back.garrafeiradopatriota.ao/';
//  export const urlCliente = 'https://independent-back.kiami.ao/';
export const urlInstalacoes = 'https://back.appkiami.ao/api/v1/';
// export const urlCliente = 'https://jornaldeangola-back.kiami.ao/';
// export const urlCliente = 'https://solip-back.kiami.ao/';
//  export const urlCliente = 'https://appdemo.kiami.ao/';
// export const urlCliente = 'https://kiami-back.kiami.ao/';
// export const urlCliente = 'https://kiamisoft-back.kiami.ao/';
// Conectivas
// export const urlCliente = 'https://kiami-back.conectiva.pt/';
// Recredit
// export const urlCliente = "https://recredit-back.kiami.ao/";
// export const urlCliente = 'https://srr-back.kiami.ao/';

// AAMGA
// export const urlCliente = "https://aamga-back.kiami.ao/";

// OCPCA
// export const urlCliente = "https://ocpca-back.kiami.ao/";

// *** INSTALAÇÕES QUE SÓ USAM GESTOR DE CONTEUDOS *** //
// const urlCliente = 'https://ensa-back.kiami.ao/';
// ASSOCIAÇÃO DE ACTUÁRIOS DE ANGOLA
// export const urlCliente = "https://kiami-back.actuariosangola.org/";
// export const urlCliente = 'https://curiositychoice-back.kiami.ao/';

// ARC-CONSULTORIA
//  export const urlCliente = 'https://arc-consultoria-back.kiami.ao/';
// export const urlCliente = "https://kiami-back.claramelo.com/";
// FAN
// export const urlCliente = "https://kiami-back.fanatacao.ao/";
// export const urlCliente = 'https://suresil.appkiami.ao/';

// TST
 //export const urlCliente = 'https://tst-back.kiami.ao/';

const env = {
    instalacao: urlInstalacoes + 'instalacoes/instalacao/',

    // aplicacao: "https://localhost:44332/api/v1/",

    // aplicacao: "https://localhost:100/api/v1/",
    aplicacao: urlCliente + 'apl/api/v1/',

    // artigos: 'https://localhost:44332/api/v1/',
    // artigos: 'https://localhost:101/api/v1/',
    artigos: urlCliente + 'art/api/v1/',

    //clientes: 'https://localhost:44332/api/v1/',
    clientes: urlCliente + 'cli/api/v1/',

    // financas: 'https://localhost:44332/api/v1/',
    financas: urlCliente + 'fin/api/v1/',

    // processos: "https://localhost:44332/api/v2/",
    processos: urlCliente + 'pro/api/v2/',

    // eventos: 'https://localhost:44332/api/v1/',
    eventos: urlCliente + 'eve/api/v1/',

    // cmsUrl: "https://localhost:44333/api/v1/",
    cmsUrl: urlCliente + 'cms/api/v1/',

    //rHumanos: "https://localhost:44318/api/v1/",
    rHumanos: urlCliente + 'rhu/api/v1/',

    // equipamentos: 'https://localhost:7066/api/v1/',
    // equipamentos: 'http://localhost:5000/api/v1/',
    equipamentos: urlCliente + 'equ/api/v1/',

    // arquivos: 'https://localhost:7246/api/v1/'
    // arquivos: 'http://localhost:5000/api/v1/'
    arquivos: urlCliente + 'gar/api/v1/'
};

export const environment = {
    production: false,
    instalacao: env.instalacao,
    apiAplicacao: env.aplicacao,
    apiClientes: env.clientes,

    apiArtigos: env.artigos,

    apiFinancas: env.financas,

    apiProcessos: env.processos,

    apiEventos: env.eventos,

    apiRHumanos: env.rHumanos,
    apiEquipamentos: env.equipamentos,
    apiArquivos: env.arquivos,
    cmsUrl: env.cmsUrl,

    enableDebug: true
};
