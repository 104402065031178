import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colCriador',
  pure : true
})
export class ColCriadorPipe implements PipeTransform {

 public transform(criador: any, prop: string): any {
     if (criador && criador[prop]) {
        return criador[prop];
     }
     return '';
  }

}
