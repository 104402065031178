import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GaArquivoResponse} from 'app/shared/models/gestao-arquivos/ga-arquivo';
import {GaTipo} from 'app/shared/models/gestao-arquivos/ga-tipo';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'k-tipos-arquivos',
    templateUrl: './k-tipos-arquivos.component.html',
    styleUrls: ['./k-tipos-arquivos.component.scss']
})
export class KTiposArquivosComponent implements OnInit {
    @Input() tipos: GaTipo[] = [];
    @Input() height = 'calc(80vh - 280px)';
    @Input() parentId = 0;
    @Input() action = '';
    @Input() area = '';
    @Input() arquivos: GaArquivoResponse[] = [];
    @Output() deleteEvent = new EventEmitter<number>();
    @Output() updateEvent = new EventEmitter<any>();


    constructor() {
    }

    ngOnInit(): void {
    }

    byTipo(item: GaArquivoResponse, tipo: GaTipo) {
        return item.idTipo === tipo.id;
    }
}
