import {Component, Input} from "@angular/core";

@Component({
// tslint:disable-next-line:component-selector
selector: 'btn-sm',
    template: `
        <button  class="btn-filter" type="button" mat-raised-button color="primary"  style="padding: 2px 10px !important">
          <span  *ngIf="icon">
              <mat-icon>{{icon}}</mat-icon>
          </span> 
           <span> <ng-content></ng-content></span>
        </button>
    `,
    styles: [
        `
        
        `
    ]
}) export class BtnSmComponent {
@Input() icon = '';
@Input() bg = '';
}
