import { Breakpoints } from '@angular/cdk/layout';
import { FiltroMetodoPagamentoDto } from 'app/shared/models/financas/filtro-metodo-pagamento.dto';

export const mapaAccao = new Map([
  ['D', 'Consultar'],
  ['C', 'Criar'],
  ['E', 'Editar'],
]);

export const btnGravarFA = new Map([
    ['GI', 'Gravar e imprimir'],
    ['GE', 'Gravar e enviar por e-mail'],
  ]);

export const breakPointMap = new Map([
  [Breakpoints.XSmall, 'XSmall'],
  [Breakpoints.Small, 'Small'],
  [Breakpoints.Medium, 'Medium'],
  [Breakpoints.Large, 'Large'],
  [Breakpoints.XLarge, 'XLarge'],
  // [Breakpoints.Tablet, 'Tablet'],
  // [Breakpoints.Web, 'Web'],
  // [Breakpoints.WebLandscape, 'WebLandscape'],
  // [Breakpoints.WebPortrait, 'WebPortrait'],
  // [Breakpoints.Handset, 'Handset'],
]);

export const lgPainel = {
  XSmall: 320,
  Small: 400,
  Medium: 500,
  Large: 600,
  XLarge: 700,
};

export const modalOverLay = {
  XSmall: 100,
  Small: 100,
  Medium: 100,
  Large: 80,
  XLarge: 70,
};

export const FILTRO_METODO_PAGAMENTO: FiltroMetodoPagamentoDto = {
  recebimento: true,
  pagamento: false,
  utilizador: null
};
