import {Directive, HostListener, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

export interface TipoSelecao {
    control: AbstractControl;
    list?: any[];
}

@Directive({
    selector: '[appOptionSelection]'
})
export class OptionSelectionDirective {
    @Input('appOptionSelection') option!: TipoSelecao;

    constructor() {
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        const values = this.option.control.value.filter(l => l !== undefined);
        // Selecionar todos
        if (this.option?.list && this.option) {
            this.option.control.patchValue(values.length >= this.option.list.length ? [] : this.option?.list);
            return;
        }
        // Selecionar todos os booleanos
        this.option.control.patchValue(values.length >= 2 ? [] : [true, false]);
    }
}
