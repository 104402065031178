import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[address]'
})
export class AddressDirective {
  @Input('texto') texto: string = '';
  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.setStyle(el.nativeElement, 'height', '40px');
    this.renderer.setStyle(el.nativeElement, 'resize', 'none');
    this.renderer.setStyle(el.nativeElement, 'overflow-y', 'hidden');
    this.renderer.setStyle(el.nativeElement, 'width', '441px');
    this.renderer.setAttribute(el.nativeElement, 'maxlength', '156');
    this.renderer.setAttribute(el.nativeElement, 'rows', '3');
    this.renderer.setAttribute(el.nativeElement, 'cols', '40');
  }
  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const linhas = this.texto.match(/\n/g);
    if (linhas && linhas.length > 1) {event.preventDefault(); return; }
    if (linhas) {
      if (linhas.length === 1 && this.texto.split('\n')[1].length > 52) {
        event.preventDefault();
      } else if (linhas.length > 1 && this.texto.split('\n')[2].length > 23) {
        event.preventDefault();
      }
    } else if (this.texto.length > 156) {
      event.preventDefault();
    }
  }

  @HostListener('keypress', ['$event']) onKeyPress(event: any) {
    const linhas = event.target.value.match(/\n/g);
    if (linhas && linhas.length > 3) {event.preventDefault(); return; }
    if (linhas) {
      if (linhas.length === 1 && event.target.value.split('\n')[1].length > 52) {
        event.preventDefault();
      } else if (linhas.length > 1 && event.target.value.split('\n')[2].length > 23) {
        event.preventDefault();
      }
      if (linhas.length > 1 && event.charCode === 13) {
        event.preventDefault();
      }
    } else if (event.target.value.length > 156) {
      event.preventDefault();
    }
  }
} // Directive //
