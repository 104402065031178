import { Pipe, PipeTransform } from '@angular/core';
import { Util } from '../models/generico/util';

@Pipe({
  name: 'dataReversePt'
})
export class DataReversePtPipe implements PipeTransform {
    
    transform( data: Date): string {
      if (data) {
        return Util.momToDate(data);
      }

      return '';
  }

}
