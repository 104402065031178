import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FinancasService} from '../../../../shared/services/financas/financas.service';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Moedas} from '../../../../shared/models/aplicacao/moedas';
import {AplicacaoService} from '../../../../shared/services/aplicacao-service/aplicacao.service';
import {LoginService} from '../../../../shared/services/aplicacao-service/login.service';
import {FiltroTaxasCambio} from '../../../../shared/models/financas/filtro-taxas-cambio';
import {formatDate} from '@angular/common';
import {TaxasCambio} from '../../../../shared/models/financas/taxas-cambio';
import {UtilizadorSessao} from '../../../../shared/models/aplicacao/utilizador-sessao';
import {NotificacaoService} from '../../../../shared/services/notificacao/notificacao.service';
import moment from 'moment';

@Component({
    selector: 'app-pesquisar-cambio',
    templateUrl: './pesquisar-cambio.component.html',
    styleUrls: ['./pesquisar-cambio.component.scss']
})
export class PesquisarCambioComponent implements OnInit, OnDestroy {
    cambios: TaxasCambio[] = [];
    cambio: any = {};
    subscricao: Subscription[] = [];
    moedaLocal: any;
    moedaPretendida: any;
    formCambio: FormGroup;
    formAdicionarCambio: FormGroup;
    moedas: Moedas[] = [];
    inverterMoeda: boolean;
    utilizadorSessao: UtilizadorSessao;
    dataHoje = new Date();
    dtInicio: Date | string;
    dtFim: Date | string;
    dataMax = moment().add(1, 'days');
    diaEmSegundos = 1000 * 60 * 60 * 24;
    haTrintaDias = new Date(this.dataHoje.getTime() - 30 * this.diaEmSegundos);
    filtroPesquisaTaxasCambio: FiltroTaxasCambio;
    activarLoader = true;
    modoAdicaoCambio = false;
    conversaoDirecta: any;
    legendaTaxaCambio: string;
    ultimos30Dias = {
        inicio: moment().subtract(29, 'days'),
        fim: moment()
    };

    desactivar: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public objecto: any,
                private financaService: FinancasService,
                private fb: FormBuilder,
                private loginService: LoginService,
                private modalRef: MatDialogRef<PesquisarCambioComponent>,
                private alertService: NotificacaoService,
                private aplicacaoService: AplicacaoService) {
        this.utilizadorSessao = loginService.getSessaoUtilizador();
        this.filtroPesquisaTaxasCambio = {
            utilizador: this.utilizadorSessao
        };
        this.formAdicionarCambio = this.fb.group({
            IdMoedaLocal: [''],
            IdMoedaPretendida: [''],
            TaxaCambio: [''],
            DataInicio: [''],
            dataCambio: [''],
        });
        this.legendaTaxaCambio = objecto.legendaTaxaCambio;
    }

    ngOnInit(): void {
        this.subscricao.push(this.aplicacaoService.getMoedas().subscribe((moedas) => {
            this.moedas = moedas;
            this.moedaLocal = this.moedas.find(x => x.id === this.objecto.idMoedaEmpresa);
            this.moedaPretendida = this.moedas.find(x => x.id === this.objecto.idMoedaPretendida);
        }));


        this.formCambio = this.fb.group({
            IdMoedaLocal: [this.objecto ? this.objecto.idMoedaEmpresa : ''],
            IdMoedaPretendida: [this.objecto ? this.objecto.idMoedaPretendida : ''],
            DataInicio: [this.objecto ? this.objecto.dataDoc : null],
            DataFim: [this.objecto ? this.objecto.dataVenc : null],
            IncluiTaxaInversa: [''],
        });

        this.conversaoDirecta = this.objecto.conversaoDirecta;
        if (!this.conversaoDirecta) {
            this.inverterCambio();
        }
    }

    filtrarMoeda(moedas: any[]): any[] {
        return moedas.filter(moeda => moeda.id !== 1);
    }


    ObterTaxaCambioMomento(modelo) {
        this.subscricao.push(
            this.financaService
                .getTaxasCambio(
                    modelo
                )
                .subscribe(
                    (res) => {
                        this.activarLoader = false;
                        this.cambios = res;
                        console.log("Cambios ",res);

                    },
                    (error) => {
                        this.alertService.notificar(error?.error.mensagem);
                    }
                ));
    }


    setDates(evento: any) {
        evento.fim;
        this.formCambio.get('DataInicio').setValue(evento.inicio);
        this.formCambio.get('DataFim').setValue(evento.fim);

    }

    getKeys(obj: any): string[] {
        return Object.keys(obj);
    }

    pesquisarCambio() {
        let dataInicio, dataFim;
        dataInicio = this.formCambio.value.DataInicio;
        dataFim = this.formCambio.value.DataFim;

        this.filtroPesquisaTaxasCambio = {
            idsMoedaLocal: [this.formCambio.value.IdMoedaLocal],
            idsMoedaPretendida: [this.formCambio.value.IdMoedaPretendida],
            intervalosData: [
                {
                    operacao: 'BT',
                    dataInicio: dataInicio,
                    dataFim: dataFim
                }
            ],
            cambiosIndirectos: !this.formCambio.value.IncluiTaxaIndirecta,
            utilizador: this.utilizadorSessao
        };

        this.ObterTaxaCambioMomento(this.filtroPesquisaTaxasCambio);
    }

    filtrarValores($event: Event, number: number) {
    }

    selecCambio(valorCambio: any) {
        const modelo = {
            cambio: valorCambio,
            moedaEmpresa: this.inverterMoeda ? this.moedaPretendida : this.moedaLocal,
            moedaDocumento: this.inverterMoeda ? this.moedaLocal : this.moedaPretendida,
            taxaInvertida: this.inverterMoeda,
        };
        modelo.cambio.conversaoDirecta = this.conversaoDirecta;
        this.modalRef.close(modelo);
    }

    inverterCambio() {
        if (this.inverterMoeda) {
            this.conversaoDirecta = true;
            this.formCambio.get('IdMoedaLocal').setValue(this.objecto.idMoedaPretendida);
            this.formCambio.get('IdMoedaPretendida').setValue(this.objecto.idMoedaEmpresa);
        } else {
            this.conversaoDirecta = false;
            this.formCambio.get('IdMoedaLocal').setValue(this.objecto.idMoedaEmpresa);
            this.formCambio.get('IdMoedaPretendida').setValue(this.objecto.idMoedaPretendida);
        }
        this.inverterMoeda = !this.inverterMoeda;

        console.log("Cambios ::",this.inverterMoeda);

        this.pesquisarCambio();
    }

    getData(event: any, opcao?: number) {
        const dtFim = new Date(this.formCambio.value.DataFim).getTime();
        const dtInicio = new Date(this.formCambio.value.DataInicio).getTime();
        if (opcao === 1) {
            if (dtFim && dtFim < dtInicio) {
            }
        }
        if (opcao === 2) {
        }
    }

    gravarCambio() {
        if (!this.formAdicionarCambio.value.IdMoedaLocal
            || !this.formAdicionarCambio.value.IdMoedaPretendida
            || !this.formAdicionarCambio.value.TaxaCambio
            || !this.formAdicionarCambio.value.dataCambio) {

            Object.keys(this.formCambio.controls).forEach(campo => {
                if (!this.formAdicionarCambio.get(campo).value) {
                    this.formAdicionarCambio.get(campo).setValidators([Validators.required]);
                    this.formAdicionarCambio.get(campo).markAsTouched({onlySelf: true});
                    this.formAdicionarCambio.get(campo).updateValueAndValidity({onlySelf: true});
                    return false;
                }
            });
            this.alertService.notificar('Por favor, preencha os campos em falta', false, 4000);
            return false;
        }
        this.desactivar = true;

        const moedaLocal = this.moedas.find(x => x.id === this.formAdicionarCambio.value.IdMoedaLocal);
        const moedaPretendida = this.moedas.find(x => x.id === this.formAdicionarCambio.value.IdMoedaPretendida);
        const dataRange = this.formAdicionarCambio.value.dataCambio;
        const modelo = {
            idMoedaLocal: this.formAdicionarCambio.value.IdMoedaLocal,
            codMoedaLocal: moedaLocal.codigo,
            idMoedaPretendida: this.formAdicionarCambio.value.IdMoedaPretendida,
            codMoedaPretendida: moedaPretendida.codigo,
            taxaCambio: this.formAdicionarCambio.value.TaxaCambio,
            dataCambio: formatDate(dataRange.inicio._d, 'yyyy-MM-ddTHH:mm:ss', 'pt-PT'),
            utilizador: this.utilizadorSessao,
        };
        this.activarLoader = true;
        this.financaService.gravarTaxasCambio(modelo).subscribe((res: any) => {
            this.desactivar = false;
            this.activarLoader = false;
            if (res.statusCode === 201) {
                this.formAdicionarCambio.reset();
            }
            this.alertService.notificar(res.mensagem, true, 4000);
            setTimeout(() => {
                this.modoAdicaoCambio = false;
                this.modalRef.updateSize('80vw', '70vh');
            });
            this.formCambio.reset();
            this.ObterTaxaCambioMomento(this.filtroPesquisaTaxasCambio);
        }, error => {
            this.activarLoader = false;
            if (error.status === 200) {
                this.formAdicionarCambio.reset();
                this.alertService.notificar(error.error.text, false, 4000);
            } else if (error.status === 418) {
                this.loginService.terminarSessao(error);
                this.alertService.notificar(JSON.stringify(error.error), false, 5000);
            } else if (error.status === 500) {
                this.alertService.notificar(JSON.stringify(error.error), false, 5000);
            } else if (error.status === 400) {
                this.alertService.notificar(JSON.stringify(error.error.mensagem), false, 5000);
            } else if (error.status === 404) {
                this.alertService.notificar(JSON.stringify(error.error), false, 5000);
            } else if (error.status === 403) {
                this.alertService.notificar(JSON.stringify(error.error), false, 5000);
            } else if (error.status === 405) {
                this.alertService.notificar(JSON.stringify(error.error), false, 5000);
            } else if (error.status === 407) {
                this.alertService.notificar(JSON.stringify(error.error), false, 5000);
            } else if (error.status === 409) {
                this.alertService.notificar(JSON.stringify(error.error), false, 5000);
            } else {
                this.alertService.notificar(JSON.stringify(error), false, 15000);
            }
            this.desactivar = false;
        });
    }

    adicionarNovoCambio() {
        if (!this.modoAdicaoCambio) {
            this.modoAdicaoCambio = true;
            this.modalRef.updateSize('60vw', '55vh');
        } else {
            this.modoAdicaoCambio = false;
            this.modalRef.updateSize('80vw', '70vh');
        }
    }

    ngOnDestroy(): void {
        if (this.subscricao.length > 0) {
            this.subscricao.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }


    resetarMP(event: any) {
        this.formCambio.get('IdMoedaLocal').setValue(event.value);
        this.formCambio.get('IdMoedaPretendida').reset();
    }

    aoSelecData(event: any) {

    }
}
