import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Resposta} from '../../models/resposta';
import {Opcao} from 'app/shared/models/equipamentos/opcao';
import {EqClassificacao, EqClassificacaoResponse} from '../../models/equipamentos/eq-classificacao';
import {catchError} from 'rxjs/operators';
import {of, throwError} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EqClassificacaoService {
    readonly url = `${environment.apiEquipamentos}Classificacoes`;

    constructor(private http: HttpClient) {
    }

    getOpcoes() {
        return this.http.get<Resposta<Opcao[]>>(`${this.url}/opcoes`);
    }

    postClassificacao(modelo: EqClassificacao) {
        return this.http.post<Resposta<EqClassificacao>>(this.url, modelo);
    }

    getClassificacoes() {
        return this.http.get<Resposta<EqClassificacao[]>>(this.url).pipe(catchError(err => {
            console.error(err);
            return err.status === 404 ?
                of({objecto: [], statusCode: err.status, mensagem: ''} as Resposta<EqClassificacao[]>) : throwError(() => err);
        }));
    }

    getClassificacao(id: number) {
        return this.http.get<Resposta<EqClassificacaoResponse>>(`${this.url}/${id}`);
    }

    putClassificacao(modelo: EqClassificacao) {
        return this.http.post<Resposta<EqClassificacao>>(`${this.url}/${modelo.id}`, modelo);
    }

    deleteClassificacao(id: number) {
        return this.http.post<Resposta<EqClassificacao>>(`${this.url}/remover/${id}`, {});
    }
}
