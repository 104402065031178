import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Equipamento, EquipamentoResponse} from '../../models/equipamentos/equipamento';
import {Resposta} from '../../models/resposta';
import {EquipamentoFiltro} from '../../models/equipamentos/equipamento-filtro';
import {catchError} from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import {Armazem} from '../../models/artigos/armazem';
import {EqEstado} from '../../models/equipamentos/eq-estado';

@Injectable({
    providedIn: 'root'
})
export class EquipamentoService {
    url = `${environment.apiEquipamentos}Equipamentos`;

    constructor(private http: HttpClient) {
    }

    postEquipamento(modelo: Equipamento) {
        return this.http.post<Resposta<Equipamento>>(this.url, modelo);
    }

    getEquipamentos() {
        return this.http.get<Resposta<EquipamentoResponse[]>>(this.url);
    }

    getEquipamento(id: number) {
        return this.http.get<Resposta<EquipamentoResponse>>(`${this.url}/${id}`);
    }

    filtrarEquipamentos(filtro: EquipamentoFiltro) {
        return this.http.post<Resposta<EquipamentoResponse[]>>(`${this.url}/filtrar`, filtro)
            .pipe(catchError(err => {
                console.error(err);
                return err.status === 404 ?
                    of({objecto: [], statusCode: err.status, mensagem: ''} as Resposta<EquipamentoResponse[]>) : throwError(err);
            }));
        ;
    }

    putEquipamento(modelo: Equipamento) {
        return this.http.post<Resposta<Equipamento>>(`${this.url}/${modelo.id}`, modelo);
    }

    deleteEquipamento(id: number) {
        return this.http.post<Resposta<Equipamento>>(`${this.url}/remover/${id}`, {});
    }

    getArmazens() {
        return this.http.get<Resposta<Armazem[]>>(`${this.url}/armazens`);
    }

    getCombustiveis() {
        return this.http.get<Resposta<EqEstado[]>>(`${this.url}/combustiveis`);
    }

    getEmpresas() {
        return this.http.get<Resposta<any[]>>(`${this.url}/empresas`);
    }

    putEquipamentoLote(req: any) {
        return this.http.post<Resposta<Equipamento>>(`${this.url}/lote`, req);
    }
}
