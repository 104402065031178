import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'printWithBr'
})
export class PrintWithBrPipe implements PipeTransform {

 public transform(valor: any): any {
     if (typeof(valor) === 'string') {
         const frase = valor.split('\n');
         let fraseFormata = '';
         frase.forEach((item, index) => {
             if (index < frase.length - 2) {
                 fraseFormata += item + '<br/>';
             } else {fraseFormata += item; }
         });
         return fraseFormata;

     } else {
        return valor;
     }
  }

}
