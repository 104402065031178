import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AccaoEntidade} from '../../models/clientes/accao-entidade';
import {Entidades} from '../../models/clientes/entidades';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'accoes-multiplas-tabela',
    template: `
        <mat-card-title class="mb-0 border-b">
            <div class="max-height-45 flex-x-btw flex-y-center choice-sec" fxLayout="row wrap" fxLayout.xs="column">
                <div class="p-0 flex-y-center">
                    <button class="btn-filter bg-primary ml-1 mr-1" type="submit" *ngIf="podeFiltrar" (click)="aoFiltrar.emit(true)"
                            mat-raised-button color="primary" matTooltip="Pesquisar"
                            matTooltipClass="blue-400">
                        <mat-icon>search</mat-icon>
                    </button>

                    <button class="btn-filter bg-primary" mat-raised-button type="reset" *ngIf="podeLimpar" (click)="aoLimpar.emit(true)"
                            matTooltip="Limpar formulário"
                            matTooltipClass="blue-400">
                        <mat-icon>cancel</mat-icon>
                    </button>

                    <button type="button" *ngIf="podeImprimir" (click)="aoImprimir.emit(true)" mat-icon-button matTooltip="Imprimir"
                            matTooltipClass="yellow-900">
                        <mat-icon>local_printshop</mat-icon>
                    </button>
                    <!--                    -->
                    <button type="button" *ngIf="podeMudarLista" mat-icon-button title="" matTooltip="Mudar visualização da tabela"
                            matTooltipClass="red-A700">
                        <mat-icon>view_list</mat-icon>
                    </button>

                    <button type="button" *ngIf="podeMudarListaComMenu" [matMenuTriggerFor]="menuListView" mat-icon-button
                            title="Mudar visualização da tabela">
                        <mat-icon>{{icon}}</mat-icon>
                    </button>

                    <mat-menu #menuListView="matMenu">
                        <button type="button" mat-menu-item [class.bg-primary]="indiceTab === 1" (click)="mudarLista(1, 'list')">
                            <mat-icon>list</mat-icon>
                            <span>Lista regular</span>
                        </button>

                        <button type="button" mat-menu-item [class.bg-primary]="indiceTab === 2" (click)="mudarLista(2, 'view_list')">
                            <mat-icon>view_list</mat-icon>
                            <span>Lista detalhes</span>
                        </button>

                        <button type="button" mat-menu-item [class.bg-primary]="indiceTab === 3" (click)="mudarLista(3,'grid_view')">
                            <mat-icon>grid_view</mat-icon>
                            <span>Lista em cards</span>
                        </button>
                    </mat-menu>


                    <button type="button" *ngIf="podeExportarExcel" (click)="exportarExcel()" mat-icon-button
                            matTooltip="Exportar para Excel" matTooltipClass="green-900" class="img-button">
                        <img src="assets/images/icons/excel-icon-alt.svg"
                             style="border-radius: 0 !important; width: 20px !important; height: 20px !important;" alt=""/>
                    </button>

                    <button type="button" *ngIf="podeImportarExcel" mat-icon-button (click)="aoImportarExcel.emit()"
                            matTooltip="Importar de Excel" class="img-button">
                        <mat-icon>upload_file</mat-icon>
                    </button>

                    <button type="button" *ngIf="podeExportarPDF" (click)="exportarPDF()" mat-icon-button matTooltip="Exportar para PDF"
                            matTooltipClass="red-900" class="img-button">
                        <img src="assets/images/icons/pdf-icone-outline.svg"
                             style="border-radius: 0 !important; width: 20px !important; height: 20px !important;" alt=""/>
                    </button>
                    <button *ngIf="podeEnviarEmail" type="button" (click)="enviarEmail()" mat-icon-button matTooltip="Enviar e-mail"
                            matTooltipClass="teal-800">
                        <mat-icon class="text-primary">email</mat-icon>
                    </button>
                    <button type="button" *ngIf="podePagarDoc" (click)="pagarDocumento()" mat-icon-button
                            color="primary"
                            matTooltip="Pagar documentos"
                            matTooltipClass="teal-800"
                    >
                        <mat-icon>credit_card</mat-icon>
                    </button>
                    <button type="button" *ngIf="podeEliminar" mat-icon-button matTooltip="Remover" matTooltipClass="teal-800" color="warn"
                            (click)="remover()">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </div>
                <div class="pt-0 pr-05 py-6 flex flex-y-center">
                    <button type="button" *ngIf="mostrarBtnCriar" [ngClass]="{ desabilitar: podeCriarItem === false }" class="btn-filter"
                            mat-raised-button color="primary" routerLink="{{rotaItem}}">
                        Criar {{tituloBtn}}
                    </button>

                    <button type="button" *ngIf="mostrarBtnAdd" (click)="adicionar()" [ngClass]="{ desabilitar: podeAddItem === false }"
                            class="btn-filter" mat-raised-button color="primary">
                        {{tituloBtnAdd}}
                    </button>
                </div>
            </div>
        </mat-card-title>
    `
// tslint:disable-next-line:component-class-suffix
})
export class TabAccoesMultiplas implements OnChanges, OnInit {
    @Output() aoEnviarEmail: EventEmitter<any> = new EventEmitter();
    @Output() aoImprimir: EventEmitter<any> = new EventEmitter();
    @Output() aoDeletar: EventEmitter<any> = new EventEmitter();
    @Output() aoRemover: EventEmitter<any> = new EventEmitter();
    @Output() aoAdicionar: EventEmitter<any> = new EventEmitter();
    @Output() aoExportarExcel: EventEmitter<any> = new EventEmitter();
    @Output() aoImportarExcel: EventEmitter<any> = new EventEmitter();
    @Output() aoExportarPDF: EventEmitter<any> = new EventEmitter();
    @Output() aoNavegarRota: EventEmitter<any> = new EventEmitter();
    @Output() aoAvaliar: EventEmitter<any> = new EventEmitter();
    @Output() aoPagarDocumento: EventEmitter<any> = new EventEmitter();
    @Output() aoFiltrar: EventEmitter<any> = new EventEmitter();
    @Output() aoLimpar: EventEmitter<any> = new EventEmitter();
    @Output() aoMudarLista: EventEmitter<any> = new EventEmitter();

    @Input() podeEliminar: boolean;
    @Input() podeImprimir: boolean;
    @Input() podeExportarExcel: boolean;
    @Input() podeImportarExcel: boolean;
    @Input() podeExportarPDF: boolean;
    @Input() podeEnviarEmail: boolean;
    @Input() podePagarDoc: boolean;
    @Input() podeCriarItem: boolean;
    @Input() podeAddItem: boolean;
    @Input() podeMudarLista: boolean;
    @Input() podeFiltrar: boolean;
    @Input() podeLimpar: boolean;
    @Input() podeMudarListaComMenu: boolean;
    @Input() mostrarBtnCriar = true;
    @Input() mostrarBtnAdd = false;
    @Input() accaoEntidade: AccaoEntidade;
    @Input() listaAccoes: AccaoEntidade[] = [];
    @Input() podeInserirCLI;
    @Input() mostrarAvaliacao: boolean;
    @Input() entidade: Entidades;
    @Input() tituloBtn: string;
    @Input() tituloBtnAdd: string;
    @Input() rotaItem: string;
    @Input() indiceTab: number;
    icon = '';
    iconLista: any = {
        1: 'list',
        2: 'view_list',
        3: 'grid_view'
    };

    ngOnInit() {
        this.icon = this.iconLista[this.indiceTab];
    }

    enviarEmail() {
        this.aoEnviarEmail.emit(true);
    }

    pagarDocumento() {
        this.aoPagarDocumento.emit(true);
    }

    remover() {
        this.aoRemover.emit(true);
    }

    adicionar() {
        this.aoAdicionar.emit();
    }

    deletar() {
        this.aoDeletar.emit(true);
    }

    exportarExcel() {
        this.aoExportarExcel.emit(true);
    }

    exportarPDF() {
        this.aoExportarPDF.emit(true);
    }

    mudarLista(indice: number, icon: string) {
        this.icon = this.iconLista[indice];
        this.aoMudarLista.emit(indice);
    }

    navegar() {
        this.aoNavegarRota.emit(true);
    }

    ngOnChanges(changes: SimpleChanges) {
    }

}
